import React from 'react';

const Radio = ({
	name,
	id,
	label,
	formClass = '',
	labelClass = '',
	checked = false,
	disabled = false,
	type = 'radio',
	formText,
	...rest
}) => {
	return (
		<div className={`form-check ${formClass}`}>
			<input
				className='form-check-input'
				type={type}
				name={name}
				id={id}
				value={id}
				defaultChecked={checked}
				disabled={disabled}
				{...rest}
			/>
			<label className={`form-check-label ${labelClass}`} htmlFor={id}>
				{label}
			</label>
			{formText && <div className='form-text'>{formText}</div>}
		</div>
	);
};

export default Radio;
