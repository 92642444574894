import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../../common/form';

class P2Form extends Form {
	state = {
		data: {
			height: '',
			weight: '',
			country: '',
			state: '',
			address: '',
			suddenly: '',
			exercise: '',
			supplements: '',
		},
		errors: {},
	};

	schema = {
		height: Joi.string().required().label('Height'),
		weight: Joi.string().required().label('Weight'),
		country: Joi.string().required().label('Country'),
		state: Joi.string().required().label('State'),
		address: Joi.string().required().label('Address'),
		suddenly: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Suddenly'),
		exercise: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Exercise'),
		supplements: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Supplements'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { onPrevBtnClick, handleChange, options } = this.props;

		return (
			<form
				className='row row-cols-lg-4 row-cols-1 g-3 text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p2'),
						name: 'height',
						label: 'Height (cm)',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p2'),
						name: 'weight',
						label: 'Weight (kg)',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p2'),
						name: 'country',
						label: 'Country',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p2'),
						name: 'state',
						label: 'State',
						type: 'text',
					})}
				</div>
				<div className='col-lg-12'>
					{this.renderInput({
						extraHandler: () => handleChange('p2'),
						name: 'address',
						label: 'Address',
						type: 'text',
					})}
				</div>

				<div className='col-lg-12 mb-0'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Have you ever lost or gained weight suddenly?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p2'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'suddenly',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 my-0'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>Do you exercise regularly?</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p2'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'exercise',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 mt-0'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Do you use bodybuilding supplements?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p2'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'supplements',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>

				<div className='col-lg-12 text-center mt-5'>
					<button
						type='button'
						onClick={onPrevBtnClick}
						className='btn btn-my-navy me-2 me-lg-4'>
						<div className='d-flex align-items-center justify-content-center'>
							<i className='fas fa-arrow-left'></i>
							<span className='ms-2'>Previous</span>
						</div>
					</button>
					{this.renderButton(
						<div className='mx-3 d-flex align-items-center justify-content-center'>
							<span className='me-2'>Next</span>
							<i className='fas fa-arrow-right'></i>
						</div>,
						'btn btn-my-navy'
					)}
				</div>
			</form>
		);
	}
}

export default P2Form;
