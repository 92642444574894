import React, { useState } from 'react';
import { RiseLoader } from 'react-spinners';
import config from '../../../../config';
import useGoProResult from '../../../../hooks/useGoProResult';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import { GoProCard } from '../../ingredients/cards';

function Bonus() {
	const { initData } = useMain();
	const [goProResult] = useGoProResult();

	const normalizedData = goProResult;
	for (const i in normalizedData) normalizedData[i].no = Number(i) + 1;

	const [currentPage, setCurrentPage] = useState(1);
	const data = paginate(normalizedData, currentPage, config.tablePageSize);
	const [isFinished, setIsFinished] = useState(
		Date.now() >= initData.contests.gopro1.end
	);

	return (
		<>
			{goProResult ? (
				<GoProCard
					title={initData.contests.gopro1.name}
					data={data}
					itemsCount={normalizedData.length}
					pageSize={config.tablePageSize}
					currentPage={currentPage}
					onPageChange={setCurrentPage}
					date={initData.contests.gopro1.end}
					isFinished={isFinished}
					setIsFinished={setIsFinished}
				/>
			) : (
				<div className='h-50 d-flex justify-content-center align-items-center'>
					<RiseLoader
						speedMultiplier={0.8}
						loading={true}
						size={20}
						sizeUnit={'px'}
						color={'#7b8cff'}
					/>
				</div>
			)}
		</>
	);
}

export default Bonus;
