import { useEffect } from 'react';
import config from '../config';

export default function useDocumentTitle(titles) {
	useEffect(() => {
		let title = config.baseTitle;
		for (const i in titles) title += ` - ${titles[i]}`;
		document.title = title;
	}, [titles]);
}
