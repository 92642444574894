import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useMain from '../../../../hooks/useMain';
import DigitalWatch from '../../../common/digitalWatch';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';

const Contest = () => {
	const { initData } = useMain();
	const { end, start } = initData.contests.stars;
	const [isFinished, setIsFinished] = useState(Date.now() >= end);
	if (Date.now() < start) return null;

	return (
		<div className='row'>
			<div className='col-sm-12'>
				<Link to='/dashboard/bonus/stars'>
					<TextCard
						className='stars'
						text={
							<div className='row gy-1 text-white align-items-center'>
								<div className='offset-lg-1 col-lg-4 text-center'>
									<div className='position-relative pt-lg-5 d-none d-lg-inline-flex'>
										<Image
											className='light w-100'
											fileName='stars/frameDesktop.webp'
										/>
										<Image
											className='w-100 position-absolute top-50 start-50 translate-middle'
											fileName='stars/twinLarge.svg'
										/>
										<Image
											className='mainLogo position-absolute start-50 translate-middle'
											fileName='stars/mainLogo.svg'
										/>
									</div>
									<Image
										className='mainLogo d-inline-flex d-lg-none'
										fileName='stars/mainLogo.svg'
									/>
									<div className='position-relative d-inline-flex d-lg-none'>
										<Image
											className='light w-100'
											fileName='stars/frameMobile.webp'
										/>
										<Image
											className='w-100 position-absolute top-50 start-50 translate-middle'
											fileName='stars/twinSmall.svg'
										/>
									</div>
								</div>

								<div className='offset-lg-2 col-lg-4 text-center'>
									{isFinished ? (
										<>
											<h4>Tournament is finished!</h4>
											<h5>check the result</h5>
										</>
									) : (
										<div>
											<div className='fw-bold fs-4 mb-1 text-uppercase'>
												Remaining Time:{' '}
											</div>
											<DigitalWatch
												className='d-inline-block'
												onComplete={() => setIsFinished(true)}
												date={end}
											/>
										</div>
									)}
								</div>
							</div>
						}
					/>
				</Link>
			</div>
		</div>
	);
};

export default Contest;
