import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useWithdrawals() {
	const [withdrawals, setWithdrawals] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/withdrawals'];

		async function getWithdrawals() {
			try {
				if (
					!http.getCurrentUser() ||
					withdrawals ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getWithdrawReqs();
				if (isMounted()) setWithdrawals(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getWithdrawals();
	}, [isMounted, withdrawals, pathname]);
	return [withdrawals, setWithdrawals];
}
