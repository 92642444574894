import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useTickets from '../../hooks/useTickets';
import http from '../../services/httpService';
import Preloader from '../common/preloader';
import Spinner from '../common/spinner';

function Tickets(props) {
	useDocumentTitle(['Tickets']);
	const [isLoading, setIsLoading] = useState(false);
	const [tickets, setTickets] = useTickets();
	if (!tickets) return <Preloader />;

	const users = tickets.sort((a, b) =>
		a.ticket.date > b.ticket.date ? 1 : -1
	);

	const handleViewTicket = (ticket) =>
		props.history.push({
			pathname: '/ticketsContent',
			state: ticket,
		});

	const handleCloseTicket = async (_id) => {
		try {
			setIsLoading(true);
			const { data } = await http.closeTicket({ _id });
			toast.success(data.message);
			setTickets(data.result);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='container my-4'>
			<div className='row gy-5'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>Tickets</div>
				</div>
				<div className='col-12 text-center'>
					<div className='table-responsive'>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>#</th>
									<th>UID</th>
									<th>Email</th>
									<th>Ticket Date</th>
									<th>Department</th>
									<th>Priority</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{users.map((user, key) => (
									<tr key={key}>
										<td>{Number(key) + 1}</td>
										<td>{user.uid}</td>
										<td>{user.email}</td>
										<td>
											{new Date(
												user.ticket.date
											).toLocaleString()}
										</td>
										<td>{user.ticket.department}</td>
										<td>{user.ticket.priority}</td>
										<td>
											<button
												onClick={() =>
													handleViewTicket(user)
												}
												className='btn btn-primary btn-sm'>
												{isLoading ? (
													<Spinner
														content={'loading'}
													/>
												) : (
													'View'
												)}
											</button>
										</td>
										<td>
											<button
												onClick={() =>
													handleCloseTicket(user._id)
												}
												className='btn btn-danger btn-sm'>
												{isLoading ? (
													<Spinner
														content={'loading'}
													/>
												) : (
													'Close Ticket'
												)}
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Tickets;
