import React from 'react';
import Joi from 'joi-browser';
import Countdown from 'react-countdown';
import Form from '../../../common/form';

class TransferToOwnForm extends Form {
	state = {
		data: {
			transferType: this.props.options.find((o) => o.checked === true).id,
			amount: this.props.min,
			remarks: '',
			securityPassword: '',
		},
		errors: {},
		textareaChars: 0,
		fromWallet: undefined,
		walletBalance: 0,
		maxTransfer: 0,
		maxAmountForInput: 0,
		currentTransferType: undefined,
	};

	schema = {
		transferType: Joi.string().required().label('Transfer Type'),
		amount: Joi.number()
			.min(this.props.min)
			.max(10)
			.multiple(this.props.step)
			.required()
			.label('Amount'),
		remarks: Joi.string()
			.min(this.props.remarks.min)
			.max(this.props.remarks.max)
			.required()
			.label('Remarks'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	updateBalances = (option) => {
		const { wallets, cardLimit } = this.props;
		const from = option.from;

		const fromWallet = wallets[from].name;
		const walletBalance = this.props[`${from}_Balance`];
		const maxTransfer = option.id === 'CW/DW' ? walletBalance : cardLimit;
		const maxAmountForInput =
			option.id === 'CW/DW'
				? walletBalance
				: Math.min(cardLimit, walletBalance);
		const currentTransferType = option.id;

		this.setState({
			fromWallet,
			walletBalance,
			maxTransfer,
			maxAmountForInput,
			currentTransferType,
		});

		this.schema.amount = Joi.number()
			.min(this.props.min)
			.max(maxAmountForInput)
			.multiple(this.props.step)
			.required()
			.label('Amount');
	};

	textareaChange = ({ currentTarget: text }) => {
		const textareaChars = text.value.length;
		this.setState({ textareaChars });
	};

	transferTypeChanged = ({ currentTarget: pType }) => {
		const option = this.props.options.find((o) => o.id === pType.value);
		this.updateBalances(option);
	};

	doSubmit = () => this.props.onSubmit(this.state.data, 'own');

	componentDidMount() {
		const option = this.props.options.find((o) => o.checked === true);
		this.updateBalances(option);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.isSubmited === true &&
			prevProps.isSubmited !== this.props.isSubmited
		) {
			const state = { ...this.state };
			state.data = {
				transferType: this.props.options.find((o) => o.checked === true)
					.id,
				amount: this.props.min,
				remarks: '',
				securityPassword: '',
			};
			this.setState(state);
		}
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
		if (
			prevProps.currentDeposit_Balance !==
				this.props.currentDeposit_Balance ||
			prevProps.usdt_Balance !== this.props.usdt_Balance ||
			prevProps.activation_Balance !== this.props.activation_Balance
		) {
			const option = this.props.options.find(
				(o) => o.id === this.state.currentTransferType
			);
			this.updateBalances(option);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			remarks,
			hasSP,
			min,
			step,
			options,
			isLoading,
			isUWAWTransferAllowed,
			counterDate,
			onRequestTimerCompleted,
		} = this.props;

		const {
			textareaChars,
			fromWallet,
			walletBalance,
			maxTransfer,
			maxAmountForInput,
			currentTransferType,
		} = this.state;

		return (
			<form
				className='text-start'
				autoComplete='off'
				onChange={this.props.onFormChange}
				onSubmit={this.handleSubmit}>
				<div className='mb-4'>
					<div className='mb-1'>Choose Transfer Type:</div>
					<div className='row'>
						{options.map((o, key) => (
							<div key={key} className='col-sm-6'>
								{this.renderRadioInput({
									key,
									name: 'transferType',
									id: o.id,
									label: o.label,
									labelClass: 'fw-semibold',
									checked: o.checked,
									disabled: o.disabled,
									formText: o.text,
									extraHandler: this.transferTypeChanged,
								})}
							</div>
						))}
					</div>
				</div>
				<div className='mb-4'>
					{this.renderInput({
						name: 'amount',
						label: 'Transfer Amount',
						type: 'number',
						min,
						max: maxAmountForInput,
						step,
						formText: (
							<>
								<div>
									<b>{fromWallet}</b> Wallet Balance:{' '}
									<b>${walletBalance}</b>
								</div>
								<div>
									Minimum transfer amount is{' '}
									<b>${min.toFixed(2)}</b>
								</div>
								<div>
									Maximum transfer amount is{' '}
									<b>${maxTransfer.toFixed(2)}</b>
								</div>
								<div>
									Transfer amount multiplier is{' '}
									<b>${step.toFixed(2)}</b>
								</div>
							</>
						),
					})}
				</div>
				<div className='mb-4'>
					{this.renderTextArea({
						name: 'remarks',
						label: 'Remarks',
						rows: 2,
						formText: `${textareaChars} of maximum ${remarks.max} characters used.`,
						extraHandler: this.textareaChange,
					})}
				</div>
				{this.getSubmitComponent(
					currentTransferType,
					isUWAWTransferAllowed,
					counterDate,
					onRequestTimerCompleted,
					hasSP,
					isLoading
				)}
			</form>
		);
	}

	getSubmitComponent(
		currentTransferType,
		isUWAWTransferAllowed,
		counterDate,
		onRequestTimerCompleted,
		hasSP,
		isLoading
	) {
		const immediateTransfers = [
			'CW/DW',
			'CW/TW',
			'CW/DFW',
			'AW/TW',
			'AW/DFW',
			'UW/TW',
			'UW/DFW',
		];
		if (
			immediateTransfers.includes(currentTransferType) ||
			(currentTransferType === 'UW/AW' && isUWAWTransferAllowed)
		)
			return this.renderSubmitBtnWithSPass({
				divClass: 'mt-3',
				hasSP,
				isLoading,
				text: 'Transfer',
				loadingText: 'Transfering...',
			});

		return (
			<div className='text-center'>
				<div>Next UW to AW transfer will be available on:</div>
				<Countdown
					className='h3'
					date={counterDate}
					onComplete={onRequestTimerCompleted}
				/>
			</div>
		);
	}
}

export default TransferToOwnForm;
