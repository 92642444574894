import 'chart.js/auto';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const WithdrawChart = ({ data }) => {
	const { time, amount, rechargePointIndex } = data;
	const colors = {
		green: '#00c89b',
		red: '#dc3545',
	};
	let borderColor = colors.green;
	let backgroundColor = colors.green + '50';

	if (rechargePointIndex >= 0) {
		backgroundColor = new Array(amount.length);
		borderColor = new Array(amount.length);

		for (let i = 0; i < rechargePointIndex; i++) {
			borderColor[i] = colors.green;
			backgroundColor[i] = colors.green + '50';
		}

		for (let i = rechargePointIndex; i < backgroundColor.length; i++) {
			borderColor[i] = colors.red;
			backgroundColor[i] = colors.red + '50';
		}
	}

	const chartData = {
		labels: time,
		datasets: [
			{
				label: 'Request Amount (USDT)',
				backgroundColor,
				borderColor,
				borderWidth: '1',
				data: amount,
			},
		],
	};

	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
				labels: {
					color: '#000',
				},
			},
			title: {
				display: false,
				text: 'Withdraw Requests',
				color: '#000',
				font: {
					size: '20',
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => `${context.formattedValue} USDT`,
				},
			},
		},
		elements: {
			line: {
				tension: 0.3,
			},
		},
		interaction: {
			mode: 'index',
			intersect: false,
		},
		scales: {
			yAxis: {
				ticks: {
					color: '#000',
					labelString: 'Time',
					display: true,
				},
			},
			xAxis: {
				ticks: {
					color: '#000',
				},
			},
		},
	};

	return <Bar data={chartData} options={chartOptions} />;
};

export default WithdrawChart;
