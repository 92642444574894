import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../../common/form';

class P1Form extends Form {
	state = {
		data: {
			name: '',
			surname: '',
			fathersName: '',
			mothersName: '',
			nationalId: '',
			dateOfBirth: this.props.birthDate,
			placeOfBirth: '',
			gender: '',
			heirsCount: '',
			email: '',
			phone: '',
		},
		errors: {},
	};

	schema = {
		name: Joi.string().required().label('Name'),
		surname: Joi.string().required().label('Surname'),
		fathersName: Joi.string().required().label("Father's Name"),
		mothersName: Joi.string().required().label("Mother's Name"),
		nationalId: Joi.string().required().label('National ID'),
		dateOfBirth: Joi.string().required().label('Date Of Birth'),
		placeOfBirth: Joi.string().required().label('Place Of Birth'),
		gender: Joi.string()
			.valid(...this.props.genders)
			.required()
			.label('Gender'),
		heirsCount: Joi.number()
			.min(this.props.minHeirsCount)
			.max(this.props.maxHeirsCount)
			.required()
			.label('Heirs Count'),
		email: Joi.string().email().required().label('Email'),
		phone: Joi.string().required().label('Phone Number'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { genders, handleChange } = this.props;

		return (
			<form
				className='row row-cols-lg-3 row-cols-1 g-3 text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'name',
						label: 'Name',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'surname',
						label: 'Surname',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'fathersName',
						label: "Father's Name",
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'mothersName',
						label: "Mother's Name",
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'nationalId',
						label: 'National ID / Passport No.',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'dateOfBirth',
						label: 'Date Of Birth',
						type: 'date',
						disabled: true,
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'placeOfBirth',
						label: 'Place Of Birth',
						type: 'text',
					})}
				</div>
				<div className='col'>
					{this.renderSelectNormal({
						extraHandler: () => handleChange('p1'),
						name: 'gender',
						label: 'Gender',
						options: genders,
					})}
				</div>
				<div className='col'>
					{this.renderInput({
						extraHandler: () => handleChange('p3'),
						name: 'heirsCount',
						label: 'Heirs Count',
						type: 'number',
						min: this.props.minHeirsCount,
						max: this.props.maxHeirsCount,
						step: 1,
					})}
				</div>
				<div className='col-lg-6'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'email',
						label: 'Email',
						type: 'email',
					})}
				</div>
				<div className='col-lg-6'>
					{this.renderInput({
						extraHandler: () => handleChange('p1'),
						name: 'phone',
						label: 'Phone Number',
						type: 'text',
					})}
				</div>
				<div className='col-lg-12 text-center mt-5'>
					{this.renderButton(
						<div className='mx-3 d-flex align-items-center justify-content-center'>
							<span className='me-2'>Next</span>
							<i className='fas fa-arrow-right'></i>
						</div>,
						'btn btn-my-navy'
					)}
				</div>
			</form>
		);
	}
}

export default P1Form;
