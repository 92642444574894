import { useEffect, useState } from 'react';
import http from '../services/httpService';

// Utility function to check internet connection
const checkInternetConnection = () => {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = `https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png?${new Date().getTime()}`;
		img.onload = () => resolve(true);
		img.onerror = () => resolve(false);
	});
};

// Retry logic for internet connection check
const checkInternetWithRetries = async (attempts) => {
	for (let i = 0; i < attempts; i++) {
		const internetConnection = await checkInternetConnection();
		if (internetConnection) {
			return true;
		}
		await new Promise((resolve) => setTimeout(resolve, 1000));
	}
	return false;
};

// Error handling function
const handleRequestError = (error) => {
	if (error.response) {
		switch (error.response.status) {
			case 429:
				return { status: 'rate_limited' };
			case 400:
			case 401:
			case 403:
			case 404:
				return {
					status: 'client_error',
					details: error.response.status,
				};
			case 500:
			case 502:
			case 503:
			case 504:
				return {
					status: 'server_error',
					details: error.response.status,
				};
			case 0:
				return { status: 'cors_error' }; // CORS related error
			default:
				return {
					status: 'unknown_error',
					details: error.response.status,
				};
		}
	} else if (error.request) {
		if (error.message.includes('Network Error')) {
			return { status: 'offline' };
		}
		return { status: 'no_response' };
	} else if (error.code === 'ECONNABORTED') {
		return { status: 'timeout' };
	} else {
		return { status: 'error', details: error.message };
	}
};

// Enhanced server status check function
const checkServerStatus = async () => {
	const retries = 3;

	try {
		const internetConnection = await checkInternetWithRetries(retries);
		if (!internetConnection) {
			return { status: 'no_internet' };
		}

		const response = await http.checkHealth(5000);
		switch (response.status) {
			case 200:
				return { status: 'online' };
			case 503:
				return { status: 'maintenance' };
			default:
				return {
					status: 'online_with_errors',
					details: response.status,
				};
		}
	} catch (error) {
		return handleRequestError(error);
	}
};

// Custom hook to use connection status
const useConnection = () => {
	const [connection, setConnection] = useState({ status: 'checking' });

	useEffect(() => {
		const updateConnectionStatus = async () => {
			const { status, details } = await checkServerStatus();
			setConnection({ status, details });
		};

		updateConnectionStatus();
	}, []);

	return [connection];
};

export default useConnection;
