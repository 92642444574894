const Anchor = ({ content, href, target = '_blank', className, ...rest }) => {
	return (
		<a
			className={`defaultLink ${className ? className : null}`}
			href={href}
			target={target}
			rel='noreferrer noopener'
			{...rest}>
			{content}
		</a>
	);
};

export default Anchor;
