import { useEffect, useState } from 'react';

const useMediaQuery = () => {
	const queries = {
		xs: ['(min-width: 1px)', '(max-width: 575.98px)'],
		sm: ['(min-width: 576px)', '(max-width: 767.98px)'],
		md: ['(min-width: 768px)', '(max-width: 991.98px)'],
		lg: ['(min-width: 992px)', '(max-width: 1199.98px)'],
		xl: ['(min-width: 1200px)', '(max-width: 1399.98px)'],
		xxl: ['(min-width: 1400px)', '(max-width: 500000px)'],
	};

	const getCurrentSize = () =>
		Object.keys(queries).find(
			(s) =>
				window.matchMedia(queries[s][0]).matches &&
				window.matchMedia(queries[s][1]).matches
		);

	const [mediaQuery, setMediaQuery] = useState(getCurrentSize());

	const resizeHandler = () => setMediaQuery(getCurrentSize());

	useEffect(() => window.addEventListener('resize', resizeHandler));
	useEffect(() => window.addEventListener('change', resizeHandler));

	return [mediaQuery];
};

export default useMediaQuery;
