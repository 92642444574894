import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import getCard from '../../../../utils/getCard';
import { FlippingDebit, TextCard } from '../../ingredients/cards';
import PurchasePackageForm from './purchasePackageForm';

const PurchasePackage = () => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [card, setCard] = useState(
		getCard(
			(userData.currentPackageValue + userData.minMMPPurchase) / 1e6,
			initData
		)
	);
	const [packageValue, setPackageValue] = useState(
		(userData.currentPackageValue + userData.minMMPPurchase) / 1e6
	);
	const userCard = initData.cards[card];

	const handleAmountChange = ({ currentTarget: amount }) => {
		const total = Number(amount.value) + userData.currentPackageValue / 1e6;
		setCard(getCard(total, initData));
		setPackageValue(total);
	};

	const handlePurchase = async (formData) => {
		if (!formData.isOverallBalanceEnough)
			return setErrors({
				amount: 'Not enough balance',
			});
		try {
			const { amount, purchaseType, securityPassword } = formData;
			setIsLoading(true);
			const { data } = await http.purchase({
				amount: Number(amount),
				purchaseType,
				securityPassword,
			});
			onUserDataChanged(data.data);
			toast.success(data.message);
			setPackageValue(
				data.data.currentPackageValue / 1e6 + initData.minPurchase
			);
			setIsSubmited(true);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='row'>
			<div className='col-12'>
				<TextCard
					title={''}
					titleClass={''}
					text={
						<div className='row mt-2 mb-4'>
							<div className='col-lg-4'>
								<div className='text-center my-4 fs-4'>
									{card.toUpperCase()} Diamond Debit Card:{' '}
									<br /> ( $ {userCard.min / 1e6} - ${' '}
									{userCard.max / 1e6} )
								</div>
								<FlippingDebit
									card={card}
									text={userData.bemchain.uid}
								/>

								<div className='mx-sm-4'>
									<div className='d-flex justify-content-between mt-4 fs-6'>
										<span>Monthly ROI Percentage:</span>
										<span>{userCard.percentage}%</span>
									</div>
									<hr className='my-2' />
									<div className='d-flex justify-content-between fs-6'>
										<span>Current Package Value:</span>
										<span>
											{userData.currentPackageValue / 1e6}
											$
										</span>
									</div>
									<hr className='my-2' />
									<div className='d-flex justify-content-between fs-6'>
										<span>
											Package Value After Purchase:
										</span>
										<span>{packageValue}$</span>
									</div>
								</div>
							</div>
							<div className='col-lg-6 offset-lg-1 mt-4'>
								<PurchasePackageForm
									onFormChange={() => setIsSubmited(false)}
									isSubmited={isSubmited}
									errors={errors}
									min={userData.minMMPPurchase / 1e6}
									max={
										initData.maxPurchase -
										userData.currentPackageValue / 1e6
									}
									step={initData.stepPurchase}
									isLoading={isLoading}
									hasSP={userData.hasSP}
									options={initData.purchaseOptions}
									activationBalance={
										userData.wallets.activation.available /
										1e6
									}
									discountBalance={
										userData.wallets.discount.available /
										1e6
									}
									onAmountChange={handleAmountChange}
									onSubmit={handlePurchase}
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default PurchasePackage;
