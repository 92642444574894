import { useEffect, useState } from 'react';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useBlogDashboard(_id) {
	const [blog, setBlog] = useState(null);
	const isMounted = useIsMounted();

	useEffect(() => {
		async function getBlogs() {
			try {
				setBlog(null);
				if (!http.getCurrentUser()) return;
				if (!_id) return setBlog('notFound');
				const { data } = await http.getBlogDashboard(_id);
				if (isMounted()) setBlog(data);
			} catch (error) {
				console.log('error :>> ', error);
				if (isMounted()) setBlog('notFound');
			}
		}
		getBlogs();
	}, [isMounted, _id]);
	return [blog];
}
