import React from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import TransferToOwnForm from './transferToOwnForm';

const TransferToOwnTab = ({
	isLoading,
	ownErrors,
	onSubmit,
	onFormChange,
	isSubmited,
}) => {
	const { userData, initData, onUserDataChanged } = useMain();
	const isUWAWTransferAllowed =
		Date.now() > initData.UWtoAW_Gap + userData.lastUWAWTransfer;
	const counterDate = initData.UWtoAW_Gap + userData.lastUWAWTransfer;

	const getCardLimit = () => {
		const card =
			!userData.isUserActive && userData.lastCard
				? userData.lastCard
				: userData.totalCard;
		return initData.cards[card].limit / 1e6;
	};

	const handleRequestTimerCompleted = async () => {
		try {
			const { data } = await http.getOnlyMe();
			onUserDataChanged(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div
			className='tab-pane fade show active'
			id='own-tab-pane'
			role='tabpanel'
			aria-labelledby='own-tab'
			tabIndex='0'>
			<div className='row p-4'>
				<div className='col-sm-8 offset-sm-2 col-12'>
					<TransferToOwnForm
						isSubmited={isSubmited}
						onFormChange={onFormChange}
						errors={ownErrors}
						isLoading={isLoading}
						onSubmit={onSubmit}
						onRequestTimerCompleted={handleRequestTimerCompleted}
						counterDate={counterDate}
						isUWAWTransferAllowed={isUWAWTransferAllowed}
						wallets={initData.wallets}
						currentDeposit_Balance={
							userData.wallets.currentDeposit.available / 1e6
						}
						usdt_Balance={userData.wallets.usdt.available / 1e6}
						activation_Balance={
							userData.wallets.activation.available / 1e6
						}
						cardLimit={getCardLimit()}
						hasSP={userData.hasSP}
						min={initData.minTransfer}
						step={initData.stepTransfer}
						options={initData.ownTransferOptions}
						remarks={initData.remarks}
					/>
				</div>
			</div>
		</div>
	);
};

export default TransferToOwnTab;
