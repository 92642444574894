import React, { useState } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useKYCRequests from '../../hooks/useKYCRequests';
import Preloader from '../common/preloader';
import useMain from './../../hooks/useMain';

function KYC(props) {
	useDocumentTitle(['KYC']);
	const { initData } = useMain();
	const { requests } = useKYCRequests();
	const [currentTab, setCurrentTab] = useState(0);

	if (!requests || !initData.verificationConfig) return <Preloader />;
	const { verificationConfig } = initData;
	const levels = Object.keys(verificationConfig);
	levels.pop();
	const content = requests.filter((r) => r.verification.level === `level_${currentTab}`);

	const handleTabClick = (l) => setCurrentTab(verificationConfig[l].level);
	const handleViewRequest = (request) =>
		props.history.push({
			pathname: `/kyc/${
				verificationConfig[`level_${currentTab + 1}`].requirements.form
			}Review`,
			state: request,
		});

	return (
		<div className='container my-4'>
			<div className='row gy-5 mb-5'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>KYC Requests</div>
				</div>
				<div className='col-12'>
					<div className='d-flex flex-wrap justify-content-between align-items-center'>
						{levels.map((l, key) => (
							<div
								key={key}
								onClick={() => handleTabClick(l)}
								className={`mb-4 position-relative btn btn-my-${
									Number(key) === currentTab ? 'green' : 'blue'
								}`}>
								Level {Number(l.replace('level_', '')) + 1} Requests
								<span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
									{requests.filter((r) => r.verification.level === l).length}
								</span>
							</div>
						))}
					</div>
				</div>
				<div className='col-12 text-center'>
					<div className='table-responsive'>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>#</th>
									<th>UID</th>
									<th>Email</th>
									<th>Request Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{content.map((user, key) => (
									<tr key={key}>
										<td>{Number(key) + 1}</td>
										<td>{user.uid}</td>
										<td>{user.email}</td>
										<td>
											{new Date(
												user.verification.lastRequest.date
											).toLocaleString()}
										</td>
										<td>
											<button
												onClick={() => handleViewRequest(user)}
												className='btn btn-my-blue btn-sm'>
												View
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default KYC;
