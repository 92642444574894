import React from 'react';

const TextArea = ({ name, label, rows, error, formText, ...rest }) => {
	return (
		<div className='form-group'>
			<label htmlFor={name}>{label}</label>
			<textarea
				{...rest}
				name={name}
				id={name}
				rows={rows}
				className='form-control'></textarea>
			{error && <div className='alert alert-danger'>{error}</div>}
			{formText && <div className='form-text mb-1'>{formText}</div>}
		</div>
	);
};

export default TextArea;
