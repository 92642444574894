import React, { useContext } from 'react';
import Hash from './../../common/hash';
import MainContext from './../../../context/mainContext';

const TransactionsTable = ({ txs }) => {
	const { theme } = useContext(MainContext);
	const hashColor = theme === 'dark' ? 'cyan' : 'blue';

	return (
		<div className='table-responsive'>
			<table className='table table-sm table-borderless myTable text-center'>
				<thead>
					<tr>
						<th>no.</th>
						<th>Date</th>
						<th>Amount</th>
						<th>Type</th>
						<th>Transaction</th>
					</tr>
				</thead>
				<tbody>
					{txs.map((tx, key) => (
						<tr key={key}>
							<td>{tx.no}</td>
							<td>{new Date(tx.date).toLocaleString()}</td>
							<td>{tx.amount}</td>
							<td>{tx.action.toUpperCase()}</td>
							<td>
								<Hash hash={tx.hash} color={hashColor} />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TransactionsTable;
