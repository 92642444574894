import React, { useState } from 'react';
import Viewer from 'react-viewer';
import Image from './image';

function ImgViewer({ height, path, src }) {
	const [visible, setVisible] = useState(false);
	return (
		<>
			<Image
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setVisible(true);
				}}
				height={height}
				fileName={path}
			/>
			<Viewer
				noToolbar
				visible={visible}
				onClose={() => {
					setVisible(false);
				}}
				images={[
					{
						src: src || path.replace('.png', '.jpg'),
						alt: 'view_img',
					},
				]}
			/>
		</>
	);
}

export default ImgViewer;
