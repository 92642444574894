import React from 'react';
import useMain from '../../../../../hooks/useMain';
import P1Form from './p1Form';

const P1 = ({ onNextBtnClick, onFieldChange }) => {
	const { userData, initData } = useMain();

	const handleSubmit = (formData) =>
		onNextBtnClick({ name: 'p1', data: formData });

	return (
		<div className='row mb-5'>
			<div className='col-lg-8 offset-lg-2 col-12'>
				<div className='mb-1'>Page 1</div>
				<div className='fs-4 fw-bold mb-3'>Personal Information</div>
				<P1Form
					genders={initData.mmiConfig.gender}
					birthDate={new Date(userData.mmi.profile.birthDate)
						.toISOString()
						.substring(0, 10)}
					minHeirsCount={initData.mmiConfig.contract.heir.min}
					maxHeirsCount={initData.mmiConfig.contract.heir.max}
					handleChange={onFieldChange}
					onSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default P1;
