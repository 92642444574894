import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import http from '../../services/httpService';

const ProtectedRouteWithProp = ({
	path,
	component: Component,
	render,
	protection,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				const user = http.getCurrentUser();
				if (!user)
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location },
							}}
						/>
					);
				if (user && !user[protection]) {
					http.removeJwt();
					window.location = '/accessDenied';
				}

				return Component ? <Component {...props} /> : render(props);
			}}
		/>
	);
};

export default ProtectedRouteWithProp;
