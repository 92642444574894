import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useBlog from '../../../hooks/useBlog';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useMain from '../../../hooks/useMain';
import http from '../../../services/httpService';
import jsonToFormData from '../../../utils/jsonToFormData';
import Preloader from '../../common/preloader';
import BlogForm from './blogForm';

function Edit(props) {
	useDocumentTitle(['Edit Blog']);
	const { onResetBlogs, initData, onUserDataChanged } = useMain();
	const { state } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const _id = state && state._id ? state._id : '';
	const [blog] = useBlog(_id);

	const handleSubmit = async (formState) => {
		try {
			setIsLoading(true);
			const { data, images } = formState;
			let response;
			if (data.img === 'same') {
				response = await http.editBlog({
					_id,
					title: data.title,
					description: data.description,
					tags: data.tags,
					domain: data.domain,
					publish: data.publish,
					story: data.story,
				});
			} else {
				const formData = jsonToFormData({
					title: data.title,
					description: data.description,
					tags: data.tags,
					domain: data.domain,
					publish: data.publish,
					story: data.story,
				});
				formData.append(
					'img',
					images.img.length > 0 ? images.img[0].file : ''
				);
				response = await http.editBlogImg(formData, _id);
			}
			setIsLoading(false);
			onUserDataChanged({ blogs: response.data.blogs });
			onResetBlogs();
			props.history.push(`/blogs`);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex.response && (ex.response.status === 400 || 403)) {
				console.log(ex.response);
				toast.error(ex.response.data);
			}
		}
	};

	if (!_id || blog === 'notFound') return <Redirect to={'/blogs'} />;
	if (!initData.blogsConfig || !blog) return <Preloader />;
	return (
		<div className='container py-5'>
			<div className='row'>
				<h1 className='text-center mb-3'>Edit Blog</h1>
				<div className='card myCards'>
					<div className='card-body'>
						<BlogForm
							titleConfig={initData.blogsConfig.title}
							descriptionConfig={initData.blogsConfig.description}
							tagsConfig={initData.blogsConfig.tags}
							domainConfig={initData.blogsConfig.domains}
							maxFileSize={initData.blogsConfig.maxFileSize}
							acceptType={initData.blogsConfig.acceptType}
							blog={blog}
							onSubmit={handleSubmit}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Edit;
