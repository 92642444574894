import React from 'react';
import http from '../../services/httpService';

class Logout extends React.Component {
	componentDidMount() {
		http.logout();
	}

	render() {
		return null;
	}
}

export default Logout;
