import React from 'react';
import useMain from '../../../hooks/useMain';

const MMIDownlinesTable = ({ users, onSort, sortColumn }) => {
	const { initData } = useMain();
	const { paymentMethod } = initData.mmiConfig;

	const headers = [
		{ label: 'No', path: 'no' },
		{ label: 'ID' },
		{ label: 'Email' },
		{ label: 'MMI Date', path: 'mmiDate' },
		{ label: 'Payment Method' },
		{ label: 'Insurance No' },
		{ label: 'Primary Amount' },
		{ label: 'Paid Installments' },
		{ label: 'Overdue Installments' },
		{ label: 'Level', path: 'level' },
		{ label: 'Position' },
	];

	const getSortIcon = (column) => {
		if (column.path !== sortColumn.path) return null;
		if (sortColumn.order === 'asc')
			return <i className='ms-1 text-success fas fa-sort-up'></i>;
		return <i className='ms-1 text-danger fas fa-sort-down' />;
	};

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						{headers.map((h, key) => (
							<th
								key={key}
								onClick={() => onSort(h.path)}
								style={h.path ? { cursor: 'pointer' } : {}}>
								<div className='d-flex justify-content-center align-items-center'>
									{h.label} {getSortIcon(h)}
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{users.map((user, key) => (
						<tr key={key}>
							<td>{user.no}</td>
							<td>{user.id}</td>
							<td>{user.email}</td>
							<td>
								{user.mmiDate
									? new Date(
											user.mmiDate
									  ).toLocaleDateString()
									: ''}
							</td>
							<td>
								{
									paymentMethod.find(
										(m) =>
											m.duration ===
											user.mmi.paymentMethod
									).content
								}
							</td>
							<td>{user.mmi.code}</td>
							<td>{`$ ${user.mmiStartAmount / 1e6}`}</td>
							<td>
								{
									user.mmi.installments.filter(
										(i) => i.isPaid
									).length
								}
							</td>
							<td>
								{
									user.mmi.installments.filter(
										(i) =>
											!i.isPaid &&
											Date.now() >
												new Date(i.dueDate).getTime()
									).length
								}
							</td>
							<td>{user.level}</td>
							<td>{user.position}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default MMIDownlinesTable;
