import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../common/image';

const MMI = () => (
	<div className='mmi py-5'>
		<div className='container'>
			<div className='row align-items-center'>
				<div className='col-md-6'>
					<h5 className='text-uppercase text-purple'>What is</h5>
					<h1 className='text-dark'>MMI?</h1>
					<p className='text-dark'>
						<strong>MMI Insurance</strong> arm, as a robust branch
						under the BEM Foundation, presents a wide array of
						investment opportunities for individuals. This insurance
						provides diverse options for investing and saving,
						ranging from job creation, dowry, housing, education
						expenses to early retirement support and building dollar
						reserves. These versatile options, coupled with
						insurance coverage for any cause of death, accidental
						death, retirement, and lifetime annuity, make it a
						comprehensive and customizable platform for customers.{' '}
						<br />
						<br />
						<strong>MMI Insurance</strong>, offering features such
						as choosing a contract duration from 3 to 30 years,
						selecting a minimum monthly insurance premium of $5, and
						opting for desired payment methods (1 month, 3 months, 6
						months, and 1 year), provides a flexible and adjustable
						platform for investors. With the ability to choose
						heirs, automatic annual increases in insurance premiums
						to combat inflation, and preserving the value of money
						through saving in dollars, <strong>MMI</strong> allows
						investors aged from birth to 60 to receive coverage up
						to $100,000. It also offers the flexibility of
						withdrawing savings at once or receiving a pension,
						among other options. <br />
						<br />
						By paying $100 a month and compounding at 10% annually,
						you can save $200,000 over 30 years with{' '}
						<strong>MMI</strong>. In addition to receiving insurance
						coverage, you have the option to receive a lump sum of 2
						billion dollars saved at once or a monthly pension of
						$200,000. Insure yourself and secure your future with{' '}
						<strong>MMI</strong> – a platform that not only provides
						extensive coverage but also offers substantial savings
						and retirement benefits, ensuring a stable and secure
						future. <br />
					</p>
					<Link to='/mmi' className='btn btn-my-purple me-3 mb-4'>
						Read More
					</Link>
				</div>
				<div className='col-md-6'>
					<Image className='w-100' fileName='home/mmi.png' />
				</div>
			</div>
		</div>
	</div>
);

export default MMI;
