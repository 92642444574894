import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../../common/form';

class P4Form extends Form {
	state = {
		data: {
			military: '',
			height: '',
			voltage: '',
			motorcycle: '',
			mine: '',
			risk: '',
			addiction: '',
			smoke: '',
			hookah: '',
			alcohol: '',
			drugs: '',
		},
		errors: {},
	};

	schema = {
		military: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Military'),
		height: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Height'),
		voltage: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Voltage'),
		motorcycle: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Motorcycle'),
		mine: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Mine'),
		risk: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Risk'),
		addiction: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Addiction'),
		smoke: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Smoke'),
		hookah: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Hookah'),
		alcohol: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Alcohol'),
		drugs: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Drugs'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { onPrevBtnClick, handleChange, options } = this.props;

		return (
			<form
				className='row text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-lg-6 col-12'>
					<div className='row-cols-1 g-3'>
						<div className='col-lg-12'>
							<div className='fs-4 fw-bold my-3'>Job Status</div>
						</div>

						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Are you in the military?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'military',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you work at height?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'height',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you work with high voltage electricity?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'voltage',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you use a motorcycle?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'motorcycle',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Is your job high risk?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'risk',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you work or have you ever worked in a
									mine?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'mine',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-12'>
					<div className='row-cols-1 g-3'>
						<div className='col-lg-12'>
							<div className='fs-4 fw-bold my-3'>Smoking</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you have an addiction?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'addiction',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>Do you smoke?</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'smoke',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>Do you use hookah?</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'hookah',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you drink alcohol?
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'alcohol',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
						<div className='col'>
							<div className='d-flex flex-wrap'>
								<span className='me-3'>
									Do you use other drugs?{' '}
								</span>
								<span>
									{options.map((o, key) =>
										this.renderRadioInput({
											extraHandler: () =>
												handleChange('p4'),
											formClass: 'mb-0 form-check-inline',
											key,
											name: 'drugs',
											id: o.id,
											label: o.label,
											checked: o.checked,
											disabled: o.disabled,
										})
									)}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-12 text-center mt-5'>
					<button
						type='button'
						onClick={onPrevBtnClick}
						className='btn btn-my-navy me-2 me-lg-4'>
						<div className='d-flex align-items-center justify-content-center'>
							<i className='fas fa-arrow-left'></i>
							<span className='ms-2'>Previous</span>
						</div>
					</button>
					{this.renderButton(
						<div className='mx-3 d-flex align-items-center justify-content-center'>
							<span className='me-2'>Next</span>
							<i className='fas fa-arrow-right'></i>
						</div>,
						'btn btn-my-navy'
					)}
				</div>
			</form>
		);
	}
}

export default P4Form;
