import React from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class SuspendForm extends Form {
	state = {
		data: {
			uid: '',
		},
		errors: {},
	};

	schema = {
		uid: Joi.string()
			.alphanum()
			.min(8)
			.max(10)
			.lowercase()
			.required()
			.label("Users's BC"),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidMount() {
		const state = { ...this.state };
		const { domains, options } = this.props;
		for (const i in domains) {
			const domain = domains[i];
			state.data[domain.id] = '';
			this.schema[domain.id] = Joi.string()
				.valid(...options.map((o) => o.id))
				.required()
				.label(domain.label);
		}
		this.setState(state);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			const state = { ...this.state };
			const keys = Object.keys(state.data);
			for (const i in keys) state.data[keys[i]] = '';
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, domains, options } = this.props;
		return (
			<form
				className='row text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-12 mb-3'>
					{this.renderInput({
						name: 'uid',
						label: "Enter Users's BC",
					})}
				</div>

				{domains.map((d, key) => (
					<div key={key} className='col-12 mb-3'>
						<span className='me-3'>{d.label}</span>
						{options.map((o, k) =>
							this.renderRadioInput({
								formClass: 'mb-0 form-check-inline',
								key: k,
								name: d.id,
								id: o.id,
								label: o.label,
								checked: o.checked,
								disabled: o.disabled,
							})
						)}
					</div>
				))}
				<div className='col-12 mb-3 text-center'>
					{this.renderButton(
						'Submit',
						`btn btn-my-navy`,
						isLoading && <Spinner content='Loading...' />
					)}
				</div>
			</form>
		);
	}
}

export default SuspendForm;
