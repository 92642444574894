import React from 'react';
import Tree from 'react-d3-tree';
import useCenteredTree from '../../../../hooks/useCenteredTree';
import useMain from '../../../../hooks/useMain';
import Preloader from '../../../common/preloader';

const NetworkView = () => {
	const { userTree } = useMain();
	const [dimensions, translate, containerRef] = useCenteredTree();
	const containerStyles = {
		width: '100%',
		height: '100%',
	};

	if (!userTree) return <Preloader />;
	return (
		<div style={containerStyles} ref={containerRef}>
			<Tree
				data={userTree}
				initialDepth={undefined}
				dimensions={dimensions}
				translate={translate}
				transitionDuration={0}
				zoom={0.1}
				orientation='vertical'
			/>
		</div>
	);
};

export default NetworkView;
