import React from 'react';
import { Link } from 'react-router-dom';
import Image from './../common/image';
import Shadow from './shadow';

function Risk(props) {
	return (
		<>
			<div className='bg-white'>
				<Shadow />
				<div className='container mb-5'>
					<div className='row align-items-center'>
						<div className='col-md-6 py-5'>
							<h1 className='text-purple'>Risk Management</h1>
							<p>
								The MMP WORLD project is a financial platform
								that operates with an official permit and
								strictly adheres to every law in the blockchain
								field. To ensure the security of users'
								investments, the project employs reliable risk
								management solutions. Risk management is an
								essential aspect of financial planning that
								involves identifying, analyzing, and evaluating
								potential risks and taking appropriate measures
								to mitigate them. The MMP WORLD project employs
								various risk management strategies, including
								diversification of investments, comprehensive
								due diligence, and continuous monitoring of
								investment portfolios. By employing these
								strategies, the project aims to minimize the
								impact of adverse events on investors'
								portfolios and ensure the long-term stability
								and profitability of its investment offerings.
							</p>
							<Link
								to={'/risks'}
								className={`btn btn-my-blue me-3`}>
								Read More
							</Link>
						</div>
						<div className='col-md-6'>
							<Image
								className={'w-100'}
								fileName={'home/risks.jpg'}
							/>
						</div>
					</div>
				</div>

				<Shadow />
			</div>
		</>
	);
}

export default Risk;
