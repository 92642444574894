import React from 'react';
import useMain from '../../../../hooks/useMain';
import Image from '../../../common/image';
import CoversTable from './coversTable';
import DownloadDashboard from './pdf/DownloadDashboard';

const InsuranceDashboard = () => {
	const { userData } = useMain();
	const { mmi } = userData;

	return (
		<div className='card myCards'>
			<div className='row my-4'>
				<div className='col-12 text-center'>
					<div className='fs-3'>My MMI Dashboard</div>
				</div>
			</div>
			<div className='row p-4'>
				<div className='col-lg-10 offset-lg-1 text-center'>
					{mmi.isActive ? (
						<>
							<CoversTable table={mmi.covers} />
							<div className='text-start'>
								<div className='mb-2'>
									<b>
										Guaranteed participation period profit:
									</b>
									<div>
										The guaranteed investment return rate by
										MMI, calculated and paid to investors
										under any circumstances, has been set at
										10%. This rate is on account and the
										profit from the participation period is
										added to it each year.
									</div>
								</div>

								<div className='mb-2'>
									<b>
										Participation period profit in benefits:{' '}
									</b>
									<div>
										The allocated profit to investors is
										calculated and publicly announced every
										January. We calculate the difference in
										excess between this profit and the
										guaranteed profit for all investors, and
										it is added to their accumulated profit
										for compound interest.
									</div>
								</div>
								<div>
									<b>Note:</b>
									<div>
										The basis for long-term investment
										returns in the MMI plan is daily
										interest-bearing investments. Therefore,
										timely installment payments, paying
										installments sooner, or delaying
										installment payments will directly
										impact the total returns at the end of
										each MMI investor's investment.
									</div>
								</div>
							</div>
							<DownloadDashboard
								className='mt-4'
								title='MY MMI DASHBOARD'
								covers={mmi.covers}
								fileName={mmi.code}
							/>
						</>
					) : (
						<div className='text-center my-5'>
							<Image height='180px' fileName='icons/noData.svg' />
							<h4 className='mt-4'>MMI is not activated yet.</h4>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InsuranceDashboard;
