import React from 'react';

const WhatIsKYC = () => {
	return (
		<>
			<div className='fw-bold fs-5 my-3'>What is KYC?</div>
			<div>
				KYC (Know Your Customer) is a process used by businesses, especially financial
				institutions, to verify the identity of their clients. This involves collecting and
				analyzing information such as identification documents (e.g., passports, driver's
				licenses), proof of address, and financial history. The purpose of KYC is to ensure
				that customers are who they claim to be and to assess their potential risks in terms
				of illegal activities, such as money laundering or fraud.
			</div>
			<div className='my-3'>
				KYC is important because it helps prevent financial crimes and enhances the overall
				security of financial systems. By verifying customer identities and monitoring their
				transactions, institutions can detect and prevent suspicious activities. This not
				only protects the institutions from potential legal and financial repercussions but
				also builds trust with their customers, ensuring a safer and more transparent
				financial environment. Moreover, regulatory compliance with KYC requirements helps
				institutions avoid penalties and maintain their reputation in the industry.
			</div>
			<div className='my-3'>
				Customer authentication requirements are only one part of a broader term called
				anti-money laundering (AML) laws. AML includes a wide range of regulatory processes
				designed to prevent money laundering. KYC is just one step of the AML process, which
				includes identity verification and due diligence.
			</div>
			<div className='my-3'>
				The Ministry of Trade of the Republic of Turkey (Türkiye Cumhuriyeti Ticaret
				Bakanlığı) has issued a notification requiring the MMP World project to conduct the
				KYC process for its customers by AML regulations.
			</div>
			<div className='my-3'>
				This authentication is to clarify the origin and destination of the transferred
				money, and the required documents may change in the future according to the opinion
				of the Turkish Ministry of Trade.
			</div>
		</>
	);
};

export default WhatIsKYC;
