const getCard = (amount, { maxPurchase, cards }) => {
	if (amount >= maxPurchase) return 'royal';
	const cardKeys = Object.keys(cards);
	const label = cardKeys.find((c) => {
		return amount < cards[c].max / 1e6 && amount >= cards[c].min / 1e6;
	});
	return label || 'white';
};

export default getCard;
