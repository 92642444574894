import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useDownlines() {
	const [userDownlines, setUserDownlines] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();
	useEffect(() => {
		const allowed = [
			'/dashboard/genealogy/downlines',
			'/dashboard/genealogy/sponsorGenealogy',
			'/dashboard/mmi/trackingInstallments',
		];

		async function getUserData() {
			try {
				if (
					!http.getCurrentUser() ||
					userDownlines ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getMyDownlines();
				if (isMounted()) {
					setUserDownlines(data);
				}
			} catch (error) {
				console.log('error :>> ', error);
				http.logout();
			}
		}

		getUserData();
	}, [isMounted, userDownlines, pathname]);

	return [userDownlines];
}
