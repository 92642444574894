import React from 'react';
import useMain from '../../../../hooks/useMain';
import Image from '../../../common/image';
import Active from './contract/active';
import Need from './contract/need';
import Pages from './contract/pages';

const InsuranceContract = () => {
	const { userData } = useMain();
	const { mmi } = userData;

	const years = new Date(mmi.activationDate).getFullYear();
	const completionDate = new Date(
		new Date(mmi.activationDate).setFullYear(years + mmi.contractPeriod)
	).toLocaleString();

	return (
		<div className='card myCards'>
			{mmi.isActive ? (
				<>
					<div className='row gy-4 my-4'>
						<div className='col-12 text-center'>
							<div className='fs-2'>My MMI Serivces Contract</div>
						</div>
						<div className='col-12 d-flex justify-content-center'>
							<div className='table-responsive'>
								<table
									width='100%'
									className='table table-bordered table-sm'>
									<tbody className='d-none d-lg-block'>
										<tr>
											<th width='20%'>
												Insurance Policy Number:
											</th>
											<td width='30%'>{mmi.code}</td>
											<th width='20%'>Issue Date:</th>
											<td width='30%'>
												{new Date(
													mmi.activationDate
												).toLocaleString()}
											</td>
										</tr>
										<tr>
											<th>Contract Period:</th>
											<td>{mmi.contractPeriod} years</td>
											<th>Completion Date:</th>
											<td>{completionDate}</td>
										</tr>
									</tbody>

									<tbody
										style={{ fontSize: '11px' }}
										className='d-block d-lg-none'>
										<tr>
											<th width='40%'>
												Insurance Policy Number:
											</th>
											<td width='60%'>{mmi.code}</td>
										</tr>
										<tr>
											<th>Issue Date:</th>
											<td>
												{new Date(
													mmi.activationDate
												).toLocaleString()}
											</td>
										</tr>
										<tr>
											<th>Contract Period:</th>
											<td>{mmi.contractPeriod} years</td>
										</tr>
										<tr>
											<th>Completion Date:</th>
											<td>{completionDate}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className='row p-4'>
						<div className='col-12 text-center'>
							{mmi.contract.state === 'notAnswered' && <Need />}
							{mmi.contract.state === 'pending' && <Pages />}
							{mmi.contract.state === 'active' && <Active />}
							{mmi.contract.state === 'denied' && (
								<div className='mb-5'>
									<Image
										height='60px'
										fileName='warning2.png'
										className='mb-1'
									/>
									<div className='fs-5 fw-bold'>
										Insurance services contract activation
										denied by the user.
									</div>
									<div>
										All the covers are considerd as zero.
									</div>
								</div>
							)}
						</div>
					</div>
				</>
			) : (
				<div className='row my-5'>
					<div className='col-12 text-center'>
						<div className='text-center'>
							<Image height='180px' fileName='icons/noData.svg' />
							<h4 className='mt-4'>MMI is not activated yet.</h4>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default InsuranceContract;
