import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';

class MigrateForm extends Form {
	state = {
		data: {
			investments: [],
			contractPeriod: this.props.contractPeriod.max,
			birthDate: '',
			paymentMethod: this.props.paymentMethod[0].content,
			increasePercent: this.props.increasePercent[2].content,
			securityPassword: '',
		},
		errors: {},
		totalSelected: 0,
	};

	schema = {
		investments: Joi.array().required().label('Active Investments'),
		contractPeriod: Joi.number()
			.min(this.props.contractPeriod.min)
			.max(this.props.contractPeriod.max)
			.multiple(this.props.contractPeriod.step)
			.required()
			.label('Contract Period')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message =
								'Contract Period should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${this.props.contractPeriod.min} years`;
							break;
						case 'number.max':
							err.message = `Invalid amount, maximum amount is ${this.props.contractPeriod.max} years`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		birthDate: Joi.string().required().label('Date of Birth'),
		paymentMethod: Joi.string()
			.valid(...this.props.paymentMethod.map((p) => p.content))
			.required()
			.label('Payment Method'),
		increasePercent: Joi.string()
			.valid(...this.props.increasePercent.map((p) => p.content))
			.required()
			.label('Increase Percent'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	handleTotalChanged = (value) => {
		const totalSelected = value.reduce((t, i) => t + i.amount, 0);
		this.setState({ totalSelected });
	};

	doSubmit = () =>
		this.props.onSubmit({
			...this.state.data,
			...{ totalSelected: this.state.totalSelected },
		});

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			hasSP,
			contractPeriod,
			paymentMethod,
			increasePercent,
			activeInvestments,
		} = this.props;

		const { totalSelected } = this.state;

		return (
			<form
				className='row text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-md-12 mb-3'>
					{this.renderSelectMultiple({
						name: 'investments',
						labelClass: 'd-flex flex-wrap justify-content-between',
						label: (
							<>
								<div>Investments:</div>{' '}
								<div className='me-3'>
									Total:{' '}
									<span className='fw-bold'>
										$ {totalSelected}
									</span>
								</div>
							</>
						),
						placeholder: 'Select Investments',
						options: activeInvestments,
						extraHandler: (e) => this.handleTotalChanged(e),
					})}
				</div>

				<div className='col-md-6 mb-3'>
					{this.renderInput({
						name: 'birthDate',
						label: 'Date of Birth:',
						max: this.props.birthDate,
						type: 'date',
					})}
				</div>
				<div className='col-md-6 mb-3'>
					{this.renderInput({
						name: 'contractPeriod',
						label: 'Contract Period (year):',
						type: 'number',
						min: contractPeriod.min,
						max: contractPeriod.max,
						step: contractPeriod.step,
					})}
				</div>
				<div className='col-md-6 mb-3'>
					<div className='mb-2'>Increase Percent:</div>
					{increasePercent.map((i, key) =>
						this.renderRadioInput({
							key,
							formClass: 'form-check-inline',
							name: 'increasePercent',
							id: i.content,
							label: i.content,
							checked:
								i.content === this.state.data.increasePercent,
							disabled: false,
						})
					)}
				</div>
				<div className='col-md-6 mb-3'>
					<div className='mb-2'>Payment Method:</div>
					{paymentMethod.map((p, key) =>
						this.renderRadioInput({
							key,
							formClass: 'form-check-inline',
							name: 'paymentMethod',
							id: p.content,
							label: p.content,
							checked:
								p.content === this.state.data.paymentMethod,
							disabled: false,
						})
					)}
				</div>

				{this.renderSubmitBtnWithSPass({
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: 'Migrate',
					loadingText: 'Migrating...',
				})}
			</form>
		);
	}
}

export default MigrateForm;
