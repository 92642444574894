import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import tick from '../img/tick.png';

const styles = StyleSheet.create({
    section: {
        width: 460,
        flexDirection: 'row',
        marginTop: 14,
        marginLeft: 22,
    },
    tick: {
        width: 14,
        height: 11,
        marginRight: 4,
    },
    acknowledgementText: {
        fontSize: 10,
    },
});

const MmiAcknowledgement = () => (
    <View style={styles.section}>
        <Image style={styles.tick} src={tick} />
        <Text style={styles.acknowledgementText}>
            Based on the principle of good faith, I certify that I do not have
            any specific disease, cancer, or disability and that I am in perfect
            physical health. If I have any of the mentioned cases or any kind of
            illness or physical or mental problem, I have declared it in the
            registration forms.
        </Text>
    </View>
);

export default MmiAcknowledgement;
