import React, { useState } from 'react';
import config from '../../../../config';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import { TransferHistoryCard } from '../../ingredients/cards';

const TransferHistory = () => {
	const { userData } = useMain();
	const sorted = userData.transferHistory.sort((a, b) =>
		a.date > b.date ? -1 : 1
	);
	for (const i in sorted) sorted[i].no = Number(i) + 1;

	const [currentPage, setCurrentPage] = useState(1);
	const data = paginate(sorted, currentPage, config.tablePageSize);

	return (
		<div className='row'>
			<div className='col-12'>
				<TransferHistoryCard
					data={data}
					itemsCount={userData.transferHistory.length}
					pageSize={config.tablePageSize}
					currentPage={currentPage}
					onPageChange={setCurrentPage}
				/>
			</div>
		</div>
	);
};

export default TransferHistory;
