import React from 'react';

const DebtTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Amount</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{d.no}</td>
							<td>{new Date(d.date).toLocaleString()}</td>
							{getAmount(d)}
							<td>{d.action}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getAmount = (d) => (
	<td className={`text-${d.action === 'Payback' ? 'danger' : 'success'}`}>
		{d.amount / 1e6} USDT
	</td>
);

export default DebtTable;
