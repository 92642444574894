import React, { useState } from 'react';
import Viewer from 'react-viewer';
import Image from './image';

function ImgViewerMulti({ name, filePath, num, height, path, ext, count }) {
	const [visible, setVisible] = useState(false);
	const imagesRaw = [];
	for (let i = 0; i < count; i++)
		imagesRaw.push({
			src: `${path}${i}.${ext}`,
			alt: `view_img_${i}`,
		});

	const part1 = imagesRaw.slice(num);
	const part2 = imagesRaw.slice(0, num);
	const images = part1.concat(part2);

	return (
		<>
			<Image
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setVisible(true);
				}}
				height={height}
				fileName={`${filePath}${name}/${num}.${ext}`}
			/>
			<Viewer
				noToolbar
				visible={visible}
				onClose={() => {
					setVisible(false);
				}}
				images={images}
			/>
		</>
	);
}

export default ImgViewerMulti;
