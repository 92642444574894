import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../../hooks/useMain';
import http from '../../../../../services/httpService';
import NeedForm from './needForm';

const Need = () => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);

	const handleRequestSubmit = async (formData) => {
		const { answer, securityPassword } = formData;
		try {
			setIsLoading(true);
			const { data } = await http.answerServicesNeed({
				answer,
				securityPassword,
			});
			setIsLoading(false);
			onUserDataChanged(data.data);
			toast.info(data.message);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<>
			<div className='fs-4'>Your services contract is not activated.</div>
			<div className='fs-4'>
				Please choose one of the following options:
			</div>
			<NeedForm
				isLoading={isLoading}
				hasSP={userData.hasSP}
				options={initData.mmiConfig.contract.needOptions}
				onSubmit={handleRequestSubmit}
			/>
		</>
	);
};

export default Need;
