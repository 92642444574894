import React, { Fragment } from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class BlogForm extends Form {
	state = {
		data: {
			title: '',
			tags: '',
			domain: '',
			publish: 'no',
			description: '',
			img: '',
			story: '',
		},
		errors: {},
		images: { img: [] },
		currentImageName: '',
		textAreaChars: 0,
		defaultImage: { img: null },
	};

	schema = {
		title: Joi.string()
			.min(this.props.titleConfig.min)
			.max(this.props.titleConfig.max)
			.regex(/^[a-zA-Z0-9- ]*$/, 'Alphanumerics, dash and space characters.')
			.required()
			.label('Title'),
		tags: Joi.array()
			.items(Joi.string().valid(this.props.tagsConfig.map((a) => a.id)))
			.required()
			.label('Tags'),
		domain: Joi.array()
			.items(Joi.string().valid(this.props.domainConfig.map((a) => a.id)))
			.required()
			.label('Domain'),
		publish: Joi.string().valid('yes', 'no').required().label('Publish'),
		description: Joi.string()
			.min(this.props.descriptionConfig.min)
			.max(this.props.descriptionConfig.max)
			.required()
			.label('Description'),
		img: Joi.string().required().label('Img'),
		story: Joi.string().required().label('Story'),
	};

	doSubmit = () => this.props.onSubmit(this.state);

	textAreaChange = ({ currentTarget: text }) => {
		const textAreaChars = text.value.length;
		this.setState({ textAreaChars });
	};

	componentDidMount() {
		const { blog } = this.props;
		if (blog) {
			const state = { ...this.state };
			state.textAreaChars = blog.description ? blog.description.length : 0;
			state.data.title = blog.title ? blog.title : '';
			state.data.tags = blog.tags ? blog.tags : '';
			state.data.domain = blog.domain ? blog.domain : '';
			state.data.publish = blog.isPublished ? 'yes' : 'no';
			state.data.description = blog.description ? blog.description : '';
			state.data.story = blog.content ? blog.content : '';

			state.data.img = blog.imageLink ? 'same' : '';
			state.defaultImage.img = blog.imageLink ? blog.imageLink : null;

			this.setState(state);
		}
	}

	componentDidUpdate(prevProps, prevState) {}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			maxFileSize,
			acceptType,
			descriptionConfig,
			tagsConfig,
			domainConfig,
			blog,
		} = this.props;
		const { textAreaChars } = this.state;

		return (
			<Fragment>
				<small>
					<span className='text-danger'>*</span> Required fields
				</small>
				<form
					className='mt-3'
					onSubmit={this.handleSubmit}>
					<div className='row align-items-center'>
						<div className='col-md-6'>
							<div className='mb-4'>
								{this.renderInput({
									name: 'title',
									label: (
										<span>
											Title <span className='text-danger'>*</span>
										</span>
									),
									type: 'text',
									formText:
										'Title must be alphanumeric, minimum 3 and maximum 30 in length.',
								})}
							</div>

							<div className='row mb-4'>
								<div className='col-4'>
									<div className='mb-2'>
										Where to dispaly? <span className='text-danger'>*</span>
									</div>
									{tagsConfig.map((t, key) =>
										this.renderRadioInput({
											key,
											name: 'tags',
											type: 'checkbox',
											id: t.id,
											label: t.label,
											checked:
												blog &&
												Array.isArray(blog.tags) &&
												blog.tags.includes(t.id)
													? true
													: t.checked,
											disabled: t.disabled,
										})
									)}
								</div>
								<div className='col-4'>
									<div className='mb-2'>
										Which domains? <span className='text-danger'>*</span>
									</div>
									{domainConfig.map((t, key) =>
										this.renderRadioInput({
											key,
											name: 'domain',
											type: 'checkbox',
											id: t.id,
											label: t.label,
											checked:
												blog &&
												Array.isArray(blog.domain) &&
												blog.domain.includes(t.id)
													? true
													: t.checked,
											disabled: t.disabled,
										})
									)}
								</div>
								<div className='col-4'>
									<div className='mb-2'>
										Must Publish? <span className='text-danger'>*</span>
									</div>
									{this.renderRadioInput({
										name: 'publish',
										id: 'yes',
										label: 'Yes',
										checked: blog ? blog.isPublished : false,
										disabled: false,
									})}
									{this.renderRadioInput({
										name: 'publish',
										id: 'no',
										label: 'No',
										checked: blog ? !blog.isPublished : true,
										disabled: false,
									})}
								</div>
							</div>

							<div className='mb-4'>
								{this.renderTextArea({
									name: 'description',
									label: (
										<span>
											Description <span className='text-danger'>*</span>
										</span>
									),
									rows: 2,
									formText: `${textAreaChars} of maximum ${descriptionConfig.max} characters used.`,
									extraHandler: this.textAreaChange,
								})}
							</div>
						</div>
						<div className='col-md-6'>
							<div className='mb-4'>
								{this.renderFileUpload({
									name: 'img',
									label: (
										<span>
											Blog Image <span className='text-danger'>*</span>
										</span>
									),
									maxFileSize,
									acceptType,
									formText: `Supported types are: ${acceptType.join(
										', '
									)}. Max file size: ${maxFileSize / 1024 ** 2} MB`,
									style: 'item',
									defaultImage: this.state.defaultImage.img,
								})}
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='mb-2'>
								Story <span className='text-danger'>*</span>
							</div>
							{this.renderEditor({
								name: 'story',
								markup: blog ? blog.content : '',
							})}
						</div>
					</div>
					<div className='text-center mt-3'>
						<div>
							{this.renderButton(
								'Save blog',
								'btn btn-lg btn-my-navy',
								isLoading && <Spinner content={'Saving blog...'} />
							)}
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

export default BlogForm;
