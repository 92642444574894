import React from 'react';
import Joi from 'joi-browser';
import Countdown from 'react-countdown';
import Form from '../../../common/form';

class SellCurrentDepositForm extends Form {
	state = {
		data: {
			amount: this.props.min,
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.max(this.props.max)
			.multiple(this.props.step)
			.required()
			.label('Amount'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	setSchema() {
		const { min, max, step, hasActiveSellRequest } = this.props;
		if (!hasActiveSellRequest) {
			this.schema.amount = Joi.number()
				.min(min)
				.max(max)
				.multiple(step)
				.required()
				.label('Amount');
		} else {
			delete this.schema.amount;
			this.schema.amount = Joi.number().label('Amount');
		}
	}

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidMount() {
		this.setSchema();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.isSubmited === true &&
			prevProps.isSubmited !== this.props.isSubmited
		) {
			const state = { ...this.state };
			state.data = {
				securityPassword: '',
			};
			this.setState(state);
		}
		if (prevProps.hasActiveSellRequest !== this.props.hasActiveSellRequest)
			this.setSchema();
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			hasSP,
			min,
			max,
			step,
			hasActiveSellRequest,
			walletBalance,
			cardLimit,
			counterDate,
			onRequestTimerCompleted,
			currentSellRequestAmount,
			isTerminated,
		} = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				noValidate={hasActiveSellRequest}
				onChange={this.props.onFormChange}
				onSubmit={this.handleSubmit}>
				<div className='mb-4'>
					{hasActiveSellRequest && (
						<div className='text-center mt-5'>
							<div className='mb-3'>
								You have a pending Sell Request of{' '}
								<b>${currentSellRequestAmount}</b> which will be
								executed on:
							</div>
							<Countdown
								className='h3'
								date={counterDate}
								onComplete={onRequestTimerCompleted}
							/>
						</div>
					)}
				</div>

				<div
					className={`mb-4 d-${
						hasActiveSellRequest ? 'none' : 'block'
					}`}>
					{this.renderInput({
						name: 'amount',
						label: 'Transfer Amount',
						type: 'number',
						min,
						max,
						step,
						formText: (
							<>
								<div>
									<b>Current Deposit</b> Wallet Balance:{' '}
									<b>${walletBalance}</b>
								</div>
								<div>
									Minimum transfer amount is{' '}
									<b>${min.toFixed(2)}</b>
								</div>
								<div>
									Maximum transfer amount is{' '}
									<b>${cardLimit.toFixed(2)}</b> (Based on
									your{' '}
									{isTerminated
										? 'last card'
										: 'current package'}
									)
								</div>
								<div>
									Transfer amount multiplier is{' '}
									<b>${step.toFixed(2)}</b>
								</div>
							</>
						),
					})}
				</div>

				{this.renderSubmitBtnWithSPass({
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: hasActiveSellRequest
						? 'Cancel Current Request'
						: 'Request',
					loadingText: 'Submitting...',
				})}
			</form>
		);
	}
}

export default SellCurrentDepositForm;
