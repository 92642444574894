import { useEffect, useState } from 'react';
import socket from '../services/socketService';

const useWatchPrices = () => {
	const [prices, setPrices] = useState({});
	const handleUpdatePrice = (data) => setPrices((prevData) => ({ ...prevData, ...data }));

	useEffect(() => {
		socket.emitGetAllTokensPrices();
		socket.bemsSocket.on('allTokensPricePong', handleUpdatePrice);
		return () => socket.bemsSocket.off('allTokensPricePong', handleUpdatePrice);
	}, []);

	useEffect(() => {
		socket.bemsSocket.on('otherTokensPricePong', handleUpdatePrice);
		return () => socket.bemsSocket.off('otherTokensPricePong', handleUpdatePrice);
	}, []);

	useEffect(() => {
		socket.bemsSocket.on('initDataPong', handleUpdatePrice);
		return () => socket.bemsSocket.off('initDataPong', handleUpdatePrice);
	}, []);

	return [prices];
};

export default useWatchPrices;
