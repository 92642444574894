import Image from '../common/image';

const advantages = {
	path: '/advantages',
	name: 'Advantages',
	title: 'MMP WORLD Advantages',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<div id='safe' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5'>
							<Image
								height={'120px'}
								fileName={`home/icons/safe.svg`}
							/>
							<h2 className='text-blue'>
								<b>SAFE AND STABLE</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								Whether you are an experienced trader or a
								cryptocurrency newcomer, MMP Global Market Maker
								Plan can help you achieve your financial goals.
								MMP WORLD offers the following features to its
								users:
							</p>
							<ul>
								<li>
									The MMP WORLD project uses open-source smart
									contract technology, which ensures safer,
									more stable, and smoother financial
									transactions.
								</li>
								<li>
									Users can benefit from hot and cold wallets,
									offline private key storage, and risk
									isolation to enhance the security of their
									funds.
								</li>
								<li>
									Email verification, Google verification, and
									remote login risk reminders are provided to
									increase the security of user accounts.
								</li>
								<li>
									The withdrawal automation system with manual
									security checks ensures that user funds are
									protected.
								</li>
							</ul>
							<p>
								MMP Global Market Maker Plan offers
								comprehensive financial investment solutions for
								institutions and individuals of all sizes. The
								platform provides a range of services, from
								cryptocurrency trading to personal account
								management and lending, allowing you to achieve
								your investment goals quickly and confidently.
							</p>
						</div>
					</div>

					<div id='fast' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5 d-block d-sm-none'>
							<Image
								height={'120px'}
								fileName={`home/icons/speed.svg`}
							/>
							<h2 className='text-blue'>
								<b>FAST AND EFFICEIENT</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								One of the key features of the MMP WORLD project
								is its 24/7 availability for deposits and
								withdrawals. This allows you to manage your
								investment portfolio at your convenience,
								without worrying about any limitations or
								restrictions. The platform's automated system
								ensures that all transactions are processed
								seamlessly, without any interruptions or delays.
							</p>
							<p>
								Moreover, the MMP WORLD project's advanced
								security measures ensure that your deposits and
								withdrawals are processed safely and securely.
								The platform employs a variety of risk
								management techniques, including hot and cold
								wallets, offline private key storage, and risk
								isolation, to minimize the risk of unauthorized
								access and theft. Additionally, the platform
								offers email verification, Google verification,
								and remote login risk reminders to ensure that
								your account is protected at all times.
							</p>
							<p>
								Finally, the MMP Global Market Maker Plan offers
								a comprehensive suite of financial investment
								solutions that are designed to meet the needs of
								both institutions and individuals of all sizes.
								From the cryptocurrency market to personal
								account management and lending, the platform
								provides a wide range of investment options that
								enable you to achieve your financial goals
								quickly and confidently. So whether you're an
								experienced trader or a cryptocurrency newcomer,
								the MMP WORLD project is the perfect platform to
								help you chart your financial path.
							</p>
						</div>

						<div className='col-sm-3 text-center mb-5 d-none d-sm-block'>
							<Image
								height={'120px'}
								fileName={`home/icons/speed.svg`}
							/>
							<h2 className='text-blue'>
								<b>FAST AND EFFICEIENT</b>
							</h2>
						</div>
					</div>

					<div id='features' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5'>
							<Image
								height={'120px'}
								fileName={`home/icons/features.svg`}
							/>
							<h2 className='text-blue'>
								<b>PERFECT FEATURES</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								The MMP WORLD project is a multifaceted project
								that is designed to cater to the needs of the
								cryptocurrency community. With various projects
								under its umbrella, MMP WORLD aims to provide a
								one-stop solution to users who are looking for
								investment opportunities in the crypto world.
							</p>
							<p>
								One of the key projects under MMP WORLD is the
								investment project, which allows users to invest
								in the platform and earn a passive income on
								their investment. The liquidity pool project, on
								the other hand, provides a platform for users to
								trade and swap cryptocurrencies with high
								liquidity, ensuring that their trades are
								executed efficiently and at the best market
								rates.
							</p>
							<p>
								The lending project of MMP WORLD is designed to
								provide loans to users who are looking to borrow
								cryptocurrency or fiat currency. With
								competitive interest rates and a streamlined
								loan application process, the lending project is
								an attractive option for those who require funds
								for personal or business needs.
							</p>
							<p>
								In addition to these projects, MMP WORLD also
								has an NFT collection project, which allows
								users to trade and collect NFTs on the platform.
								The store project enables users to purchase
								products using cryptocurrency, further
								integrating the use of cryptocurrencies into our
								daily lives. Lastly, the stock market project
								provides a platform for users to invest in
								stocks using cryptocurrency.
							</p>
							<p>
								With all these projects, MMP WORLD aims to
								create a comprehensive ecosystem for the
								cryptocurrency community, providing a range of
								services that cater to the diverse needs of
								users. The success of each of these projects is
								interdependent, and the continued development
								and growth of the MMP WORLD project will surely
								lead to a bright future in the cryptocurrency
								space.
							</p>
						</div>
					</div>

					<div id='powerfull' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5 d-block d-sm-none'>
							<Image
								height={'120px'}
								fileName={`home/icons/powerfull.svg`}
							/>
							<h2 className='text-blue'>
								<b>POWERFULL OPERATING SYSTEM</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								At MMP WORLD, we understand that the success of
								our project largely depends on our investors'
								trust and satisfaction. That is why we have
								developed a robust support system that is
								available 24/7 to ensure that our investors are
								always satisfied with our services. Our support
								system is made up of highly trained and
								experienced professionals who are always
								available to answer your questions and address
								any concerns you may have. <br />
								In addition to our support system, we also have
								a powerful operating system that allows us to
								ensure that our services are always available to
								our investors without any interruption. Our
								decentralized financial system provides a high
								level of security and independence, allowing us
								to continue our activity for many years to come
								and ensuring that our investors can trust us to
								provide them with quality financial services.
							</p>
						</div>

						<div className='col-sm-3 text-center mb-5 d-none d-sm-block'>
							<Image
								height={'120px'}
								fileName={`home/icons/powerfull.svg`}
							/>
							<h2 className='text-blue'>
								<b>POWERFULL OPERATING SYSTEM</b>
							</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	),
};

export default advantages;
