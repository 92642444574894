import React from 'react';
import Image from '../../common/image';
import { subString } from '../../../utils/helpers';

const GoProTable = ({ data, title }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Rank</th>
						<th>UID</th>
						<th>Email</th>
						<th>Balance</th>
						<th>{`${
							title === 'Go Pro 3' ? 'Money Bags' : 'Tickets'
						}`}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{getRank(d.no)}</td>
							<td>{d.uid}</td>
							<td>{subString(d.email, 8, 6, '.')}</td>
							<td>
								<b>$</b> {d.balance / 1e6}
							</td>
							<td>{getTickets(d.tickets, title)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getRank = (no) => {
	if (no === 1) return <Image height={'20px'} fileName={'goPro/gold.svg'} />;
	if (no === 2)
		return <Image height={'20px'} fileName={'goPro/silver.svg'} />;
	if (no === 3)
		return <Image height={'20px'} fileName={'goPro/bronze.svg'} />;
	return no;
};

const getTickets = (t, title) => {
	if (t > 0)
		return (
			<div className='d-flex align-items-center justify-content-center'>
				<span>{t}</span>
				{title === 'Go Pro 3' ? (
					<Image
						className='ms-1'
						height='23px'
						fileName='goPro/money.svg'
					/>
				) : (
					<Image
						className='ms-2'
						height='20px'
						fileName='goPro/ticket.svg'
					/>
				)}
			</div>
		);

	return t;
};

export default GoProTable;
