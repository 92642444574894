import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    formContainer: {
        marginTop: 110,
    },
    formTitle: {
        width: 530,
        color: '#000000',
        letterSpacing: 1,
        fontSize: 14,
        textAlign: 'center',
        margin: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
    },
});

const MmiFormTitle = () => (
    <View style={styles.formContainer}>
        <Text style={styles.formTitle}>Insurance Contract</Text>
    </View>
);

export default MmiFormTitle;
