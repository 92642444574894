import React from 'react';
import { Link } from 'react-router-dom';
import Image from './common/image';

const ComingSoon = ({ withLink = true }) => {
	return (
		<main className='container'>
			<div className='row my-0'>
				<div className='col-md-10 offset-md-1 col text-center'>
					<Image className='w-100 my-0' fileName='comingSoon.png' />
					{withLink && (
						<Link className='btn btn-lg btn-my-navy mb-4' to='/'>
							Take me back home!
						</Link>
					)}
				</div>
			</div>
		</main>
	);
};

export default ComingSoon;
