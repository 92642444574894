import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from './logo.png';

const styles = StyleSheet.create({
	titleContainer: {
		position: 'relative',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	logo: {
		position: 'absolute',
		width: 100,
		height: 'auto',
	},
	reportTitle: {
		color: '#000000',
		letterSpacing: 2,
		fontSize: 18,
		textAlign: 'center',
		margin: 'auto',
	},
});

const DashboardTitle = ({ title }) => (
	<View style={styles.titleContainer}>
		<Image style={styles.logo} src={logo} />
		<Text style={styles.reportTitle}>{title}</Text>
	</View>
);

export default DashboardTitle;
