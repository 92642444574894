import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function Video(props) {
	return (
		<div className='container-fluid pb-5 pt-0'>
			<div className='row align-items-center'>
				<div className='col-lg-4 offset-lg-1'>
					<h1>
						<b>BEM Foundation</b>
					</h1>
					<p>
						BEM foundation was established in 2020 as an entrepreneur in the blockchain
						field to develop this industry globally. <br /> The foundation consists of
						experts in cryptocurrency, blockchain programming, and marketing. Each
						member of the foundation has several years of experience in their field.
						Inspired by blockchain principles (decentralization) and to increase the
						security level of projects, these individuals' identities remain disclosed.{' '}
						<br /> BEM foundation was established to create a blockchain-based ecosystem
						and job opportunities in blockchain. We explore market needs and create
						projects so that all people can quickly understand blockchain projects and
						start working on them.
					</p>
					<div className='py-4'>
						<Link
							to='/about'
							className='btn btn-my-blue me-3'>
							Read More
						</Link>
					</div>
				</div>
				<div className='col-lg-4 offset-lg-2'>
					<ReactPlayer
						url='video/office.mp4'
						width='100%'
						// height='100%'
						controls={true}
						light='video/tumbnail.jpg'
					/>
				</div>
			</div>
		</div>
	);
}

export default Video;
