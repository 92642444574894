import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useDebts() {
	const [debts, setDebts] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/debts'];

		async function getDebts() {
			try {
				if (
					!http.getCurrentUser() ||
					debts ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getDebts();
				if (isMounted()) setDebts(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getDebts();
	}, [isMounted, debts, pathname]);
	return [debts, setDebts];
}
