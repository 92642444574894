const uiInfoReducer = (uiInfo, newData) => {
	const updatedInfo = { ...uiInfo, ...newData };

	updatedInfo.mainPanel = updatedInfo.mainPanelHeader
		? updatedInfo.mainPanelHeader.height + updatedInfo.dashboardSpacer
		: 0 + updatedInfo.dashboardSpacer;

	updatedInfo.sideBar = uiInfo.isLessThanMD
		? updatedInfo.header
			? updatedInfo.header.height + updatedInfo.dashboardSpacerMD
			: 0 + updatedInfo.dashboardSpacerMD
		: updatedInfo.summary
		? updatedInfo.summary.height + updatedInfo.dashboardSpacer
		: 0 + updatedInfo.dashboardSpacer;

	return updatedInfo;
};

export default uiInfoReducer;
