import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useKYCData from '../../../hooks/useKYCData';
import useKYCRequests from '../../../hooks/useKYCRequests';
import useMain from '../../../hooks/useMain';
import http from '../../../services/httpService';
import ImgViewerExternal from '../../common/imgViewerExternal';
import Preloader from '../../common/preloader';
import ReviewForm from './reviewForm';

function BasicPersonalInfoReview(props) {
	useDocumentTitle(['Basic Personal Info Review']);
	const { initData } = useMain();
	const { setRequests } = useKYCRequests();
	const [isLoading, setIsLoading] = useState(false);
	const [KYCData] = useKYCData();
	const { formOptions } = initData;
	if (!formOptions || !KYCData) return <Preloader />;
	if (!props.location.state) window.location = '/';

	const { email, uid, verification, _id } = props.location.state;
	const { basicInfo, basicInfoDocs } = verification;
	const showData = { ...KYCData };
	showData['Declartions'] = [
		{
			name: 'aml',
			label: 'AML accepted',
		},
		{
			name: 'accuracy',
			label: 'Accuracy declared',
		},
	];
	const titles = Object.keys(showData);

	const handleReviewSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.submitKYCReviewResult({ ...formData, ...{ userId: _id } });
			setIsLoading(false);
			toast.success(data.message);
			setRequests(data.data);
			props.history.replace('/kyc');
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		}
	};

	return (
		<div className='container my-4'>
			<div className='row gy-3'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>Basic Personal Info Review</div>
					<div className='text-center'>(Level 1 Request)</div>
				</div>

				<div className='col-12'>
					<div className='d-flex flex-wrap justify-content-evenly align-items-center'>
						<div>
							<span className='me-1'>Email: </span>
							<span className='fw-bold text-my-blue'>{email}</span>
						</div>
						<div>
							<span className='me-1'>UID: </span>
							<span className='fw-bold text-my-blue'>{uid}</span>
						</div>
					</div>
					<hr />
				</div>
				<div className='col-12'>
					<div className='d-flex flex-wrap justify-content-evenly align-items-center'>
						{basicInfoDocs.map((d, key) => (
							<ImgViewerExternal
								key={key}
								src={d.link}
								title={d.name}
								height='150px'
							/>
						))}
					</div>
					<hr />
				</div>
				{titles.map((t, key) => (
					<div
						key={key}
						className='col-12'>
						<div className='fw-bold fs-5 my-3'>{t}</div>
						<div className='row gy-3'>
							{showData[t].map((r, k) => (
								<div
									key={k}
									className='col-md-4 col-lg-3'>
									<div className='d-flex flex-wrap text-break'>
										<span className='me-1'>{r.label}: </span>
										<span className='fw-bold text-my-blue'>
											{String(basicInfo[r.name])}
										</span>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
			<div className='row my-5'>
				<div className='col-md-4 offset-md-4'>
					<div className='fw-bold fs-5 text-center my-4'>Submit Review Result</div>
					<ReviewForm
						isLoading={isLoading}
						options={formOptions.reviewKYCFormOptions}
						onSubmit={handleReviewSubmit}
					/>
				</div>
			</div>
		</div>
	);
}

export default BasicPersonalInfoReview;
