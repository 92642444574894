import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../../hooks/useMain';
import http from '../../../../../services/httpService';
import jsonToFormData from '../../../../../utils/jsonToFormData';
import SingleDocumentUploadForm from './singleDocumentUploadForm';

const RiskDisclosureContract = () => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (formState) => {
		console.log('formState :>> ', formState);
		try {
			setIsLoading(true);
			const formData = jsonToFormData({
				documentType: formState.data.documentType,
				securityPassword: formState.data.securityPassword,
				level: 'level_3',
			});
			formData.append(
				'img',
				formState.images.img.length > 0 ? formState.images.img[0].file : ''
			);
			const { data } = await http.submitKYCDocuments(formData);
			setIsLoading(false);
			onUserDataChanged(data.data);
			toast.info(data.message);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='row mb-5'>
			<div className='col-lg-4 offset-lg-4 col-12'>
				<div className='fs-4 fw-bold mb-3 text-center'>Risk Disclosure Contract</div>
				<SingleDocumentUploadForm
					hasSP={userData.hasSP}
					isLoading={isLoading}
					maxFileSize={initData.formOptions.maxFileSize}
					acceptType={initData.formOptions.acceptType}
					options={initData.verificationConfig.level_3.requirements.documents}
					onSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default RiskDisclosureContract;
