import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../../common/form';

class P5Form extends Form {
	disease = [
		'Cardiac disease',
		'Endocrine disease',
		'Epilepsy and convulsions',
		'Infectious disease',
		'Blood pressure disease',
		'Cancer',
		'Bone disease',
		'Respiratory disease',
		'Any type of tumor',
		'AIDS',
		'Blood disease',
		'Kidney and urinary tract',
		'Brain disease',
		'Any type of hepatitis',
		'Mental and nervous illness',
		'Skin disease',
		'Digestive Disease',
		'Ear, throat and nose disease',
		'Eye disease',
		'Diabetes',
	];

	state = {
		data: {
			diseasesDescribe: '',
			notListed: '',
			accident: '',
			surgery: '',
			suicide: '',
			depression: '',
			family: '',
			female: '',
			pregnant: '',
			childbirth: '',
			abortion: '',
		},
		errors: {},
	};

	schema = {
		diseasesDescribe: Joi.string()
			.allow('')
			.allow(null)
			.label('Diseases Describe'),
		notListed: Joi.string().allow('').allow(null).label('Not Listed'),
		accident: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Accident'),
		surgery: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Surgery'),
		suicide: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Suicide'),
		depression: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Depression'),
		family: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Family'),
		female: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.allow('')
			.allow(null)
			.label('Female'),
		pregnant: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.allow('')
			.allow(null)
			.label('Pregnant'),
		childbirth: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.allow('')
			.allow(null)
			.label('Childbirth'),
		abortion: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.allow('')
			.allow(null)
			.label('Abortion'),
	};

	componentDidMount() {
		const state = { ...this.state };
		for (const i in this.disease) {
			const elem = this.disease[i];
			const elemName = elem.replaceAll(' ', '_').toLocaleLowerCase();
			state.data[elemName] = '';
			this.setState(state);

			this.schema[elemName] = Joi.string()
				.valid(...this.props.options.map((o) => o.id))
				.required()
				.label(elem);
		}
	}

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { onPrevBtnClick, handleChange, options } = this.props;

		return (
			<form
				className='row row-cols-lg-4 row-cols-1 g-0 text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-lg-12'>
					<div className='my-3 fs-5'>
						Which of the following diseases do you have?
					</div>
				</div>
				{this.disease.map((d, k) => (
					<div key={k} className='col'>
						<div className='d-flex flex-wrap'>
							<span className='me-3 fw-bold'>{d}</span>
							<span>
								{options.map((o, key) =>
									this.renderRadioInput({
										extraHandler: () => handleChange('P5'),
										formClass: 'mb-0 form-check-inline',
										key,
										name: d
											.replaceAll(' ', '_')
											.toLocaleLowerCase(),
										id: o.id,
										label: o.label,
										checked: o.checked,
										disabled: o.disabled,
									})
								)}
							</span>
						</div>
					</div>
				))}

				<div className='col-lg-6 my-4 px-3'>
					{this.renderTextArea({
						name: 'diseasesDescribe',
						label: 'If you have any of the diseases, describe it:',
						rows: 3,
					})}
				</div>
				<div className='col-lg-6 my-4 px-3'>
					{this.renderTextArea({
						name: 'notListed',
						label: 'If you have any other disease that is not listed in the above table, describe it:',
						rows: 3,
					})}
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Have you ever been injured in an accident?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'accident',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>Have you ever had surgery?</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'surgery',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Do you have a history of suicide?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'suicide',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Do you have a history of depression?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'depression',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Has anyone in your family (father, mother,
							grandmother, grandfather, sister, brother, wife and
							children) suffered from tuberculosis, cancer, heart
							disease, endocrine disease, blood disease, diabetes,
							mental illness, nervous disease, or epilepsy?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'family',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>

				<div className='col-lg-12 px-3'>
					<div className='my-3 fs-5'>For Women:</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Do you have a history of specific female diseases?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'female',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>Are you pregnant?</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'pregnant',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Have you had a history of childbirth?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'childbirth',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>
				<div className='col-lg-12 px-3'>
					<div className='d-flex flex-wrap'>
						<span className='me-3'>
							Have you ever had an abortion?
						</span>
						<span>
							{options.map((o, key) =>
								this.renderRadioInput({
									extraHandler: () => handleChange('p5'),
									formClass: 'mb-0 form-check-inline',
									key,
									name: 'abortion',
									id: o.id,
									label: o.label,
									checked: o.checked,
									disabled: o.disabled,
								})
							)}
						</span>
					</div>
				</div>

				<div className='col-lg-12 text-center mt-5'>
					<button
						type='button'
						onClick={onPrevBtnClick}
						className='btn btn-my-navy me-2 me-lg-4'>
						<div className='d-flex align-items-center justify-content-center'>
							<i className='fas fa-arrow-left'></i>
							<span className='ms-2'>Previous</span>
						</div>
					</button>
					{this.renderButton(
						<div className='mx-3 d-flex align-items-center justify-content-center'>
							<span className='me-2'>Next</span>
							<i className='fas fa-arrow-right'></i>
						</div>,
						'btn btn-my-navy'
					)}
				</div>
			</form>
		);
	}
}

export default P5Form;
