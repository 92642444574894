import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';
import http from '../../../../services/httpService';
import { toast } from 'react-toastify';

class TransferToOthersForm extends Form {
	state = {
		data: {
			destBC: '',
			transferType: this.props.options.find((o) => o.checked === true).id,
			amount: this.props.min,
			remarks: '',
			securityPassword: '',
		},
		errors: {},
		textareaChars: 0,
		fromWallet: undefined,
		walletBalance: 0,
		maxTransfer: 0,
		maxAmountForInput: 0,
		currentTransferType: undefined,
		isCheckBtnLoading: false,
		isBCValid: false,
		afterCheckMessage: 'Invalid ID',
	};

	schema = {
		destBC: Joi.string().required().label('Destination'),
		transferType: Joi.string().required().label('Transfer Type'),
		amount: Joi.number()
			.min(this.props.min)
			.max(10)
			.multiple(this.props.step)
			.required()
			.label('Amount'),
		remarks: Joi.string()
			.min(this.props.remarks.min)
			.max(this.props.remarks.max)
			.required()
			.label('Remarks'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	updateBalances = (option) => {
		const { wallets } = this.props;
		const from = option.from;

		const fromWallet = wallets[from].name;
		const walletBalance = this.props[`${from}_Balance`];
		const maxTransfer = walletBalance;
		const maxAmountForInput = walletBalance;
		const currentTransferType = option.id;

		this.setState({
			fromWallet,
			walletBalance,
			maxTransfer,
			maxAmountForInput,
			currentTransferType,
		});

		this.schema.amount = Joi.number()
			.min(this.props.min)
			.max(maxAmountForInput)
			.multiple(this.props.step)
			.required()
			.label('Amount');
	};

	textareaChanged = ({ currentTarget: text }) => {
		const textareaChars = text.value.length;
		this.setState({ textareaChars });
	};

	transferTypeChanged = ({ currentTarget: pType }) => {
		const option = this.props.options.find((o) => o.id === pType.value);
		this.updateBalances(option);
	};

	doSubmit = () =>
		this.props.onSubmit(
			{
				...this.state.data,
				...{ isBCValid: this.state.isBCValid },
			},
			'others'
		);

	checkBCHandler = async () => {
		const { destBC } = this.state.data;
		if (!destBC) return;

		try {
			this.setState({ isCheckBtnLoading: true });
			const { data } = await http.checkBC({ destBC });
			const errors = { ...this.state.errors };
			delete errors.destBC;
			this.setState({
				errors,
				afterCheckMessage: data.message,
				isBCValid: true,
			});
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				this.setState({
					afterCheckMessage: data.message,
					isBCValid: false,
				});
			}
			if (ex && ex.response && ex.response.data) {
				this.setState({ isBCValid: false });
				return toast.error(ex.response.data);
			}
			if (ex && ex.message) {
				this.setState({ isBCValid: false });
				return toast.error(ex.message);
			}
		} finally {
			this.setState({ isCheckBtnLoading: false });
		}
	};

	onDestBCChanged = ({ currentTarget: bc }) => {
		if (bc.value)
			this.setState({
				afterCheckMessage:
					'Press Check button to examine the entered BC.',
				isBCValid: false,
			});
		else
			this.setState({
				afterCheckMessage: 'Invalid BC',
				isBCValid: false,
			});
	};

	componentDidMount() {
		const option = this.props.options.find((o) => o.checked === true);
		this.updateBalances(option);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.isSubmited === true &&
			prevProps.isSubmited !== this.props.isSubmited
		) {
			const state = { ...this.state };
			state.data = {
				destBC: '',
				transferType: this.props.options.find((o) => o.checked === true)
					.id,
				amount: this.props.min,
				remarks: '',
				securityPassword: '',
			};
			this.setState(state);
		}
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
		if (
			prevProps.activation_Balance !== this.props.activation_Balance ||
			prevProps.discount_Balance !== this.props.discount_Balance
		) {
			const option = this.props.options.find(
				(o) => o.id === this.state.currentTransferType
			);
			this.updateBalances(option);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { remarks, hasSP, min, step, options, isLoading } = this.props;

		const {
			textareaChars,
			fromWallet,
			walletBalance,
			maxTransfer,
			maxAmountForInput,
			isBCValid,
			afterCheckMessage,
			isCheckBtnLoading,
		} = this.state;

		return (
			<form
				className='text-start'
				autoComplete='off'
				onChange={this.props.onFormChange}
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'destBC',
						label: 'To Member (Enter ID)',
						type: 'text',
						btnClass: `btn btn-my-navy`,
						extraHandler: this.onDestBCChanged,
						btnText: isCheckBtnLoading ? (
							<Spinner content={'Checking...'} />
						) : (
							<span>Check</span>
						),
						btnDisable: isCheckBtnLoading || isLoading,
						btnHandler: this.checkBCHandler,
						formText: (
							<div
								className={`text-${
									isBCValid ? 'success' : 'danger'
								}`}>
								<span>{afterCheckMessage}</span>{' '}
								<i
									className={`fas fa-${
										isBCValid ? 'check' : 'times'
									} me-2`}></i>
							</div>
						),
					})}
				</div>
				<div className='mb-3'>
					<div className='mb-1'>Choose Transfer Type:</div>
					{options.map((o, key) =>
						this.renderRadioInput({
							key,
							name: 'transferType',
							id: o.id,
							label: o.label,
							labelClass: 'fw-semibold',
							checked: o.checked,
							disabled: o.disabled,
							formText: o.text,
							extraHandler: this.transferTypeChanged,
						})
					)}
				</div>
				<div className='mb-3'>
					{this.renderInput({
						name: 'amount',
						label: 'Transfer Amount',
						type: 'number',
						min,
						max: maxAmountForInput,
						step,
						formText: (
							<>
								<div>
									<b>{fromWallet}</b> Wallet Balance:{' '}
									<b>${walletBalance}</b>
								</div>
								<div>
									Minimum transfer amount is{' '}
									<b>${min.toFixed(2)}</b>
								</div>
								<div>
									Maximum transfer amount is{' '}
									<b>${maxTransfer.toFixed(2)}</b>
								</div>
								<div>
									Transfer amount multiplier is{' '}
									<b>${step.toFixed(2)}</b>
								</div>
							</>
						),
					})}
				</div>
				<div className='mb-3'>
					{this.renderTextArea({
						name: 'remarks',
						label: 'Remarks',
						rows: 2,
						formText: `${textareaChars} of maximum ${remarks.max} characters used.`,
						extraHandler: this.textareaChanged,
					})}
				</div>
				{this.renderSubmitBtnWithSPass({
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: 'Transfer',
					loadingText: 'Transfering...',
				})}
			</form>
		);
	}
}

export default TransferToOthersForm;
