import { useEffect, useState } from 'react';
import useIsMounted from './useIsMounted';

const useImage = (fileName) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [image, setImage] = useState(null);
	const isMounted = useIsMounted();

	useEffect(() => {
		const fetchImage = async () => {
			try {
				if (!fileName) return;
				const response = await import(`../img/${fileName}`);
				if (isMounted()) {
					setImage(response.default);
					setLoading(false);
				}
			} catch (err) {
				console.log(err);
				setError(err);
				setLoading(false);
			}
		};
		fetchImage();

		return () => {
			// console.log('useImage cleaned up');
		};
	}, [fileName, isMounted]);

	return { loading, error, image };
};

export default useImage;
