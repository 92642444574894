import Joi from 'joi-browser';
import React from 'react';
import Form from '../../../../common/form';

class NeedForm extends Form {
	state = {
		data: {
			answer: this.props.options.find((o) => o.checked === true).id,
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		answer: Joi.string().required().label('Answer'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP, options } = this.props;

		return (
			<form className='row mt-4' onSubmit={this.handleSubmit}>
				<div className='col-md-4 offset-md-4 col-12 text-start'>
					<div className='my-4'>
						{options.map((o, key) =>
							this.renderRadioInput({
								formClass: 'mb-2 fs-5',
								key,
								name: 'answer',
								id: o.id,
								label: o.label,
								checked: o.checked,
								disabled: o.disabled,
							})
						)}
					</div>

					{this.renderSubmitBtnWithSPass({
						divClass: 'my-4',
						hasSP,
						isLoading,
						text: 'Submit',
						loadingText: 'Submitting...',
					})}
				</div>
			</form>
		);
	}
}

export default NeedForm;
