import React from 'react';
import { Link } from 'react-router-dom';
import Image from './../common/image';

const Crowdfunding = () => (
	<div className='container mb-5'>
		<div className='row align-items-center'>
			<div className='col-md-6 pt-4'>
				<h5 className='text-uppercase text-blue'>What is</h5>
				<h1 className='text-purple'>Crowdfunding?</h1>
				<p>
					Crowdfunding is the practice of raising money for a project
					or venture by soliciting contributions from a large number
					of people. It is commonly used by entrepreneurs, inventors,
					musicians, filmmakers, artists, and charities to fund their
					projects. <br />
					<br />
					The method was first used by a British rock band named
					Marillion in 1997. The band did not have enough money to
					tour the United States, so they turned to their fans and
					raised $60,000 for the tour. This success became an
					inspiration for companies and startups to use crowdfunding.{' '}
					<br />
					<br />
					Crowdfunding typically takes place on online platforms for a
					set period with a specific funding goal. Projects use
					marketing techniques such as public relations, social media,
					and network marketing to attract investors.
				</p>
				<Link to={'/crowdfunding'} className={`btn btn-my-blue me-3`}>
					Read More
				</Link>
			</div>
			<div className='col-md-6'>
				<Image className={'w-100'} fileName={'home/crowdfunding.png'} />
			</div>
		</div>
	</div>
);

export default Crowdfunding;
