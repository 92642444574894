import Joi from 'joi-browser';
import React from 'react';
import Form from '../../../common/form';

class CencelForm extends Form {
	state = {
		data: {
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				{this.renderSubmitBtnWithSPass({
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: 'Cancel Current Request',
					loadingText: 'Submitting...',
				})}
			</form>
		);
	}
}

export default CencelForm;
