import React, { useEffect, useState } from 'react';
import useMain from '../../../../../hooks/useMain';
import P3Form from './p3Form';
import Joi from 'joi-browser';

const P3 = ({ onNextBtnClick, onFieldChange, heirsCount, onPrevBtnClick }) => {
	const { initData } = useMain();
	const [error, setError] = useState(false);
	const [p3Data, setP3Data] = useState({});
	useEffect(() => {
		const names = [
			'name',
			'surname',
			'fathersName',
			'nationalId',
			'relation',
			'priority',
			'percent',
		];
		const primData = {};

		for (let i = 0; i < heirsCount; i++)
			for (const j in names) primData[`${names[j]}_${i}`] = '';

		setP3Data({ ...primData });
		const props = Object.keys(primData);
		for (const i in props) {
			const elem = document.getElementById(props[i]);
			if (elem.tagName !== 'SELECT') elem.innerText = '';
		}
	}, [heirsCount]);

	const schema = {
		name: Joi.string().required().label('Name'),
		surname: Joi.string().required().label('Surname'),
		fathersName: Joi.string().required().label("Father's Name"),
		nationalId: Joi.string().required().label('National ID'),
		relation: Joi.string()
			.valid(...initData.mmiConfig.contract.heir.type)
			.required()
			.label('Relation'),
		priority: Joi.number()
			.min(1)
			.max(heirsCount || 0)
			.required()
			.label('Priority'),
		percent: Joi.number().min(0).max(100).required().label('Percent'),
	};

	const validate = () => {
		if (heirsCount === 0) return true;

		const myState = { ...p3Data };
		const props = Object.keys(myState);
		for (let i = 0; i < props.length; i++) {
			const errorMessage = validateProperty({
				name: props[i].split('_')[0],
				value: myState[props[i]],
			});
			if (errorMessage) {
				addError(props[i], errorMessage);
				return false;
			}
		}

		const arrayOfPercentages = Object.keys(myState).filter((d) =>
			d.includes('percent')
		);
		const totalPercentages = arrayOfPercentages.reduce(
			(t, a) => t + Number(myState[a]),
			0
		);

		if (totalPercentages !== 100) {
			setError('Total percentages must be equal to 100');
			for (const i in arrayOfPercentages) {
				const errorElement = document.getElementById(
					arrayOfPercentages[i]
				);
				errorElement?.classList.add('p3FormError');
			}
			return false;
		}

		return true;
	};

	const validateProperty = ({ name, value }) => {
		try {
			const obj = { [name]: value };
			const mySchema = { [name]: schema[name] };
			const { error } = Joi.validate(obj, mySchema);
			return error ? error.details[0].message : null;
		} catch (error) {
			console.log(error);
			return error.message;
		}
	};

	const addError = (name, msg) => {
		setError(msg);
		const errorElement = document.getElementById(name);
		errorElement?.classList.add('p3FormError');
	};

	const clearErrors = () => {
		setError(false);
		document
			.querySelectorAll('.p3FormError')
			.forEach((e) => e.classList.remove('p3FormError'));
	};

	const handleChange = ({ currentTarget: input }) => {
		onFieldChange('p3');
		clearErrors();
		const name = input.name || input.id;
		const value = input.value || input.innerText;
		const schemaName = name.split('_')[0];

		const errorMessage = validateProperty({
			name: schemaName,
			value,
		});
		if (errorMessage) addError(name, errorMessage);

		const data = { ...p3Data };
		data[name] = value;
		setP3Data(data);
	};

	const handleSubmit = () => {
		if (!validate()) return;
		onNextBtnClick({
			name: 'p3',
			data: heirsCount === 0 ? {} : p3Data,
		});
	};

	return (
		<div className='row mb-5 gy-5'>
			<div className='col-lg-10 offset-lg-1 col-12'>
				<div className='mb-1'>Page 3</div>
				<div className='fs-4 fw-bold mb-3'>Specify Heirs</div>
				{heirsCount > 0 ? (
					<P3Form
						p3FormState={{ ...p3Data }}
						heirsCount={heirsCount}
						heirTypes={initData.mmiConfig.contract.heir.type}
						onChange={handleChange}
					/>
				) : (
					<div className='fs-5'>
						Since you have not designated an heir, the defined
						capital will be given to the legal heir as defined by
						the laws of your respective country.
					</div>
				)}
			</div>
			{error && (
				<div className='col-lg-4 offset-lg-4 col-12 alert alert-danger p-2'>
					{error}
				</div>
			)}
			<div className='col-lg-4 offset-lg-4 col-12 text-center'>
				<button
					onClick={onPrevBtnClick}
					className='btn btn-my-navy me-2 me-lg-4'>
					<div className='d-flex align-items-center justify-content-center'>
						<i className='fas fa-arrow-left'></i>
						<span className='ms-2'>Previous</span>
					</div>
				</button>
				<button onClick={handleSubmit} className='btn btn-my-navy'>
					<div className='mx-3 d-flex align-items-center justify-content-center'>
						<span className='me-2'>Next</span>
						<i className='fas fa-arrow-right'></i>
					</div>
				</button>
			</div>
		</div>
	);
};

export default P3;
