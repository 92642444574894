import React from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import config from '../config';
import http from '../services/httpService';

const TimerProvider = ({ children, sessionTime }) => {
	const handleIdle = (event) => {
		// console.log('its idle');
		const user = http.getCurrentUser();
		if (!user) return;
		if (!user.exp || Date.now() > user.exp * 1000) http.logout();
	};

	const handleActive = async (event) => {
		// console.log('its active');
		const user = http.getCurrentUser();
		if (!sessionTime || !user) return;
		if (!user.exp) http.logout();
		if (Date.now() > user.iat * 1000 + config.reauthGap)
			try {
				// console.log('calling reauth');
				const { headers } = await http.reauth();
				http.setJwt(headers['x-auth-token']);
			} catch (ex) {
				console.log(ex);
			}
	};

	return (
		<IdleTimerProvider
			timeout={config.idleTimeout}
			crossTab={true}
			onIdle={handleIdle}
			onActive={handleActive}>
			{children}
		</IdleTimerProvider>
	);
};

export default TimerProvider;
