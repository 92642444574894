import { useEffect, useState } from 'react';
import http from './../services/httpService';

export default function useInitData() {
	const [initData, setInitData] = useState({});
	useEffect(() => {
		async function getInitData() {
			try {
				const { data } = await http.init();
				setInitData(data);
			} catch (error) {
				if (error.response) {
					console.log('error.response :>> ', error.response);
				} else if (error.request) {
					console.log('error.request :>> ', error.request);
					// return setInitData({ underMaintenance: true });
				} else {
					console.log('error :>> ', error);
				}
			}
		}
		getInitData();
	}, []);
	return [initData, setInitData];
}
