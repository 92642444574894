import React from 'react';
import Slider from 'react-slick';
import Image from '../common/image';
import articles from '../articles/articles';
import { Link } from 'react-router-dom';

function ArticleCarousel(props) {
	const settings = {
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div id='blog_carousel' className='container my-5 pb-5'>
			<div className='row'>
				<div className='col-12 px-5'>
					<Slider {...settings}>
						{articles
							.filter((b) => b.carousel === true)
							.map((b, key) => (
								<div key={key} className='px-2'>
									<div
										className='card'
										style={{ borderWidth: 0 }}>
										<Image
											className={'card-img-top'}
											fileName={`blogs/${b.image}`}
										/>
										<div className='card-body bg-light'>
											<h5 className='card-title'>
												{b.title}
											</h5>
											<p className='card-text'>
												{b.description}
											</p>
											<Link
												to={b.path}
												className='text-purple'>
												Read More
											</Link>
										</div>
									</div>
								</div>
							))}
					</Slider>
				</div>
			</div>
		</div>
	);
}

export default ArticleCarousel;
