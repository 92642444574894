import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class GetUserByEmailForm extends Form {
	state = {
		data: {
			email: '',
		},
		errors: {},
	};

	schema = {
		email: Joi.string().email().required().label('Email'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;

		return (
			<form className='row' onSubmit={this.handleSubmit}>
				<div className='col-md-6 offset-md-3 col-12 text-start'>
					<div className='mt-4'>
						{this.renderInput({
							name: 'email',
							label: 'Email',
							type: 'email',
						})}
					</div>

					<div className='my-3 text-center'>
						{this.renderButton(
							'Search user by email',
							`btn btn-my-navy w-100`,
							isLoading && <Spinner content='Loading...' />
						)}
					</div>
				</div>
			</form>
		);
	}
}

export default GetUserByEmailForm;
