import React, { useState } from 'react';
import { paginate } from '../../../utils/paginate';
import Pagination from '../../common/pagination';
import { numberWithCommas } from '../../../utils/helpers';

const CumTable = ({ data, rechargePointIndex }) => {
	const pageSize = 12;
	const [currentPage, setCurrentPage] = useState(1);
	const tableData = paginate(data, currentPage, pageSize);

	return (
		<>
			<table className='table table-sm table-striped text-center fs-7'>
				<thead>
					<tr>
						<th>Hour</th>
						<th>Time</th>
						<th>Count</th>
						<th>Amount</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{tableData.map((u, i) => (
						<tr
							className={u.hour - 1 >= rechargePointIndex ? 'bg-warning-subtle' : ''}
							key={i}>
							<td>{u.hour}</td>
							<td>{new Date(u.time).toLocaleString()}</td>
							<td>{u.count}</td>
							<td>{numberWithCommas(u.amount)} USDT</td>
							<td>{numberWithCommas(u.cum)} USDT</td>
						</tr>
					))}
				</tbody>
			</table>

			<Pagination
				customClass='mt-3 myPagination'
				activeClass='myActive'
				totalCount={data.length}
				pageSize={pageSize}
				currentPage={currentPage}
				onPageChange={setCurrentPage}
			/>
		</>
	);
};

export default CumTable;
