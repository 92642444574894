import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    section: {
        marginTop: 14,
    },
    formSubtitle: {
        marginLeft: 40,
        marginBottom: 4,
    },
    formBox: {
        border: '1px solid black',
        padding: 6,
    },
    formText: {
        fontSize: 10,
        marginBottom: 4,
    },
    formTextLast: {
        fontSize: 10,
        marginBottom: 0,
    },
});

const MmiObligations = () => (
    <View style={styles.section}>
        <Text style={styles.formSubtitle}>MMI Obligations</Text>
        <View style={styles.formBox}>
            <Text style={styles.formText}>
                1- In case of the natural death of the insured, the insurance
                capital, in addition to the redemption value of the policy, is
                paid to the heirs.
            </Text>
            <Text style={styles.formText}>
                2- In the event of the accidental death of the insured, the
                insurance capital, along with twice the amount and redemption
                value, is paid to the heirs.
            </Text>
            <Text style={styles.formText}>
                3- If the insured does not designate an heir, the defined
                capital outlined in items 1 and 2 will be given to the legal
                heir as defined by the laws of the respective country.
            </Text>
            <Text style={styles.formText}>
                4- In case of suicide of the insured, no amount will be refunded
                to the heirs..
            </Text>
            <Text style={styles.formText}>
                5- Upon redemption, only the capital of the redemption value
                will be paid.
            </Text>
            <Text style={styles.formText}>
                6- Upon policy completion, the insured can apply for retirement
                benefits, calculated and disbursed according to the profit table
                of the MMP WORLD project.
            </Text>
            <Text style={styles.formText}>
                7- Falling behind on installments for up to one month will
                result in no coverage for the insured.
            </Text>
            <Text style={styles.formText}>
                8- To receive the insurance capital, the heirs must inform the
                company within one month at most, and send their identification
                documents and a certificate of the insured's death from a legal
                physician in the country where the insured lived to the
                company's main office.
            </Text>
            <Text style={styles.formText}>
                9- If proof of prior knowledge of cancer, specific illnesses, or
                disability is established before the insurance policy, no
                capital will be paid to the heir.
            </Text>
            <Text style={styles.formTextLast}>
                10- In case of the insured's death after the age of 60 or before
                the age of 5, only the redemption value of the policy is granted
                to the heirs.
            </Text>
        </View>
    </View>
);

export default MmiObligations;
