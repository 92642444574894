import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import useMain from '../../hooks/useMain';

function MMP(props) {
	const { onHashLinkClick } = useMain();
	return (
		<div
			id='mmpCarousel'
			className='carousel slide'
			data-bs-ride='carousel'>
			<div className='carousel-indicators'>
				<button
					type='button'
					data-bs-target='#mmpCarousel'
					data-bs-slide-to='0'
					className='active'
					aria-current='true'
					aria-label='Slide 1'></button>
				<button
					type='button'
					data-bs-target='#mmpCarousel'
					data-bs-slide-to='1'
					aria-label='Slide 2'></button>
				<button
					type='button'
					data-bs-target='#mmpCarousel'
					data-bs-slide-to='2'
					aria-label='Slide 3'></button>
			</div>
			<div className='carousel-inner'>
				<div
					className='carousel-item active'
					data-bs-interval='10000'>
					<MMP1 onHashLinkClick={onHashLinkClick} />
				</div>
				<div
					className='carousel-item'
					data-bs-interval='10000'>
					<MMP2 onHashLinkClick={onHashLinkClick} />
				</div>
				<div
					className='carousel-item'
					data-bs-interval='10000'>
					<MMP3 onHashLinkClick={onHashLinkClick} />
				</div>
			</div>
			<button
				className='carousel-control-prev d-none d-md-block'
				type='button'
				data-bs-target='#mmpCarousel'
				data-bs-slide='prev'>
				<span
					className='carousel-control-prev-icon'
					aria-hidden='true'></span>
				<span className='visually-hidden'>Previous</span>
			</button>
			<button
				className='carousel-control-next d-none d-md-block'
				type='button'
				data-bs-target='#mmpCarousel'
				data-bs-slide='next'>
				<span
					className='carousel-control-next-icon'
					aria-hidden='true'></span>
				<span className='visually-hidden'>Next</span>
			</button>
		</div>
	);
}

const MMP1 = ({ onHashLinkClick }) => {
	return (
		<div
			id='mmp_1'
			className='d-block w-100'>
			<div className='container py-md-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>Market Maker Plan</b>
						</h3>
						<p>
							The MMP WORLD project is one of the initiatives of the BEM Foundation
							that began operations on August 12, 2022, operating under the auspices
							of the BEM Foundation. This project will operate crowdfunding and market
							maker platforms with the goal of providing market-making services for
							projects initiated by the BEM Foundation.
						</p>
						<NavHashLink
							className={`btn btn-my-blue me-3`}
							smooth
							scroll={onHashLinkClick}
							to={'/mmpworld#mmp_main'}>
							Read More
						</NavHashLink>
					</div>
				</div>
			</div>
		</div>
	);
};

const MMP2 = ({ onHashLinkClick }) => {
	return (
		<div
			id='mmp_2'
			className='d-block w-100'>
			<div className='container py-md-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>MMP World Project</b>
						</h3>
						<p>
							MMP WORLD is a platform that offers a range of financial services to
							help users generate additional income. The project's primary focus is on
							market-making, starting with the BCN token, which is one of the ten
							established projects of the BEM Foundation.
						</p>
						<NavHashLink
							className={`btn btn-my-blue me-3`}
							smooth
							scroll={onHashLinkClick}
							to={'/mmpworld#mmp_projects'}>
							Read More
						</NavHashLink>
					</div>
				</div>
			</div>
		</div>
	);
};

const MMP3 = ({ onHashLinkClick }) => {
	return (
		<div
			id='mmp_3'
			className='d-block w-100'>
			<div className='container py-md-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>MMP World Goals</b>
						</h3>
						<p>
							The MMP WORLD project has launched its first market maker project aimed
							at globalizing the BCN token. As a market maker, MMP WORLD offers
							comprehensive financial services to help users fund more ways to earn
							money in the cryptocurrency market. With its expertise in market making,
							MMP WORLD will provide liquidity and help ensure the stability of the
							BCN token in the market.
						</p>
						<NavHashLink
							className={`btn btn-my-blue me-3`}
							smooth
							scroll={onHashLinkClick}
							to={'/mmpworld#mmp_goals'}>
							Read More
						</NavHashLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MMP;
