import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { RiseLoader } from 'react-spinners';
import styles from '../../../../css/dashboard/ingredients.module.css';
import useMain from '../../../../hooks/useMain';
import useStarsResult from '../../../../hooks/useStarsResult';
import getCSSVars from '../../../../utils/getCSSVars';
import { numberWithCommas } from '../../../../utils/helpers';
import { paginate } from '../../../../utils/paginate';
import DigitalWatch from '../../../common/digitalWatch';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import StarsPioneersTable from '../../ingredients/starsPioneersTable';
import StarsTable from '../../ingredients/starsTable';

function Bonus() {
	const pageSize = 12;
	const { initData } = useMain();
	const { end, start, amount, pioneersAmount, team_1, team_2 } = initData.contests.stars;
	const [timeIsFinished, setTimeIsFinished] = useState(Date.now() >= end);
	const [currentPage, setCurrentPage] = useState(1);
	const [result] = useStarsResult();

	if (!result)
		return (
			<div className='h-50 d-flex justify-content-center align-items-center my-5'>
				<RiseLoader
					speedMultiplier={0.8}
					loading={true}
					size={20}
					sizeUnit='px'
					color={getCSSVars('my-blue')}
				/>
			</div>
		);

	const saleVolumScore =
		parseInt(((result.team_1 * 100) / (result.team_1 + result.team_2)) * 100) / 100;

	const team_1_data = [...result.table].filter((d) => d.team === team_1.id);
	const team_2_data = [...result.table].filter((d) => d.team === team_2.id);

	const team_1_persons = team_1_data.length;
	const team_2_persons = team_2_data.length;
	const personsScore =
		parseInt(((team_1_persons * 100) / (team_1_persons + team_2_persons)) * 100) / 100;

	const team_1_tickets = team_1_data.reduce((acc, d) => d.tickets + acc, 0);
	const team_2_tickets = team_2_data.reduce((acc, d) => d.tickets + acc, 0);
	const ticketsScore =
		parseInt(((team_1_tickets * 100) / (team_1_tickets + team_2_tickets)) * 100) / 100;

	const firstTwenty = result.table.slice(0, 20);
	const team_1_20 = firstTwenty.filter((d) => d.team === team_1.id).length;
	const team_2_20 = firstTwenty.filter((d) => d.team === team_2.id).length;
	const topTwentyScore = parseInt(((team_1_20 * 100) / (team_1_20 + team_2_20)) * 100) / 100;

	const pioneersData = [...result.pioneers];
	if (pioneersData.length < pioneersAmount) {
		for (let i = pioneersData.length; i < pioneersAmount; i++) {
			pioneersData.push({
				email: '',
				team: null,
				date: null,
			});
		}
	}
	for (const i in pioneersData) pioneersData[i].no = Number(i) + 1;
	const normalizedData = [...result.table];
	if (normalizedData.length < amount) {
		for (let i = normalizedData.length; i < amount; i++) {
			normalizedData.push({
				email: '',
				uid: null,
				tickets: null,
			});
		}
	}
	for (const i in normalizedData) normalizedData[i].no = Number(i) + 1;
	const data = paginate(normalizedData, currentPage, pageSize);

	if (Date.now() < start)
		return (
			<div className='row mt-4'>
				<div className='col text-center'>
					<h4>Tournament will start in:</h4>
					<Countdown
						className='h2'
						onComplete={() => window.location.reload()}
						date={start}
					/>
				</div>
			</div>
		);
	return (
		<div className='mb-4 card myCards'>
			<div className='card-body'>
				<div className='row text-center'>
					{timeIsFinished ? (
						<h2>Time Is Finished</h2>
					) : (
						<div className='text-center'>
							<div className='fw-bold fs-3 mb-1 text-uppercase'>Remaining Time</div>
							<DigitalWatch
								className='d-inline-block'
								onComplete={() => setTimeIsFinished(true)}
								date={end}
							/>
						</div>
					)}
				</div>

				<div className='row g-5 my-5'>
					<div className='offset-lg-1 col-lg-4'>
						<div className='row gy-5 align-items-center'>
							<div className='col-12'>
								<div className='text-center fw-bold fs-5 mb-2 text-uppercase'>
									Pioneers Table
								</div>
								<StarsPioneersTable data={pioneersData} />
							</div>

							<div className='col-12'>
								<div className='text-center fw-bold fs-5 mb-2 text-uppercase'>
									Teams stats
								</div>
								<hr />
								<div className='d-flex justify-content-evenly align-items-center mb-4'>
									<div className='text-center'>
										<Image
											height='50px'
											fileName={`stars/${team_1.id}.svg`}
										/>
										<div className='fs-5'>{team_1.name}</div>
									</div>
									<div className='text-center'>
										<Image
											height='50px'
											fileName={`stars/${team_2.id}.svg`}
										/>
										<div className='fs-5'>{team_2.name}</div>
									</div>
								</div>

								<div className='mx-4'>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='centerAll'>
											<span>{numberWithCommas(result.team_1 - 1)}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/usd.svg'
											/>
										</div>
										<div className='centerAll'>
											<span>{numberWithCommas(result.team_2 - 1)}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/usd.svg'
											/>
										</div>
									</div>
									<div
										style={{ height: '12px' }}
										className='progress'
										role='progressbar'
										aria-valuenow={saleVolumScore}
										aria-valuemin='0'
										aria-valuemax='100'>
										<div
											className='progress-bar'
											style={{ width: `${saleVolumScore}%` }}></div>{' '}
									</div>
									<div className='text-center'>Total Sale Volume</div>
								</div>

								<div className='mx-4 my-3'>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='centerAll'>
											<span>{team_1_persons}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/person.svg'
											/>
										</div>
										<div className='centerAll'>
											<span>{team_2_persons}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/person.svg'
											/>
										</div>
									</div>
									<div
										style={{ height: '12px' }}
										className='progress'
										role='progressbar'
										aria-valuenow={personsScore}
										aria-valuemin='0'
										aria-valuemax='100'>
										<div
											className='progress-bar'
											style={{ width: `${personsScore}%` }}></div>
									</div>
									<div className='text-center'>Total Persons</div>
								</div>

								<div className='mx-4 my-3'>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='centerAll'>
											<span>{team_1_20}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/crown_black.svg'
											/>
										</div>
										<div className='centerAll'>
											<span>{team_2_20}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/crown_black.svg'
											/>
										</div>
									</div>
									<div
										style={{ height: '12px' }}
										className='progress'
										role='progressbar'
										aria-valuenow={topTwentyScore}
										aria-valuemin='0'
										aria-valuemax='100'>
										<div
											className='progress-bar'
											style={{ width: `${topTwentyScore}%` }}></div>
									</div>
									<div className='text-center'>Top Twenty</div>
								</div>

								<div className='mx-4'>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='centerAll'>
											<span>{team_1_tickets}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/pass.svg'
											/>
										</div>
										<div className='centerAll'>
											<span>{team_2_tickets}</span>
											<Image
												className='ms-1'
												height='20px'
												fileName='icons/pass.svg'
											/>
										</div>
									</div>
									<div
										style={{ height: '12px' }}
										className='progress'
										role='progressbar'
										aria-valuenow={ticketsScore}
										aria-valuemin='0'
										aria-valuemax='100'>
										<div
											className='progress-bar'
											style={{ width: `${ticketsScore}%` }}></div>
									</div>
									<div className='text-center'>Total Tickets</div>
								</div>
							</div>
						</div>
					</div>

					<div className='offset-lg-1 col-lg-5'>
						{data.length > 0 ? (
							<div className='mb-5'>
								<div className='text-center fw-bold fs-5 mb-2 text-uppercase'>
									Tickets Table
								</div>
								<div className='d-flex justify-content-evenly align-items-center fs-5 my-3'>
									<div className='centerAll'>
										<div className='me-1'>
											{result.table.filter((i) => i.tickets > 0).length} /{' '}
											{amount}
										</div>
										<Image
											height='20px'
											className='starsTableNums mt-0'
											fileName='icons/user.svg'
										/>
									</div>
									<div>
										<span>{team_1_tickets + team_2_tickets}</span>
										<Image
											className='ms-1'
											height='35px'
											fileName='stars/ticket.svg'
										/>
									</div>
								</div>
								<StarsTable data={data} />
								<div className='d-flex justify-content-center'>
									<Pagination
										customClass={`mt-1 ${styles.myPagination}`}
										activeClass={styles.myActive}
										totalCount={normalizedData.length}
										pageSize={pageSize}
										currentPage={currentPage}
										onPageChange={setCurrentPage}
									/>
								</div>
							</div>
						) : (
							<div className='text-center mb-5'>
								<Image
									height='180px'
									fileName='icons/noData.svg'
								/>
								<h4 className='mt-4'>No Data</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Bonus;
