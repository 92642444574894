import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';

class PurchasePackageForm extends Form {
	state = {
		data: {
			amount: this.props.min,
			purchaseType: this.props.options.find((o) => o.checked === true).id,
			securityPassword: '',
		},
		errors: {},
		activationPercentage: 100,
		activationAmount: this.props.min,
		discountPercentage: 0,
		discountAmount: 0,
		isActivationEnough: this.props.activationBalance >= this.props.min,
		isDiscountEnough: false,
		isOverallBalanceEnough: this.props.activationBalance >= this.props.min,
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.max(this.props.max)
			.multiple(this.props.step)
			.required()
			.label('Amount')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message = 'Amount should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${this.props.min} USDT `;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		purchaseType: Joi.string().required().label('Purchase Type'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	updateBalances = (option, value) => {
		const activationAmount = parseInt((value * option.activation) / 100);
		const discountAmount =
			value - parseInt((value * option.activation) / 100);
		const isActivationEnough =
			this.props.activationBalance >= activationAmount;
		const isDiscountEnough = this.props.discountBalance >= discountAmount;
		const activationPercentage = option.activation;
		const discountPercentage = option.discount;

		this.setState({
			activationPercentage,
			discountPercentage,
			activationAmount,
			discountAmount,
			isActivationEnough,
			isDiscountEnough,
			isOverallBalanceEnough: isActivationEnough && isDiscountEnough,
		});
	};

	purchaseTypeChanged = ({ currentTarget: pType }) => {
		const { options } = this.props;
		const option = options.find((o) => o.id === pType.value);
		this.updateBalances(option, this.state.data.amount);
	};

	amountChanged = ({ currentTarget: amount }) => {
		const value = amount.value;
		const { options } = this.props;
		const option = options.find(
			(o) => o.id === this.state.data.purchaseType
		);
		this.updateBalances(option, value);
	};

	doSubmit = () =>
		this.props.onSubmit({
			...this.state.data,
			...{ isOverallBalanceEnough: this.state.isOverallBalanceEnough },
		});

	componentDidMount() {
		const { options, min } = this.props;
		const option = options.find((o) => o.checked === true);
		this.updateBalances(option, min);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.isSubmited === true &&
			prevProps.isSubmited !== this.props.isSubmited
		) {
			const state = { ...this.state };
			state.data = {
				amount: this.props.min,
				purchaseType: this.props.options.find((o) => o.checked === true)
					.id,
				securityPassword: '',
			};
			this.setState(state);
		}
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
		if (
			prevProps.activationBalance !== this.props.activationBalance ||
			prevProps.discountBalance !== this.props.discountBalance
		) {
			const { options } = this.props;
			const option = options.find(
				(o) => o.id === this.state.data.purchaseType
			);
			this.updateBalances(option, this.state.data.amount);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			options,
			hasSP,
			activationBalance,
			discountBalance,
			min,
			max,
			step,
			onAmountChange,
		} = this.props;

		const {
			activationPercentage,
			activationAmount,
			discountPercentage,
			discountAmount,
			isActivationEnough,
			isDiscountEnough,
		} = this.state;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onChange={this.props.onFormChange}
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'amount',
						label: 'Investment Package Amount',
						type: 'number',
						min,
						max,
						step,
						extraHandler: (e) => {
							this.amountChanged(e);
							onAmountChange(e);
						},
					})}
				</div>
				<div className='mb-3'>
					<div className='mb-1'>Purchase Type:</div>
					{options.map((o, key) =>
						this.renderRadioInput({
							key,
							name: 'purchaseType',
							id: o.id,
							label: o.label,
							labelClass: 'fw-semibold',
							checked: o.checked,
							disabled: o.disabled,
							formText: o.text,
							extraHandler: this.purchaseTypeChanged,
						})
					)}
				</div>
				<div
					className='row mb-3 mx-0'
					style={{
						borderWidth: '1px',
						borderStyle: 'solid',
						borderColor: '#0000002d',
						borderRadius: '0.375rem',
					}}>
					<div className='row p-3'>
						<div className='col-sm-6 text-center'>
							<div
								className={
									activationAmount > 0
										? `text-${
												isActivationEnough
													? 'success'
													: 'danger'
										  }`
										: ''
								}>
								My Activation Wallet Balance:{' '}
								<b>{activationBalance} $</b>
							</div>
							<hr className='m-1' />
							<div>
								Usage on Purchase:{' '}
								<b className='text-purple'>
									{activationPercentage} %
								</b>
							</div>
							<div className='d-flex align-items-center justify-content-center'>
								Amount:
								<b className='text-purple ms-2'>
									{activationAmount} ${' '}
								</b>
								{activationAmount > 0 && (
									<i
										className={`fas fa-${
											isActivationEnough
												? 'check text-success'
												: 'times text-danger'
										} ms-2`}></i>
								)}
							</div>
						</div>
						<div className='col-sm-6 text-center mt-sm-0 mt-4'>
							<div
								className={
									discountAmount > 0
										? `text-${
												isDiscountEnough
													? 'success'
													: 'danger'
										  }`
										: ''
								}>
								My Discount Wallet Balance:{' '}
								<b>{discountBalance} $</b>
							</div>
							<hr className='m-1' />
							<div>
								Usage on Purchase:{' '}
								<b className='text-purple'>
									{discountPercentage} %
								</b>
							</div>
							<div className='d-flex align-items-center justify-content-center'>
								Amount:
								<b className='text-purple ms-2'>
									{discountAmount} ${' '}
								</b>
								{discountAmount > 0 && (
									<i
										className={`fas fa-${
											isDiscountEnough
												? 'check text-success'
												: 'times text-danger'
										} ms-2`}></i>
								)}
							</div>
						</div>
					</div>
				</div>
				{this.renderSubmitBtnWithSPass({
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: 'Purchase',
					loadingText: 'Purchasing...',
				})}
			</form>
		);
	}
}

export default PurchasePackageForm;
