import React from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useTerminations from '../../hooks/useTerminations';
import Preloader from '../common/preloader';

function Terminations(props) {
	useDocumentTitle(['Terminations']);
	const [terminations] = useTerminations();
	if (!terminations) return <Preloader />;

	const users = terminations.sort((a, b) =>
		a.terminationRequest.date > b.terminationRequest.date ? 1 : -1
	);

	const handleViewUser = (user) =>
		props.history.push({
			pathname: '/userTerminationRequest',
			state: user,
		});

	return (
		<div className='container my-4'>
			<div className='row gy-5'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>
						Termination Requests
					</div>
				</div>

				<div className='col-12 text-center'>
					<div className='table-responsive'>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>#</th>
									<th>UID</th>
									<th>Email</th>
									<th>Termination Request Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{users.map((user, key) => (
									<tr key={key}>
										<td>{Number(key) + 1}</td>
										<td>{user.uid}</td>
										<td>{user.email}</td>
										<td>
											{new Date(
												user.terminationRequest.date
											).toLocaleString()}
										</td>
										<td>
											<button
												onClick={() =>
													handleViewUser(user)
												}
												className='btn btn-primary btn-sm'>
												View
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Terminations;
