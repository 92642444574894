import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useConfigs from '../../hooks/useConfigs';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMain from '../../hooks/useMain';
import http from '../../services/httpService';
import Preloader from '../common/preloader';
import Spinner from '../common/spinner';
import Image from './../common/image';

function Configs() {
	const { onInitDataChanged } = useMain();
	useDocumentTitle(['Configs']);
	const [isLoading, setIsLoading] = useState(false);
	const [currentName, setCurrentName] = useState(null);
	const [currentProp, setCurrentProp] = useState(null);
	const [configs, setConfigs] = useConfigs();
	if (!configs) return <Preloader />;

	const mmpw_rules = Object.keys(configs.mmpw);
	const main_rules = Object.keys(configs.main);

	const handleChangeConfig = async (name, prop) => {
		try {
			setIsLoading(true);
			setCurrentName(name);
			setCurrentProp(prop);
			const { data } = await http.setConfig({
				name,
				prop,
				rule: !configs[name][prop],
			});
			toast.success(data.message);
			setConfigs(data.data);
			if (name === 'mmpw') {
				const { data: initData } = await http.init();
				onInitDataChanged(initData);
			}
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
			setCurrentName(null);
			setCurrentProp(null);
		}
	};

	return (
		<div className='container my-4'>
			<div className='row gy-5'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>
						Rules Control
					</div>
				</div>

				<div className='col-12'>
					<div className='d-flex align-items-center justify-content-center mb-4'>
						<Image height='32px' fileName='icons/bemchain.png' />
						<div className='ms-2 fs-5 fw-bold'>Bemchain</div>
					</div>
					<hr />
					<div className='d-flex flex-wrap align-items-center justify-content-start'>
						{main_rules.map((r, key) => (
							<div key={key} className='mx-5 my-3'>
								<div>
									<span className='me-1'>{r}: </span>
									<span
										className={`fw-bold text-${
											configs.main[r]
												? 'success'
												: 'danger'
										}`}>
										{configs.main[r]
											? 'Active'
											: 'Inactive'}
									</span>
								</div>
								<button
									disabled={isLoading}
									onClick={() =>
										handleChangeConfig('main', r)
									}
									className='btn btn-sm btn-my-green'>
									{isLoading &&
									currentName === 'main' &&
									currentProp === r ? (
										<Spinner content={'loading...'} />
									) : (
										'Change'
									)}
								</button>
							</div>
						))}
					</div>
				</div>

				<div className='col-12'>
					<div className='d-flex align-items-center justify-content-center mb-4'>
						<Image height='32px' fileName='icons/mmp.png' />
						<div className='ms-2 fs-5 fw-bold'>MMP World</div>
					</div>
					<hr />
					<div className='d-flex flex-wrap align-items-center justify-content-start'>
						{mmpw_rules.map((r, key) => (
							<div key={key} className='mx-5 my-3'>
								<div>
									<span className='me-1'>{r}: </span>
									<span
										className={`fw-bold text-${
											configs.mmpw[r]
												? 'success'
												: 'danger'
										}`}>
										{configs.mmpw[r]
											? 'Active'
											: 'Inactive'}
									</span>
								</div>
								<button
									disabled={isLoading}
									onClick={() =>
										handleChangeConfig('mmpw', r)
									}
									className='btn btn-sm btn-my-blue'>
									{isLoading &&
									currentName === 'mmpw' &&
									currentProp === r ? (
										<Spinner content={'loading...'} />
									) : (
										'Change'
									)}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Configs;
