import React, { useState } from 'react';
import config from '../../../config';
import useMain from '../../../hooks/useMain';
import { paginate } from '../../../utils/paginate';
import { IncomeCard } from '../ingredients/cards';

function IncomeHistory() {
	const { userData } = useMain();
	const {
		binaryHistory,
		mlmHistory,
		sponsorHistory,
		stakingHistory,
		devRewardHistory,
	} = userData;
	const binary = binaryHistory.map((h) => ({ ...h, type: 'Binary' }));
	const mlm = mlmHistory.map((h) => ({ ...h, type: 'MLM' }));
	const sponsor = sponsorHistory.map((h) => ({ ...h, type: 'Sponsor' }));
	const staking = stakingHistory.map((h) => ({ ...h, type: 'Staking' }));
	const devReward = devRewardHistory.map((h) => ({
		...h,
		type: 'Development Reward',
	}));

	const merged = [...binary, ...mlm, ...sponsor, ...staking, ...devReward];
	const filtered = merged.filter((m) => m.commission > 0);
	const sorted = filtered.sort((a, b) => (a.date > b.date ? -1 : 1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;

	const [currentPage, setCurrentPage] = useState(1);
	const data = paginate(sorted, currentPage, config.tablePageSize);

	const getChartData = () => {
		const colors = ['#3da405', '#ffd800', '#ff7800', '#f93eea', '#7f94ff'];
		const labels = [
			'Binary',
			'MLM',
			'Sponsor',
			'Staking',
			'Development Reward',
		];

		const data = [];
		const metaData = [];
		const totalIncome = merged.reduce((a, b) => a + b.commission, 0);
		const title = `Total Income: $ ${(totalIncome / 1e6).toFixed(2)}`;

		for (const i in labels) {
			const myFiltered = merged.filter((u) => u.type === labels[i]);
			data.push(
				(
					(myFiltered.reduce((a, b) => a + b.commission, 0) * 100) /
					totalIncome
				).toFixed(1)
			);
			metaData.push(
				myFiltered.reduce((a, b) => a + b.commission, 0) / 1e6
			);
		}
		const toolTipCB = ({ label, dataIndex, parsed }) =>
			`${label} Income: $ ${metaData[dataIndex]} (${parsed}%)`;

		return {
			colors,
			labels,
			data,
			toolTipCB,
			title,
			legendPosition: 'bottom',
		};
	};
	return (
		<IncomeCard
			data={data}
			itemsCount={filtered.length}
			pageSize={config.tablePageSize}
			currentPage={currentPage}
			onPageChange={setCurrentPage}
			chartData={getChartData()}
		/>
	);
}

export default IncomeHistory;
