import React, { useState } from 'react';
import { paginate } from '../../utils/paginate';
import useAllHomeBlogs from './../../hooks/useAllHomeBlogs';
import BlogCard from './blogCard';
import Pagination from './../common/pagination';
import styles from '../../css/dashboard/ingredients.module.css';

function Blogs(props) {
	const [blogs] = useAllHomeBlogs();
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 4;
	const data = paginate(blogs, currentPage, pageSize);

	if (!blogs) return null;
	return (
		<div className='container'>
			<div className='row'>
				<div className='col-12 text-center'>
					<h3>Latest News</h3>
				</div>
			</div>
			<div className='row mt-5'>
				{data.map((d, key) => (
					<div key={key} className='col-sm-3 mb-4'>
						<BlogCard to={`/blog/${d._id}`} blog={d} />
					</div>
				))}
			</div>
			<div className='row mb-5'>
				<div className='col-12 text-end'>
					<Pagination
						customClass={`mt-1 ${styles.myPagination}`}
						activeClass={styles.myActive}
						totalCount={blogs.length}
						pageSize={pageSize}
						currentPage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</div>
			</div>
		</div>
	);
}

export default Blogs;
