import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000';
const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		borderColor: borderColor,
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 'auto',
		textAlign: 'center',
		fontStyle: 'bold',
	},
	tableHeader: {
		width: '18.75%',
		fontSize: 8,
		borderRightColor: borderColor,
		borderRightWidth: 1,
	},
	tableSubHeader: {
		width: '6.25%',
		fontSize: 8,
		borderRightColor: borderColor,
		borderRightWidth: 1,
	},
});

const DashboardTableSubHeader = () => (
	<View style={styles.container}>
		<Text style={styles.tableHeader}>Insurance Premium</Text>
		<Text style={styles.tableSubHeader}>Monthly</Text>
		<Text style={styles.tableSubHeader}>Annual</Text>
		<Text style={styles.tableSubHeader}>Total</Text>
	</View>
);

export default DashboardTableSubHeader;
