import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMain from '../../hooks/useMain';
import http from '../../services/httpService';
import Preloader from './../common/preloader';
import ResourceCard from './resources/resourceCard';

function Resources(props) {
	useDocumentTitle(['Resources']);
	const { resources, userData, onRemoveResource, onUserDataChanged } =
		useMain();
	const [isLoading, setIsLoading] = useState(false);

	const handleDelete = async (_id) => {
		try {
			setIsLoading(true);
			const { data } = await http.deleteResource({ _id });
			toast.success(data.message);
			onRemoveResource(_id);
			onUserDataChanged({ resources: data.resources });
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	if (!resources || !userData._id) return <Preloader />;
	return (
		<div className='container my-4'>
			<div className='row gy-5'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>
						Media Resources
					</div>
				</div>
				<div className='col-12'>
					<Link
						to={'/resources/addResource'}
						className={'btn btn-my-navy'}>
						<i className='me-2 fas fa-plus'></i>Add Resource
					</Link>
				</div>

				{resources
					.sort((a, b) => (a.date > b.date ? -1 : 1))
					.map((r, key) => (
						<div key={key} className='col-sm-2 mb-4'>
							<ResourceCard
								{...props}
								isLoading={isLoading}
								onDelete={handleDelete}
								resource={r}
							/>
						</div>
					))}
			</div>
		</div>
	);
}

export default Resources;
