import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    formSubtitle: {
        marginLeft: 40,
        marginBottom: 4,
    },
    formBox: {
        border: '1px solid black',
        padding: 8,
    },
    formRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
    },
    formRowLast: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 0,
    },
    formInputContainer: {
        width: 260,
        flexDirection: 'row',
    },
    formInputLabel: {
        paddingRight: 4,
    },
});

const MmiInsured = ({ personalData }) => (
    <View>
        <Text style={styles.formSubtitle}>Insured</Text>
        <View style={styles.formBox}>
            <View style={styles.formRow}>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Name:</Text>
                    <Text style={styles.formInput}>{personalData.name}</Text>
                </View>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Surname:</Text>
                    <Text style={styles.formInput}>{personalData.surname}</Text>
                </View>
            </View>
            <View style={styles.formRow}>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Father's Name:</Text>
                    <Text style={styles.formInput}>
                        {personalData.fathersName}
                    </Text>
                </View>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Mother's Name:</Text>
                    <Text style={styles.formInput}>
                        {personalData.mothersName}
                    </Text>
                </View>
            </View>
            <View style={styles.formRow}>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>National ID:</Text>
                    <Text style={styles.formInput}>
                        {personalData.nationalId}
                    </Text>
                </View>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Gender:</Text>
                    <Text style={styles.formInput}>{personalData.gender}</Text>
                </View>
            </View>
            <View style={styles.formRow}>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Date of Birth:</Text>
                    <Text style={styles.formInput}>
                        {personalData.dateOfBirth}
                    </Text>
                </View>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Place of Birth:</Text>
                    <Text style={styles.formInput}>
                        {personalData.placeOfBirth}
                    </Text>
                </View>
            </View>
            <View style={styles.formRowLast}>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Email:</Text>
                    <Text style={styles.formInput}>{personalData.email}</Text>
                </View>
                <View style={styles.formInputContainer}>
                    <Text style={styles.formInputLabel}>Phone:</Text>
                    <Text style={styles.formInput}>{personalData.phone}</Text>
                </View>
            </View>
        </View>
    </View>
);

export default MmiInsured;
