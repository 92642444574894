import React from 'react';
import { Link, Redirect, Switch } from 'react-router-dom';
import useMain from '../../hooks/useMain';
import AccessDenied from '../accessDenied';
import ComingSoon from '../comingSoon';
import AlertBox from '../common/alertBox';
import ProtectedRoute from '../common/protectedRoute';
import Email from '../email';
import HeaderImage from './ingredients/headerImage';
import MainPanelHeader from './mainPanelHeader';
import Summary from './summary';
import tabs from './tabs.json';
import Preview from './tabs/preview/preview';
import Image from '../common/image';

const MainPanel = () => {
	const { userData, uiInfo, mediaQuery, initData } = useMain();
	const isEmailConfirmed = userData.emailStatus === 'Active';
	const isTerminating = userData.terminationRequest.isActive;
	const isTerminated = userData.terminationRequest.isApproved;

	const routes = getComponents(isEmailConfirmed, isTerminating, isTerminated);
	const redirects = [];
	for (const i in tabs) {
		const tab = tabs[i];
		const hasChildren = tab.children && tab.children.length > 0;
		if (!tab.toBeShown || !hasChildren) continue;
		const firstActiveChildIndex = tab.children.findIndex((c) => c.toBeShown === true);
		redirects.push(
			<Redirect
				key={i}
				from={`/dashboard/${tab.id}`}
				to={`/dashboard/${tab.id}/${tab.children[firstActiveChildIndex].id}`}
			/>
		);
	}

	return (
		<Switch>
			{routes.map(({ path, Component, tab, crumbs, name }, key) => (
				<ProtectedRoute
					exact
					path={path}
					key={key}
					name='test'
					render={(props) => (
						<>
							<MainPanelHeader
								crumbs={crumbs}
								name={name}
							/>
							<div className='d-block d-md-none'>
								<Summary />
							</div>
							<main
								id='contentsCard'
								className='card contentsCard mt-4 mb-4 mb-md-0'>
								<div
									id='contentsBody'
									className='card-body contentsBody p-lg-4 my-md-2 me-md-2'
									style={
										mediaQuery === 'xs' || mediaQuery === 'sm'
											? null
											: {
													overflowX: 'hidden',
													overflowY: 'scroll',
													height: `calc(100vh - ${uiInfo.mainPanel}px)`,
											  }
									}>
									{getUserAlerts(userData, initData)}
									<HeaderImage tab={tab} />
									<Component
										{...props}
										tab={tab}
									/>
								</div>
							</main>
						</>
					)}
				/>
			))}
			{redirects}
			<Redirect
				from='/dashboard'
				to='/dashboard/home'
			/>
			<Redirect to='/notFound' />
		</Switch>
	);
};

const getComponents = (isEmailConfirmed, isTerminating, isTerminated) => {
	const routes = [];
	for (let i = 0; i < tabs.length; i++) {
		const tab = tabs[i];
		if (!tab.toBeShown) continue;
		const hasChildren = tab.children && tab.children.length > 0;
		if (!hasChildren) {
			const route = {
				tab,
				path: `/dashboard/${tab.id}`,
				name: tab.name,
				crumbs: [tab.name],
				Component:
					tab.email && !isEmailConfirmed
						? Email
						: !tab.active
						? () => ComingSoon({ withLink: false })
						: tab.termination && (isTerminating || isTerminated)
						? () => AccessDenied({ withLink: false })
						: tab.isPreview
						? () => Preview({ tab })
						: require(`./tabs/${tab.id}`).default,
			};
			routes.push(route);
		} else {
			for (const j in tab.children) {
				const child = tab.children[j];
				if (!child.toBeShown) continue;
				const route = {
					tab: child,
					path: `/dashboard/${tab.id}/${child.id}`,
					name: child.name,
					crumbs: [tab.name, child.name],
					Component:
						child.email && !isEmailConfirmed
							? Email
							: !child.active
							? () => ComingSoon({ withLink: false })
							: child.termination && (isTerminating || isTerminated)
							? () => AccessDenied({ withLink: false })
							: require(`./tabs/${tab.id}/${child.template || child.id}`).default,
				};
				routes.push(route);
			}
		}
	}
	return routes;
};

const getUserAlerts = (user, initData) => {
	return (
		<>
			{user.terminationRequest.isApproved && (
				<AlertBox
					extraClass='alertBox'
					type='danger'
					content='This account is Early Terminated. some features are not active.'
				/>
			)}
			{user.terminationRequest.isActive && (
				<AlertBox
					extraClass='alertBox'
					type='danger'
					content='Early Termination is in progress. some features are not active.'
				/>
			)}
			{initData.kycRequest &&
				user.verificationLevel === 'level_0' &&
				user.verificationRequest.status !== 'pending' && (
					<AlertBox
						extraClass='alertBox'
						type='danger'
						icon={
							<Image
								height='40px'
								className='me-2'
								fileName='icons/identity.svg'
							/>
						}
						content={
							<Link
								to='/dashboard/myProfile/kyc'
								style={{
									textDecoration: 'none',
									color: 'unset',
								}}>
								Verify Your Identity: KYC Process Required.
							</Link>
						}
					/>
				)}
		</>
	);
};

export default MainPanel;
