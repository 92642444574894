import React from 'react';
import Image from './../common/image';

function Shadow(props) {
	return (
		<div className='mt-0 pt-0 mb-3 d-none d-md-block'>
			<Image className={'w-100'} fileName={'home/shadow.png'} />
		</div>
	);
}

export default Shadow;
