import React from 'react';

const Socials = (props) => {
	const { data, navClass, iconsClass } = props;

	return (
		<nav className={`nav ${navClass}`}>
			{data.map((d) => (
				<a
					key={data.indexOf(d)}
					href={d.link}
					target='_blank'
					rel='noreferrer'
					className={`nav-link me-1 ${iconsClass}`}>
					<i className={d.className}></i>
				</a>
			))}
		</nav>
	);
};

export default Socials;
