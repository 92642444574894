import React, { useEffect, useState } from 'react';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import InstallmentTable from '../../ingredients/installmentTable';

const InstallmentsTable = () => {
	const pageSize = 15;
	const { userData } = useMain();
	const { mmi } = userData;
	const [currentPage, setCurrentPage] = useState(1);
	const data = paginate(mmi.installments, currentPage, pageSize);

	useEffect(() => {
		if (mmi.installments.length > 0) {
			const index = mmi.installments.findIndex(
				(i) => i._id === mmi.nextPayment
			);
			setCurrentPage(Math.floor((index + 1) / pageSize) + 1);
		}
	}, [mmi]);

	return (
		<div className='card myCards'>
			<div className='row p-4'>
				<div className='col-md-10 offset-md-1 col-12'>
					<div className='fs-1 py-4 text-center'>
						Installments Table
					</div>
					{mmi.installments.length > 0 ? (
						<>
							<InstallmentTable data={data} />
							<Pagination
								customClass='mt-1 myPagination'
								activeClass='myActive'
								totalCount={mmi.installments.length}
								pageSize={pageSize}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</>
					) : (
						<div className='text-center my-5'>
							<Image
								height={'180px'}
								fileName={'icons/noData.svg'}
							/>
							<h4 className='mt-4'>
								There are no installments yet.
							</h4>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InstallmentsTable;
