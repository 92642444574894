import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useStarsResult() {
	const [result, setResult] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/dashboard/bonus/stars', '/dashboard/bonus/stars', '/dashboard/home'];
		async function getResult() {
			try {
				if (!http.getCurrentUser() || result || !allowed.includes(pathname)) return;
				const { data } = await http.getStarsResult();
				if (isMounted()) setResult(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getResult();
	}, [isMounted, result, pathname]);
	return [result, setResult];
}
