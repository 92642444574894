import React, { useContext } from 'react';
import MainContext from '../../../context/mainContext';
import ReceiptModal from './receiptModal';

const TransferHistoryTable = ({ data }) => {
	const { userData } = useContext(MainContext);
	const getBC = (bc) => (bc === userData.bemchain.uid ? 'Myself' : bc);

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Side</th>
						<th>Amount</th>
						<th>Transfer Type</th>
						<th>From</th>
						<th>To</th>
						<th>Remarks</th>
						<th>Receipt</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{d.no}</td>
							<td>{new Date(d.date).toLocaleString()}</td>
							<td>{d.side}</td>
							<td>{d.amount / 1e6} USDT</td>
							<td>{d.transferType}</td>
							<td>{getBC(d.sender)}</td>
							<td>{getBC(d.receiver)}</td>
							<td>{d.remarks}</td>
							<td>
								{d.side === 'Others' ? (
									<ReceiptModal data={d} />
								) : (
									''
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TransferHistoryTable;
