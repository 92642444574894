import React from 'react';
import { Link } from 'react-router-dom';
import useMain from '../../../hooks/useMain';

const InstallmentTable = ({ data }) => {
	const { initData } = useMain();
	const getDescription = (tx) =>
		tx ? `"${tx.type}" ${new Date(tx.date).toLocaleString()}` : '...';

	const getPayment = (isPaid, i) =>
		isPaid ? (
			'Is Paid'
		) : data[i - 1] && data[i - 1].isPaid ? (
			<Link to='/dashboard/mmi/installmentPayment'>Awaiting Payment</Link>
		) : (
			'Not Paid'
		);

	const getDeadLine = (date) => {
		const miliseconds =
			new Date(date).getTime() + initData.mmiConfig.paymentDeadline;
		return new Date(miliseconds).toLocaleString();
	};

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Number</th>
						<th>ِDue Date</th>
						<th>Deadline</th>
						<th>Amount</th>
						<th>Payment</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{`Installment ${d.number}`}</td>
							<td>{new Date(d.dueDate).toLocaleString()}</td>
							<td>{getDeadLine(d.dueDate)}</td>
							<td>$ {d.amount / 1e6}</td>
							<td>{getPayment(d.isPaid, Number(key))}</td>
							<td>{getDescription(d.transaction)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default InstallmentTable;
