import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

const DoughnutChart = ({ chartData }) => {
	const {
		data,
		labels,
		colors,
		toolTipCB,
		title,
		textColor = '#656565',
		legendPosition = 'left',
	} = chartData;

	return (
		<Chart
			type='doughnut'
			data={{
				datasets: [
					{
						data,
						backgroundColor: colors.map((c) => c + 'd1'),
						hoverBackgroundColor: colors,
					},
				],
				labels,
			}}
			options={{
				plugins: {
					legend: {
						position: legendPosition,
						labels: {
							color: textColor,
						},
					},
					title: {
						display: true,
						text: title,
						color: textColor,
						font: {
							size: '20',
						},
					},

					tooltip: {
						callbacks: {
							label: (context) => toolTipCB(context),
						},
					},
				},
			}}
		/>
	);
};

export { DoughnutChart };
