import React from 'react';
import useMain from '../../../../hooks/useMain';
import TransferToOthersForm from './transferToOthersForm';

const TransferToOthersTab = ({
	isLoading,
	othersErrors,
	onSubmit,
	onFormChange,
	isSubmited,
}) => {
	const { userData, initData } = useMain();

	return (
		<div
			className='tab-pane fade'
			id='others-tab-pane'
			role='tabpanel'
			aria-labelledby='others-tab'
			tabIndex='0'>
			<div className='row p-4'>
				<div className='col-sm-8 offset-sm-2 col-12'>
					<TransferToOthersForm
						isSubmited={isSubmited}
						onFormChange={onFormChange}
						errors={othersErrors}
						isLoading={isLoading}
						onSubmit={onSubmit}
						wallets={initData.wallets}
						activation_Balance={
							userData.wallets.activation.available / 1e6
						}
						discount_Balance={
							userData.wallets.discount.available / 1e6
						}
						hasSP={userData.hasSP}
						min={initData.minTransfer}
						step={initData.stepTransfer}
						options={initData.othersTransferOptions}
						remarks={initData.remarks}
					/>
				</div>
			</div>
		</div>
	);
};

export default TransferToOthersTab;
