import { StyleSheet, Text, View } from '@react-pdf/renderer';
import MmiHeirsTableHeader from './MmiHeirsTableHeader';
import MmiHeirsTableRow from './MmiHeirsTableRow';

const styles = StyleSheet.create({
    section: {
        marginTop: 14,
    },
    formBox: {
        border: '1px solid black',
        padding: 10,
    },
    formText: {
        textAlign: 'center',
        lineHeight: 1.3,
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
        borderColor: '#000000',
        borderBottom: 'none',
    },
    formSubtitle: {
        marginLeft: 40,
        marginBottom: 4,
    },
});

const MmiHeirsTable = ({ personalData }) => {
    if (personalData.heirs.length === 0) {
        return (
            <View style={styles.section}>
                <Text style={styles.formSubtitle}>Heirs</Text>
                <View style={styles.formBox}>
                    <Text style={styles.formText}>
                        No heirs are designated.
                    </Text>
                    <Text style={styles.formText}>
                        The defined capital will be given to the legal heirs as
                        defined by the laws of the respective country.
                    </Text>
                </View>
            </View>
        );
    } else {
        return (
            <View style={styles.section}>
                <Text style={styles.formSubtitle}>Heirs</Text>
                <View style={styles.tableContainer}>
                    <MmiHeirsTableHeader />
                    <MmiHeirsTableRow personalData={personalData} />
                </View>
            </View>
        );
    }
};

export default MmiHeirsTable;
