import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import http from '../../services/httpService';
import Preloader from './../common/preloader';
import useDebts from '../../hooks/useDebts';
import Spinner from '../common/spinner';

function Debts(props) {
	useDocumentTitle(['Debts']);
	const [isLoading, setIsLoading] = useState(false);
	const [debts, setDebts] = useDebts();
	if (!debts) return <Preloader />;

	const handleEditDebt = (_id) =>
		props.history.push({
			pathname: '/debts/editDebt',
			state: debts.find((d) => d._id === _id),
		});

	const handleClearDebt = async (_id) => {
		try {
			setIsLoading(true);
			const { data } = await http.clearDebt({ _id });
			toast.success(data.message);
			setDebts(data.result);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='container my-4'>
			<div className='row gy-5'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>
						Official Debts
					</div>
				</div>
				<div className='col-12'>
					<Link to={'/debts/newDebt'} className={'btn btn-my-navy'}>
						<i className='me-2 fas fa-plus'></i>Add New Official
						Debt
					</Link>
				</div>

				<div className='col-12 text-center'>
					<div className='table-responsive'>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>#</th>
									<th>UID</th>
									<th>Email</th>
									<th>Total Amount</th>
									<th>Remaining Amount</th>
									<th>Total Payback (CW)</th>
									<th>Percentage</th>
									<th>Deadline</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{debts.map(({ uid, email, debt, _id }, key) => (
									<tr key={key}>
										<td>{Number(key) + 1}</td>
										<td>{uid}</td>
										<td>{email}</td>
										<td>
											${' '}
											{debt.history.find(
												(d) => d.action !== 'Payback'
											).amount / 1e6}
										</td>
										<td>$ {debt.amount / 1e6}</td>
										<td>
											${' '}
											{(debt.history.find(
												(d) => d.action !== 'Payback'
											).amount -
												debt.amount) /
												1e6}
										</td>
										<td>
											{debt.paybackPercentage * 100} %
										</td>
										<td>
											{new Date(
												debt.deadline
											).toLocaleString()}
										</td>
										<td>
											<button
												onClick={() =>
													handleEditDebt(_id)
												}
												className='btn btn-primary btn-sm'>
												{isLoading ? (
													<Spinner
														content={'loading'}
													/>
												) : (
													'Edit Debt'
												)}
											</button>
										</td>
										<td>
											<button
												onClick={() =>
													handleClearDebt(_id)
												}
												className='btn btn-success btn-sm'>
												{isLoading ? (
													<Spinner
														content={'loading'}
													/>
												) : (
													'Clear Debt'
												)}
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Debts;
