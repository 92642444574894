import React, { useState } from 'react';
import Viewer from 'react-viewer';

function ImgViewerExternal({ height, src, title }) {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<div>
				<div className='text-center fw-bold mb-3'>{title}</div>
				<img
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setVisible(true);
					}}
					loading='lazy'
					src={src}
					alt={title}
					crossOrigin='true'
					height={height}
				/>
			</div>
			<Viewer
				noToolbar
				visible={visible}
				onClose={() => {
					setVisible(false);
				}}
				images={[
					{
						src: src,
						alt: `view_${title}`,
						crossorigin: 'true',
					},
				]}
			/>
		</>
	);
}

export default ImgViewerExternal;
