import React, { useRef } from 'react';
import useCollapsible from '../hooks/useCollapsible';
import useMain from '../hooks/useMain';
import Preloader from './common/preloader';
import Header from './dashboard/header';
import MainPanel from './dashboard/mainPanel';
import SideBar from './dashboard/sideBar';
import Summary from './dashboard/summary';
import tabs from './dashboard/tabs.json';

const Dashboard = (props) => {
	const { userData, initData } = useMain();
	const myNavRef = useRef();
	const [myCollapsible, showOthers] = useCollapsible(myNavRef);

	if (!initData.emailGap || !userData._id) return <Preloader />;

	const pathArray = props.location.pathname.split('/');
	const currentTab = pathArray[pathArray.length - 1];
	const userTabs = [...tabs];

	return (
		<div className='dashboardPage'>
			<Header onTogglerClicked={() => myCollapsible.toggle()} />
			<div className='container-fluid'>
				<div className='row pt-3'>
					<div className='col-md-4 col-lg-3 col-xl-2'>
						<div className='d-none d-md-block'>
							<Summary />
						</div>
						<SideBar
							tabs={userTabs}
							activePath={currentTab}
							myNavRef={myNavRef}
							myCollapsible={myCollapsible}
						/>
					</div>
					<div className={`col-md-8 col-lg-9 col-xl-10 ${showOthers ? '' : 'd-none'}`}>
						<MainPanel />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
