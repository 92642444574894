import React, { useState } from 'react';
import useMain from '../../../../../hooks/useMain';
import Image from '../../../../common/image';
import P1 from './p1';
import P2 from './p2';
import P3 from './p3';
import P4 from './p4';
import P5 from './p5';
import P6 from './p6';

const Pages = () => {
	const { mediaQuery } = useMain();
	const isSmall =
		mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md';

	const [currentPage, setCurrentPage] = useState(0);
	const [totalData, setTotalData] = useState({
		p1: null,
		p2: null,
		p3: null,
		p4: null,
		p5: null,
	});

	const handleNextBtnClick = ({ name, data }) => {
		const newState = { ...totalData };
		newState[name] = data;
		setTotalData(newState);
		setCurrentPage((o) => ++o);
	};

	const handlePrevBtnClick = () => setCurrentPage((o) => --o);

	const handleFieldChange = (name) => {
		const newState = { ...totalData };
		newState[name] = null;
		setTotalData(newState);
	};

	const myPages = [
		{
			name: 'p1',
			content: (
				<P1
					onNextBtnClick={handleNextBtnClick}
					onFieldChange={handleFieldChange}
				/>
			),
		},
		{
			name: 'p2',
			content: (
				<P2
					onPrevBtnClick={handlePrevBtnClick}
					onNextBtnClick={handleNextBtnClick}
					onFieldChange={handleFieldChange}
				/>
			),
		},
		{
			name: 'p3',
			content: (
				<P3
					heirsCount={Number(totalData.p1?.heirsCount)}
					onPrevBtnClick={handlePrevBtnClick}
					onNextBtnClick={handleNextBtnClick}
					onFieldChange={handleFieldChange}
				/>
			),
		},
		{
			name: 'p4',
			content: (
				<P4
					onPrevBtnClick={handlePrevBtnClick}
					onNextBtnClick={handleNextBtnClick}
					onFieldChange={handleFieldChange}
				/>
			),
		},
		{
			name: 'p5',
			content: (
				<P5
					onPrevBtnClick={handlePrevBtnClick}
					onNextBtnClick={handleNextBtnClick}
					onFieldChange={handleFieldChange}
				/>
			),
		},
		{
			name: 'p6',
			content: (
				<P6 onPrevBtnClick={handlePrevBtnClick} totalData={totalData} />
			),
		},
	];

	return (
		<>
			<div className='mb-4 fs-5'>
				Please fill the following forms carefully:
			</div>
			<nav className='mb-4 d-flex justify-content-center align-items-center'>
				{myPages.map((n, k) => (
					<div
						key={k}
						className={`contractNavs position-relative ${
							k === currentPage ? 'active' : ''
						}${Number(k) <= 0 ? '' : isSmall ? ' ms-3' : ' ms-5'} ${
							totalData[n.name] ? ' compelte' : ''
						}`}
						role='tab'
						data-page={k}
						aria-controls={`nav-${n.name}`}
						aria-selected={Number(k) === currentPage}>
						<Image
							fileName={`contract/${n.name}.png`}
							width={isSmall ? '32px' : '64px'}
						/>
						{totalData[n.name] && (
							<Image
								className='trueImage'
								fileName='contract/true.png'
								width={isSmall ? '14px' : '25px'}
							/>
						)}
					</div>
				))}
			</nav>

			<div className='tabContent'>
				{myPages.map((n, k) => (
					<div
						key={k}
						className={`fade ${
							k === currentPage ? 'd-block show' : 'd-none'
						}`}
						role='tabpanel'
						aria-labelledby={`nav-${n.name}-tab`}>
						<div>{n.content}</div>
					</div>
				))}
			</div>
		</>
	);
};

export default Pages;
