import React from 'react';
import { TextCard } from '../../ingredients/cards';
import Anchor from './../../../common/anchor';

const DownloadMT4 = () => {
	return (
		<div className='row'>
			<div className='col-12'>
				<TextCard
					title={''}
					titleClass={''}
					text={
						<>
							<div className='row my-4'>
								<div className='col mx-5'>
									<div className='mb-5'>
										<h3>MT4 for Android</h3>
										<p>
											Download Meta Trader 4 for Android
											on your smartphone or tablet and
											trade cryptocurrencies, forex,
											stocks, CFD and futures instruments
											anytime and anywhere in the world!
										</p>
										<p>
											Our free Android OS app lets you
											trade securely on the MMP platform
											while you’re on the go from any
											compatible Android mobile device. It
											has all the features and
											functionalities of the desktop
											version, which include customizable
											tools and full access to your
											trading portfolio.
										</p>
										<p>
											All you need is an Internet
											connection to access all trading
											analysis, interactive real-time
											price charts with zoom and scroll
											options, some of the most popular
											technical indicators and tools,
											detailed online trading history and
											many more helpful tools to help you
											navigate the dynamic, intricate
											financial markets landscape. In
											short, MT4 offers the perfect
											solution to even the most demanding
											trading needs.
										</p>
										<Anchor
											href={
												'https://download.mql5.com/cdn/mobile/mt4/android?utm_source=www.metatrader4.com&utm_campaign=download'
											}
											content={
												<div>
													<i className='fas fa-download me-2'></i>
													<span>Download</span>
												</div>
											}
											className={'btn btn-my-navy'}
										/>
									</div>
									<div className='mb-5'>
										<h3>MT4 for Apple</h3>
										<p>
											Meta Trader 4 for iOS allows traders
											to trade on the full range of
											financial instruments available with
											MMP. Traders can trade in various
											financial markets via their desktop
											or mobile Apple devices.
										</p>
										<p>
											Not only does it boast a set of
											high-performance trading charts, it
											also has a customizable chart color
											scheme, dozens of financial news and
											reading materials, secure and fast
											chats with other traders, trade
											levels visualizing the prices of
											pending orders, as well as accurate
											SL and TP values on each chart.
										</p>
										<p>
											The mobile trading platform also
											allows you to receive quotes of
											financial instruments, analyze
											markets using charts and technical
											indicators, perform demo trades,
											connect to brokers for live trading
											and view the history of trading
											operations. Its functionality is
											nearly identical to the desktop
											trading platform, and allows our
											traders to trade anytime, anywhere.
										</p>
										<Anchor
											href={
												'https://download.mql5.com/cdn/mobile/mt4/ios?utm_source=www.metatrader4.com&utm_campaign=download'
											}
											content={
												<div>
													<i className='fas fa-download me-2'></i>
													<span>Download</span>
												</div>
											}
											className={'btn btn-my-navy'}
										/>
									</div>
									<div className='mb-5'>
										<h3>MT4 for Windows</h3>
										<p>
											The Meta Trader 4 platform is
											today’s gold standard in innovative
											and powerful trading platforms. It
											is capable of processing dozens of
											financial instruments and is used by
											retail investors to buy and sell
											currencies. In addition to allowing
											users to trade in forex, the MT4
											platform supports futures, indices,
											equities and other contracts for
											difference (CFD) transactions.
										</p>
										<p>
											MT4 with MMP is perfect for traders
											of all experience levels and is
											recognized for its user-friendly
											interface, advanced charting
											features, technical analysis tools,
											and automated trading capabilities.
											Our Meta Trader 4 platform has an
											information window that displays
											trading orders, trading history,
											emails, news, and even transaction
											logs, all from the app itself. It
											also supports multiple account base
											currencies such as USD, EUR, RBP,
											SGD, and PLN as well as over 30
											different languages for an
											international client base.
										</p>
										<p>
											Its distributed architecture, robust
											security system, and convenient
											mobile trading functionality are
											some of the core competencies that
											give MetaTrader 4 its competitive
											advantages.
										</p>

										<Anchor
											href={
												'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/mt4setup.exe?utm_source=www.metatrader4.com&utm_campaign=download'
											}
											content={
												<div>
													<i className='fas fa-download me-2'></i>
													<span>Download</span>
												</div>
											}
											className={'btn btn-my-navy'}
										/>
									</div>
								</div>
							</div>
						</>
					}
				/>
			</div>
		</div>
	);
};

export default DownloadMT4;
