import React, { useState } from 'react';
import { toast } from 'react-toastify';
import http from '../../../services/httpService';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import DebtForm from './debtForm';

function NewDebt({ history, location }) {
	useDocumentTitle(['New Debt']);
	const [isLoading, setIsLoading] = useState(false);
	const { pathname, state } = location;
	const isEdit = pathname === '/debts/editDebt';

	const user = {
		email: isEdit ? state.email : '',
		amount: isEdit ? state.debt.amount / 1e6 : '',
		percent: isEdit ? state.debt.paybackPercentage * 100 : 50,
		deadline: isEdit
			? state.debt.deadline.substring(0, 10)
			: new Date().toISOString().substring(0, 10),
	};

	const handleSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.addNewDebt({
				email: formData.email,
				amount: formData.amount,
				percent: formData.percent,
				deadline: new Date(formData.deadline).getTime(),
			});
			setIsLoading(false);
			toast.success(data.message);
			history.push(`/debts`);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex.response && (ex.response.status === 400 || 403)) {
				console.log(ex.response);
				toast.error(ex.response.data);
			}
		}
	};

	return (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-md-4 offset-md-4 col-12'>
					<h1 className='text-center mb-3'>Add New Debt</h1>
					<div className='card myCards'>
						<div className='card-body'>
							<DebtForm
								{...user}
								minAmount={10}
								stepAmount={1}
								minPercent={1}
								maxPercent={50}
								stepPercent={1}
								onSubmit={handleSubmit}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewDebt;
