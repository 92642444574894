import React from 'react';
import useMain from '../../../../../hooks/useMain';
import Image from '../../../../common/image';
import DownloadContract from '../pdf/DownloadContract';

const Active = () => {
	const { userData } = useMain();
	const { mmi } = userData;

	return (
		<div className='row'>
			<div className='col-12 text-center mb-5'>
				<Image height='60px' fileName='success.png' className='mb-1' />
				<div className='fs-5 fw-bold my-2'>
					Your insurance services contract is active.
				</div>
				<div>You can download the contract pdf file here:</div>

				<DownloadContract
					data={mmi.contractData}
					className='mt-4'
					title='MY MMI CONTRACT'
					fileName={`contarct_${mmi.code}`}
				/>
			</div>
		</div>
	);
};

export default Active;
