import React from 'react';
import useMain from '../../../../../hooks/useMain';
import P2Form from './p2Form';

const P2 = ({ onPrevBtnClick, onNextBtnClick, onFieldChange }) => {
	const { initData } = useMain();

	const handleSubmit = (formData) =>
		onNextBtnClick({ name: 'p2', data: formData });

	return (
		<div className='row mb-5'>
			<div className='col-lg-8 offset-lg-2 col-12'>
				<div className='mb-1'>Page 2</div>
				<div className='fs-4 fw-bold mb-3'>Personal Information</div>
				<P2Form
					onPrevBtnClick={onPrevBtnClick}
					handleChange={onFieldChange}
					onSubmit={handleSubmit}
					options={initData.mmiConfig.contract.yesNoOptions}
				/>
			</div>
		</div>
	);
};

export default P2;
