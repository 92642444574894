import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import Spinner from './common/spinner';

class MMIQueryTableForm extends Form {
	state = {
		data: {
			amount: this.props.min,
			contractPeriod: this.props.contractPeriod.max,
			birthDate: '',
			paymentMethod: this.props.paymentMethod[0].content,
			increasePercent: this.props.increasePercent[2].content,
		},
		errors: {},
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.multiple(this.props.step)
			.required()
			.label('Amount')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message = 'Amount should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${this.props.min} USDT`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		contractPeriod: Joi.number()
			.min(this.props.contractPeriod.min)
			.max(this.props.contractPeriod.max)
			.multiple(this.props.contractPeriod.step)
			.required()
			.label('Contract Period')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message =
								'Contract Period should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${this.props.contractPeriod.min} years`;
							break;
						case 'number.max':
							err.message = `Invalid amount, maximum amount is ${this.props.contractPeriod.max} years`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		birthDate: Joi.string().required().label('Date of Birth'),
		paymentMethod: Joi.string()
			.valid(...this.props.paymentMethod.map((p) => p.content))
			.required()
			.label('Payment Method'),
		increasePercent: Joi.string()
			.valid(...this.props.increasePercent.map((p) => p.content))
			.required()
			.label('Increase Percent'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			contractPeriod,
			paymentMethod,
			increasePercent,
			min,
			step,
		} = this.props;

		return (
			<form
				className='row text-start mt-4'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-md-4 mb-3'>
					{this.renderInput({
						name: 'amount',
						label: 'Insurance Package Amount (usd):',
						type: 'number',
						min,
						step,
						extraHandler: (e) => this.amountChanged(e),
					})}
				</div>

				<div className='col-md-4 mb-3'>
					{this.renderInput({
						name: 'birthDate',
						label: 'Date of Birth:',
						max: this.props.birthDate,
						type: 'date',
					})}
				</div>
				<div className='col-md-4 mb-3'>
					{this.renderInput({
						name: 'contractPeriod',
						label: 'Contract Period (year):',
						type: 'number',
						min: contractPeriod.min,
						max: contractPeriod.max,
						step: contractPeriod.step,
					})}
				</div>
				<div className='col-md-6 mb-3'>
					<div className='mb-2'>Increase Percent:</div>
					{increasePercent.map((i, key) =>
						this.renderRadioInput({
							key,
							formClass: 'form-check-inline',
							name: 'increasePercent',
							id: i.content,
							label: i.content,
							checked:
								i.content === this.state.data.increasePercent,
							disabled: false,
						})
					)}
				</div>
				<div className='col-md-6 mb-3'>
					<div className='mb-2'>Payment Method:</div>
					{paymentMethod.map((p, key) =>
						this.renderRadioInput({
							key,
							formClass: 'form-check-inline',
							name: 'paymentMethod',
							id: p.content,
							label: p.content,
							checked:
								p.content === this.state.data.paymentMethod,
							disabled: false,
						})
					)}
				</div>

				<div className='text-center'>
					{this.renderButton(
						'Query Table',
						'btn btn-my-navy',
						isLoading && <Spinner content='Loading...' />
					)}
				</div>
			</form>
		);
	}
}

export default MMIQueryTableForm;
