import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Big from 'big.js';
import { numberWithCommas } from '../../../../utils/helpers';

class DEFIActivationRequestForm extends Form {
	state = {
		data: {
			amount: this.props.min,
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.max(this.props.max)
			.multiple(this.props.step)
			.required()
			.label('Investment Amount'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP, min, max, step, walletBalance, trxRate } =
			this.props;
		const { amount } = this.state.data;
		return (
			<div className='mt-4'>
				<div className='d-flex justify-content-evenly mb-4'>
					<span>
						DEFI Wallet Balance:{' '}
						<b>{numberWithCommas(walletBalance)} USD</b>
					</span>
					<span>
						Estimated needed TRX:{' '}
						<b>
							{amount
								? numberWithCommas(
										Big(amount).div(Big(trxRate)).toFixed(6)
								  )
								: 0}{' '}
							TRX
						</b>{' '}
						({amount} USDT)
					</span>
				</div>
				<form
					className='text-start'
					autoComplete='off'
					onSubmit={this.handleSubmit}>
					<div className='mb-3'>
						{this.renderInput({
							name: 'amount',
							min,
							max,
							step,
							label: 'Investment amount',
							type: 'number',
						})}
					</div>

					{this.renderSubmitBtnWithSPass({
						divClass: 'mb-3',
						hasSP,
						isLoading,
						text: 'Submit',
						loadingText: 'Submitting...',
					})}
				</form>
			</div>
		);
	}
}

export default DEFIActivationRequestForm;
