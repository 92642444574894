import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useMain from '../../../hooks/useMain';
import http from '../../../services/httpService';
import Image from '../../common/image';
import Preloader from '../../common/preloader';
import UserTerminationRequestForm from './userTerminationRequestForm';

function UserTerminationRequest(props) {
	useDocumentTitle(['User Termination Request']);
	const { initData } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	if (!props.location.state) window.location = '/';
	const { email, uid, terminationRequest, _id, debt } = props.location.state;
	const {
		activeInvestments: data,
		date: requestDate,
		lastCard,
		usedMaxCap,
		maxCap,
		paidInstallments: MMIData,
	} = terminationRequest;

	if (!initData.termination) return <Preloader />;

	const hasPackage = data.length > 0;
	const hasMMI = MMIData.length > 0;

	const getPercentage = (date) => {
		const time = new Date(requestDate).getTime() - new Date(date).getTime();
		const rule = initData.termination.find((c) => {
			return time < c.max && time >= c.min;
		});

		return rule ? (1 - rule.percentage) * 100 : 0;
	};

	const getMMIRefundPercentage = (date) => {
		const time = new Date(requestDate).getTime() - new Date(date).getTime();
		const rule = initData.mmiConfig.termination.find((c) => {
			return time < c.max && time >= c.min;
		});

		return rule ? rule.percentage * 100 : 0;
	};

	const getRefund = (d) => (d.amount / 1e6) * (getPercentage(d.date) / 100);
	const getMMIRefund = (d) =>
		(d.amount / 1e6) * (getMMIRefundPercentage(d.transaction.date) / 100);

	const totalFromPackage = data
		.map((d) => getRefund(d))
		.reduce((a, s) => a + s, 0);
	const totalFromMMI = MMIData.map((d) => getMMIRefund(d)).reduce(
		(a, s) => a + s,
		0
	);

	const packageValue = data.map((d) => d.amount).reduce((a, s) => a + s, 0);
	const xValue = maxCap - packageValue;
	const isAboveXValue = usedMaxCap > xValue;

	const totalRefund = isAboveXValue
		? (maxCap - usedMaxCap) / 1e6
		: totalFromPackage + totalFromMMI;

	const getCorporateDebtPercentage = (date) => {
		const time = new Date(requestDate).getTime() - new Date(date).getTime();
		const rule = initData.terminationDebt.find((c) => {
			return time < c.max && time >= c.min;
		});

		return rule.percentage * 100 || 0;
	};

	const handleSubmit = async (formData) => {
		const { amount } = formData;
		try {
			setIsLoading(true);
			const { data } = await http.approveTermination({ amount, _id });
			toast.success(data.message);
			setIsLoading(false);
			props.history.replace('/terminations');
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		}
	};

	return (
		<div className='container'>
			<div className='row my-3'>
				<div className='col-12 text-center'>
					<h2>User Termination Request</h2>
					<hr />
				</div>
			</div>

			<div className='row my-4'>
				<div className='col-md-10 offset-md-1 col-12'>
					<div className='alert alert-dark mb-4'>
						<div className='row'>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Email:</b> {email}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>UID:</b> {uid}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Last Card:</b> {lastCard}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Investment Package Value:</b> $
								{packageValue / 1e6}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Termination Date:</b>{' '}
								{new Date(requestDate).toLocaleString()}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Max CAP:</b> ${maxCap / 1e6}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Total Profit:</b> ${usedMaxCap / 1e6}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>MMI:</b> {hasMMI ? 'Active' : 'Not Active'}
							</div>
							<div className='col-sm-4 mb-3 text-center'>
								<b>Paid Installments:</b> {MMIData.length}
							</div>
							<div className='col-sm-12 mb-3 text-center'>
								<div className={debt > 0 ? 'text-danger' : ''}>
									<b>Current Official Debt:</b> ${debt / 1e6}
								</div>
							</div>
						</div>
					</div>

					<div className='alert alert-dark mb-4'>
						<div className='fs-5 mb-3 text-center'>
							Investment Packages
						</div>
						{hasPackage ? (
							<div className='table-responsive'>
								<table className='table table-sm myTable text-center'>
									<thead>
										<tr>
											<th>#</th>
											<th>Date</th>
											<th>Amount</th>
											<th>Purchase Type</th>
											<th>Active Days</th>
											<th>Refund Percentage</th>
											<th>Refund Amount</th>
											<th>Corporate Debt Percentage</th>
										</tr>
									</thead>
									<tbody>
										{data.map((d, key) => {
											return (
												<tr key={key}>
													<td>{Number(key) + 1}</td>
													<td>
														{new Date(
															d.date
														).toLocaleString()}
													</td>
													<td>
														{'$' + d.amount / 1e6}
													</td>
													<td>{d.purchaseType}</td>
													<td>
														{parseInt(
															(new Date(
																requestDate
															).getTime() -
																new Date(
																	d.date
																).getTime()) /
																(24 *
																	60 *
																	60 *
																	1000)
														)}{' '}
														days
													</td>
													<td>
														{getPercentage(d.date)}{' '}
														%
													</td>
													<td>${getRefund(d)}</td>
													<td>
														{getCorporateDebtPercentage(
															d.date
														)}
														%
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : (
							<div className='d-flex align-items-center justify-content-center'>
								<Image
									height='50px'
									fileName='icons/noData.svg'
								/>
								<div className='ms-2'>
									There is no active investment package
								</div>
							</div>
						)}
						<div className='text-end'>
							<b>Total Investment Refund: ${totalFromPackage}</b>
						</div>
					</div>

					<div className='alert alert-dark mb-4'>
						<div className='fs-5 mb-3 text-center'>
							MMI Installments
						</div>
						{hasMMI ? (
							<div className='table-responsive'>
								<table className='table table-sm myTable text-center'>
									<thead>
										<tr>
											<th>#</th>
											<th>Payment Date</th>
											<th>Amount</th>
											<th>Payment Type</th>
											<th>Active Years</th>
											<th>Refund Percentage</th>
											<th>Refund Amount</th>
											<th>Corporate Debt Percentage</th>
										</tr>
									</thead>
									<tbody>
										{MMIData.map((d, key) => {
											return (
												<tr key={key}>
													<td>{d.number}</td>
													<td>
														{new Date(
															d.transaction.date
														).toLocaleString()}
													</td>
													<td>
														{'$' + d.amount / 1e6}
													</td>
													<td>
														{d.transaction.type}
													</td>
													<td>
														{(
															(new Date(
																requestDate
															).getTime() -
																new Date(
																	d.transaction.date
																).getTime()) /
															(365 *
																24 *
																60 *
																60 *
																1000)
														).toFixed(1)}{' '}
														years
													</td>
													<td>
														{getMMIRefundPercentage(
															d.transaction.date
														)}{' '}
														%
													</td>
													<td>${getMMIRefund(d)}</td>
													<td>
														{getCorporateDebtPercentage(
															d.transaction.date
														)}
														%
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : (
							<div className='d-flex align-items-center justify-content-center'>
								<Image
									height='50px'
									fileName='icons/noData.svg'
								/>
								<div className='ms-2'>
									MMI is not activated.
								</div>
							</div>
						)}
						<div className='text-end'>
							<b>Total MMI Refund: ${totalFromMMI}</b>
						</div>
					</div>

					<div className='alert alert-info mb-4 text-center'>
						<div className='mb-3'>
							<div className='d-flex align-items-center justify-content-center'>
								<div>
									<div>
										<b>Total Profit</b>
									</div>{' '}
									<div>(${usedMaxCap / 1e6})</div>{' '}
								</div>
								<div className='mx-4'>
									<b className='fs-3'>
										{isAboveXValue ? '>' : '≦'}
									</b>
								</div>
								<div>
									<div>
										<b>Max CAP - Package Value</b>
									</div>
									<div>
										(${maxCap / 1e6} - ${packageValue / 1e6}{' '}
										= ${(maxCap - packageValue) / 1e6})
									</div>
								</div>
							</div>
						</div>
						<div className='fs-5'>
							Maximum Possible Refund: <br />
							{isAboveXValue ? (
								<div>
									Max CAP - Total Profit = ${maxCap / 1e6} - $
									{usedMaxCap / 1e6} = <b>${totalRefund}</b>
								</div>
							) : (
								<b>${totalRefund}</b>
							)}
						</div>
					</div>
				</div>
			</div>
			<hr />

			<div className='row mb-4'>
				<div className='col-sm-4 offset-sm-4 col-12'>
					<UserTerminationRequestForm
						onSubmit={handleSubmit}
						max={totalRefund}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
}

export default UserTerminationRequest;
