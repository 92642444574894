import Anchor from '../common/anchor';
import Image from '../common/image';

const mmi = {
	path: '/mmi',
	name: 'MMI',
	title: 'Market Maker Insurance (MMI)',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='offset-md-3 col-md-6 col-12 text-center'>
					<Image className='w-75 mb-4' fileName='logoMMI.svg' />
				</div>
			</div>

			<div className='row'>
				<div className='col-12'>
					<div className='fw-bold text-center fs-3 my-4'>
						Services & privacy policy
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Death for any
						reason
					</div>
					<div>
						If the investor dies for any reason other than suicide,
						the compensation for death for any reason will be given
						to the heirs. If the investor directly specifies the
						heirs, the compensation due to death will be paid to the
						specified heirs, otherwise it will be paid to his legal
						heirs through the company. In case of death for any
						reason, 120 times the monthly insurance premium up to
						$50,000 will be given to the investors. This service is
						provided for people between 5 and 60 years old.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Death due to an
						accident
					</div>
					<div>
						If the investor's death is due to an accident, in
						addition to receiving compensation for death for any
						reason, the compensation for the death from the accident
						will be awarded to the heirs. In case of death due to an
						accident, 240 times the monthly insurance premium up to
						$50,000 will be awarded to the investors. This service
						is provided for people between 5 and 60 years old.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Total and
						permanent disability
					</div>
					<div>
						Total and permanent disability is awarded to investors
						who become permanently and completely medically disabled
						due to an illness or accident. Disability is a state
						where the insured person has lost 66% or more of his
						working power. When the working power of the insured
						person decreases between 33 and 66% due to illness or
						accident caused by work, it is included as partial
						disability. In this case, no compensation is awarded. In
						the case of total disability, 20 times the monthly
						premium up to 5000 dollars is awarded to the investor.
						Disability compensation is provided for people aged 18
						to 50.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Special diseases
					</div>
					<div>
						Compensation for special diseases is awarded to
						investors who include one of the following:
					</div>
					<ol>
						<li> Heart attack</li>
						<li> Stroke</li>
						<li>
							Transplantation of major body organs such as:
							kidney, liver, heart, lung and bone marrow
						</li>
						<li> Open heart surgery</li>
						<li>
							Cancers such as bone cancer, malignant breast
							cancer, blood cancer, brain tumors, skin cancer,
							stomach cancer, lung cancer
						</li>
					</ol>
					<div>
						For special diseases, 25 times the monthly premium up to
						5000 dollars is awarded for each disease (5 types of
						special diseases). This service is provided for people
						aged 15 to 60.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Physical
						disability
					</div>
					<div>
						Payment of compensation for physical disability is
						awarded to investors who suffer from one of the
						following cases:
					</div>
					<ol>
						<li> Complete blindness in both eyes</li>
						<li>
							Permanent disability of both hands, at least from
							the wrist completely
						</li>
						<li>
							Permanent disability of both legs, at least from the
							paw completely
						</li>
						<li>
							Permanent disability of one arm, at least from the
							wrist, and one leg, at least from the paw
						</li>
						<li> Complete spinal cord injury</li>
						<li> Complete deafness in both ears</li>
						<li> Removal of the lower jaw</li>
					</ol>

					<div>
						In the case of physical disability, 30 times the monthly
						premium up to 5000 dollars will be awarded to the
						investor. This service is provided for people aged 15 to
						60.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Fire incident
					</div>
					<div>
						Fire incidents include incidents caused by fire,
						explosions and lightning. This service is provided only
						for the investor's place of residence, whose address is
						included in the contract form. Obviously, the fire
						insurance compensation can only be proven and paid for
						with the report of the fire department. Fire
						compensation up to 15 times the monthly insurance
						premium up to $2000 is awarded to investors. This
						service has no age limit.
					</div>
					<br />
					<div>
						<strong>Note:</strong> The condition for payment of
						compensation is that the insurance policy installments
						are up to date at the time of the accident or illness,
						and the principle of good faith is observed at the time
						of completing the contract form.
					</div>

					<div className='fw-bold text-center fs-3 my-4'>
						MMI obligations
					</div>
					<ol>
						<li>
							In the case of natural death of the insured, the
							insurance capital, in addition to the redemption
							value of the policy, is paid to the heirs.
						</li>
						<li>
							In the event of accidental death of the insured, the
							insurance capital, along with twice the amount and
							redemption value, is paid to the heirs.
						</li>
						<li>
							If the insured does not designate an heir, the
							defined capital outlined in items 1 and 2 will be
							given to the legal heir as defined by the laws of
							the respective country.
						</li>
						<li>
							In case of suicide of the insured, no amount will be
							refunded to the heirs.
						</li>
						<li>
							Upon redemption, only the capital of the redemption
							value will be paid.
						</li>
						<li>
							Upon policy completion, the insured can apply for
							retirement benefits, calculated and disbursed
							according to the profit table of the MMP WORLD
							project.
						</li>
						<li>
							Falling behind on installments for up to one month
							will result in no coverage for the insured.
						</li>
						<li>
							In order to receive the insurance capital, the heirs
							must inform the company within one month at most,
							and send their identification documents and a
							certificate of the insured's death from a legal
							physician in the country where the insured lived, to
							the company's main office.
						</li>
						<li>
							If proof of prior knowledge of cancer, specific
							illnesses, or disability is established before the
							insurance policy, no capital will be paid to the
							heir.
						</li>
						<li>
							In case of the insured's death after the age of 60
							or before the age of 5, only the redemption value of
							the policy is granted to the heirs.
						</li>
					</ol>

					<div className='fw-bold text-center fs-3 my-4'>
						Participation period profit and Guaranteed profit
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						Guaranteed participation period profit:
					</div>
					<div>
						The guaranteed investment return rate by MMI, calculated
						and paid to investors under any circumstances, has been
						set at 10%. This rate is on account and the profit from
						the participation period is added to it each year.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						Participation period profit in benefits:
					</div>
					<div>
						The allocated profit to investors is calculated and
						publicly announced every January. We calculate the
						difference in excess between this profit and the
						guaranteed profit for all investors, and it is added to
						their accumulated profit for compound interest.
					</div>
					<br />
					<div>
						<strong>Note:</strong> The basis for long-term
						investment returns in the MMI plan is daily
						interest-bearing investments. Therefore, timely
						installment payments, paying installments sooner, or
						delaying installment payments will directly impact the
						total returns at the end of each MMI investor's
						investment.
					</div>

					<div className='fw-bold text-center fs-3 my-4'>
						General conditions
					</div>
					<ul>
						<li> Minimum investment: $50</li>
						<li> Maximum investment: None</li>
						<li> Annual increase percent (5% - 10% - 15% - 20%)</li>
						<li> Minimum age of the investor: From birth</li>
						<li> Maximum age of the investor: None</li>
						<li>
							Contract period: minimum 3 years, maximum 30 years
						</li>
						<li>
							Applying changes to the insurance policy can be done
							by sending the application form, and only after the
							company's approval.
						</li>
						<li> Applying changes may take up to 1 month.</li>
						<li>
							It is possible to apply changes to each investor
							only 5 times, and once per year.
						</li>
						<li>
							In case of non-payment of installments for 24 hours,
							the service will be disabled and no compensation
							will be paid in case of an accident.
						</li>
						<li>
							The service will be activated from the time the
							installments are repaid and the installments are
							up-to-date.
						</li>
						<li>
							If the application form is not filled in, only
							long-term investment will be activated for the
							investor.
						</li>
						<li>
							By filling out the service form, 10% of the annual
							investment amount will be deducted for the service.
						</li>
					</ul>

					<div className='fw-bold text-center fs-3 my-4'>
						General conditions of MMI investment
					</div>
					<div className='fw-bold text-center fs-5 my-4'>
						Definitions and general conditions of long-term
						investment in MMI project
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>Investor:</div>
					<div>
						It is a natural person who concludes the investment
						contract with the MMI project and undertakes to pay the
						installments in the order listed in the installments
						table.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>MMI project:</div>
					<div>
						The legal company is a subsidiary of BEM Foundation,
						which concludes the investment agreement between the
						foundation and the investor and provides investment
						services and coverage.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						BEM Foundation:
					</div>
					<Anchor
						content='https://bemfoundation.com'
						href='https://bemfoundation.com'
					/>

					<div className='fw-bold fs-5 mt-4 mb-1'>Heirs:</div>
					<div>
						A natural person or natural persons that the investor
						introduces through the service request form for the
						benefit of death compensation and investment amounts. If
						the heirs are not introduced by the investor, the heirs
						will be identified based on the laws of the country
						where the investor lives.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>Service:</div>
					<div>
						The service consists of 6 compensation items, which are
						included in the executive obligations of the MMI project
						and are explained in the general conditions.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>Investment:</div>
					<div>
						Long-term investment is one of the projects of BEM
						Foundation, which is implemented under the support of
						the MMP project and with the executive management of the
						MMI project at the international level.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Rights arising from the contract:
					</div>
					<div>
						This contract is between the MMI project and the
						investor, of which investment is an integral part. The
						MMI project undertakes to be honest and diligent in
						maintaining the investor's capital, and to try to make
						the investment as profitable as possible, and according
						to the defined laws, to pay the investor's right to
						receive investment and profit and to receive services.
					</div>
					<div>
						Also, the investor undertakes to provide only the truth
						and avoid any deceptive behavior to receive services,
						and to be diligent in updating his or her installments.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Transfer of investment contract:
					</div>
					<div>
						In no case is it possible to transfer the investment
						contract to another person.
					</div>
					<div>
						Only if the investor dies, or is declared medically
						incapable of making correct decisions, can the
						investment be transferred to the designated heirs or
						legal heirs. Otherwise, the investment will end and all
						the capital with its profit will go to the heirs.
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Reestablishment of services:
					</div>
					<div>
						If the investor does not pay one or more installments,
						the investment will not be included in the service, and
						the service will be suspended.
					</div>
					<div>
						The time frame for paying the installments is only 24
						hours and after that the service will be suspended.
					</div>
					<div>
						To re-establish the services, after paying and updating
						the installments, the investor must send the addendum to
						the company and acknowledge that he or she was healthy
						when not paying the installments. Otherwise, the
						decision to establish services will be with the MMI
						project.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						Additional risks:
					</div>
					<ol>
						<li>
							Risk of air travel: If the investor travels as a
							normal passenger by passenger plane or Airbus on
							authorized commercial transportation lines, he will
							be covered by the services without paying additional
							service fees and without the need to give prior
							notice to the MMI project. Death due to speed
							competition and exploratory and aerobatic flights
							and any kind of air flight training does not include
							any services, and in case of death during such
							flights, only the amount saved up to the day of the
							accident will be paid by MMI.
						</li>
						<li>
							Risk of war: If the investor dies as a result of
							offensive and defensive operations or any other type
							of accident that has a military aspect against the
							attacking force, MMI will only pay the balance of
							the investment paid until the day of the accident.
							If the investor is sent to war as a soldier or in a
							military organization under any title, the investor
							is not subject to any service from the date of
							deployment and only the investment contract remains
							active.
						</li>
						<li>
							Police operations will be considered military
							operations.
						</li>
					</ol>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Risks that are not subject to compensation:
					</div>
					<div>
						If the investor commits suicide or dies as a result of a
						suicide attempt, the following conditions will be
						followed:
					</div>
					<ul>
						<li>
							No compensation will be paid to the heirs for death
							due to any cause or accidental death.
						</li>
						<li>
							If three full years have not passed since the
							investment period, only the investment payments will
							be returned.
						</li>
						<li>
							If three full years have passed since the investment
							period, only guaranteed interest will be paid.
						</li>
					</ul>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Time to use MMI investment services:
					</div>
					<ol>
						<li>
							It is possible to use the death capital for 1 month
							from the date of the investor's death. It should be
							noted that if the contract has expired, there is no
							specific time frame for withdrawing the total amount
							of investment, and the heirs can take action
							whenever they want.
						</li>
						<li>
							If the insurance capital becomes payable due to the
							death of the insured, it belongs to the legal heirs
							of the investor, unless another heir is specified in
							the application form or its appendices, in which
							case the insurance capital will be awarded to the
							designated heir.
						</li>
					</ol>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						Necessary documents for death capital payment:
					</div>
					<ol>
						<li>
							The official death certificate from the medical
							examiner of the country where the investor died.
						</li>
						<li>
							Annotated certificate of the last treating doctor,
							in which the cause of the disease, its onset and
							course are mentioned, and if the treatment is not
							carried out, positive documents indicating the
							disease and the causes of death and the
							circumstances in which the death occurred should be
							prepared and submitted.
						</li>
						<li>
							Certificate of exclusive legal inheritance if the
							heirs are not specified in the insurance policy and
							supplementary documents.
						</li>
						<li>
							BEM Foundation has the right to request or prepare
							additional information and documents in this regard.
						</li>
						<li>
							If there is a mistake in the date of birth of the
							investor at the time of signing the contract, the
							wrong capital amounts will be returned and no
							compensation will be awarded.
						</li>
						<li>
							BEM Foundation will fulfill its obligations within
							one month after receiving the documents and ensuring
							their accuracy.
						</li>
						<li>
							The announcement of the investor's death must be
							officially notified to the company within a maximum
							period of one month. Otherwise, only investment
							amounts and profits will be paid to the heirs.
						</li>
					</ol>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						2FA code of the user account:
					</div>
					<div>
						The Google Authenticator two-factor password is
						available for more security of investors' account.
					</div>
					<div>
						If the private key is lost, the consequences will be
						borne by the investor, and the private key cannot be
						recovered or deleted by the company.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						E-mail address:
					</div>
					<div>
						The email address registered to access the user account
						is considered as the official email address of the
						investor.
					</div>
					<div>
						Whenever the MMI project wants to receive information
						from the investor, it will send its request to the
						investor's registered email from the company's support
						email address at: support_mmp@mmp.world
					</div>
					<div>
						If the investor does not pay attention to the emails
						sent by the company, the consequences will be directed
						at the investor and are irreparable.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						Residence address:
					</div>
					<ol>
						<li>
							The address of the investor as written in the
							service contract form is considered a definitive
							address and will only include services.
						</li>
						<li>
							If the investor changes his place of residence, he
							or she is obliged to inform the company of the
							address of his or her new place of residence through
							the "Addendum form".
						</li>
					</ol>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Resolve disputes between parties:
					</div>
					<div>
						This investment is prepared and regulated according to
						the rules of the BEM Foundation at the international
						level, and it is expressly agreed that all claims that
						may be raised against the MMI project regarding the
						investment or its implementation will be resolved
						initially by correspondence between the parties. And
						then it will be referred to the company's head office in
						Turkey-Istanbul, and finally it will be referred to the
						competent courts of the same country.
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						Statute of limitations:
					</div>
					<div>
						The statute of limitations for all claims arising from
						investment is one year from the date of the incident
						that caused the claim.
					</div>

					<div className='fw-bold text-center fs-3 my-4'>
						Terms of termination
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						Termination of the MMI project investment contract is
						possible in 4 ways:
					</div>
					<ul>
						<li>
							Termination under 1 year: the return percentage of
							the investment amount is 0%
						</li>
						<li>
							Termination between 1 and 2 years: the return
							percentage of the investment amount is 50%
						</li>
						<li>
							Termination between 2 and 3 years: the return
							percentage of the investment amount is 70%
						</li>
						<li>
							Termination between 3 and 4 years: the return
							percentage of the investment amount is 100%
						</li>
					</ul>
					<br />
					<div>
						<strong>Note:</strong> After 4 years, if the contract is
						terminated by the investor, the amount paid will be
						calculated and paid with a guaranteed interest of 10%.
					</div>
					<div>
						In case of the investor's death, from the first
						installment payment, in addition to the death
						compensation, the investment amount is not subject to
						the cancellation rules, and the paid amounts are paid
						with a guaranteed interest of 10%.
					</div>

					<div className='fw-bold text-center fs-3 my-4'>
						Marketing conditions and receiving commission in the MMI
						project
					</div>
					<div>
						4 types of commissions have been defined for the sale of
						services and marketing of MMI's long-term investment
						plan, which are:
					</div>
					<ul>
						<li> Binary commissions</li>
						<li> multi-level commissions</li>
						<li> direct referral commissions</li>
						<li> development bonuses</li>
					</ul>
					<div>
						The above commissions are paid according to the
						definitions of the MMP project in the first year by
						paying each installment of the investor.
					</div>
					<div>
						From the second year onwards, only the binary commission
						is paid. For every installment paid by the investor,
						only 10% of that installment is included in the binary
						commission.
					</div>
					<div>
						Non-payment of installments has no effect on the user
						account of the organization's support line leaders.
					</div>
					<div className='fw-bold text-center fs-3 my-4'>MAX CAP</div>
					<div>
						The amount of investment in the MMI project is added to
						the amount of investment in the MMP project, and
						according to the definition of increasing the MAX CAP of
						the MMP project, it affects the MAX CAP.
					</div>
					<div>
						Paying installments from the second year onwards only
						affects 10% in MAX CAP.
					</div>

					<div>
						All investor information will be confidential with the
						company and will not be disclosed to anybody or
						organization.
					</div>
					<div className='fw-bold text-center fs-3 my-4'>
						Market Maker Insurance
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investment and
						savings to create jobs
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investment and
						savings to buy dowry
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving to buy housing
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving to cover the cost of education
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving to create an annuity
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving for the retirement of housewives
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving for the future of children
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving for early retirement
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investing and
						saving to create dollar reserves
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Tax-free
						investment and savings
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> And more…
					</div>

					<div className='fw-bold text-center fs-3 my-4'>
						Market Maker Insurance
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Savings
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Investment
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Participation in
						benefits
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Insurance coverage
						for death from any cause
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Insurance coverage
						for accidental death
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Retirement and
						lifetime annuity
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> One-time savings
						in dollars
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> And more…
					</div>

					<div className='fw-bold text-center fs-3 my-4'>
						Market Maker Insurance
					</div>

					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						entering into the contract from 3 to 30 years
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						choosing to pay the desired insurance premium from a
						minimum of 5 dollars per month
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						choosing the desired payment method in the form of 1
						month, 3 months, 6 months and 1 year
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						determining heirs
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						an automatic annual increase in insurance premiums to
						eliminate the effect of inflation
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Preserve the value
						of money by saving dollars
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						getting insurance from birth to 60 years’ old
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						receiving coverage up to 100 thousand dollars
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> Receiving
						guaranteed interest and participation in benefits
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						withdrawing the savings at once
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> The possibility of
						receiving a pension
					</div>
					<div className='fw-bold fs-5 mt-4 mb-1'>
						<i className='fas fa-check me-2'></i> And more…
					</div>

					<div className='fw-bold text-center fs-5 my-4'>
						By paying $100 a month and compounding at 10% annually,
						save $200,000 over 30 years. <br /> And in addition to
						receiving insurance coverage, get 2 billion dollars
						saved at once, or get a monthly pension of 200 thousand
						dollars. <br />
						<br />
						<em>Insure yourself and your future with MMI.</em>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-md-8 offset-md-2'>
					<div className='fw-bold text-center fs-3 my-4'>
						The cycle of MMI value creators
					</div>
					<div className='fw-bold text-center fs-5 my-4'>
						The cycle of wealth
					</div>
					<div>
						<i className='fas fa-check me-2'></i>Funds attracted in
						each project of BEM Foundation are invested in the
						following projects, and the profit is returned to the
						entire investment cycle and BEM Foundation. The
						possibility of receiving a pension
					</div>
					<div className='table-responsive mt-4'>
						<table className='table table-sm table-bordered table-striped myTable text-center'>
							<thead>
								<tr>
									<th>Free Projects</th>
									<th>Crypto Projects</th>
									<th>Tokens</th>
									<th>Investment Projects</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Petrochemical</td>
									<td>BCN Garden</td>
									<td>TIM Token</td>
									<td>MMP WORLD</td>
								</tr>
								<tr>
									<td>Oil and Gas</td>
									<td>BEMCHAIN</td>
									<td>BCN Token</td>
									<td>MMB (Bank)</td>
								</tr>
								<tr>
									<td>Mine</td>
									<td>BEMDEFI</td>
									<td>BEMT PRO Token</td>
									<td>MMI (Insurance)</td>
								</tr>
								<tr>
									<td>Construction industry</td>
									<td>BEMPIRE </td>
									<td></td>
									<td>MMC (Cryptocurrency)</td>
								</tr>
								<tr>
									<td></td>
									<td>BEM GAMES</td>
									<td></td>
									<td>MMF (Future)</td>
								</tr>
								<tr>
									<td></td>
									<td>BEMEX</td>
									<td></td>
									<td>Foreign Exchange</td>
								</tr>
								<tr>
									<td></td>
									<td>BEMULATOR</td>
									<td></td>
									<td>Stock Market</td>
								</tr>
								<tr>
									<td></td>
									<td>BEMSCAN</td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className='row my-4'>
				<div className='col-12 text-end'>
					<Anchor
						href={'/download/mmi.pdf'}
						className='btn btn-danger'
						content={
							<>
								<i className='fas fa-file-pdf me-2'></i>
								<span>Download PDF</span>
							</>
						}
					/>
				</div>
			</div>
		</div>
	),
};

export default mmi;
