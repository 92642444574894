import React from 'react';
import { toast } from 'react-toastify';
import ToolTip from './toolTip';

function CopyBtn({ content, className }) {
	const handleCopy = (text) => {
		// navigator.clipboard.writeText(text);
		const textField = document.createElement('textarea');
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
		toast.success('Successfully copied!', {
			autoClose: 2700,
		});
	};
	return (
		<ToolTip title={'Click to copy'} placement={'right'}>
			<span
				style={{ cursor: 'pointer' }}
				className={className}
				onClick={() => handleCopy(content)}>
				<i className='ms-2 fas fa-clone'></i>
			</span>
		</ToolTip>
	);
}

export default CopyBtn;
