import React, { useState } from 'react';
import config from '../../../../config';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import { WalletHistoryCard } from '../../ingredients/cards';
import { numberWithCommas } from './../../../../utils/helpers';

const WalletHistory = ({ tab }) => {
	const { userData, initData } = useMain();
	const wallet = tab.id.replace('History', '');
	const tableData = [
		...userData.wallets[wallet].withdrawTransactions.map((x) => ({
			...x,
			type: 'Withdraw',
		})),
		...userData.wallets[wallet].depositTransactions.map((x) => ({
			...x,
			type: 'Deposit',
		})),
	];
	const sorted = tableData.sort((a, b) => (a.date > b.date ? -1 : 1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;

	const [currentPage, setCurrentPage] = useState(1);
	const data = paginate(sorted, currentPage, config.tablePageSize);
	const amount = userData.wallets[wallet].available / 1e6;
	const bcn_usdt_rate = 1 / initData.bcn_price_usd;

	return (
		<div className='row'>
			<div className='col-12'>
				<WalletHistoryCard
					data={data}
					itemsCount={tableData.length}
					pageSize={config.tablePageSize}
					currentPage={currentPage}
					onPageChange={setCurrentPage}
					name={initData.wallets[wallet].name}
					balance={`$${numberWithCommas(Number(amount.toFixed(2)))}`}
					bcn_balance={numberWithCommas(
						Number((amount * bcn_usdt_rate).toFixed(2))
					)}
					image={wallet}
				/>
			</div>
		</div>
	);
};

export default WalletHistory;
