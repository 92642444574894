import React from 'react';
import Image from '../../common/image';
import { subString } from '../../../utils/helpers';

const StarsTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Rank</th>
						<th>UID</th>
						<th>Email</th>
						<th>Balance</th>
						<th>Tickets</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr
							className='align-middle'
							style={{
								backgroundColor: getColor(d.team),
							}}
							key={key}>
							<td>{getRank(d.no)}</td>
							<td>{d.uid}</td>
							<td>{subString(d.email, 8, 6, '.')}</td>
							<td>
								{d.balance ? (
									<>
										<b>$</b>
										<span>{d.balance / 1e6}</span>
									</>
								) : (
									''
								)}
							</td>
							<td>
								<div className='d-flex align-items-center justify-content-center'>
									<span>{d.tickets}</span>
									<Image
										className='ms-2'
										height='25px'
										fileName='stars/ticket.svg'
									/>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getRank = (no) => (
	<div className='starsTableNums'>
		{no}
		{no < 21 && (
			<Image
				className='crown'
				fileName='icons/crown.svg'
			/>
		)}
	</div>
);

const getColor = (team) => {
	if (team === 'empire') return '#f7aeae95';
	if (team === 'noAkhtaran') return '#ace8f195';
	return '#ffffff';
};

export default StarsTable;
