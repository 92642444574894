import { useEffect } from 'react';
import socket from '../services/socketService';

export default function useWatchPrice(setInitData) {
	useEffect(() => {
		const handlePricePong = (data) => setInitData((prevData) => ({ ...prevData, ...data }));
		socket.socket.on('pricePong', handlePricePong);
		return () => socket.socket.off('pricePong', handlePricePong);
	}, [setInitData]);
}
