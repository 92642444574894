import React from 'react';
import Image from '../common/image';
import ArticleCarousel from '../home/articleCarousel';
import useDocumentTitle from '../../hooks/useDocumentTitle';

function Article({ articleProps }) {
	const { title, content, image, carousel, name } = articleProps;
	useDocumentTitle([name]);
	return (
		<>
			<div className='blog_header'>
				<div className='container py-5'>
					<div className='row py-5'>
						<div className='col-12 text-center'>
							<h1>
								<b>{title}</b>
							</h1>
						</div>
					</div>
				</div>
			</div>
			{image && (
				<div className='container'>
					<div className='row'>
						<div className='col-md-8 offset-md-2 text-center'>
							<Image
								className={'w-75 blog_image'}
								fileName={`blogs/${image}`}
							/>
						</div>
					</div>
				</div>
			)}
			{content}
			{carousel && <ArticleCarousel />}
		</>
	);
}

export default Article;
