import React, { Fragment } from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class DebtForm extends Form {
	state = {
		data: {
			email: this.props.email,
			amount: this.props.amount,
			percent: this.props.percent,
			deadline: this.props.deadline,
		},
		errors: {},
	};

	schema = {
		email: Joi.string().email().required().label('Email'),
		amount: Joi.number()
			.min(this.props.minAmount)
			.multiple(this.props.stepAmount)
			.required()
			.label('Amount'),
		percent: Joi.number()
			.min(this.props.minPercent)
			.max(this.props.maxPercent)
			.multiple(this.props.stepPercent)
			.required()
			.label('Percent'),
		deadline: Joi.string().required().label('Deadline'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			minAmount,
			stepAmount,
			minPercent,
			maxPercent,
			stepPercent,
		} = this.props;
		const { percent } = this.state.data;

		return (
			<Fragment>
				<small>
					<span className='text-danger'>*</span> Required fields
				</small>
				<form className='mt-3' onSubmit={this.handleSubmit}>
					<div className='mb-4'>
						{this.renderInput({
							name: 'email',
							label: (
								<span>
									Email <span className='text-danger'>*</span>
								</span>
							),
							type: 'email',
						})}
					</div>
					<div className='mb-4'>
						{this.renderInput({
							name: 'amount',
							min: minAmount,
							step: stepAmount,
							label: (
								<span>
									Amount{' '}
									<span className='text-danger'>*</span>
								</span>
							),
							type: 'number',
						})}
					</div>
					<div className='mb-4'>
						{this.renderInput({
							name: 'percent',
							min: minPercent,
							step: stepPercent,
							max: maxPercent,
							label: (
								<span>
									Percent: <b>{percent}%</b>
								</span>
							),
							inputClass: 'form-range border border-0',
							type: 'range',
						})}
					</div>
					<div className='mb-4'>
						{this.renderInput({
							name: 'deadline',
							label: (
								<span>
									Deadline Date (UTC Time){' '}
									<span className='text-danger'>*</span>
								</span>
							),
							type: 'date',
						})}
					</div>

					<div className='text-center mt-3'>
						<div>
							{this.renderButton(
								'Submit',
								'btn btn-lg btn-my-navy',
								isLoading && (
									<Spinner content={'Submitting...'} />
								)
							)}
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

export default DebtForm;
