import { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { numberWithCommas } from '../../../../../../utils/helpers';

const borderColor = '#000000';
const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		borderBottomColor: borderColor,
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 12,
	},
	tableData: {
		width: '6.25%',
		height: 12,
		fontSize: 7,
		paddingTop: 2.5,
		textAlign: 'center',
		borderRightColor: borderColor,
		borderRightWidth: 1,
	},
	tableDataLast: {
		width: '6.25%',
		fontSize: 7,
		textAlign: 'center',
		borderRight: 'none',
	},
});

const DashboardTableRow = ({ covers }) => {
	return (
		<Fragment>
			{covers.map((item, key) => (
				<View style={styles.row} key={key}>
					<Text style={styles.tableData}>{item.year}</Text>
					<Text style={styles.tableData}>{item.userAge}</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.monthly)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.annual)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.total)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.death)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.accident)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.disabilities)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.special)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.physical)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.fire)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.termination)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.rate_10)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.rate_10)}
					</Text>
					<Text style={styles.tableData}>
						{numberWithCommas(item.rate_15)}
					</Text>
					<Text style={styles.tableDataLast}>
						{numberWithCommas(item.rate_18)}
					</Text>
				</View>
			))}
		</Fragment>
	);
};

export default DashboardTableRow;
