import React, { useContext } from 'react';
import MainContext from './../../../context/mainContext';
import { capitalize } from 'lodash';

const InvestmentHistoryTable = ({ data }) => {
	const { initData } = useContext(MainContext);

	const getPaymentObject = ({ amount, purchaseType }) => {
		const option = initData.purchaseOptions.find(
			(o) => o.id === purchaseType
		);
		const activationAmount =
			parseInt((amount * option.activation) / 100) / 1e6;
		const discountAmount =
			(amount - parseInt((amount * option.activation) / 100)) / 1e6;
		return {
			activationAmount,
			discountAmount,
		};
	};

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center caption-top'>
				<caption className='form-text'>
					<div>AW: Activation Wallet</div>
					<div>AW/DW: Activation Wallet + Discount Wallet</div>
				</caption>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Purchase Type</th>
						<th>Total Amount</th>
						<th>Activation</th>
						<th>Discount</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => {
						const { activationAmount, discountAmount } =
							getPaymentObject(d);
						return (
							<tr key={key}>
								<td>{d.no}</td>
								<td>{new Date(d.date).toLocaleString()}</td>
								<td>{d.purchaseType}</td>
								<td>{d.amount / 1e6} USDT</td>
								<td>{activationAmount} USDT</td>
								<td>{discountAmount} USDT</td>
								<td>{capitalize(d.status)}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default InvestmentHistoryTable;
