const userRank = (userData, initData) => {
	const { L, R } = userData;
	const { userRanks } = initData;
	const balance = Math.min(L.totalValue, R.totalValue) / 1e6;
	const rank = userRanks.find((u) => {
		return balance >= u.min && balance < u.max;
	});
	return rank;
};

export default userRank;
