import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useMain from '../hooks/useMain';
import http from '../services/httpService';
import Preloader from './common/preloader';
import CoversTable from './dashboard/tabs/mmi/coversTable';
import DownloadDashboard from './dashboard/tabs/mmi/pdf/DownloadDashboard';
import MMIQueryForm from './mmiQueryForm';
import MMIQueryTableForm from './mmiQueryTableForm';

const MmiQuery = () => {
	useDocumentTitle(['MMI Queri']);
	const [isLoading, setIsLoading] = useState(false);
	const [foundUser, setFoundUser] = useState(undefined);
	const [table, setTable] = useState(undefined);
	const { initData } = useMain();
	const { mmiConfig, stepPurchase, purchaseOptions } = initData;

	const handleQueryCode = async (formData) => {
		try {
			setFoundUser(undefined);
			setIsLoading(true);
			const { data } = await http.mmiQueryCode(formData);
			setFoundUser(data.user);
			toast.success(data.message);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleQueryTable = async (formData) => {
		try {
			const {
				amount,
				contractPeriod,
				paymentMethod,
				increasePercent,
				birthDate,
			} = formData;
			setIsLoading(true);
			const { data } = await http.mmiQueryTable({
				amount: Number(amount),
				contractPeriod,
				paymentMethod,
				increasePercent,
				birthDate: new Date(birthDate).getTime(),
			});
			setTable(data.table);
			toast.success(data.message);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	if (!stepPurchase) return <Preloader />;
	return (
		<>
			<div className='blog_header'>
				<div className='container py-5'>
					<div className='row py-5'>
						<div className='col-12 text-center'>
							<h1>
								<b>MMI QUERY</b>
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div className='container py-5'>
				<div className='row'>
					<div className='col-12 text-center'>
						<h3>Search Insurance Number</h3>
						<MMIQueryForm
							onSubmit={handleQueryCode}
							isLoading={isLoading}
						/>
					</div>
					{foundUser && (
						<>
							<h3 className='my-3 text-center'>Search Result:</h3>
							<p className='text-center'>
								Your insurance investment is correct and
								approved by BEM Foundation.{' '}
								<i className='fas fa-check text-my-green'></i>
							</p>
							<hr />
							<div className='row'>
								<div className='col-sm-4 text-start'>
									<ul>
										<li className='mb-1 ml-3'>
											ID:{' '}
											<span className='text-purple'>
												{foundUser.userID.uid}
											</span>
										</li>
										<li className='mb-1 ml-3'>
											Email:{' '}
											<span className='text-purple'>
												{foundUser.email}
											</span>
										</li>
										<li className='mb-1 ml-3'>
											Insurance Code:{' '}
											<span className='text-purple'>
												{foundUser.mmi.code}
											</span>
										</li>
									</ul>
								</div>

								<div className='col-sm-4 text-start'>
									<ul>
										<li className='mb-1 ml-3'>
											MMI Activation Date:{' '}
											<span className='text-purple'>
												{new Date(
													foundUser.mmi.activationDate
												).toLocaleDateString()}
											</span>
										</li>
										<li className='mb-1 ml-3'>
											Payment Method:{' '}
											<span className='text-purple'>
												{
													initData.mmiConfig.paymentMethod.find(
														(m) =>
															m.duration ===
															foundUser.mmi
																.paymentMethod
													).content
												}
											</span>
										</li>
										<li className='mb-1 ml-3'>
											Primary Amount:{' '}
											<span className='text-purple'>
												$
												{
													foundUser.mmi.covers[0]
														.monthly
												}
											</span>
										</li>
									</ul>
								</div>

								<div className='col-sm-4 text-start'>
									<ul>
										<li className='mb-1 ml-3'>
											Contract Period:{' '}
											<span className='text-purple'>
												{foundUser.mmi.contractPeriod}{' '}
												Years
											</span>
										</li>
										<li className='mb-1 ml-3'>
											Paid Installments:{' '}
											<span className='text-purple'>
												{
													foundUser.mmi.installments.filter(
														(i) => i.isPaid
													).length
												}
											</span>
										</li>
										<li className='mb-1 ml-3'>
											Overdue Installments:{' '}
											<span className='text-purple'>
												{
													foundUser.mmi.installments.filter(
														(i) =>
															!i.isPaid &&
															Date.now() >
																new Date(
																	i.dueDate
																).getTime()
													).length
												}
											</span>
										</li>
									</ul>
								</div>
							</div>
						</>
					)}
				</div>
				<hr />

				<div className='row'>
					<div className='col-lg-8 offset-lg-2 col-12 text-center'>
						<h3>Query The Insurance Table</h3>
						<MMIQueryTableForm
							birthDate={new Date()
								.toISOString()
								.substring(0, 10)}
							min={mmiConfig.minAmount}
							step={stepPurchase}
							isLoading={isLoading}
							contractPeriod={mmiConfig.contractPeriod}
							paymentMethod={mmiConfig.paymentMethod}
							increasePercent={mmiConfig.increasePercent}
							options={purchaseOptions}
							onSubmit={handleQueryTable}
						/>
					</div>
					{table && (
						<div className='col-12 text-center mt-5'>
							<h3>Test Dashboard</h3>
							<CoversTable table={table} />
							<DownloadDashboard
								covers={table}
								title='TEST DASHBOARD'
								fileName='testTable'
								className='mt-4'
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MmiQuery;
