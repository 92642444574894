import React, { Fragment } from 'react';

const InputGroup = ({
	name,
	label,
	error,
	customClass,
	labelClass,
	formText,
	spanText,
	...rest
}) => {
	return (
		<Fragment>
			<label className={labelClass} htmlFor={name}>
				{label}
			</label>
			<div className={'input-group ' + customClass}>
				<span className='input-group-text' id='basic-addon1'>
					{spanText}
				</span>
				<input
					{...rest}
					name={name}
					id={name}
					className='form-control'
				/>
			</div>
			{formText && <div className='form-text'>{formText}</div>}
			{error && <div className='alert alert-danger'>{error}</div>}
		</Fragment>
	);
};

export default InputGroup;
