import React from 'react';
import useMain from '../../../hooks/useMain';
import Anchor from '../../common/anchor';
import Preloader from './../../common/preloader';

function UserResource() {
	const { resources, initData } = useMain();
	const types = initData.resourcesConfig.types;

	return (
		<>
			{resources ? (
				types.map((type, key) => {
					const resourceArray = resources.filter(
						(r) => r.type === type
					);
					return (
						<div
							key={key}
							className={`mb-4 d-${
								resourceArray.length > 0 ? 'block' : 'none'
							}`}>
							<div className='h2 text-center'>
								<b>{type}</b>:
							</div>
							<hr />
							<div className='row'>
								{resourceArray.map((r, k) => (
									<div key={k} className={'col-sm-2'}>
										<Anchor
											href={r.link}
											content={
												<>
													<img
														loading='lazy'
														src={r.imageLink}
														className='card-img-top'
														alt={r._id}
														crossOrigin='true'
													/>
													<p className='h5 text-center my-2 text-dark'>
														<b>{r.title}</b>
													</p>
												</>
											}
										/>
									</div>
								))}
							</div>
						</div>
					);
				})
			) : (
				<Preloader />
			)}
		</>
	);
}

export default UserResource;
