import React from 'react';
import { ClockLoader } from 'react-spinners';

const Preloader = ({ theme }) => {
	let color = '#3498db';
	if (theme) color = theme === 'dark' ? '#bc67c7' : '#00c292';

	return (
		<div className='vh-100 d-flex justify-content-center align-items-center'>
			<ClockLoader
				loading={true}
				size={150}
				sizeUnit={'px'}
				color={color}
			/>
		</div>
	);
};

export default Preloader;
