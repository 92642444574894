import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useConfigs() {
	const [configs, setConfigs] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/configs'];

		async function getConfigs() {
			try {
				if (
					!http.getCurrentUser() ||
					configs ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getConfigs();
				if (isMounted()) setConfigs(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getConfigs();
	}, [isMounted, configs, pathname]);
	return [configs, setConfigs];
}
