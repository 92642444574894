import _ from 'lodash';
import React, { useState } from 'react';
import { RiseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import { MMIDownlinesCard, TextCard } from '../../ingredients/cards';
import GetUserByEmailForm from './getUserByEmailForm';
import GetUserByUIDForm from './getUserByUIDForm';

const TrackingInstallments = () => {
	const pageSize = 10;
	const { initData, userDownlines } = useMain();
	const [foundUser, setFoundUser] = useState(undefined);
	const [usersCurrentPage, setUsersCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState({
		path: 'no',
		order: 'asc',
	});
	const normalizeUsers = () => {
		const merged = userDownlines
			? [...userDownlines.right, ...userDownlines.left]
			: [];
		const filtered = merged.filter((m) => m.mmiIsActive);
		const sorted = filtered.sort((a, b) =>
			a.mmiDate > b.mmiDate ? 1 : -1
		);

		for (const i in sorted) {
			sorted[i].no = Number(i) + 1;
			if (!sorted[i].card) sorted[i].currentPackageValue = 0;
		}

		return sorted;
	};

	const allUsers = normalizeUsers();
	const sortedUsers = _.orderBy(
		allUsers,
		[sortColumn.path],
		[sortColumn.order]
	);
	const users = paginate(sortedUsers, usersCurrentPage, pageSize);

	const handleSort = (path) =>
		setSortColumn((prev) => {
			const sortColumn = { ...prev };
			if (!path) return sortColumn;
			if (prev.path === path) {
				sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
			} else {
				sortColumn.path = path;
				sortColumn.order = 'asc';
			}
			return sortColumn;
		});

	const handleSearchByEmail = async (formData) => {
		setFoundUser(undefined);
		const user = allUsers.find((u) => u.email === formData.email);
		if (!user) return toast.error('User not found.');
		setFoundUser(user);
	};

	const handleSearchByUID = async (formData) => {
		setFoundUser(undefined);
		const user = allUsers.find((u) => u.id === formData.uid);
		if (!user) return toast.error('User not found.');
		if (!user.mmpDate) return toast.error('User is not a MMP user.');
		setFoundUser(user);
	};

	return (
		<>
			{userDownlines ? (
				<>
					<MMIDownlinesCard
						data={users}
						itemsCount={allUsers.length}
						pageSize={pageSize}
						currentPage={usersCurrentPage}
						sortColumn={sortColumn}
						onPageChange={setUsersCurrentPage}
						onSort={handleSort}
					/>

					<div className='row'>
						<div className='col-12'>
							<TextCard
								title={'Search MMI User'}
								titleClass={'fs-3 text-center'}
								text={
									<>
										<hr />
										<div className='row'>
											<div className='col-sm-6'>
												<GetUserByEmailForm
													onSubmit={
														handleSearchByEmail
													}
												/>
											</div>
											<div className='col-sm-6'>
												<GetUserByUIDForm
													onSubmit={handleSearchByUID}
												/>
											</div>
										</div>

										{foundUser && (
											<>
												<h3 className='my-3 text-center'>
													Search Result:
												</h3>
												<hr />
												<div className='row'>
													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																ID:{' '}
																<span className='text-purple'>
																	{
																		foundUser.id
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Email:{' '}
																<span className='text-purple'>
																	{
																		foundUser.email
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Insurance Code:{' '}
																<span className='text-purple'>
																	{
																		foundUser
																			.mmi
																			.code
																	}
																</span>
															</li>
														</ul>
													</div>

													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																MMI Activation
																Date:{' '}
																<span className='text-purple'>
																	{new Date(
																		foundUser.mmiDate
																	).toLocaleDateString()}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Payment Method:{' '}
																<span className='text-purple'>
																	{
																		initData.mmiConfig.paymentMethod.find(
																			(
																				m
																			) =>
																				m.duration ===
																				foundUser
																					.mmi
																					.paymentMethod
																		)
																			.content
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Primary Amount:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.mmiStartAmount /
																		1e6}
																</span>
															</li>
														</ul>
													</div>

													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																Paid
																Installments:{' '}
																<span className='text-purple'>
																	{
																		foundUser.mmi.installments.filter(
																			(
																				i
																			) =>
																				i.isPaid
																		).length
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Overdue
																Installments:{' '}
																<span className='text-purple'>
																	{
																		foundUser.mmi.installments.filter(
																			(
																				i
																			) =>
																				!i.isPaid &&
																				Date.now() >
																					new Date(
																						i.dueDate
																					).getTime()
																		).length
																	}
																</span>
															</li>
														</ul>
													</div>
												</div>
											</>
										)}
									</>
								}
							/>
						</div>
					</div>
				</>
			) : (
				<div className='h-50 d-flex justify-content-center align-items-center'>
					<RiseLoader
						speedMultiplier={0.8}
						loading={true}
						size={20}
						sizeUnit={'px'}
						color={'#7b8cff'}
					/>
				</div>
			)}
		</>
	);
};

export default TrackingInstallments;
