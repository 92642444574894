import React, { useState } from 'react';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import DEFITable from './../../ingredients/defiTable';

const ActiveInvestments = () => {
	const pageSize = 15;
	const { userData, initData } = useMain();
	const { defi } = userData;
	const [currentPage, setCurrentPage] = useState(1);

	const activeDEFIs = defi.filter((d) => d.isActive);
	const data = paginate(activeDEFIs, currentPage, pageSize);

	const pendingDEFIs = defi.filter((d) => d.isActive && !d.provided);
	if (pendingDEFIs.length > 0)
		setTimeout(
			() => window.location.reload(),
			initData.defiTime - Date.now()
		);

	return (
		<div className='card myCards'>
			<div className='row p-4'>
				<div className='col-md-10 offset-md-1 col-12'>
					<div className='fs-1 pt-4 text-center'>
						Investments Table
					</div>
					<div className='fs-3 pb-5 text-center'>
						Active Investments: {activeDEFIs.length}
					</div>
					{activeDEFIs.length > 0 ? (
						<div className='pb-5'>
							<DEFITable data={data} />
							<Pagination
								customClass='mt-1 myPagination'
								activeClass='myActive'
								totalCount={activeDEFIs.length}
								pageSize={pageSize}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					) : (
						<div className='text-center my-5'>
							<Image height='180px' fileName='icons/noData.svg' />
							<h4 className='mt-4'>
								There are no active investments.
							</h4>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ActiveInvestments;
