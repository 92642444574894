import _ from 'lodash';
import React, { useState } from 'react';
import { RiseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { paginate } from '../../../../utils/paginate';
import { DownlinesCard, TextCard } from '../../ingredients/cards';
import GetUserByEmailForm from './getUserByEmailForm';
import GetUserByUIDForm from './getUserByUIDForm';

const NetworkView = () => {
	const pageSize = 15;
	const userTypes = [
		{
			_id: 0,
			content: 'Active Users',
			fn: (users) => users.filter((u) => u.active === 'Yes'),
		},
		{
			_id: 1,
			content: 'Inactive Users',
			fn: (users) => users.filter((u) => u.active === 'No'),
		},
		{
			_id: 2,
			content: 'All MMP Users',
			fn: (users) => users.filter((u) => u.email),
		},
		{
			_id: 3,
			content: 'All Users',
			fn: (users) => users,
		},

		{
			_id: 4,
			content: 'Purple Cards',
			fn: (users) => users.filter((u) => u.card === 'purple'),
		},
		{
			_id: 5,
			content: 'Blue Cards',
			fn: (users) => users.filter((u) => u.card === 'blue'),
		},
		{
			_id: 6,
			content: 'Red Cards',
			fn: (users) => users.filter((u) => u.card === 'red'),
		},
		{
			_id: 7,
			content: 'Black Cards',
			fn: (users) => users.filter((u) => u.card === 'black'),
		},
		{
			_id: 8,
			content: 'Royal Cards',
			fn: (users) => users.filter((u) => u.card === 'royal'),
		},
	];
	const { userDownlines } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [foundUser, setFoundUser] = useState(undefined);
	const [usersCurrentPage, setUsersCurrentPage] = useState(1);
	const [selectedUserType, setSelectedUserType] = useState(userTypes[0]);
	const [sortColumn, setSortColumn] = useState({
		path: 'currentPackageValue',
		order: 'desc',
	});
	const normalizeUsers = () => {
		const merged = userDownlines
			? [...userDownlines.right, ...userDownlines.left]
			: [];
		const sorted = merged.sort((a, b) =>
			a.bemchainDate > b.bemchainDate ? 1 : -1
		);
		for (const i in sorted) {
			sorted[i].no = Number(i) + 1;
			if (!sorted[i].card) sorted[i].currentPackageValue = 0;
		}

		return sorted;
	};

	const allUsers = normalizeUsers();
	const filteredUsers = selectedUserType.fn(allUsers);
	const sortedUsers = _.orderBy(
		filteredUsers,
		[sortColumn.path],
		[sortColumn.order]
	);
	const users = paginate(sortedUsers, usersCurrentPage, pageSize);

	const handleTypeSelect = (userType) => {
		setSelectedUserType(userType);
		setUsersCurrentPage(1);
	};

	const handleSort = (path) =>
		setSortColumn((prev) => {
			const sortColumn = { ...prev };
			if (!path) return sortColumn;
			if (prev.path === path) {
				sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
			} else {
				sortColumn.path = path;
				sortColumn.order = 'asc';
			}
			return sortColumn;
		});

	const handleSearchByEmail = async (formData) => {
		setFoundUser(undefined);
		const user = allUsers.find((u) => u.email === formData.email);
		if (!user) return toast.error('User not found.');
		setFoundUser(user);
	};

	const handleGetUserByUID = async (formData) => {
		try {
			setIsLoading(true);
			setFoundUser(undefined);
			const { data } = await http.getUserByUID(formData);
			toast.success('User data fetched successfully');
			setFoundUser(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{userDownlines ? (
				<>
					<DownlinesCard
						data={users}
						itemsCount={filteredUsers.length}
						pageSize={pageSize}
						currentPage={usersCurrentPage}
						userTypes={userTypes}
						selectedUserType={selectedUserType}
						sortColumn={sortColumn}
						onPageChange={setUsersCurrentPage}
						onTypeSelect={handleTypeSelect}
						onSort={handleSort}
					/>
					<div className='row'>
						<div className='col-12'>
							<TextCard
								title={'Serach User'}
								titleClass={'fs-3 text-center'}
								text={
									<>
										<hr />
										<div className='row'>
											<div className='col-sm-6'>
												<GetUserByEmailForm
													isLoading={isLoading}
													onSubmit={
														handleSearchByEmail
													}
												/>
											</div>
											<div className='col-sm-6'>
												<GetUserByUIDForm
													isLoading={isLoading}
													onSubmit={
														handleGetUserByUID
													}
												/>
											</div>
										</div>

										{foundUser && (
											<>
												<h3 className='my-3 text-center'>
													Search Result:
												</h3>
												<hr />
												<div className='row'>
													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																ID:{' '}
																<span className='text-purple'>
																	{
																		foundUser.id
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Level:{' '}
																<span className='text-purple'>
																	{
																		foundUser.level
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Position:{' '}
																<span className='text-purple'>
																	{
																		foundUser.position
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Email:{' '}
																<span className='text-purple'>
																	{
																		foundUser.email
																	}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Name:{' '}
																<span className='text-purple'>
																	{
																		foundUser.fullName
																	}
																</span>
															</li>
														</ul>
													</div>

													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																Unpaid left
																balance:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.L
																		.unPaid /
																		1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Unpaid right
																balance:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.R
																		.unPaid /
																		1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Total left
																balance:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.L
																		.totalValue /
																		1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Total right
																balance:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.R
																		.totalValue /
																		1e6}
																</span>
															</li>
														</ul>
													</div>

													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																Card:{' '}
																<span className='text-purple'>
																	{_.capitalize(
																		foundUser.card
																	)}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Package Value:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.currentPackageValue /
																		1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																BemChain
																Registration
																Date:{' '}
																<span className='text-purple'>
																	{new Date(
																		foundUser.bemchainDate
																	).toLocaleDateString()}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																MMP Registration
																Date:{' '}
																<span className='text-purple'>
																	{new Date(
																		foundUser.mmpDate
																	).toLocaleDateString()}
																</span>
															</li>
														</ul>
													</div>
												</div>
											</>
										)}
									</>
								}
							/>
						</div>
					</div>
				</>
			) : (
				<div className='h-50 d-flex justify-content-center align-items-center'>
					<RiseLoader
						speedMultiplier={0.8}
						loading={true}
						size={20}
						sizeUnit={'px'}
						color={'#7b8cff'}
					/>
				</div>
			)}
		</>
	);
};

export default NetworkView;
