import React from 'react';
import useMain from '../../../../../hooks/useMain';
import P4Form from './p4Form';

const P4 = ({ onPrevBtnClick, onNextBtnClick, onFieldChange }) => {
	const { initData } = useMain();

	const handleSubmit = (formData) =>
		onNextBtnClick({ name: 'p4', data: formData });

	return (
		<div className='row mb-5'>
			<div className='col-lg-10 offset-lg-1 col-12'>
				<div className='mb-1'>Page 4</div>
				<P4Form
					onPrevBtnClick={onPrevBtnClick}
					handleChange={onFieldChange}
					onSubmit={handleSubmit}
					options={initData.mmiConfig.contract.yesNoOptions}
				/>
			</div>
		</div>
	);
};

export default P4;
