import React from 'react';
import data from './data.json';
import Image from '../../../common/image';

const Preview = ({ tab }) => {
	return (
		<div className='row align-items-center'>
			<div className='col-lg-4 Text-center order-2 order-lg-1'>
				<Image className='w-100 my-0' fileName='comingSoon.png' />
			</div>
			<div className='col-lg-6 order-1 order-lg-2'>
				<h2>{tab.name}</h2>
				<p>{data[tab.id]}</p>
			</div>
		</div>
	);
};

export default Preview;
