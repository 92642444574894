import { useEffect, useState } from 'react';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useAllHomeBlogs() {
	const [blogs, setBlogs] = useState(null);
	const isMounted = useIsMounted();

	useEffect(() => {
		async function getBlogs() {
			try {
				const { data } = await http.getAllHomeBlogs();
				if (isMounted()) setBlogs(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getBlogs();
	}, [isMounted]);
	return [blogs, setBlogs];
}
