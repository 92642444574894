import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import socket from '../../../../services/socketService';
import CopyBtn from '../../../common/copyBtn';
import Spinner from '../../../common/spinner';
import { TextCard } from '../../ingredients/cards';
import ConfirmModal from '../../ingredients/confirmModal';
import TopupForm from './topupForm';

const TopupActivationWallet = ({ history }) => {
	const { initData, userData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [expired, setExpired] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	useEffect(() => {
		if (userData.hasActivePaymentRequest) {
			const handleUserDataPong = (data) => {
				onUserDataChanged(data.userData);
				toast[data.type](data.message);
				history.replace('/dashboard');
			};

			socket.socket.once('userDataPong', handleUserDataPong);

			return () => {
				socket.socket.off('userDataPong', handleUserDataPong);
			};
		}
	}, [userData, onUserDataChanged, history]);

	const handleModalShow = (formData) => {
		setCurrentFormData(formData);
		const markup = userData.hasActivePaymentRequest ? (
			<div>Are you sure you want to cancel the current payment?</div>
		) : (
			<>
				<div className='fs-3'>
					You are going to deposit <b>{formData.amount} USDT</b>
				</div>
				<div className='fs-5 mt-3'>Important Note:</div>
				<ul>
					<li>
						Make sure to complete your payment before timer runs
						out.
					</li>
					<li>Do not send funds from exchanges.</li>
					<li>
						Please do not send digital assets other than{' '}
						<b>USDT TRC20</b> to this address, otherwise the assets
						will not be recoverable.
					</li>
				</ul>
			</>
		);

		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response && !userData.hasActivePaymentRequest)
			handleRequestSubmit();
		if (response && userData.hasActivePaymentRequest) handleCancel();
	};

	const handleRequestSubmit = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.requestTopup({
				amount: currentFormData.amount * 1e6,
				network: currentFormData.network,
			});
			onUserDataChanged(data);
			toast.info('Request submitted!');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const onRequestTimerCompleted = () => {
		toast.info('Payment time expired');
		setExpired(true);
		setTimeout(async () => {
			try {
				const { data } = await http.getMe();
				onUserDataChanged(data);
			} catch (ex) {
				console.log(ex);
				if (ex && ex.response && ex.response.data)
					return toast.error(ex.response.data);
				if (ex && ex.message) return toast.error(ex.message);
			} finally {
				setExpired(false);
			}
		}, 15000);
	};

	const handleCancel = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.cancelTopup({
				_id: userData.paymentRequests[0]._id,
			});
			onUserDataChanged(data);
			toast.info('Request cancelled');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	if (
		userData.hasActivePaymentRequest &&
		initData.paymentDuration +
			new Date(userData.paymentRequests[0].date).getTime() <
			Date.now()
	)
		http.getOnlyMe().then((res) => {
			onUserDataChanged(res.data);
			setModalShow(false);
		});

	return (
		<>
			<div className='row'>
				<div className='col-12 text-center'>
					<TextCard
						title={'Top Up Activation Wallet'}
						titleClass={'fs-1'}
						text={
							<>
								<div className='row mt-5 align-items-center'>
									{userData.hasActivePaymentRequest && (
										<div className='col-md-4 col-12'>
											<p>
												<Countdown
													className='fs-4 text-danger'
													date={
														initData.paymentDuration +
														new Date(
															userData.paymentRequests[0].date
														).getTime()
													}
													onComplete={
														onRequestTimerCompleted
													}
												/>
											</p>
											<QRCode
												value={
													userData.tronAccount.address
												}
												level={'Q'}
												size={256}
											/>
											{expired ? (
												<div className='d-flex align-items-center justify-content-center text-danger mt-4'>
													<i className='me-3 fa-2x fas fa-exclamation-circle'></i>
													<span className='fs-4'>
														Payment Time Is Expired!
													</span>
												</div>
											) : (
												<>
													<p className='mt-4'>
														{
															userData.tronAccount
																.address
														}
														<CopyBtn
															content={
																userData
																	.tronAccount
																	.address
															}
														/>
													</p>
													<p className='mt-4 fs-4 text-purple'>
														Amount:{' '}
														{userData
															.paymentRequests[0]
															.amount / 1e6}{' '}
														USDT
													</p>
													<button
														onClick={
															handleModalShow
														}
														className='btn btn-my-navy'>
														{isLoading ? (
															<Spinner
																content={
																	'Cancelling...'
																}
															/>
														) : (
															'Cancel'
														)}
													</button>
												</>
											)}
										</div>
									)}
									<div
										className={`col-md-6 offset-md-${
											userData.hasActivePaymentRequest
												? 1
												: 3
										} col-12`}>
										<TopupForm
											options={initData.topupOptions}
											onSubmit={handleModalShow}
											min={initData.minTopup / 1e6}
											current={
												userData.hasActivePaymentRequest
													? userData
															.paymentRequests[0]
															.amount / 1e6
													: null
											}
											isLoading={isLoading}
											step={1}
											hasActivePaymentRequest={
												userData.hasActivePaymentRequest
											}
										/>
									</div>
								</div>
								{userData.hasActivePaymentRequest && (
									<div className='row'>
										<div className='col-sm-6 offset-sm-3 col-12'>
											<h4 className='mb-3'>
												Deposit Terms:
											</h4>
											<div>
												<i className='fas fa-check me-1'></i>
												Make sure to complete your
												payment before timer runs out.
											</div>
											<div>
												<i className='fas fa-check me-1'></i>
												Do not send funds from
												exchanges.
											</div>
											<div>
												<i className='fas fa-check me-1'></i>
												This address only accepts
												deposits with USDT TRC20.
											</div>
											<div>
												<i className='fas fa-check me-1'></i>
												Please do not send digital
												assets other than USDT TRC20 to
												this address, otherwise the
												assets will not be recoverable.
											</div>
										</div>
									</div>
								)}
							</>
						}
					/>
				</div>
			</div>
			<div className='row'>
				<div className='col-lg-12'>
					<TextCard
						title={'Cryptocurrency Top Up Disclaimer'}
						titleClass={'mb-4'}
						text={
							<ul>
								<li>
									Please note that only make your deposit
									within the specified time, only deposits
									received before the time expires will be
									credited to your account. (that is, before
									30 minutes)
								</li>
								<li>
									Note that the amount must be deposited
									according to the initial request. The
									amounts more or less than requested amount
									will not be credited to your account.
								</li>
								<li>
									If the deposit is made after the specified
									time, or if the requested amount is
									different from the deposited amount, the
									experts will not be able to recognize your
									transaction and the amount will not be
									deposited into your account and will not be
									returned to your wallet.
								</li>
							</ul>
						}
					/>
				</div>
			</div>
			<ConfirmModal
				show={modalShow}
				onClose={handleModalClose}
				title={'Top Up Activation Wallet'}
				body={modalBody}
			/>
		</>
	);
};

export default TopupActivationWallet;
