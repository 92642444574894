import React, { Fragment, useState, useRef } from 'react';
import { createRef } from 'react';

const AccordionBasic = ({
	data,
	className = '',
	panelClass = '',
	iconClassOpen = '',
	iconClassClose = '',
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const panelRefs = useRef(data.map(() => createRef()));

	const handleClick = (key) => {
		setIsOpen((o) => !o);
		const panel = panelRefs.current[key].current;
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px';
		}
	};

	return data.map((d, key) => (
		<Fragment key={key}>
			<div
				onClick={() => handleClick(key)}
				className={`d-flex align-items-center ${className} ${
					isOpen ? 'active' : ''
				}`}>
				<span>{d.title}</span>{' '}
				<i
					className={`fas fa-chevron-down ${
						isOpen ? iconClassOpen : iconClassClose
					} ms-auto me-2`}></i>
			</div>
			<div ref={panelRefs.current[key]} className={panelClass}>
				<div className='py-3'>{d.content}</div>
			</div>
		</Fragment>
	));
};

export default AccordionBasic;
