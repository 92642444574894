const goals = {
	image: 'goals.jpg',
	carousel: true,
	path: '/goals',
	name: 'Goals',
	title: 'MMP WORLD Goals',
	description:
		'Start of the first market maker project for globalizing the BCN...',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<p>
						Start of the first market maker project for globalizing
						the BCN token
					</p>
					<ul>
						<li>
							Listing in the top 10 exchanges of the Coin Market
							Cap
						</li>
						<li> Price: + $ 0.10</li>
						<li> Market cap: + $ 50.000.000 </li>
						<li> 24 hours trading volume: + $ 5.000.000</li>
						<li> Liquidity pool: + $ 1.000.000</li>
						<li> Holders: + 2M</li>
						<li> Telegram member: + 500K</li>
						<li> Twitter: + 1M</li>
					</ul>
				</div>
			</div>
		</div>
	),
};

export default goals;
