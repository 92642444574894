import { Fragment } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const borderColor = '#000000';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 12,
    },
    tableDataLg: {
        width: '20%',
        height: 12,
        fontSize: 7,
        paddingTop: 2.5,
        paddingLeft: 4,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    tableDataSm: {
        width: '10%',
        height: 12,
        fontSize: 7,
        paddingTop: 2.5,
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    tableDataLast: {
        width: '10%',
        height: 12,
        fontSize: 7,
        paddingTop: 2.5,
        textAlign: 'center',
        borderRight: 'none',
    },
});

const MmiHeirsTableRow = ({ personalData }) => (
    <Fragment>
        {personalData.heirs.map((item, key) => (
            <View style={styles.row} key={key}>
                <Text style={styles.tableDataLg}>{item.name}</Text>
                <Text style={styles.tableDataLg}>{item.surname}</Text>
                <Text style={styles.tableDataLg}>{item.fathersName}</Text>
                <Text style={styles.tableDataSm}>{item.nationalId}</Text>
                <Text style={styles.tableDataSm}>{item.relationship}</Text>
                <Text style={styles.tableDataSm}>{item.priority}</Text>
                <Text style={styles.tableDataLast}>{item.percent}</Text>
            </View>
        ))}
    </Fragment>
);

export default MmiHeirsTableRow;
