import React from 'react';

const Input = ({
	name,
	label,
	error,
	customClass = '',
	labelClass = '',
	inputClass = '',
	formText,
	...rest
}) => {
	return (
		<div className={`form-group ${customClass}`}>
			{label && (
				<label className={`form-label ${labelClass}`} htmlFor={name}>
					{label}
				</label>
			)}
			<input
				{...rest}
				name={name}
				id={name}
				className={`form-control ${inputClass}`}
			/>
			{error && <div className='alert alert-danger'>{error}</div>}
			{formText && <div className='form-text'>{formText}</div>}
		</div>
	);
};

export default Input;
