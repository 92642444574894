import * as bootstrap from 'bootstrap';
import React, { useCallback, useReducer } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ScrollToTop from './components/common/scrollToTop';
import ScrollToTopBtn from './components/common/scrollToTopBtn';
import Footer from './components/footer';
import NavBar from './components/navbar';
import UnderMaintenance from './components/underMaintenance';
import config from './config';
import MainContext from './context/mainContext';
import uiInfoReducer from './context/reducers/uiInfoReducer';
import useAllDashboardBlogs from './hooks/useAllDashboardBlogs';
import useAllDashboardResources from './hooks/useAllDashboardResources';
import useConnection from './hooks/useConnection';
import useDownlines from './hooks/useDownlines';
import useInitData from './hooks/useInitData';
import useMediaQuery from './hooks/useMediaQuery';
import useUserData from './hooks/useUserData';
import useWatchPrice from './hooks/useWatchPrice';
import useWatchPrices from './hooks/useWatchPrices';
import generateRoutes, { getIsGlobal } from './routes/appRoutes';
import http from './services/httpService';
import TimerProvider from './timerProvider/timerProvider';
import { getQueries } from './utils/helpers';

function App() {
	//check redirect from bemchain
	const { uid } = getQueries();
	const user = http.getCurrentUser();
	if (uid && user && uid !== user.bc) http.logout();
	const { protectedRoutes, routes } = generateRoutes();

	const location = useLocation();
	const isGlobal = getIsGlobal(location.pathname);

	const [connection] = useConnection();
	const { status } = connection;
	status === 'no_internet' && toast.error('No internet connection');
	const [initData, setInitData] = useInitData();
	const [userData, setUserData] = useUserData();
	const [prices] = useWatchPrices();

	const [userDownlines] = useDownlines();
	const [dashboardBlogs] = useAllDashboardBlogs();
	const [resources] = useAllDashboardResources();

	const [mediaQuery] = useMediaQuery();
	const [uiInfo, uiInfoDispatch] = useReducer(uiInfoReducer, config.uiInfo);
	useCallback(
		() =>
			uiInfoDispatch({
				isLessThanMD: mediaQuery === 'xs' || mediaQuery === 'sm',
			}),
		[mediaQuery]
	);

	const handleUserDataChanged = (data) => setUserData((prevData) => ({ ...prevData, ...data }));
	const handleInitDataChanged = (data) => setInitData((prevData) => ({ ...prevData, ...data }));

	useWatchPrice(setInitData);

	const handleScroll = async (el) => {
		if (mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md') {
			const myCollapse = document.getElementById('navbarSupportedContent');
			await new bootstrap.Collapse(myCollapse, { show: false });
		}

		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const myNav = document.getElementById('myNav');
		const yOffset = -myNav.offsetHeight;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	// console.log('mediaQuery :>> ', mediaQuery);

	return (
		<MainContext.Provider
			value={{
				prices,
				connection,
				initData,
				userData,
				mediaQuery,
				uiInfo,
				userDownlines,
				dashboardBlogs,
				resources,
				onLogout: () => http.logout(),
				onInitDataChanged: handleInitDataChanged,
				onUserDataChanged: handleUserDataChanged,
				onUIInfoChanged: uiInfoDispatch,
				onHashLinkClick: handleScroll,
			}}>
			<TimerProvider sessionTime={initData.sessionTime}>
				<ToastContainer
					position='bottom-right'
					theme='colored'
				/>
				<ScrollToTopBtn showBelow={250} />
				{initData.underMaintenance ? (
					<UnderMaintenance />
				) : (
					<>
						<ScrollToTop />
						<NavBar isGlobal={isGlobal} />
						<Switch>
							{protectedRoutes}
							{routes}
							<Redirect to='/notFound' />
						</Switch>
						<Footer isGlobal={isGlobal} />
					</>
				)}
			</TimerProvider>
		</MainContext.Provider>
	);
}

export default App;
