import React from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class UserTerminationRequestForm extends Form {
	state = {
		data: {
			amount: '',
		},
		errors: {},
	};

	schema = {
		amount: Joi.number()
			.min(0)
			.max(this.props.max)
			.required()
			.label('Amount'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, max } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'amount',
						min: 0,
						max,
						label: 'Enter the refund amount',
						type: 'number',
					})}
				</div>
				<div className='mb-3 text-center'>
					{this.renderButton(
						'Approve & refund',
						`btn btn-my-navy`,
						isLoading && <Spinner content={'Submitting...'} />
					)}
				</div>
			</form>
		);
	}
}

export default UserTerminationRequestForm;
