import React from 'react';
import { Redirect } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useMain from '../hooks/useMain';
import http from '../services/httpService';
import config from './../config';
import Advantages from './home/advantages';
import Blogs from './home/blogs';
import Crowdfunding from './home/crowdfunding';
import FAQ from './home/faq';
import Hero from './home/hero';
import MarketMaker from './home/marketMaker';
import MMI from './home/mmi';
import MMP from './home/mmp';
import Permissions from './home/permissions';
import Risk from './home/risk';
import Video from './home/video';
import Prices from './prices';

const Home = () => {
	useDocumentTitle([]);
	const { initData } = useMain();
	if (config.domain === 'backOffice') {
		const user = http.getCurrentUser();
		if (user) return <Redirect to={`/${initData.underMaintenance ? 'configs' : 'blogs'}`} />;
		else return <Redirect to='/login' />;
	}

	return (
		<>
			<Prices />
			<Hero />
			<Video />
			<Advantages />
			<Crowdfunding />
			<MarketMaker />
			<MMP />
			<MMI />
			<Risk />
			<FAQ />
			<Blogs />
			<Permissions />
		</>
	);
};

export default Home;
