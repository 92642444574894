import React from 'react';

const Checks = ({
	name,
	label,
	error,
	customClass = '',
	labelClass = '',
	inputClass = '',
	checked = false,
	formText,
	...rest
}) => {
	return (
		<div className={`form-check ${customClass}`}>
			<input
				{...rest}
				className='form-check-input'
				type='checkbox'
				value={checked}
				defaultChecked={checked}
				name={name}
			/>
			<label className={`form-check-label ${labelClass}`} htmlFor={name}>
				{label}
			</label>
			{error && <div className='alert alert-danger'>{error}</div>}
			{formText && <div className='form-text'>{formText}</div>}
		</div>
	);
};

export default Checks;
