import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';

class SumbitAccountForm extends Form {
	state = {
		data: {
			broker: '',
			account: '',
			loginNumber: '',
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		broker: Joi.string().required().label('Broker'),
		account: Joi.string().required().label('Account Type'),
		loginNumber: Joi.string().required().label('Login Number'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.isSubmited === true &&
			prevProps.isSubmited !== this.props.isSubmited
		) {
			const state = { ...this.state };
			state.data = {
				broker: '',
				account: '',
				loginNumber: '',
				securityPassword: '',
			};
			this.setState(state);
		}
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP, brokers, accounts } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onChange={this.props.onFormChange}
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderSelectNormal({
						name: 'broker',
						label: 'Broker',
						options: brokers,
					})}
				</div>
				<div className='mb-3'>
					{this.renderSelectNormal({
						name: 'account',
						label: 'Account',
						options: accounts,
					})}
				</div>
				<div className='mb-3'>
					{this.renderInput({
						name: 'loginNumber',
						label: 'Login Number',
						type: 'text',
					})}
				</div>

				{this.renderSubmitBtnWithSPass({
					divClass: 'mb-3',
					hasSP,
					isLoading,
					text: 'Submit',
					loadingText: 'Submitting...',
				})}
			</form>
		);
	}
}

export default SumbitAccountForm;
