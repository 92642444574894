import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000';
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 14,
        borderColor: borderColor,
        borderBottomWidth: 1,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tableHeaderLg: {
        width: '20%',
        height: 14,
        fontSize: 7,
        paddingTop: 3,
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    tableHeaderSm: {
        width: '10%',
        height: 14,
        fontSize: 7,
        paddingTop: 3,
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    tableHeaderLast: {
        width: '10%',
        height: 14,
        fontSize: 7,
        paddingTop: 3,
        textAlign: 'center',
        borderRight: 'none',
    },
});

const MmiHeirsTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.tableHeaderLg}>Name</Text>
        <Text style={styles.tableHeaderLg}>Surname</Text>
        <Text style={styles.tableHeaderLg}>Father's Name</Text>
        <Text style={styles.tableHeaderSm}>National ID</Text>
        <Text style={styles.tableHeaderSm}>Relationship</Text>
        <Text style={styles.tableHeaderSm}>Priority</Text>
        <Text style={styles.tableHeaderLast}>Percent</Text>
    </View>
);

export default MmiHeirsTableHeader;
