import { View, StyleSheet } from '@react-pdf/renderer';
import DashboardTableHeader from './DashboardTableHeader';
import DashboardTableRow from './DashboardTableRow';

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 12,
		borderWidth: 1,
		borderColor: '#000000',
		borderBottom: 'none',
	},
});

const DashboardTable = ({ covers }) => (
	<View style={styles.tableContainer}>
		<DashboardTableHeader />
		<DashboardTableRow covers={covers} />
	</View>
);

export default DashboardTable;
