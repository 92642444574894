import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	captionTitle: {
		marginTop: 8,
		fontSize: 10,
		fontStyle: 'bold',
	},
	captionText: {
		marginTop: 3,
		fontSize: 10,
	},
});

const DashboardCaption = () => (
	<View>
		<Text style={styles.captionTitle}>Guaranteed participation period profit:</Text>
		<Text style={styles.captionText}>
			The guaranteed investment return rate by MMI, calculated and paid to investors under any circumstances, has
			been set at 10%. This rate is on account and the profit from the participation period is added to it each
			year.
		</Text>
		<Text style={styles.captionTitle}>Participation period profit in benefits:</Text>
		<Text style={styles.captionText}>
			The allocated profit to investors is calculated and publicly announced every January. We calculate the
			difference in excess between this profit and the guaranteed profit for all investors, and it is added to
			their accumulated profit for compound interest.
		</Text>
		<Text style={styles.captionTitle}>Note:</Text>
		<Text style={styles.captionText}>
			The basis for long-term investment returns in the MMI plan is daily interest-bearing investments. Therefore,
			timely installment payments, paying installments sooner, or delaying installment payments will directly
			impact the total returns at the end of each MMI investor's investment.
		</Text>
	</View>
);

export default DashboardCaption;
