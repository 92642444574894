import React from 'react';
import { capitalize } from 'lodash';

const SellHistoryTable = ({ data }) => {
	const classes = {
		pending: 'warning',
		canceled: 'danger',
		fulfilled: 'success',
	};

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Amount</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{d.no}</td>
							<td>{new Date(d.date).toLocaleString()}</td>
							<td>{d.amount / 1e6} USDT</td>
							<td className={`text-${classes[d.status]}`}>
								{capitalize(d.status)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default SellHistoryTable;
