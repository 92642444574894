import React, { createElement } from 'react';
import useMain from '../../../../hooks/useMain';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';
import VerificationTable from './kyc/verificationsTable';
import WhatIsKYC from './kyc/whatIsKYC';

const KYC = () => {
	const { userData, initData } = useMain();
	const { verificationConfig } = initData;
	const { verificationLevel, verificationRequest } = userData;
	const levels = Object.keys(verificationConfig);
	const userLevel = verificationConfig[verificationLevel].level;
	const nextLevel = verificationConfig[`level_${userLevel + 1}`];

	return (
		<div className='row'>
			<div className='col-12'>
				<TextCard
					text={
						<div className='row gy-4 mb-5'>
							<div className='col-lg-6 offset-lg-3 col-12'>
								<VerificationTable />
							</div>
							<div className='col-12 text-center pt-4'>
								<div className='fw-bold fs-5'>
									Your Current Verification Status:
								</div>
								<div className='centerAll'>
									<Image
										className='me-2'
										fileName={`verification/${verificationLevel}.svg`}
										height='25px'
									/>
									<span>Level {userLevel}</span>
								</div>
							</div>
							<div className='col-12'>
								<WhatIsKYC />
								<hr className=' my-1 my-lg-5' />
							</div>

							{verificationLevel === levels[levels.length - 1] ? (
								<div className='col-12 text-center fs-5'>
									<Image
										className='me-2'
										fileName='icons/success.svg'
										height='50px'
									/>
									<div>Congratulations!</div>
									<div>You passed all the KYC steps successfully.</div>
								</div>
							) : verificationRequest.status === 'pending' ? (
								<div className='col-lg-6 offset-lg-3'>
									<div className='centerAll'>
										<Image
											className='me-2'
											fileName='icons/pending.svg'
											height='50px'
										/>
										<div className='fw-bold'>
											Your last verification request for the next level is
											received successfully and being reviwed...
										</div>
									</div>
								</div>
							) : (
								<>
									<div className='col-lg-6 offset-lg-3'>
										<div className='centerAll flex-wrap'>
											<Image
												className='me-2 mb-2'
												fileName='icons/info.svg'
												height='50px'
											/>
											<div>
												{verificationRequest.status === 'accepted' && (
													<div className='centerAll my-3'>
														<Image
															className='me-2'
															fileName='icons/success.svg'
															height='25px'
														/>
														<div>
															Congratulations! your last verification
															request is accepted.
														</div>
													</div>
												)}
												{verificationRequest.status === 'rejected' && (
													<div className='my-3'>
														<div className='centerAll'>
															<Image
																className='me-2'
																fileName='icons/failed.svg'
																height='25px'
															/>
															<div>
																Your last verification request is
																rejected.
															</div>
														</div>
														{verificationRequest.lastRejectionReason && (
															<div className='text-center'>
																<span className='fw-bold'>
																	Reason:{' '}
																</span>
																<span className='text-danger'>
																	{
																		verificationRequest.lastRejectionReason
																	}
																</span>
															</div>
														)}
													</div>
												)}
												<div>
													To apply for the next level, please complete the
													form below. Note that the result of your
													application will be determined within a maximum
													of 10 business days.
												</div>
											</div>
										</div>
									</div>
									<div className='col-12 pt-5'>
										{createElement(
											require(`./kyc/${nextLevel.requirements.form}.jsx`)
												.default
										)}
									</div>
								</>
							)}
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default KYC;
