import React from 'react';
import useMain from '../../../../../hooks/useMain';
import Image from '../../../../common/image';

const VerificationTable = () => {
	const { initData } = useMain();
	const { verificationConfig } = initData;
	const levels = Object.keys(verificationConfig);

	return (
		<>
			<div className='fw-bold mb-4 text-center'>MMP World Verification Levels</div>
			<div className='table-responsive'>
				<table className='table table-sm myTable text-center'>
					<thead>
						<tr>
							<th>Level</th>
							<th>Requirments</th>
							<th>Limits</th>
						</tr>
					</thead>
					<tbody>
						{levels.map((l, k) => (
							<tr key={k}>
								<td>
									<span className='d-inline-flex align-items-center justify-content-center'>
										<Image
											fileName={`verification/${l}.svg`}
											height='25px'
										/>
										<div className='ms-2'>Level {k}</div>
									</span>
								</td>
								<td>{verificationConfig[l].requirements.title}</td>
								<td>{verificationConfig[l].limits}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default VerificationTable;
