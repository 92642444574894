import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import MmiForm from './MMI-Contract/MmiForm';

const DownloadContract = ({ data, className, fileName }) => {
	return (
		<div className={className}>
			<PDFDownloadLink
				document={<MmiForm personalData={data} />}
				fileName={`${fileName}.pdf`}>
				{({ blob, url, loading, error }) => {
					if (error) return null;
					return loading ? null : (
						<div className='btn btn-danger'>
							<i className='fas fa-file-pdf me-2'></i>
							<span>Download PDF</span>
						</div>
					);
				}}
			</PDFDownloadLink>
		</div>
	);
};

export default DownloadContract;
