import Joi from 'joi-browser';
import React from 'react';
import { toast } from 'react-toastify';
import http from '../../../../services/httpService';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class WithdrawalForm extends Form {
	state = {
		data: {
			amount: this.props.min,
			wallet: '',
			securityPassword: '',
		},
		errors: {},
		isCheckBtnLoading: false,
		isWalletValid: false,
		afterCheckMessage: 'A valid Tron address is required.',
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.max(Math.min(this.props.walletBalance, this.props.max))
			.multiple(this.props.step)
			.required()
			.label('Amount'),
		wallet: Joi.string().required().label('Wallet'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	checkWalletHandler = async () => {
		const { wallet } = this.state.data;
		if (!wallet) return;

		try {
			this.setState({ isCheckBtnLoading: true });
			const { data } = await http.checkWallet({ wallet });
			const errors = { ...this.state.errors };
			delete errors.wallet;
			this.setState({
				errors,
				afterCheckMessage: data.message,
				isWalletValid: true,
			});
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				this.setState({
					afterCheckMessage: data.message,
					isWalletValid: false,
				});
			}
			if (ex && ex.response && ex.response.data) {
				this.setState({ isWalletValid: false });
				return toast.error(ex.response.data);
			}
			if (ex && ex.message) {
				this.setState({ isWalletValid: false });
				return toast.error(ex.message);
			}
		} finally {
			this.setState({ isCheckBtnLoading: false });
		}
	};

	onWalletChanged = ({ currentTarget: wallet }) => {
		if (wallet.value)
			this.setState({
				afterCheckMessage: 'Press the Check button to examine the entered wallet.',
				isWalletValid: false,
			});
		else
			this.setState({
				afterCheckMessage: 'A valid Tron address is required.',
				isWalletValid: false,
			});
	};

	doSubmit = () =>
		this.props.onSubmit({
			...this.state.data,
			...{ isWalletValid: this.state.isWalletValid },
		});

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			hasSP,
			min,
			max,
			step,
			hasActiveWithdrawRequest,
			walletBalance,
			mustVerify,
		} = this.props;
		const { isWalletValid, afterCheckMessage, isCheckBtnLoading } = this.state;
		return (
			<form
				className='text-start'
				autoComplete='off'
				noValidate={hasActiveWithdrawRequest}
				onChange={this.props.onFormChange}
				onSubmit={this.handleSubmit}>
				<div className='mb-4'>
					{this.renderInput({
						name: 'amount',
						label: 'Transfer Amount',
						type: 'number',
						min,
						max: Math.min(walletBalance, max),
						step,
						formText: (
							<>
								<div>
									<b>USDT</b> Wallet Balance: <b>${walletBalance}</b>
								</div>
								<div>
									Minimum transfer amount is <b>${min.toFixed(2)}</b>
								</div>
								<div>
									<span>
										Maximum transfer amount is <b>${max.toFixed(2)}</b>
									</span>
									{mustVerify && <span> (verification level limit)</span>}
								</div>
								<div>
									Transfer amount multiplier is <b>${step.toFixed(2)}</b>
								</div>
							</>
						),
					})}
				</div>

				<div className='mb-4'>
					{this.renderInputGroupBtnEnd({
						name: 'wallet',
						label: 'Wallet Address',
						type: 'text',
						btnClass: `btn btn-my-navy`,
						extraHandler: this.onWalletChanged,
						btnText: isCheckBtnLoading ? (
							<Spinner content={'Checking...'} />
						) : (
							<span>Check</span>
						),
						btnDisable: isCheckBtnLoading || isLoading,
						btnHandler: this.checkWalletHandler,
						formText: (
							<div className={`text-${isWalletValid ? 'success' : 'danger'}`}>
								<span>{afterCheckMessage}</span>{' '}
								<i
									className={`fas fa-${
										isWalletValid ? 'check' : 'times'
									} me-2`}></i>
							</div>
						),
					})}
				</div>

				{this.renderSubmitBtnWithSPass({
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: 'Request',
					loadingText: 'Submitting...',
				})}
			</form>
		);
	}
}

export default WithdrawalForm;
