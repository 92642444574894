import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000';
const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: 24,
		borderColor: borderColor,
		borderBottomWidth: 1,
		textAlign: 'center',
		fontStyle: 'bold',
		flexGrow: 1,
	},
	insurancePremiumContainer: {
		width: '18.75%',
		height: 24,
		display: 'flex',
		flexDirection: 'column',
	},
	insurancePremium: {
		fontSize: 8,
		height: 13,
		paddingTop: 2,
		borderRightColor: borderColor,
		borderRightWidth: 1,
		borderBottomColor: borderColor,
		borderBottomWidth: 1,
	},
	subHeaderContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		fontStyle: 'bold',
	},
	subHeader: {
		width: '33.33%',
		fontSize: 8,
		height: 11,
		paddingTop: 1,
		borderRightColor: borderColor,
		borderRightWidth: 1,
	},
	tableHeader: {
		width: '6.25%',
		height: 24,
		fontSize: 7,
		paddingTop: 4,
		paddingRight: 2,
		paddingLeft: 2,
		borderRightColor: borderColor,
		borderRightWidth: 1,
	},
	tableHeaderLast: {
		width: '6.25%',
		fontSize: 7,
		borderRight: 'none',
	},
});

const DashboardTableHeader = () => (
	<View style={styles.container}>
		<Text style={styles.tableHeader}>Insurance Year</Text>
		<Text style={styles.tableHeader}>Insured Age</Text>
		<View style={styles.insurancePremiumContainer}>
			<Text style={styles.insurancePremium}>Insurance Premium</Text>
			<View style={styles.subHeaderContainer}>
				<Text style={styles.subHeader}>Monthly</Text>
				<Text style={styles.subHeader}>Annual</Text>
				<Text style={styles.subHeader}>Total</Text>
			</View>
		</View>
		<Text style={styles.tableHeader}>Death for Any Reason</Text>
		<Text style={styles.tableHeader}>Death due to an Accident</Text>
		<Text style={styles.tableHeader}>Disabilities</Text>
		<Text style={styles.tableHeader}>Special&#173;Diseases</Text>
		<Text style={styles.tableHeader}>Physycal&#173;Disability</Text>
		<Text style={styles.tableHeader}>Fire Incident</Text>
		<Text style={styles.tableHeader}>Termination</Text>
		<Text style={styles.tableHeader}>Mathematical Reserve</Text>
		<Text style={styles.tableHeader}>Guaranteed 10% Profit</Text>
		<Text style={styles.tableHeader}>Predicted Rate of 15%</Text>
		<Text style={styles.tableHeaderLast}>Predicted Rate of 18%</Text>
	</View>
);

export default DashboardTableHeader;
