import React from 'react';
import Image from '../../common/image';
import { TextCard } from '../ingredients/cards';

function Bonus() {
	return (
		<TextCard
			text={
				<>
					<div className='row'>
						<div className='col text-center'>
							<h4 className='my-5'>
								We will always consider temporary or long-term
								rewards.
							</h4>
							<h5 className='mb-5 lh-lg'>
								Tournament <br />
								Campaign <br />
								Rewards
							</h5>
							<Image
								className='mb-4 px-sm-5 w-100'
								fileName='bonus/xmas.jpg'
							/>
							<p>
								MMP WORLD Financial Group will announce the
								details of competitions, tournaments and
								campaigns in advance. <br />
								The conditions and method of holding, results,
								training and everything you need to know to
								receive the bonus will be announced.
							</p>

							{/* <h5 className='my-5'>
									No new bonus has been announced yet.
								</h5> */}
						</div>
					</div>
				</>
			}
		/>
	);
}

export default Bonus;
