import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ConfirmModal({ onClose, show, title, body }) {
	return (
		<Modal centered show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{body}</Modal.Body>
			<Modal.Footer className='d-flex justify-content-evenly align-items-center'>
				<button
					onClick={() => onClose(false)}
					className='btn btn-my-navy'>
					Cancel
				</button>
				<button
					onClick={() => onClose(true)}
					className='btn btn-my-navy'>
					Confirm
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export default ConfirmModal;
