import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';

class SubmitTransactionForm extends Form {
	state = {
		data: {
			transaction: '',
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		transaction: Joi.string()
			.required()
			.length(64)
			.label('USDT Transaction hash'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'transaction',
						label: 'USDT Transaction hash',
						type: 'text',
					})}
				</div>

				{this.renderSubmitBtnWithSPass({
					divClass: 'mb-3',
					hasSP,
					isLoading,
					text: 'Submit',
					loadingText: 'Submitting...',
				})}
			</form>
		);
	}
}

export default SubmitTransactionForm;
