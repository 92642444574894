import React from 'react';

const Hash = ({ hash, color }) => {
	if (hash.length !== 64) return <span>{hash}</span>;
	return (
		<a
			href={`https://tronscan.org/#/transaction/${hash}`}
			target='_blank'
			rel='noreferrer'
			style={{ color: color ? color : 'blue', textDecoration: 'none' }}>
			{hash.substring(0, 5) + '...' + hash.substring(59)}
		</a>
	);
};

export default Hash;
