import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useKYCRequests() {
	const [requests, setRequests] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/kyc', '/kyc/'];

		async function fetchData() {
			try {
				if (!http.getCurrentUser() || requests || !allowed.includes(pathname)) return;
				const { data } = await http.getKYCRequests();
				if (isMounted()) setRequests(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		fetchData();
	}, [isMounted, requests, pathname]);
	return { requests, setRequests };
}
