import React from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class ChangeEmailForm extends Form {
	state = {
		data: {
			oldEmail: '',
			newEmail: '',
		},
		errors: {},
	};

	schema = {
		oldEmail: Joi.string().email().required().label('Old Email'),
		newEmail: Joi.string().email().required().label('New Email'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			const state = { ...this.state };
			const keys = Object.keys(state.data);
			for (const i in keys) state.data[keys[i]] = '';
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'oldEmail',
						label: 'Enter the old email',
					})}
				</div>
				<div className='mb-3'>
					{this.renderInput({
						name: 'newEmail',
						label: 'Enter the new email',
					})}
				</div>
				<div className='mb-3 text-center'>
					{this.renderButton(
						'Submit',
						`btn btn-my-navy`,
						isLoading && <Spinner content='Loading...' />
					)}
				</div>
			</form>
		);
	}
}

export default ChangeEmailForm;
