import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import useMain from '../../hooks/useMain';
import Image from './../common/image';
import Shadow from './shadow';

const advs = [
	{
		img: 'safe.svg',
		title: 'Safe And Stable',
		content: 'Secure and updated measures are taken in MMP WORLD.',
		to: '/advantages#safe',
	},
	{
		img: 'speed.svg',
		title: 'Fast And Efficient',
		content: 'All-day deposit and withdrawal services (24/7), fast and timely arrival.',
		to: '/advantages#fast',
	},
	{
		img: 'features.svg',
		title: 'Perfect Features',
		content: 'A perfect future awaits users with the completion of MMP WORLD projects.',
		to: '/advantages#features',
	},
	{
		img: 'powerfull.svg',
		title: 'Powerfull Operating System',
		content:
			'One of the most substantial support and operating systems is used for the MMP WORLD project.',
		to: '/advantages#powerfull',
	},
];

function Advantages(props) {
	const { onHashLinkClick } = useMain();
	return (
		<>
			<div className='advantages'>
				<div className='container pt-5'>
					<div className='row'>
						<div className='col-12'>
							<h1>MMP WORLD Advantages</h1>
						</div>
					</div>
					<div className='row py-2 py-md-5'>
						{advs.map((a, key) => (
							<div
								key={key}
								className='col-xl-3 col-6 mb-5'>
								<div className='adv_cards p-4'>
									<Image
										height={'75px'}
										className={'mb-3'}
										fileName={`home/icons/${a.img}`}
									/>
									<h4 className='mb-2 mb-md-5'>{a.title}</h4>
									<p className='d-none d-md-block'>{a.content}</p>

									<NavHashLink
										className='btn btn-my-blue adv_cards_btn'
										style={{ borderRadius: '25px' }}
										smooth
										scroll={onHashLinkClick}
										to={a.to}>
										Learn More
									</NavHashLink>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<Shadow />
		</>
	);
}

export default Advantages;
