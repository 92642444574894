import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useTerminations() {
	const [terminations, setTerminations] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/terminations'];

		async function getTerminations() {
			try {
				if (
					!http.getCurrentUser() ||
					terminations ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getTerminations();
				if (isMounted()) setTerminations(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getTerminations();
	}, [isMounted, terminations, pathname]);
	return [terminations, setTerminations];
}
