import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useAllResources() {
	const [resources, setResources] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/resources'];

		async function getResources() {
			try {
				if (
					!http.getCurrentUser() ||
					resources ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getAllResources();
				if (isMounted()) setResources(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getResources();
	}, [isMounted, resources, pathname]);
	return [resources, setResources];
}
