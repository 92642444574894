import { capitalize } from 'lodash';
import React from 'react';
import { ProgressBar } from 'react-progressbar-fancy';
import { Link } from 'react-router-dom';
import useElemSize from '../../hooks/useElemSize';
import useMain from '../../hooks/useMain';
import userRank from '../../utils/userRank';
import Image from '../common/image';
import MaxCapModal from './maxcapModal';

const Summary = () => {
	const { userData, initData } = useMain();
	const [ref] = useElemSize('summary');
	const rank = userRank(userData, initData);
	const score = ((userData.usedMaxCap * 100) / userData.maxCap).toFixed(2);
	const maxCapMode =
		userData.usedMaxCap / userData.maxCap >= initData.maxCapAlert ? 'alert' : 'normal';

	const progressBarColors = {
		normal: ['#d07df6', '#ace4fd'],
		alert: ['#ff0000', '#920b0b'],
	};

	return (
		<div
			ref={ref}
			id='userSummary'
			className='card contentsCard'>
			<div className='card-body'>
				<div className='text-center mb-2 d-none d-md-block'>
					<Link to='/'>
						<Image
							className='w-75'
							fileName='logo_org.png'
						/>
					</Link>
				</div>
				<div className='mb-2 d-block d-md-none'>
					{rank && (
						<>
							<div className='d-flex justify-content-evenly align-items-center'>
								<div>
									<b>{rank.title}</b>
								</div>
								<Image
									fileName={`ranks/${rank.name}.svg`}
									height='55px'
								/>
							</div>
							<hr className='my-1' />
						</>
					)}
				</div>

				<div className='d-flex align-items-center justify-content-evenly'>
					<div className='d-flex flex-xxl-row flex-column align-items-center'>
						<div>
							<Image
								height='30px'
								fileName='icons/coins/bcn.png'
								className='mx-1'
							/>
						</div>
						<div className='text-center'>
							<div>
								<span style={{ fontSize: '14px' }}>{initData.bcn_price}</span>
								<span
									className='ms-1'
									style={{ fontSize: '9px' }}>
									trx
								</span>
							</div>
							<div style={{ fontSize: '11px' }}>($ {initData.bcn_price_usd})</div>
						</div>
					</div>

					<div className='d-flex flex-xxl-row flex-column align-items-center'>
						<div>
							<Image
								height='35px'
								fileName='icons/coins/tim.png'
							/>
						</div>
						<div className='text-center'>
							<div>
								<span style={{ fontSize: '14px' }}>{initData.tim_price}</span>
								<span
									className='ms-1'
									style={{ fontSize: '9px' }}>
									trx
								</span>
							</div>
							<div style={{ fontSize: '11px' }}>($ {initData.tim_price_usd})</div>
						</div>
					</div>
				</div>

				<hr className='my-2' />
				<div className='row g-0 align-items-center'>
					<div className='col-2 text-center'>
						<div className='mmpCardImage'>
							<Image
								fileName={`diamonds/${userData.card}.png`}
								className='w-100'
							/>
						</div>
					</div>
					<div
						className='col text-center summaryEmail'
						style={{ fontSize: '14px' }}>
						<div className='centerAll'>
							<div>{userData.bemchain.uid}</div>
							<Image
								className='ms-1'
								fileName={`verification/${userData.verificationLevel}.svg`}
								height='18px'
							/>
						</div>
						<div>{userData.email}</div>
						<div>
							{userData.managerRank === 'none' ? (
								''
							) : (
								<div className='centerAll'>
									<span>
										{userData.isGalactic
											? 'Galactic'
											: capitalize(userData.managerRank)}{' '}
										Manager
									</span>
									{userData.isGalactic && (
										<Image
											className='ms-1'
											fileName='icons/star.svg'
											height='18px'
										/>
									)}
								</div>
							)}
						</div>
					</div>
					<div className='col-2 text-center'>
						<Image
							fileName={`diamonds/mmi_${userData.mmi.isActive}.png`}
							className='w-100'
						/>
					</div>
				</div>
				{userData.isUserActive && (
					<ProgressBar
						className='my-1'
						label={
							<div className='d-flex align-items-center'>
								<span className='me-1'>Max Cap</span> <MaxCapModal />
							</div>
						}
						primaryColor={progressBarColors[maxCapMode][0]}
						secondaryColor={progressBarColors[maxCapMode][1]}
						score={Number(score)}
					/>
				)}
			</div>
		</div>
	);
};

export default Summary;
