import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import { TextCard } from '../../ingredients/cards';
import http from './../../../../services/httpService';
import ProfileForm from './profileForm';

const Profile = ({ history }) => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);

	const handleProfileUpdate = async (formData) => {
		try {
			const { fullName, country, nationalID, securityPassword } = formData;
			setIsLoading(true);
			const { data } = await http.updateProfile({
				securityPassword,
				fullName,
				country,
				nationalID,
				phoneNumber: String(formData.dial_code) + ' ' + String(formData.phoneNumber),
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setIsLoading(false);
			history.replace('/dashboard');
		} catch (ex) {
			console.log(ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='row'>
			<div className='col-12 text-center'>
				<TextCard
					title={'Update My Profile'}
					titleClass={'fs-3'}
					text={
						<div className='row'>
							<div className='col-sm-8 offset-sm-2 col-12'>
								<ProfileForm
									isLoading={isLoading}
									uid={userData.bemchain.uid}
									email={userData.email}
									sponsor={userData.sponsor}
									countries={initData.countries}
									onSubmit={handleProfileUpdate}
									hasSP={userData.hasSP}
									securityPassword={''}
									fullName={userData.profile.fullName}
									nationalID={userData.profile.nationalID}
									country={userData.profile.country}
									phoneNumber={
										userData.profile.phoneNumber
											? userData.profile.phoneNumber.split(' ')[1]
											: ''
									}
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default Profile;
