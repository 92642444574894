import Big from 'big.js';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { numberWithCommas } from '../../../../utils/helpers';
import CopyBtn from '../../../common/copyBtn';
import Image from '../../../common/image';
import ConfirmModal from '../../ingredients/confirmModal';
import BuyTokenRequestForm from './buyTokenRequestForm';
import SubmitTransactionForm from './submitTransactionForm';

const BuyTokenRequest = ({ history }) => {
	const { userData, onUserDataChanged, initData } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	const { defiConfig, tokenConfig, trx_price } = initData;
	const { currentToken } = tokenConfig;
	const tokenRate =
		initData[`${currentToken.toLowerCase()}_price`] * trx_price;

	const { wallets, hasSP, activationRequests, token } = userData;
	const activationRequest = activationRequests.find(
		(a) => a.activationType === 'token'
	);
	const activeTokens = token.filter((d) => d.isActive);

	const handleModalShow = (formData) => {
		setCurrentFormData(formData);
		const markup = (
			<>
				<div className='d-flex align-items-center mb-3'>
					<Image className='me-3' fileName='warning.png' />
					<div>
						Token purchase request:{' '}
						<b>
							{numberWithCommas(
								Big(formData.amount)
									.mul(Big(tokenConfig.multiplier))
									.div(Big(tokenRate))
									.toFixed(6)
							)}{' '}
							{currentToken}
						</b>
					</div>
				</div>
				<div>
					You will need to deposit{' '}
					<b>{numberWithCommas(formData.amount)} USDT</b> within{' '}
					<b>{defiConfig.activationDeadline / (60 * 1000)}</b>{' '}
					minutes.
				</div>
				<div>Do you confirm?</div>
			</>
		);
		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handleActivationRequest();
	};

	const handleActivationRequest = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.tokenPurchaseRequest(currentFormData);
			onUserDataChanged(data.data);
			toast.success(data.message);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmitTransaction = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.tokenSubmitTransaction({
				...formData,
				...{
					activationId: activationRequest._id,
				},
			});
			onUserDataChanged(data.data);
			toast.success(data.message);
			setIsLoading(false);
			history.replace('/dashboard/tokenWallet/tokenPurchases');
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='card myCards p-4'>
			<div className='row my-4'>
				<div className='col-sm-6 offset-sm-3 col-12'>
					{!!activationRequest ? (
						<div className='text-center'>
							<div className='fs-5'>
								Please send{' '}
								<b>
									{activationRequest.data.usdtAmount / 1e6}{' '}
									USDT
								</b>{' '}
								to the below address and submit the form
								provided with the transaction hash
							</div>
							<div className='my-4'>
								<QRCode
									value={activationRequest.data.address}
									level={'Q'}
									size={256}
								/>
							</div>
							<div>
								{activationRequest.data.address}
								<CopyBtn
									content={activationRequest.data.address}
								/>
							</div>
							<div className='mb-3 mt-1'>
								Amount :{' '}
								{activationRequest.data.usdtAmount / 1e6}
								<CopyBtn
									content={
										activationRequest.data.usdtAmount / 1e6
									}
								/>
							</div>
							<Countdown
								className='fs-2 text-danger'
								date={activationRequest.deadline}
								onComplete={() => window.location.reload()}
							/>
							<SubmitTransactionForm
								hasSP={hasSP}
								isLoading={isLoading}
								onSubmit={handleSubmitTransaction}
							/>
						</div>
					) : (
						<>
							<div className='fs-2 mb-3 text-center'>
								Purchase Token Request
							</div>
							<p>
								When the total amount collected in the Token
								wallet reaches 250 dollars, users can request to
								buy TIM token. The request to buy TIM token must
								be accompanied by the user's deposit of 250
								dollars. <br />
								In this way, the user deposits 250 dollars to
								his user account and the project robot buys TIM
								in the amount of 500 Tether. This amount is
								frozen for 1 year in the wallet that is created
								automatically. <br />
								Investors can see their balance through the
								"Watch Wallet", and after one year, the private
								key will be available to users.
							</p>
							<div className='fs-5 fw-bold my-2 text-center'>
								Current Token: {tokenConfig.currentToken} token
							</div>
							<div className='fs-6 text-center'>
								My Active Purchases: {activeTokens.length}
							</div>

							<BuyTokenRequestForm
								trxRate={trx_price}
								currentToken={currentToken}
								tokenRate={tokenRate}
								walletBalance={wallets.token.available / 1e6}
								min={tokenConfig.min}
								max={
									parseInt(
										wallets.token.available /
											1e6 /
											tokenConfig.step
									) * tokenConfig.step
								}
								step={tokenConfig.step}
								multiplier={tokenConfig.multiplier}
								hasSP={hasSP}
								isLoading={isLoading}
								onSubmit={handleModalShow}
							/>
						</>
					)}
					<div className='mt-5'>
						<b>Note:</b> Your purchased tokens will be securely
						locked in for a duration of 1 year. At the end of each
						period, you may request to receive the private key
						necessary to access your funds.
					</div>
				</div>
			</div>
			<ConfirmModal
				show={modalShow}
				onClose={handleModalClose}
				title={'Token Purchase'}
				body={modalBody}
			/>
		</div>
	);
};

export default BuyTokenRequest;
