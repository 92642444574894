import React from 'react';
import Shadow from './shadow';
import Image from './../common/image';

function Hero(props) {
	return (
		<>
			<div
				id='hero-carousel'
				className='carousel slide carousel-fade'
				data-bs-ride='carousel'>
				<div className='carousel-indicators'>
					<button
						type='button'
						data-bs-target='#hero-carousel'
						data-bs-slide-to='0'
						className='active'
						aria-current='true'
						aria-label='Slide 1'></button>
					<button
						type='button'
						data-bs-target='#hero-carousel'
						data-bs-slide-to='1'
						aria-label='Slide 2'></button>
					<button
						type='button'
						data-bs-target='#hero-carousel'
						data-bs-slide-to='2'
						aria-label='Slide 3'></button>
				</div>
				<div className='carousel-inner'>
					<div
						className='carousel-item active'
						data-bs-interval='10000'>
						<Hero1 />
					</div>
					<div
						className='carousel-item'
						data-bs-interval='10000'>
						<Hero2 />
					</div>
					<div
						className='carousel-item'
						data-bs-interval='10000'>
						<Hero3 />
					</div>
				</div>
				<button
					className='carousel-control-prev d-none d-md-block'
					type='button'
					data-bs-target='#hero-carousel'
					data-bs-slide='prev'>
					<span
						className='carousel-control-prev-icon'
						aria-hidden='true'></span>
					<span className='visually-hidden'>Previous</span>
				</button>
				<button
					className='carousel-control-next d-none d-md-block'
					type='button'
					data-bs-target='#hero-carousel'
					data-bs-slide='next'>
					<span
						className='carousel-control-next-icon'
						aria-hidden='true'></span>
					<span className='visually-hidden'>Next</span>
				</button>
			</div>
			<Shadow />
		</>
	);
}

const Hero1 = () => {
	return (
		<div
			id='hero_1'
			className='d-block w-100'>
			<div className='container py-md-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>Empower Your Dreams with MMP WORLD’s Innovative Solutions</b>
						</h3>
						<p>
							At MMP WORLD, we believe in empowering people to pursue their passions.
							Our platform provides innovative solutions that allow you to invest in
							what you love. Whether it’s a creative project, a social cause, or a
							startup, we make it easy for you to turn your passions into
							opportunities for growth and impact. Join us in making the world a
							better place, one investment at a time.
						</p>
						<Image
							className='w-100 d-block d-md-none'
							fileName='home/hero/1.jpg'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Hero2 = () => {
	return (
		<div
			id='hero_2'
			className='d-block w-100'>
			<div className='container py-md-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>Partner with MMP WORLD to Achieve Financial Success</b>
						</h3>
						<p>
							MMP WORLD is committed to helping you achieve financial success through
							personalized guidance and support. Whether you’re saving for the future,
							building wealth, or funding a dream project, we’re here to help you
							every step of the way. Our dedicated team provides expert advice and
							tailored solutions to ensure your financial goals are met with
							confidence and ease. Let us be your partner in financial growth.
						</p>
						<Image
							className='w-100 d-block d-md-none'
							fileName='home/hero/2.jpg'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Hero3 = () => {
	return (
		<div
			id='hero_3'
			className='d-block w-100'>
			<div className='container py-md-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5'>
						<h3 className='pb-4'>
							<b>Join the Revolution of Crowdfunding with MMP WORLD</b>
						</h3>
						<p>
							With MMP WORLD, investing in startups has never been more accessible or
							rewarding. We are at the forefront of the crowdfunding revolution,
							offering you the opportunity to be part of innovative projects and
							promising startups. Our platform makes it easy for you to invest in the
							future while enjoying the potential for significant rewards. Start your
							journey with MMP WORLD today and help shape the future of crowdfunding.
						</p>
						<Image
							className='w-100 d-block d-md-none'
							fileName='home/hero/3.jpg'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
