import React from 'react';
import { Link } from 'react-router-dom';

function BlogCard({ to, blog, scrollRule }) {
	const { title, description, thumbLink, _id, date } = blog;
	// if (scrollRule) scrollRule();

	return (
		<Link
			to={to}
			className='card myCards text-dark'
			onClick={
				scrollRule
					? scrollRule
					: () => {
							return;
					  }
			}>
			<img
				loading='lazy'
				src={thumbLink}
				className='card-img-top'
				alt={_id}
				crossOrigin='true'
			/>
			<div className='card-body'>
				<div className='card-title text-center'>
					<span className='h6 mb-0'>
						<b>{title}</b>
					</span>
				</div>

				<figure className='card-text text-center'>
					<blockquote className='blockquote'>
						<div>{description.substring(0, 20) + '...'}</div>
						<p className='fs-6 opacity-75'>
							{new Date(date).toLocaleDateString()}
						</p>
						<p className='fs-6 opacity-50'>Read More...</p>
					</blockquote>
				</figure>
			</div>
		</Link>
	);
}

export default BlogCard;
