import React from 'react';

const BinaryTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Day</th>
						<th>Date</th>
						<th>Left Balance</th>
						<th>Right Balance</th>
						<th>Balance</th>
						<th>Commission</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{d.no}</td>
							<td>{new Date(d.date).toLocaleDateString()}</td>
							<td>{`$ ${d.leftBalance / 1e6}`}</td>
							<td>{`$ ${d.rightBalance / 1e6}`}</td>
							<td>{d.balance}</td>
							<td>{`$ ${d.commission / 1e6}`}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default BinaryTable;
