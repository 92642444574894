import React from 'react';
import Countdown from 'react-countdown';
import useMain from '../../../hooks/useMain';
import { TextCard } from '../ingredients/cards';
import InvestmentDashboardTable from './../ingredients/investmentDashboardTable';

const InvestmentDashboard = ({ tab }) => {
	const { initData, userData } = useMain();
	const { data, sum } = userData.investData;

	return (
		<div className='row'>
			<div className='col-12'>
				<TextCard
					titleClass={''}
					title={''}
					text={
						<>
							<div className='row'>
								<div className='col text-center'>
									<h4>Next Staking Distribution Time:</h4>
									<Countdown
										className='h2'
										onComplete={() =>
											window.location.reload()
										}
										date={initData.stakingTime + 15000}
									/>
								</div>
							</div>
							<hr />
							{userData.card === 'white' ? (
								<div className='row my-5'>
									<div className='col text-center'>
										<h4>
											You do not have any active package
											right now.
										</h4>
									</div>
								</div>
							) : (
								<div className='row my-5'>
									<div className='col-sm-6 offset-sm-3 col-12 text-center'>
										<h4 className='mb-4'>
											My Cumulative Active Investments:
										</h4>
										<InvestmentDashboardTable data={data} />
										<h5 className='mt-4'>
											This month total profit: ${sum}
										</h5>
									</div>
								</div>
							)}
						</>
					}
				/>
			</div>
		</div>
	);
};

export default InvestmentDashboard;
