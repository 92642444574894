import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import styles from '../../../css/dashboard/ingredients.module.css';
import useMain from '../../../hooks/useMain';
import { paginate } from '../../../utils/paginate';
import Pagination from '../../common/pagination';
import HeaderImage from '../ingredients/headerImage';
import useBlogDashboard from './../../../hooks/useBlogDashboard';
import Preloader from './../../common/preloader';
import { StringToJSX } from './../../common/stringToJSX';
import BlogCard from './../../home/blogCard';

const News = () => {
	const { dashboardBlogs } = useMain();
	const { state } = useLocation();
	const _id =
		state && state._id
			? state._id
			: dashboardBlogs && dashboardBlogs.length > 0
			? dashboardBlogs[0]._id
			: null;
	const [blog] = useBlogDashboard(_id);
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 4;
	const data = paginate(dashboardBlogs, currentPage, pageSize);

	const scrollRule = () => {
		const myElement = document.getElementById('blogImgHolder');
		const topPos = myElement.offsetTop;
		document
			.getElementById('contentsBody')
			.scrollTo({ top: topPos, behavior: 'smooth' });
	};

	if (!dashboardBlogs || !blog) return <Preloader />;
	if (!_id) return <Redirect to={'dashboard/home'} />;
	return (
		<>
			{!blog.imageLink && (
				<HeaderImage fileName={'headers/news&Announcement.jpg'} />
			)}

			<div className='row'>
				<div
					id='blogImgHolder'
					className='col-md-6 offset-md-3 text-center'>
					<img
						loading='lazy'
						src={blog.imageLink}
						className={'w-75 blog_image'}
						alt={blog._id}
						crossOrigin='true'
					/>
				</div>
			</div>
			<div className='row py-5'>
				<div className='col-12'>
					<figure className='card-text text-center'>
						<blockquote className='blockquote'>
							<p className='h2'>
								<b>{blog.title}</b>
							</p>
							<p className='h4'>{blog.description}</p>
						</blockquote>
						<figcaption className='blockquote-footer mt-3'>
							<em>Written By : {blog.author.profile.fullName}</em>{' '}
							<br />
							<span className='fs-5'>
								{new Date(blog.date).toLocaleDateString()}
							</span>
						</figcaption>
					</figure>
					<hr />
					<StringToJSX domString={blog.content} />
				</div>
			</div>
			<hr />
			<div className='row mt-5'>
				{data.map((d, key) => (
					<div key={key} className='col-sm-3 mb-4'>
						<BlogCard
							to={{
								pathname: '/dashboard/news',
								state: {
									_id: d._id,
								},
							}}
							blog={d}
							scrollRule={scrollRule}
						/>
					</div>
				))}
			</div>
			<div className='row mb-5'>
				<div className='col-12 text-end'>
					<Pagination
						customClass={`mt-1 ${styles.myPagination}`}
						activeClass={styles.myActive}
						totalCount={dashboardBlogs.length}
						pageSize={pageSize}
						currentPage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</div>
			</div>
		</>
	);
};

export default News;
