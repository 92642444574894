import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import Image from '../../../common/image';
import { FlippingMMICard } from '../../ingredients/cards';
import ConfirmModal from '../../ingredients/confirmModal';
import PurchaseInsuranceForm from './purchaseInsuranceForm';

const PurchaseInsurance = ({ history }) => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	const { mmi, wallets } = userData;
	const { isActive } = mmi;
	const { mmiConfig, stepPurchase, purchaseOptions } = initData;

	const handleModalShow = (formData) => {
		if (!formData.isOverallBalanceEnough)
			return setErrors({
				amount: 'Not enough balance',
			});
		setCurrentFormData(formData);
		const oneYear = 365 * 24 * 60 * 60 * 1000;
		const userAge = Number(
			(
				(Date.now() - new Date(formData.birthDate).getTime()) /
				oneYear
			).toFixed(0)
		);
		const markup = (
			<>
				<div className='d-flex align-items-center'>
					<Image className={'me-3'} fileName={'warning.png'} />
					<div>
						You are going to activate your Market Maker Insurance
						card with the following information:
					</div>
				</div>
				<ul className='my-4'>
					<li>
						Your Age: <b>{userAge} years</b>
					</li>
					<li>
						Amount: <b>$ {formData.amount}</b>
					</li>
					<li>
						Purchase Type: <b>{formData.purchaseType}</b>
					</li>
					<li>
						Increase Percent: <b>{formData.increasePercent}</b>
					</li>
					<li>
						Payment Method: <b>{formData.paymentMethod}</b>
					</li>
					<li>
						Contract Period: <b>{formData.contractPeriod} years</b>
					</li>
				</ul>
				<div>Do you confirm?</div>
			</>
		);
		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handlePurchase();
	};

	const handlePurchase = async () => {
		try {
			const {
				amount,
				contractPeriod,
				paymentMethod,
				increasePercent,
				purchaseType,
				securityPassword,
				birthDate,
			} = currentFormData;
			setIsLoading(true);
			const { data } = await http.activateMMI({
				amount: Number(amount),
				contractPeriod,
				paymentMethod,
				increasePercent,
				purchaseType,
				securityPassword,
				birthDate: new Date(birthDate).getTime(),
			});
			onUserDataChanged(data.data);
			toast.success(data.message);
			setTimeout(() => {
				history.replace('/dashboard/mmi/insuranceDashboard');
			}, 1000);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='card myCards'>
			<div className='row p-4'>
				<div className={`${isActive && 'offset-lg-4'} col-lg-4`}>
					<div className='text-center my-4 fs-3'>Insurance Card:</div>
					<FlippingMMICard mmi={mmi} bc={userData.bemchain.uid} />
				</div>
				{isActive && (
					<div className='col-lg-6 offset-lg-3'>
						<div className='d-flex justify-content-evenly flex-wrap text-center mt-5 fs-5'>
							<div>
								<b>Contract Period</b>
								<div>{mmi.contractPeriod} years</div>
							</div>
							<div>
								<b>Payment Method</b>
								<div>
									{
										mmiConfig.paymentMethod.find(
											(m) =>
												m.duration === mmi.paymentMethod
										).content
									}
								</div>
							</div>
							<div>
								<b>Increase Percent</b>
								<div>{mmi.increasePercent * 100}%</div>
							</div>
						</div>
					</div>
				)}
				{!isActive && (
					<div className='col-lg-7 offset-lg-1 mt-4'>
						<PurchaseInsuranceForm
							errors={errors}
							birthDate={new Date()
								.toISOString()
								.substring(0, 10)}
							min={mmiConfig.minAmount}
							step={stepPurchase}
							isLoading={isLoading}
							hasSP={userData.hasSP}
							contractPeriod={mmiConfig.contractPeriod}
							paymentMethod={mmiConfig.paymentMethod}
							increasePercent={mmiConfig.increasePercent}
							options={purchaseOptions}
							activationBalance={
								wallets.activation.available / 1e6
							}
							discountBalance={wallets.discount.available / 1e6}
							onSubmit={handleModalShow}
						/>
					</div>
				)}
				<ConfirmModal
					show={modalShow}
					onClose={handleModalClose}
					title={'Purchase Insurance'}
					body={modalBody}
				/>
			</div>
		</div>
	);
};

export default PurchaseInsurance;
