import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../common/image';

const MarketMaker = () => (
	<div className='marketmaker py-5'>
		<div className='container'>
			<div className='row align-items-center'>
				<div className='col-md-6 order-2 order-md-1'>
					<Image className='w-100' fileName='home/marketmaker.png' />
				</div>
				<div className='col-md-6 order-1 order-md-2'>
					<h5 className='text-uppercase text-purple'>What is</h5>
					<h1 className='text-dark'>Market Maker?</h1>
					<p className='text-dark'>
						A <strong>market maker</strong> or{' '}
						<strong>liquidity provider</strong> is a person or legal
						entity that constantly buys and sells a currency at the
						rate at which it is sold in the market. By doing this,
						the market maker acts as an opposing side to most
						transactions that take place by retail traders. In other
						words, when a retail trader buys a certain currency, the
						market maker sells that currency or vice versa. <br />
						<br />
						It is important to note that a market maker always
						trades against the population of retail traders. One of
						the main duties of a market maker is to provide enough
						liquidity for every exchanged asset. The price offered
						by a market maker is merely based on supply and demand.{' '}
						<br />
						<br />
						Market makers are active in currency exchange markets,
						stock exchanges, and decentralized network protocols.
					</p>
					<Link to='/marketmaker' className='btn btn-my-purple me-3'>
						Read More
					</Link>
				</div>
			</div>
		</div>
	</div>
);

export default MarketMaker;
