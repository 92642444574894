import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { TextCard } from '../../ingredients/cards';
import ConfirmModal from '../../ingredients/confirmModal';
import TransferToOthersTab from './transferToOthersTab';
import TransferToOwnTab from './transferToOwnTab';

const InternalTransfer = () => {
	const { initData, onUserDataChanged } = useMain();
	const [isSubmited, setIsSubmited] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentForm, setCurrentForm] = useState(null);
	const [currentFormData, setCurrentFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [ownErrors, setOwnErrors] = useState({});
	const [othersErrors, setOthersErrors] = useState({});

	const handleModalShow = (formData, type) => {
		if (type === 'others' && !formData.isBCValid) {
			return setOthersErrors({
				destBC: 'You need to Check the entered BC before going further.',
			});
		}
		const option = initData[`${type}TransferOptions`].find(
			(o) => o.id === formData.transferType
		);
		setCurrentForm(type);
		setCurrentFormData(formData);
		setModalBody(
			<>
				<div>{option.label}</div>
				<div>{option.text}</div>
				<div>Amount: {formData.amount} USDT</div>
				{type === 'others' && <div>Destination: {formData.destBC}</div>}
			</>
		);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response && currentForm === 'own')
			handleOwnTransfer(currentFormData);
		if (response && currentForm === 'others')
			handleOthersTransfer(currentFormData);
	};

	const handleOwnTransfer = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.ownTransfer(formData);
			setOwnErrors({});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setIsSubmited(true);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setOwnErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentForm(null);
			setCurrentFormData(null);
		}
	};

	const handleOthersTransfer = async (formData) => {
		const { destBC, transferType, amount, remarks, securityPassword } =
			formData;
		try {
			setIsLoading(true);
			const { data } = await http.othersTransfer({
				destBC,
				transferType,
				amount,
				remarks,
				securityPassword,
			});
			setOthersErrors({});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setIsSubmited(true);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setOthersErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentForm(null);
			setCurrentFormData(null);
		}
	};

	return (
		<div className='row'>
			<div className='col-12 text-center'>
				<TextCard
					title={''}
					titleClass={''}
					text={
						<div className='row mt-4'>
							<div className='col-12'>
								<ul
									className='nav nav-tabs justify-content-center nav-justified'
									id='internalTransferTab'
									role='tablist'>
									<li
										className='nav-item fs-4'
										role='presentation'>
										<button
											className={`nav-link active ${
												isLoading ? 'disabled' : ''
											}`}
											id='own-tab'
											data-bs-toggle='tab'
											data-bs-target='#own-tab-pane'
											type='button'
											role='tab'
											aria-controls='own-tab-pane'
											aria-selected='true'>
											Transfer to own wallets
										</button>
									</li>
									<li
										className='nav-item fs-4'
										role='presentation'>
										<button
											className={`nav-link ${
												isLoading ? 'disabled' : ''
											}`}
											id='others-tab'
											data-bs-toggle='tab'
											data-bs-target='#others-tab-pane'
											type='button'
											role='tab'
											aria-controls='others-tab-pane'
											aria-selected='false'>
											Transfer to other's wallets
										</button>
									</li>
								</ul>
								<div
									className='tab-content innerTabPanels pt-4'
									id='internalTransferTabContent'>
									<TransferToOwnTab
										isLoading={isLoading}
										ownErrors={ownErrors}
										onSubmit={handleModalShow}
										onFormChange={() =>
											setIsSubmited(false)
										}
										isSubmited={isSubmited}
									/>
									<TransferToOthersTab
										isLoading={isLoading}
										othersErrors={othersErrors}
										onSubmit={handleModalShow}
										onFormChange={() =>
											setIsSubmited(false)
										}
										isSubmited={isSubmited}
									/>
								</div>
								<ConfirmModal
									show={modalShow}
									onClose={handleModalClose}
									title={'Internal Transfer'}
									body={modalBody}
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default InternalTransfer;
