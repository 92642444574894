import axios from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import config from '../config';

class Http {
	constructor() {
		this.tokenKey = 'authToken';
		this.baseURL = config.server;
		this.init_url = '/init';
		this.auth_url = '/auth';
		this.admin_url = '/admin';
		this.otp_url = '/auth/otp';
		this.users_url = '/users';
		this.email_url = '/email';
		this.wallets_url = '/wallets';
		this.blogs_url = '/blogs';
		this.resources_url = '/resources';
		this.contests_url = '/contests';
		this.mmi_url = '/mmi';
		this.mmiContract_url = '/mmi/contract';
		this.defi_url = '/defi';
		this.token_url = '/token';
		this.kyc_url = '/kyc';

		axios.interceptors.request.use((config) => {
			config.headers['x-auth-token'] = this.getJwt();
			return config;
		});
		axios.interceptors.response.use(null, (error) => {
			const expectedError =
				error.response && error.response.status >= 400 && error.response.status < 500;

			const errorTooMany = error.response && error.response.status === 429;
			if (errorTooMany) toast.error('Too many requests, please try again later!');

			const authError = error.response && error.response.status === 401;
			if (authError) this.logout();

			if (!expectedError) console.error('An unexpected error occurred!');

			return Promise.reject(error);
		});
	}

	// Utils *****************************************************

	setJwt = (jwt) => localStorage.setItem(this.tokenKey, jwt);

	getJwt = () => localStorage.getItem(this.tokenKey);

	removeJwt = () => localStorage.removeItem(this.tokenKey);

	getCurrentUser = () => {
		try {
			const token = localStorage.getItem(this.tokenKey);
			const user = jwtDecode(token);
			return user;
		} catch (ex) {}
	};

	logout = () => {
		if (this.getCurrentUser()) {
			this.removeJwt();
			window.location = '/';
		}
	};

	request = ({ url, method = 'get', data = null, ...rest }) =>
		axios.request({ baseURL: this.baseURL, url, method, data, ...rest });

	// Methods *****************************************************

	// init ***********************

	checkHealth = (timeout) => this.request({ url: `${this.init_url}/health`, timeout });

	init = () => this.request({ url: this.init_url });

	// auth ***********************

	login = (data) => this.request({ url: this.auth_url, method: 'post', data });

	loginOTP = (data) =>
		this.request({
			url: `${this.auth_url}/loginotp`,
			method: 'post',
			data,
		});

	register = (data) =>
		this.request({
			url: `${this.auth_url}/register`,
			method: 'post',
			data,
		});

	checkID = (data) => this.request({ url: `${this.auth_url}/checkID`, method: 'post', data });

	reauth = () => this.request({ url: `${this.auth_url}/reauth` });

	confirm = (data) => this.request({ url: `${this.auth_url}/confirm`, method: 'post', data });

	updateP = (data) => this.request({ url: `${this.auth_url}/updateP`, method: 'post', data });

	updateSP = (data) =>
		this.request({
			url: `${this.auth_url}/updateSP`,
			method: 'post',
			data,
		});

	resetP = (data) => this.request({ url: `${this.auth_url}/resetP`, method: 'post', data });

	// admin ***********************

	getWithdrawReqs = () => this.request({ url: `${this.admin_url}/getWithdrawReqs` });

	getTickets = () => this.request({ url: `${this.admin_url}/getTickets` });

	closeTicket = (data) =>
		this.request({
			url: `${this.admin_url}/closeTicket`,
			method: 'post',
			data,
		});

	getTerminations = () => this.request({ url: `${this.admin_url}/getTerminations` });

	approveTermination = (data) =>
		this.request({
			url: `${this.admin_url}/approveTermination`,
			method: 'post',
			data,
		});

	getDebts = () => this.request({ url: `${this.admin_url}/getDebts` });

	addNewDebt = (data) =>
		this.request({
			url: `${this.admin_url}/addNewDebt`,
			method: 'post',
			data,
		});

	clearDebt = (data) =>
		this.request({
			url: `${this.admin_url}/clearDebt`,
			method: 'post',
			data,
		});

	getConfigs = () => this.request({ url: `${this.admin_url}/getConfigs` });

	setConfig = (data) =>
		this.request({
			url: `${this.admin_url}/setConfig`,
			method: 'post',
			data,
		});

	getUser = (data) =>
		this.request({
			url: `${this.admin_url}/getUser`,
			method: 'post',
			data,
		});

	suspend = (data) =>
		this.request({
			url: `${this.admin_url}/suspend`,
			method: 'post',
			data,
		});

	changeWallet = (data) =>
		this.request({
			url: `${this.admin_url}/changeWallet`,
			method: 'post',
			data,
		});

	changeEmail = (data) =>
		this.request({
			url: `${this.admin_url}/changeEmail`,
			method: 'post',
			data,
		});

	getKYCRequests = () => this.request({ url: `${this.admin_url}/getKYCRequests` });

	submitKYCReviewResult = (data) =>
		this.request({
			url: `${this.admin_url}/submitKYCReviewResult`,
			method: 'post',
			data,
		});

	// email ***********************

	requestActivation = () => this.request({ url: `${this.email_url}/requestActivation` });

	requestP_VC = () => this.request({ url: `${this.email_url}/requestP_VC` });

	requestSP_VC = () => this.request({ url: `${this.email_url}/requestSP_VC` });

	requestPR_VC = (data) =>
		this.request({
			url: `${this.email_url}/requestPR_VC`,
			method: 'post',
			data,
		});

	// user ***********************

	getMe = () => this.request({ url: `${this.users_url}/me` });

	getOnlyMe = () => this.request({ url: `${this.users_url}/onlyme` });

	getMyDownlines = () => this.request({ url: `${this.users_url}/mydownlines` });

	requestTopup = (data) =>
		this.request({
			url: `${this.users_url}/requestTopup`,
			method: 'post',
			data,
		});

	cancelTopup = (data) =>
		this.request({
			url: `${this.users_url}/cancelTopup`,
			method: 'post',
			data,
		});

	updateProfile = (data) =>
		this.request({
			url: `${this.users_url}/updateProfile`,
			method: 'post',
			data,
		});

	purchase = (data) =>
		this.request({
			url: `${this.users_url}/purchase`,
			method: 'post',
			data,
		});

	terminate = (data) =>
		this.request({
			url: `${this.users_url}/terminate`,
			method: 'post',
			data,
		});

	submitTicket = (data) =>
		this.request({
			url: `${this.users_url}/submitTicket`,
			method: 'post',
			data,
		});

	// wallet ***********************

	ownTransfer = (data) =>
		this.request({
			url: `${this.wallets_url}/ownTransfer`,
			method: 'post',
			data,
		});

	checkBC = (data) =>
		this.request({
			url: `${this.wallets_url}/checkBC`,
			method: 'post',
			data,
		});

	othersTransfer = (data) =>
		this.request({
			url: `${this.wallets_url}/othersTransfer`,
			method: 'post',
			data,
		});

	sellCurrentDeposit = (data) =>
		this.request({
			url: `${this.wallets_url}/sellCurrentDeposit`,
			method: 'post',
			data,
		});

	cancelSellCurrentDeposit = (data) =>
		this.request({
			url: `${this.wallets_url}/cancelSellCurrentDeposit`,
			method: 'post',
			data,
		});

	checkWallet = (data) =>
		this.request({
			url: `${this.wallets_url}/checkWallet`,
			method: 'post',
			data,
		});

	withdrawRequest = (data) =>
		this.request({
			url: `${this.wallets_url}/withdrawRequest`,
			method: 'post',
			data,
		});

	cancelWithdrawRequest = (data) =>
		this.request({
			url: `${this.wallets_url}/cancelWithdrawRequest`,
			method: 'post',
			data,
		});

	// blogs ***********************

	getAllBlogs = () => this.request({ url: `${this.blogs_url}/all` });

	createBlog = (data) => this.request({ url: `${this.blogs_url}/create`, method: 'post', data });

	getBlog = (_id) => this.request({ url: `${this.blogs_url}/blog/${_id}` });

	getBlogHome = (_id) => this.request({ url: `${this.blogs_url}/blogHome/${_id}` });

	getBlogDashboard = (_id) => this.request({ url: `${this.blogs_url}/blogDashboard/${_id}` });

	deleteBlog = (data) => this.request({ url: `${this.blogs_url}/delete`, method: 'post', data });

	editBlogImg = (data, _id) =>
		this.request({
			url: `${this.blogs_url}/editImg/${_id}`,
			method: 'post',
			data,
		});

	editBlog = (data, _id) =>
		this.request({
			url: `${this.blogs_url}/edit/${_id}`,
			method: 'post',
			data,
		});

	getAllHomeBlogs = () => this.request({ url: `${this.blogs_url}/allHome` });

	getAllDashboardBlogs = () => this.request({ url: `${this.blogs_url}/allDashboard` });

	// resources ***********************

	getAllResources = () => this.request({ url: `${this.resources_url}/all` });

	addResource = (data) =>
		this.request({
			url: `${this.resources_url}/add`,
			method: 'post',
			data,
		});

	deleteResource = (data) =>
		this.request({
			url: `${this.resources_url}/delete`,
			method: 'post',
			data,
		});

	getAllDashboardResources = () =>
		this.request({ url: `${this.resources_url}/allDashboardResources` });

	// otp ***********************

	generateOTP = (data) => this.request({ url: `${this.otp_url}/generate`, method: 'post', data });

	verifyOTP = (data) => this.request({ url: `${this.otp_url}/verify`, method: 'post', data });

	disableOTP = (data) => this.request({ url: `${this.otp_url}/disable`, method: 'post', data });

	enableOTP = (data) => this.request({ url: `${this.otp_url}/enable`, method: 'post', data });

	// contests ***********************

	getGoProResult = () => this.request({ url: `${this.contests_url}/gopro1` });

	getGoPro2Result = () => this.request({ url: `${this.contests_url}/gopro2` });

	getGoPro3Result = () => this.request({ url: `${this.contests_url}/gopro3` });

	getGoPro4Result = () => this.request({ url: `${this.contests_url}/gopro4` });

	getFlagshipBattleResult = () => this.request({ url: `${this.contests_url}/flgshipBattle` });

	getStarsResult = () => this.request({ url: `${this.contests_url}/stars` });

	// mmi ***********************

	activateMMI = (data) => this.request({ url: `${this.mmi_url}/activate`, method: 'post', data });

	migrate = (data) => this.request({ url: `${this.mmi_url}/migrate`, method: 'post', data });

	installmentPayment = (data) =>
		this.request({
			url: `${this.mmi_url}/installmentPayment`,
			method: 'post',
			data,
		});

	mmiQueryCode = (data) =>
		this.request({
			url: `${this.mmi_url}/queryCode`,
			method: 'post',
			data,
		});

	mmiQueryTable = (data) =>
		this.request({
			url: `${this.mmi_url}/queryTable`,
			method: 'post',
			data,
		});

	// mmi contract ***********************

	answerServicesNeed = (data) =>
		this.request({
			url: `${this.mmiContract_url}/answerServicesNeed`,
			method: 'post',
			data,
		});

	pages = (data) =>
		this.request({
			url: `${this.mmiContract_url}/pages`,
			method: 'post',
			data,
		});

	// defi ***********************

	defiActivationRequest = (data) =>
		this.request({
			url: `${this.defi_url}/activationRequest`,
			method: 'post',
			data,
		});

	defiSubmitTransaction = (data) =>
		this.request({
			url: `${this.defi_url}/submitTransaction`,
			method: 'post',
			data,
		});

	// token ***********************

	tokenPurchaseRequest = (data) =>
		this.request({
			url: `${this.token_url}/activationRequest`,
			method: 'post',
			data,
		});

	tokenSubmitTransaction = (data) =>
		this.request({
			url: `${this.token_url}/submitTransaction`,
			method: 'post',
			data,
		});

	// kyc ***********************

	getKYCData = () =>
		this.request({
			url: `${this.kyc_url}/getKYCData`,
		});

	setBasicPersonalInfo = (data) =>
		this.request({
			url: `${this.kyc_url}/setBasicPersonalInfo`,
			method: 'post',
			data,
		});

	submitKYCDocuments = (data) =>
		this.request({
			url: `${this.kyc_url}/submitDocuments`,
			method: 'post',
			data,
		});
}

const http = new Http();
export default http;
