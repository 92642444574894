import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { numberWithCommas } from '../../../../utils/helpers';
import Image from '../../../common/image';
import { FlippingMMICard } from '../../ingredients/cards';
import ConfirmModal from '../../ingredients/confirmModal';
import MigrateForm from './migrateForm';

const Migrate = ({ history }) => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	const { mmi, card, investmentHistory } = userData;
	const { isActive } = mmi;
	const { mmiConfig } = initData;
	const activeInvestments = investmentHistory
		.filter((i) => i.status === 'active')
		.map((i) => ({
			value: i._id,
			label: `$ ${numberWithCommas(i.amount / 1e6)} --> ${new Date(
				i.date
			).toLocaleDateString()}`,
			amount: i.amount / 1e6,
		}));

	const handleModalShow = (formData) => {
		if (!formData.totalSelected)
			return setErrors({
				investments: 'Select at least one investment',
			});
		setCurrentFormData(formData);
		const oneYear = 365 * 24 * 60 * 60 * 1000;
		const userAge = Number(
			(
				(Date.now() - new Date(formData.birthDate).getTime()) /
				oneYear
			).toFixed(0)
		);
		const markup = (
			<>
				<div className='d-flex align-items-center'>
					<Image className={'me-3'} fileName={'warning.png'} />
					<div>
						You are going to activate your Market Maker Insurance
						card with the following information:
					</div>
				</div>
				<ul className='my-4'>
					<li>
						Your Age: <b>{userAge} years</b>
					</li>
					<li>
						Amount: <b>$ {formData.totalSelected}</b>
					</li>
					<li>
						Increase Percent: <b>{formData.increasePercent}</b>
					</li>
					<li>
						Payment Method: <b>{formData.paymentMethod}</b>
					</li>
					<li>
						Contract Period: <b>{formData.contractPeriod} years</b>
					</li>
				</ul>
				<div>Do you confirm?</div>
			</>
		);
		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handleMigrate();
	};

	const handleMigrate = async () => {
		try {
			const {
				investments,
				contractPeriod,
				paymentMethod,
				increasePercent,
				securityPassword,
				birthDate,
			} = currentFormData;
			setIsLoading(true);
			const { data } = await http.migrate({
				investments: investments.map((i) => i.value),
				contractPeriod,
				paymentMethod,
				increasePercent,
				securityPassword,
				birthDate: new Date(birthDate).getTime(),
			});
			onUserDataChanged(data.data);
			toast.success(data.message);
			setIsLoading(false);
			history.replace('/dashboard/mmi/insuranceDashboard');
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='card myCards p-4'>
			{isActive ? (
				<>
					<div className='row'>
						<div className='col-lg-4 offset-lg-4 text-center'>
							<div className='text-center my-4 fs-3'>
								Insurance Card:
							</div>
							<FlippingMMICard
								mmi={mmi}
								bc={userData.bemchain.uid}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-6 offset-lg-3'>
							<div className='d-flex justify-content-evenly flex-wrap text-center mt-5 fs-5'>
								<div>
									<b>Contract Period</b>
									<div>{mmi.contractPeriod} years</div>
								</div>
								<div>
									<b>Payment Method</b>
									<div>
										{
											mmiConfig.paymentMethod.find(
												(m) =>
													m.duration ===
													mmi.paymentMethod
											).content
										}
									</div>
								</div>
								<div>
									<b>Increase Percent</b>
									<div>{mmi.increasePercent * 100}%</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : card === 'white' ? (
				<div className='row'>
					<div className='col-12'>
						<div className='text-center my-5'>
							<Image
								height={'180px'}
								fileName={'icons/noData.svg'}
							/>
							<h4 className='mt-4'>
								You do not have any active investment card to
								migrate.
							</h4>
						</div>
					</div>
				</div>
			) : (
				<div className='row align-items-center'>
					{Date.now() <= 1701216000000 && (
						<div className='col-12 text-center mb-4'>
							<div className='fs-4'>Migrate Deadline:</div>
							<Countdown
								className='h1'
								onComplete={() => window.location.reload()}
								date={1701216000000}
							/>
						</div>
					)}
					<div className='col-lg-7 order-1 order-lg-0 mt-4'>
						<MigrateForm
							errors={errors}
							birthDate={new Date()
								.toISOString()
								.substring(0, 10)}
							activeInvestments={activeInvestments}
							isLoading={isLoading}
							hasSP={userData.hasSP}
							contractPeriod={mmiConfig.contractPeriod}
							paymentMethod={mmiConfig.paymentMethod}
							increasePercent={mmiConfig.increasePercent}
							onSubmit={handleModalShow}
						/>
					</div>
					<div className='col-lg-5 order-0 order-lg-1 text-center mb-5'>
						<Image fileName='migrateImg.png' className='w-100' />
					</div>
					<ConfirmModal
						show={modalShow}
						onClose={handleModalClose}
						title={'Migrate To MMI'}
						body={modalBody}
					/>
				</div>
			)}
		</div>
	);
};

export default Migrate;
