import { useEffect, useContext } from 'react';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import useIsMounted from './useIsMounted';
import MainContext from '../context/mainContext';

export default function useElemSize(elem) {
	const { onUIInfoChanged } = useContext(MainContext);
	const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
	const isMounted = useIsMounted();

	useEffect(() => {
		if (bounds?.height > 0 && isMounted())
			onUIInfoChanged({ [`${elem}`]: bounds });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bounds, isMounted, elem]);

	return [ref];
}
