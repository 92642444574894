import { Image, StyleSheet, View } from '@react-pdf/renderer';
import footerMMI from '../img/footerMMI.png';

const styles = StyleSheet.create({
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    image: {
        width: 600,
        height: 'auto',
    },
});

const MmiFormFooter = () => (
    <View style={styles.footerContainer}>
        <Image style={styles.image} src={footerMMI} />
    </View>
);

export default MmiFormFooter;
