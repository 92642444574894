import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { RiseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import { DoughnutChart } from '../../../common/charts';
import { DownlinesCard, TextCard } from '../../ingredients/cards';
import GetUserByEmailForm from './getUserByEmailForm';
import GetUserByUIDForm from './getUserByUIDForm';

const Downlines = () => {
	const pageSize = 15;
	const userTypes = [
		{
			_id: 0,
			content: 'Active (MMP or MMI)',
			fn: (users) => users.filter((u) => u.active === 'Yes'),
		},
		{
			_id: 1,
			content: 'MMP Users',
			fn: (users) => users.filter((u) => u.active === 'Yes' && u.card !== 'white'),
		},
		{
			_id: 2,
			content: 'MMI Users',
			fn: (users) => users.filter((u) => u.mmiIsActive),
		},
		{
			_id: 3,
			content: 'Inactive (White)',
			fn: (users) => users.filter((u) => u.active === 'No'),
		},
		{
			_id: 4,
			content: 'Purple Cards',
			fn: (users) => users.filter((u) => u.card === 'purple'),
		},
		{
			_id: 5,
			content: 'Blue Cards',
			fn: (users) => users.filter((u) => u.card === 'blue'),
		},
		{
			_id: 6,
			content: 'Red Cards',
			fn: (users) => users.filter((u) => u.card === 'red'),
		},
		{
			_id: 7,
			content: 'Black Cards',
			fn: (users) => users.filter((u) => u.card === 'black'),
		},
		{
			_id: 8,
			content: 'Royal Cards',
			fn: (users) => users.filter((u) => u.card === 'royal'),
		},
		{
			_id: 9,
			content: 'All Users',
			fn: (users) => users,
		},
	];
	const { userDownlines } = useMain();
	const [foundUser, setFoundUser] = useState(undefined);
	const [usersCurrentPage, setUsersCurrentPage] = useState(1);
	const [selectedUserType, setSelectedUserType] = useState(userTypes[0]);
	const [sortColumn, setSortColumn] = useState({
		path: 'no',
		order: 'asc',
	});

	const allUsers = useMemo(() => {
		const merged = userDownlines ? [...userDownlines.right, ...userDownlines.left] : [];
		const sorted = merged.sort((a, b) => (a.bemchainDate > b.bemchainDate ? 1 : -1));
		for (const i in sorted) if (!sorted[i].card) sorted[i].currentPackageValue = 0;

		return sorted;
	}, [userDownlines]);

	const filteredUsers = selectedUserType.fn(allUsers);
	for (const i in filteredUsers) filteredUsers[i].no = Number(i) + 1;

	const sortedUsers = _.orderBy(filteredUsers, [sortColumn.path], [sortColumn.order]);
	const users = paginate(sortedUsers, usersCurrentPage, pageSize);

	const handleTypeSelect = (userType) => {
		setSelectedUserType(userType);
		setUsersCurrentPage(1);
	};

	const handleSort = (path) =>
		setSortColumn((prev) => {
			const sortColumn = { ...prev };
			if (!path) return sortColumn;
			if (prev.path === path) {
				sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
			} else {
				sortColumn.path = path;
				sortColumn.order = 'asc';
			}
			return sortColumn;
		});

	const handleSearchByEmail = async (formData) => {
		setFoundUser(undefined);
		const user = allUsers.find((u) => u.email === formData.email);
		if (!user) return toast.error('User not found.');
		setFoundUser(user);
	};

	const handleSearchByUID = async (formData) => {
		setFoundUser(undefined);
		const user = allUsers.find((u) => u.id === formData.uid);
		if (!user) return toast.error('User not found.');
		if (!user.mmpDate) return toast.error('User is not a MMP user.');
		setFoundUser(user);
	};

	const getCardsDistChartData = useMemo(() => {
		const colors = ['#a020e9', '#1badde', '#fc0000', '#4e4e4e', '#d3a43c'];
		const labels = ['purple', 'blue', 'red', 'black', 'royal'];
		const title = 'Cards Distribution';
		const data = [];
		const metaData = [];
		const activeUsers = allUsers.filter((u) => u.active === 'Yes');
		for (const i in labels) {
			const filtered = activeUsers.filter((u) => u.card === labels[i]);
			data.push(((filtered.length * 100) / activeUsers.length).toFixed(1));
			metaData.push(filtered.length);
		}
		const toolTipCB = ({ label, dataIndex, parsed }) =>
			`${label} Holders: ${metaData[dataIndex]} Persons (${parsed}%)`;

		return { colors, labels, data, toolTipCB, title };
	}, [allUsers]);

	const getActiveUsersChartData = useMemo(() => {
		const colors = ['#5514fb', '#fbc714'];
		const labels = ['Yes', 'No'];
		const title = 'Active/Inactive Users';
		const data = [];
		const metaData = [];
		const mmpUsers = allUsers.filter((u) => u.email);
		for (const i in labels) {
			const filtered = mmpUsers.filter((u) => u.active === labels[i]);
			data.push(((filtered.length * 100) / mmpUsers.length).toFixed(1));
			metaData.push(filtered.length);
		}
		const toolTipCB = ({ label, dataIndex, parsed }) =>
			`${label} Users: ${metaData[dataIndex]} Users (${parsed}%)`;

		return {
			colors,
			labels: ['Active', 'InActive'],
			data,
			toolTipCB,
			title,
		};
	}, [allUsers]);

	return (
		<>
			{userDownlines ? (
				<>
					<DownlinesCard
						data={users}
						itemsCount={filteredUsers.length}
						pageSize={pageSize}
						currentPage={usersCurrentPage}
						userTypes={userTypes}
						selectedUserType={selectedUserType}
						sortColumn={sortColumn}
						onPageChange={setUsersCurrentPage}
						onTypeSelect={handleTypeSelect}
						onSort={handleSort}
					/>
					<div className='row'>
						<div className='col-12'>
							<TextCard
								title={''}
								titleClass={''}
								text={
									<div className='row'>
										<div className='col-sm-4 offset-sm-1'>
											<DoughnutChart chartData={getCardsDistChartData} />
										</div>
										<div className='col-sm-4 offset-sm-1'>
											<DoughnutChart chartData={getActiveUsersChartData} />
										</div>
									</div>
								}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<TextCard
								title={'Search User'}
								titleClass={'fs-3 text-center'}
								text={
									<>
										<hr />
										<div className='row'>
											<div className='col-sm-6'>
												<GetUserByEmailForm
													onSubmit={handleSearchByEmail}
												/>
											</div>
											<div className='col-sm-6'>
												<GetUserByUIDForm onSubmit={handleSearchByUID} />
											</div>
										</div>

										{foundUser && (
											<>
												<h3 className='my-3 text-center'>Search Result:</h3>
												<hr />
												<div className='row'>
													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																ID:{' '}
																<span className='text-purple'>
																	{foundUser.id}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Level:{' '}
																<span className='text-purple'>
																	{foundUser.level}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Position:{' '}
																<span className='text-purple'>
																	{foundUser.position}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Email:{' '}
																<span className='text-purple'>
																	{foundUser.email}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Name:{' '}
																<span className='text-purple'>
																	{foundUser.fullName}
																</span>
															</li>
														</ul>
													</div>

													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																BemChain Registration Date:{' '}
																<span className='text-purple'>
																	{new Date(
																		foundUser.bemchainDate
																	).toLocaleDateString()}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Unpaid left balance:{' '}
																<span className='text-purple'>
																	${foundUser.L.unPaid / 1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Unpaid right balance:{' '}
																<span className='text-purple'>
																	${foundUser.R.unPaid / 1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Total left balance:{' '}
																<span className='text-purple'>
																	${foundUser.L.totalValue / 1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																Total right balance:{' '}
																<span className='text-purple'>
																	${foundUser.R.totalValue / 1e6}
																</span>
															</li>
														</ul>
													</div>

													<div className='col-sm-4 text-start'>
														<ul>
															<li className='mb-1 ml-3'>
																MMP Registration Date:{' '}
																<span className='text-purple'>
																	{new Date(
																		foundUser.mmpDate
																	).toLocaleDateString()}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																MMP Package Value:{' '}
																<span className='text-purple'>
																	$
																	{foundUser.currentPackageValue /
																		1e6}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																MMP Card:{' '}
																<span className='text-purple'>
																	{_.capitalize(foundUser.card)}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																MMI Activation Date:{' '}
																<span className='text-purple'>
																	{foundUser.mmiIsActive
																		? `${new Date(
																				foundUser.mmiDate
																		  ).toLocaleDateString()}`
																		: 'MMI Inactive'}
																</span>
															</li>
															<li className='mb-1 ml-3'>
																MMI Start Amount:{' '}
																<span className='text-purple'>
																	{foundUser.mmiIsActive
																		? `$ ${
																				foundUser.mmiStartAmount /
																				1e6
																		  }`
																		: 'MMI Inactive'}
																</span>
															</li>
														</ul>
													</div>
												</div>
											</>
										)}
									</>
								}
							/>
						</div>
					</div>
				</>
			) : (
				<div className='h-50 d-flex justify-content-center align-items-center my-5'>
					<RiseLoader
						speedMultiplier={0.8}
						loading={true}
						size={20}
						sizeUnit={'px'}
						color={'#7b8cff'}
					/>
				</div>
			)}
		</>
	);
};

export default Downlines;
