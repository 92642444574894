import React, { useContext } from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import MainContext from './../../../context/mainContext';

const BalanceChart = ({ data }) => {
	const { theme } = useContext(MainContext);
	const textColor = theme === 'dark' ? '#ffffff' : '#000000';

	const { label, left, right } = data;
	for (const i in right) {
		if (right[i] === 0) right[i] = 0.1;
		if (left[i] === 0) left[i] = 0.1;
		// to show the zero in the logarithmic chart
	}
	const chartData = {
		labels: label,
		datasets: [
			{
				label: 'Left',
				borderColor: 'rgba(171, 140, 228, .9)',
				borderWidth: '1',
				backgroundColor: 'rgba(171, 140, 228, 0.5)',
				data: left,
				fill: true,
			},
			{
				label: 'Right',
				borderColor: 'rgba(251, 150, 120, 0.9)',
				borderWidth: '1',
				backgroundColor: 'rgba(251, 150, 120, 0.5)',
				pointHighlightStroke: 'rgba(26,179,148,1)',
				data: right,
				fill: true,
			},
		],
	};

	const chartOptions = {
		animations: {
			y: {
				easing: 'easeInOutElastic',
				from: (ctx) => {
					if (ctx.type === 'data') {
						if (ctx.mode === 'default' && !ctx.dropped) {
							ctx.dropped = true;
							return 0;
						}
					}
				},
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
				labels: {
					color: textColor,
				},
			},
			title: {
				display: false,
				text: 'Weekly Gained Balance',
				color: textColor,
				font: {
					size: '20',
				},
			},
			tooltip: {
				callbacks: {
					label: (context) =>
						context.raw === 0.1 ? '0' : context.raw,
				},
			},
		},
		elements: {
			line: {
				tension: 0.3,
			},
		},
		interaction: {
			mode: 'index',
			intersect: false,
		},
		scales: {
			yAxis: {
				type: 'logarithmic',
				ticks: {
					color: textColor,
					labelString: 'Balance',
					display: true,
					callback: function (value, index, values) {
						if (value === 1000000) return '1M';
						if (value === 100000) return '100K';
						if (value === 10000) return '10K';
						if (value === 1000) return '1K';
						if (value === 100) return '100';
						if (value === 10) return '10';
						if (value === 1) return '1';
						if (value === 0.1) return '0'; // extra if, to show the zero in the logarithmic chart
						return null;
					},
				},
			},
			xAxis: {
				ticks: {
					color: textColor,
				},
			},
		},
	};

	return <Line data={chartData} options={chartOptions} />;
};

export default BalanceChart;
