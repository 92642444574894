import React from 'react';
import Form from '../../../../common/form';
import ImgViewerMulti from '../../../../common/imgViewerMulti';

const Images = ({ name }) => {
	return (
		<>
			<div className='centerAll'>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={0}
					height='150px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
			</div>
			<div className='centerAll'>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={1}
					height='100px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={2}
					height='100px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={3}
					height='100px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
			</div>

			<div className='centerAll'>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={4}
					height='100px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={5}
					height='100px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
				<ImgViewerMulti
					name={name}
					filePath='kyc/'
					num={6}
					height='100px'
					path={`../../kyc/${name}/`}
					ext='jpg'
					count={7}
				/>
			</div>
		</>
	);
};

class IDUploader extends Form {
	renderIDUploader({ type, side, maxFileSize, acceptType }) {
		if (type === 'Passport')
			return (
				<div className='row g-2 my-4'>
					<div className='col-lg-6 offset-lg-3'>
						<div className='row align-items-center g-4'>
							<div className='col-12 text-center fs-5'>
								{side === 'heir' && 'Heir '}Passport Image
							</div>
							<div className='col-lg-6 order-1 order-lg-0'>
								{this.renderFileUpload({
									name: `${side}Passport`,
									maxFileSize,
									acceptType,
									formText: `Supported types are: ${acceptType.join(
										', '
									)}. Max file size: ${maxFileSize / 1024 ** 2} MB`,
									style: 'uploadBoxPass',
								})}
							</div>
							<div className='col-lg-6 order-0 order-lg-1'>
								<Images name='passport' />
							</div>
						</div>
					</div>
				</div>
			);

		if (type === 'National ID')
			return (
				<div className='row g-2 my-4'>
					<div className='col-lg-6'>
						<div className='row align-items-center g-4'>
							<div className='col-12 text-center fs-5'>
								{side === 'heir' && 'Heir '}National ID Card Front Image
							</div>
							<div className='col-lg-6 order-1 order-lg-0'>
								{this.renderFileUpload({
									name: `${side}IdFront`,
									maxFileSize,
									acceptType,
									formText: `Supported types are: ${acceptType.join(
										', '
									)}. Max file size: ${maxFileSize / 1024 ** 2} MB`,
									style: 'uploadBoxID',
								})}
							</div>
							<div className='col-lg-6 order-0 order-lg-1'>
								<Images name='idFront' />
							</div>
						</div>
					</div>

					<div className='col-lg-6'>
						<div className='row align-items-center g-4'>
							<div className='col-12 text-center fs-5'>
								{side === 'heir' && 'Heir '}National ID Card Back Image
							</div>
							<div className='col-lg-6 order-1 order-lg-0'>
								{this.renderFileUpload({
									name: `${side}IdBack`,
									maxFileSize,
									acceptType,
									formText: `Supported types are: ${acceptType.join(
										', '
									)}. Max file size: ${maxFileSize / 1024 ** 2} MB`,
									style: 'uploadBoxID',
								})}
							</div>
							<div className='col-lg-6 order-0 order-lg-1'>
								<Images name='idBack' />
							</div>
						</div>
					</div>
				</div>
			);
	}
}

export default IDUploader;
