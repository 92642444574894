import React from 'react';

function Benefits(props) {
	return (
		<div
			id='benefitsCarousel'
			className='carousel slide'
			data-bs-ride='true'>
			<div className='carousel-indicators'>
				<button
					type='button'
					data-bs-target='#benefitsCarousel'
					data-bs-slide-to='0'
					className='active'
					aria-current='true'
					aria-label='Slide 1'></button>
				<button
					type='button'
					data-bs-target='#benefitsCarousel'
					data-bs-slide-to='1'
					aria-label='Slide 2'></button>
				<button
					type='button'
					data-bs-target='#benefitsCarousel'
					data-bs-slide-to='2'
					aria-label='Slide 3'></button>
			</div>
			<div className='carousel-inner'>
				<div className='carousel-item active' data-bs-interval='10000'>
					<Benefit1 />
				</div>
				<div className='carousel-item' data-bs-interval='10000'>
					<Benefit2 />
				</div>
				<div className='carousel-item' data-bs-interval='10000'>
					<Benefit3 />
				</div>
			</div>
			<button
				className='carousel-control-prev'
				type='button'
				data-bs-target='#benefitsCarousel'
				data-bs-slide='prev'>
				<span
					className='carousel-control-prev-icon'
					aria-hidden='true'></span>
				<span className='visually-hidden'>Previous</span>
			</button>
			<button
				className='carousel-control-next'
				type='button'
				data-bs-target='#benefitsCarousel'
				data-bs-slide='next'>
				<span
					className='carousel-control-next-icon'
					aria-hidden='true'></span>
				<span className='visually-hidden'>Next</span>
			</button>
		</div>
	);
}

const Benefit1 = () => {
	return (
		<div id='benefits_1' className='d-block w-100'>
			<div className='container py-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>Benefits of crowdfunding</b>
						</h3>
						<p>
							This type of investment is becoming increasingly
							common. Every day more and more people become
							investors and shareholders. The good news is that
							personal campaigns are channeling more money towards
							businesses.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const Benefit2 = () => {
	return (
		<div id='benefits_2' className='d-block w-100'>
			<div className='container py-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>Ease in marketing</b>
						</h3>
						<p>
							This type of investment is becoming increasingly
							common. Every day more and more people become
							investors and shareholders. The good news is that
							personal campaigns are channeling more money towards
							businesses. <br /> When the projects benefit the
							people that are active in them, it will cause people
							to introduce the projects to other people. This will
							increase the funds and speed up the development of
							the projects, making a much easier situation for
							marketing.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const Benefit3 = () => {
	return (
		<div id='benefits_3' className='d-block w-100'>
			<div className='container py-5'>
				<div className='row align-items-center mb-5'>
					<div className='col-md-5 pt-5 ps-sm-5'>
						<h3 className='pb-4'>
							<b>More profit with less risk</b>
						</h3>
						<p>
							By placing their investment alongside other
							investments, investors provide a big capital for the
							projects. Bigger capitals always come with bigger
							profits in the economy. Because of new investments
							that keep on coming, the risk is reduced to zero
							since the most common reason for the failure of
							projects is the lack of sufficient financial
							support.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Benefits;
