import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { TextCard } from '../../ingredients/cards';
import SecurityPasswordForm from './securityPasswordForm';

const SecurityPassword = ({ history }) => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [isEmailBtnLoading, setIsEmailBtnLoading] = useState(false);
	const isEmailAllowed = Date.now() > userData.lastEmail + initData.emailGap;
	const counterDate = userData.lastEmail + initData.emailGap;

	const handleSPUpdate = async (formData) => {
		if (formData.password !== formData.password_confirmation)
			return setErrors({
				password_confirmation: 'Passwords do not match',
			});
		try {
			const { password, loginPassword, vcCode } = formData;
			setIsLoading(true);
			const { data } = await http.updateSP({
				password,
				loginPassword,
				vcCode,
			});
			setErrors({});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setTimeout(() => history.replace('/dashboard'), 1000);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleEmailBtnClick = async () => {
		try {
			setIsEmailBtnLoading(true);
			const { data } = await http.requestSP_VC();
			onUserDataChanged(data);
			toast.info('Eamil sent, please check your inbox');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsEmailBtnLoading(false);
		}
	};

	const onRequestTimerCompleted = async () => {
		try {
			const { data } = await http.getOnlyMe();
			onUserDataChanged(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='row'>
			<div className='col-12 text-center'>
				<TextCard
					title={'Setting Security Password'}
					titleClass={'fs-3'}
					text={
						<div className='row mt-4'>
							<div className='col-sm-4 offset-sm-4 col-12'>
								<p>
									Password must be strong. At least one upper
									case alphabet. At least one lower case
									alphabet. At least one digit. Minimum 8 and
									maximum 26 in length.
								</p>
								<SecurityPasswordForm
									isSettingSPAllowed={initData.settingSP}
									errors={errors}
									isLoading={isLoading}
									uid={userData.bemchain.uid}
									email={userData.email}
									countries={initData.countries}
									onSubmit={handleSPUpdate}
									isEmailAllowed={isEmailAllowed}
									counterDate={counterDate}
									onRequestTimerCompleted={
										onRequestTimerCompleted
									}
									isEmailBtnLoading={isEmailBtnLoading}
									onEmailRequest={handleEmailBtnClick}
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default SecurityPassword;
