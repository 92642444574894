import React, { useState } from 'react';

function AlertBox({ content, icon = null, type, canClose = true, extraClass = '' }) {
	const [show, setShow] = useState(true);
	const icons = {
		danger: 'fas fa-exclamation-circle',
		info: 'fas fa-info-circle',
		success: 'fas fa-check-circle',
	};

	return (
		<>
			{show && (
				<div className={`alert alert-${type} ${extraClass}`}>
					<div className='d-flex justify-content-between'>
						<div className='d-flex align-items-center justify-content-between'>
							{icon || <i className={`me-3 fa-2x ${icons[type]}`}></i>}
							<span>{content}</span>
						</div>
						{canClose && (
							<button
								type='button'
								className='btn-close'
								onClick={() => setShow(false)}></button>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default AlertBox;
