import React from 'react';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Image from './common/image';

const AccessDenied = ({ withLink = true }) => {
	useDocumentTitle(['Access Denied']);
	return (
		<main className='container'>
			<div className='row my-0'>
				<div className='col-md-10 offset-md-1 col text-center'>
					<Image className={'w-100 my-0'} fileName={`access.png`} />
					{withLink && (
						<Link
							className='btn btn-lg btn-my-navy mb-4'
							to='/login'>
							Login
						</Link>
					)}
				</div>
			</div>
		</main>
	);
};

export default AccessDenied;
