import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../../css/dashboard/sideBar.module.css';
import useMain from '../../hooks/useMain';
import http from '../../services/httpService';
import AccordionBasic from '../common/accordionBasic';
import Image from '../common/image';

const SideBar = ({ tabs, activePath, myNavRef, myCollapsible }) => {
	const { uiInfo, mediaQuery, dashboardBlogs } = useMain();

	const handleClick = (e) => {
		if (e.target.id === 'exit-tab') return http.logout();
		if (mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md')
			myCollapsible.hide();
	};

	const tabTitle = (tab) => {
		if (tab.badge === 'Soon')
			return (
				<>
					{tab.name}{' '}
					<span className='badge bg-danger ms-2'>Soon</span>
				</>
			);
		if (dashboardBlogs && dashboardBlogs[0] && tab.id === 'news') {
			const twoDays = 2 * 24 * 60 * 60 * 1000;
			const count = dashboardBlogs.filter(
				(d) => new Date(d.date).getTime() > Date.now() - twoDays
			).length;
			if (count > 0)
				return (
					<div className='position-relative'>
						<div>{tab.name}</div>{' '}
						<span className='notifBadge'>{count}</span>
					</div>
				);
			else return tab.name;
		} else return tab.name;
	};

	const generateTabListItem = (tab, key, parent) => {
		const linkTo =
			tab.link || `/dashboard${parent ? '/' + parent.id : ''}/${tab.id}`;
		const path = [];
		if (parent) path.push(parent.name);
		path.push(tab.name);

		if (tab.toBeShown)
			return (
				<li key={key} className='nav-item'>
					<NavLink
						to={linkTo}
						className={`p-2 d-flex align-items-center ${
							styles.myNavLink
						} ${
							tab.id === activePath
								? 'myActiveNavLink active'
								: ''
						}`}
						id={`${tab.id}-tab`}
						onClick={handleClick}>
						{tab.icon ? (
							<i className={`${tab.icon} me-2`}></i>
						) : (
							<Image
								className={'me-2'}
								fileName={`icons/menu/${tab.id}.svg`}
								height={'32px'}
							/>
						)}

						{tabTitle(tab)}
					</NavLink>
				</li>
			);
		return null;
	};

	return (
		<nav
			ref={myNavRef}
			id='sidebarMenu'
			className={`${styles.sidebar} d-md-block collapse card mt-md-4 mb-4 mb-md-0`}>
			<div
				className={`${styles.sidebarBody} card-body my-2 me-2`}
				style={{
					overflowX: 'hidden',
					overflowY: 'scroll',
					height: `calc(100svh - ${uiInfo.sideBar}px)`,
				}}>
				<ul
					className='nav flex-column my-0 my-sm-3'
					id='myTab'
					role='tablist'>
					{tabs.map((tab, key) => {
						const hasChildren =
							tab.children && tab.children.length > 0;
						if (tab.toBeShown)
							return hasChildren ? (
								<AccordionBasic
									key={key}
									className={`${styles.accordion} ${styles.myNavLink} p-0`}
									panelClass={`${styles.accordionPanel}`}
									iconClassOpen={`${styles.iconOpen}`}
									iconClassClose={`${styles.iconClose}`}
									data={[
										{
											id: tab.id,
											title: (
												<div className='p-2 d-flex align-items-center'>
													{tab.icon ? (
														<i
															className={`${tab.icon} me-2`}></i>
													) : (
														<Image
															className='me-2'
															fileName={`icons/menu/${tab.id}.svg`}
															height='32px'
														/>
													)}
													<span>{tabTitle(tab)}</span>
												</div>
											),
											content: tab.children.map((t, k) =>
												generateTabListItem(t, k, tab)
											),
										},
									]}
								/>
							) : (
								generateTabListItem(tab, key)
							);
						return null;
					})}
				</ul>
			</div>
		</nav>
	);
};

export default SideBar;
