import React from 'react';
import Select from '../../../../common/select';

const P3Form = ({ heirsCount, heirTypes, onChange, p3FormState }) => {
	const rows = [];
	for (let i = 0; i < heirsCount; i++) rows.push(i);
	return (
		<>
			<div className='table-responsive'>
				<table
					width='100%'
					className='table table-bordered table-sm text-center'>
					<thead>
						<tr className='align-middle'>
							<th width='2%'>#</th>
							<th width='14%'>Name</th>
							<th width='14%'>Surname</th>
							<th width='14%'>Fathers Name</th>
							<th width='14%'>National ID</th>
							<th width='14%'>Relation</th>
							<th width='14%'>Priority</th>
							<th width='14%'>Percent</th>
						</tr>
					</thead>
					<tbody>
						{rows.map((d, key) => (
							<tr className='align-middle' key={key}>
								<td>{d + 1}</td>
								<td
									id={`name_${d}`}
									className='heir_name'
									contentEditable='true'
									onInput={onChange}></td>
								<td
									id={`surname_${d}`}
									className='heir_surname'
									contentEditable='true'
									onInput={onChange}></td>
								<td
									id={`fathersName_${d}`}
									className='heir_fathersName'
									contentEditable='true'
									onInput={onChange}></td>
								<td
									id={`nationalId_${d}`}
									className='heir_nationalId'
									contentEditable='true'
									onInput={onChange}></td>
								<td>
									<Select
										name={`relation_${d}`}
										className='heir_relation'
										value={p3FormState[`relation_${d}`]}
										options={heirTypes}
										onChange={onChange}
									/>
								</td>
								<td
									id={`priority_${d}`}
									className='heir_priority'
									contentEditable='true'
									onInput={onChange}></td>
								<td
									id={`percent_${d}`}
									className='heir_percent'
									contentEditable='true'
									onInput={onChange}></td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default P3Form;
