import React from 'react';
import Image from '../../common/image';
import { subString } from '../../../utils/helpers';

const GoPro4Table = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Rank</th>
						<th>UID</th>
						<th>Email</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{getRank(d.no)}</td>
							<td>{d.uid}</td>
							<td>{subString(d.email, 8, 6, '.')}</td>
							<td>
								{d.date
									? new Date(d.date).toLocaleString()
									: d.date}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getRank = (no) => {
	if (no === 1)
		return (
			<div className='d-flex justify-content-center align-items-center'>
				<div>
					<Image height='20px' fileName='goPro/top20.svg' />
				</div>
				<div className='ms-1'>
					<Image height='20px' fileName='goPro/gold.svg' />
				</div>
			</div>
		);
	if (no === 2)
		return (
			<div className='d-flex justify-content-center align-items-center'>
				<div>
					<Image height='20px' fileName='goPro/top20.svg' />
				</div>
				<div className='ms-1'>
					<Image height='20px' fileName='goPro/silver.svg' />
				</div>
			</div>
		);
	if (no === 3)
		return (
			<div className='d-flex justify-content-center align-items-center'>
				<div>
					<Image height='20px' fileName='goPro/top20.svg' />
				</div>
				<div className='ms-1'>
					<Image height='20px' fileName='goPro/bronze.svg' />
				</div>
			</div>
		);
	if (no <= 20 && no > 3)
		return (
			<div className='d-flex justify-content-center align-items-center'>
				<div>
					<Image height='20px' fileName='goPro/top20.svg' />
				</div>
				<div className='ms-1'>{no}</div>
			</div>
		);
	return no;
};

export default GoPro4Table;
