import Image from '../common/image';
import Benefits from './benefits';

const crowdfunding = {
	path: '/crowdfunding',
	name: 'Crowdfunding',
	title: 'Crowdfunding',
	content: (
		<>
			<div className='container pt-5'>
				<div className='row'>
					<div className='col-12'>
						<div className='row gy-4 align-items-center mb-5'>
							<div className='col-md-8'>
								<h2>What is Crowdfunding?</h2>
								<div className='mb-2'>
									Crowdfunding is the practice of raising funds for a project or
									venture by soliciting contributions from a large number of
									people, typically via the internet. This method of funding has
									gained popularity in recent years due to the ease of access to
									online platforms and the rise of social media. Crowdfunding is
									commonly used by entrepreneurs, inventors, musicians,
									filmmakers, artists, and charities to fund their projects and
									bring their ideas to life.
								</div>
								<div className='mb-2'>
									The process of crowdfunding typically takes place on online
									platforms for a set period with a specific funding goal. The
									platforms facilitate the fundraising process by providing
									project creators with a space to showcase their idea and connect
									with potential investors. Project creators can offer incentives,
									such as early access to products or exclusive merchandise, to
									entice investors to contribute to their campaign.
								</div>
								<div>
									There are several types of crowdfunding, including
									donation-based, reward-based, equity-based, and debt-based. In
									donation-based crowdfunding, individuals donate money to a
									project without expecting anything in return. Reward-based
									crowdfunding, on the other hand, offers investors a
									non-financial reward, such as a product or experience, in
									exchange for their contribution. Equity-based crowdfunding
									involves investors receiving a share of the company in exchange
									for their investment, while debt-based crowdfunding allows
									individuals to lend money to a project in exchange for interest
									payments.
								</div>
							</div>
							<div className='col-md-4 text-center'>
								<Image
									className='w-75'
									fileName='blogs/crowdfunding.webp'
								/>
							</div>
							<div className='col-12'>
								<div>
									Crowdfunding can be a valuable tool for startups and
									entrepreneurs who may have difficulty accessing traditional
									forms of funding, such as bank loans or venture capital. By
									using crowdfunding, project creators can test the viability of
									their idea and gauge interest from potential customers before
									investing significant time and resources into the project. In
									addition, crowdfunding allows entrepreneurs to retain ownership
									and control of their company, which may not be possible with
									traditional forms of funding.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Benefits />

			<div className='container pb-5'>
				<div className='row mt-5'>
					<div className='col-12'>
						<h2>Types of crowdfunding</h2>
						<p>
							There are four main types of crowdfunding, each targeting a different
							investor/donor audience.
						</p>
					</div>
				</div>

				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/financialAssistance.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Financial help</h4>
						<p>
							Financial-help-crowdfunding is a type of crowdfunding in which the
							donors to a company, campaign, or person do not expect anything in
							return. Let's say you've started a campaign to buy new equipment for
							your company. People that donate to you do it to support the development
							of your business and expect nothing in return from you.
						</p>
					</div>
				</div>
				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/debt.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Debt</h4>
						<p>
							Debt help is a sort of peer-to-peer loan, which is considered a type of
							crowdfunding. Debt-based crowdfunding is a loan provided by a bank and
							must be paid back in a specific window with its interest.
						</p>
					</div>
				</div>
				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/reward.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Reward</h4>
						<p>
							The reward is when the donors receive something in return for their
							donation. Rewards differ based on donations to encourage more
							participation. The donor may receive a gift (like a T-shirt), product,
							or service at discounted prices.
						</p>
					</div>
				</div>
				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/equity.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Stockholder income</h4>
						<p>
							Some crowdfundings allow the supporters to own a portion of the company
							they've supported. Stockholder income crowdfunding allows small
							businesses and startups to raise money by giving the supporter a portion
							of their company's stocks. This type of help is a sort of investment in
							which the supporters will receive stocks for the money they invest
						</p>
					</div>
				</div>
			</div>
		</>
	),
};

export default crowdfunding;
