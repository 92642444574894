import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/common/protectedRoute';
import ProtectedRouteWithProp from '../components/common/protectedRouteWithProp';

import AccessDenied from '../components/accessDenied';
import Login from '../components/auth/login';
import Logout from '../components/auth/logout';
import PasswordReset from '../components/auth/passwordReset';
import Blogs from '../components/backOffice/blogs';
import BlogPreview from '../components/backOffice/blogs/blogPreview';
import Create from '../components/backOffice/blogs/create';
import Edit from '../components/backOffice/blogs/edit';
import Configs from '../components/backOffice/configs';
import Debts from '../components/backOffice/debts';
import NewDebt from '../components/backOffice/debts/newDebt';
import KYC from '../components/backOffice/kyc';
import BasicPersonalInfoReview from '../components/backOffice/kyc/basicPersonalInfoReview';
import IdentityInfoReview from '../components/backOffice/kyc/identityInfoReview';
import Resources from '../components/backOffice/resources';
import AddResource from '../components/backOffice/resources/addResource';
import Terminations from '../components/backOffice/terminations';
import UserTerminationRequest from '../components/backOffice/terminations/userTerminationRequest';
import Tickets from '../components/backOffice/tickets';
import TicketsContent from '../components/backOffice/tickets/ticketsContent';
import Users from '../components/backOffice/users';
import WithdrawRequests from '../components/backOffice/withdrawRequests';
import ComingSoon from '../components/comingSoon';
import Home from '../components/home';
import NotFound from '../components/notFound';
import UnderMaintenance from '../components/underMaintenance';
import ResidentialInfoReview from '../components/backOffice/kyc/residentialInfoReview';

const routes = [
	{
		path: '/login',
		exact: true,
		name: 'Login',
		isTopLevel: true,
		Component: Login,
	},
	{
		path: '/passwordReset',
		exact: true,
		name: 'Password Reset',
		Component: PasswordReset,
	},
	{
		path: '/comingSoon',
		isGlobal: true,
		exact: true,
		name: 'Coming Soon',
		Component: ComingSoon,
	},
	{
		path: '/accessDenied',
		isGlobal: true,
		exact: true,
		isTopLevel: true,
		name: 'Access Denied',
		Component: AccessDenied,
	},
	{ path: '/notFound', exact: true, name: 'Not Found', Component: NotFound },
	{
		path: ['/', '/home'],
		isGlobal: true,
		exact: true,
		isTopLevel: true,
		name: 'Home',
		Component: Home,
	},
];

const protectedRoutes = [{ path: '/logout', exact: true, name: 'Logout', Component: Logout }];

const protectedRoutesWithProps = [
	{
		path: '/blogs/create',
		isGlobal: true,
		exact: true,
		name: 'Create',
		protection: 'isAuthor',
		Component: Create,
	},
	{
		path: '/blogs/edit',
		isGlobal: true,
		exact: true,
		name: 'Edit',
		protection: 'isAuthor',
		Component: Edit,
	},
	{
		path: '/blogs',
		isGlobal: true,
		exact: true,
		name: 'Blogs',
		protection: 'isAuthor',
		Component: Blogs,
	},
	{
		path: '/blog/:_id',
		isGlobal: true,
		exact: true,
		name: 'Preview',
		protection: 'isAuthor',
		Component: BlogPreview,
	},
	{
		path: '/blog',
		isGlobal: true,
		exact: true,
		name: 'Preview',
		protection: 'isAuthor',
		Component: BlogPreview,
	},
	{
		path: '/resources/addResource',
		isGlobal: true,
		exact: true,
		name: 'Add Resource',
		protection: 'isAuthor',
		Component: AddResource,
	},
	{
		path: '/resources',
		isGlobal: true,
		exact: true,
		name: 'Resources',
		protection: 'isAuthor',
		Component: Resources,
	},
	{
		path: '/kyc/basicPersonalInfoReview',
		isGlobal: true,
		exact: true,
		name: 'BasicPersonalInfoReview',
		protection: 'isAdmin',
		Component: BasicPersonalInfoReview,
	},
	{
		path: '/kyc/identityInfoReview',
		isGlobal: true,
		exact: true,
		name: 'IdentityInfoReview',
		protection: 'isAdmin',
		Component: IdentityInfoReview,
	},
	{
		path: '/kyc/residentialInfoReview',
		isGlobal: true,
		exact: true,
		name: 'ResidentialInfoReview',
		protection: 'isAdmin',
		Component: ResidentialInfoReview,
	},
	{
		path: '/kyc',
		isGlobal: true,
		exact: false,
		name: 'KYC',
		protection: 'isAuthor',
		Component: KYC,
	},
	{
		path: '/withdrawals',
		isGlobal: true,
		exact: false,
		name: 'Withdrawals',
		protection: 'isAdmin',
		Component: WithdrawRequests,
	},
	{
		path: '/tickets',
		isGlobal: true,
		exact: false,
		name: 'Tickets',
		protection: 'isAdmin',
		Component: Tickets,
	},
	{
		path: '/ticketsContent',
		isGlobal: true,
		exact: true,
		name: 'Preview',
		protection: 'isAdmin',
		Component: TicketsContent,
	},
	{
		path: '/terminations',
		isGlobal: true,
		exact: false,
		name: 'Tickets',
		protection: 'isAdmin',
		Component: Terminations,
	},
	{
		path: '/userTerminationRequest',
		isGlobal: true,
		exact: false,
		name: 'Tickets',
		protection: 'isAdmin',
		Component: UserTerminationRequest,
	},
	{
		path: '/debts/newDebt',
		isGlobal: true,
		exact: true,
		name: 'Debts',
		protection: 'isAdmin',
		Component: NewDebt,
	},
	{
		path: '/debts/editDebt',
		isGlobal: true,
		exact: true,
		name: 'Debts',
		protection: 'isAdmin',
		Component: NewDebt,
	},
	{
		path: '/debts',
		isGlobal: true,
		exact: true,
		name: 'Debts',
		protection: 'isAdmin',
		Component: Debts,
	},
	{
		path: '/configs',
		isGlobal: true,
		exact: true,
		name: 'Configs',
		protection: 'isAdmin',
		isTopLevel: true,
		Component: Configs,
	},
	{
		path: '/users',
		isGlobal: true,
		exact: true,
		name: 'Users',
		protection: 'isAdmin',
		Component: Users,
	},
];

const allRoutes = [...protectedRoutesWithProps, ...protectedRoutes, ...routes];

const generateCrumbs = (props) => {
	const crumbs = allRoutes
		.filter(({ path }) => props.match.path.includes(path))
		.map(({ path, ...rest }) => ({
			path: Object.keys(props.match.params).length
				? Object.keys(props.match.params).reduce(
						(path, param) => path.replace(`:${param}`, props.match.params[param]),
						path
				  )
				: path,
			...rest,
		}))
		.sort((a, b) => (a.path.split('/').length > b.path.split('/').length ? 1 : -1));
	return crumbs;
};

const renderRoute = (props, Component) => {
	const crumbs = generateCrumbs(props);
	return (
		<Component
			{...props}
			crumbs={crumbs}
		/>
	);
};

const generateRoutes = (underMaintenance) => {
	return {
		protectedRoutesWithProps: protectedRoutesWithProps.map(
			({ path, Component, exact, protection, isTopLevel }, key) => (
				<ProtectedRouteWithProp
					exact={exact}
					path={path}
					key={key}
					protection={protection}
					render={(props) =>
						renderRoute(
							props,
							underMaintenance && !isTopLevel ? UnderMaintenance : Component
						)
					}
				/>
			)
		),
		protectedRoutes: protectedRoutes.map(({ path, Component, exact, isTopLevel }, key) => (
			<ProtectedRoute
				exact={exact}
				path={path}
				key={key}
				render={(props) =>
					renderRoute(
						props,
						underMaintenance && !isTopLevel ? UnderMaintenance : Component
					)
				}
			/>
		)),
		routes: routes.map(({ path, Component, exact, isTopLevel }, key) => (
			<Route
				exact={exact}
				path={path}
				key={key}
				render={(props) =>
					renderRoute(
						props,
						underMaintenance && !isTopLevel ? UnderMaintenance : Component
					)
				}
			/>
		)),
	};
};

export function getIsGlobal(pathname) {
	const pathArray = pathname.split('/');
	const parentPath = `${pathArray[0]}/${pathArray[1]}`;
	const route = allRoutes.find(
		(r) => r.path === parentPath || (Array.isArray(r.path) && r.path.includes(parentPath))
	);
	return route ? route.isGlobal : false;
}

export default generateRoutes;
