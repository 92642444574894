import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import faq from './faqTexts';
import Shadow from './shadow';

const Accordion = withStyles({
	root: {
		border: '0px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		borderRadius: 0,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: '#faf6ff',
		borderBottom: '0px solid #dfdfdf',
		marginBottom: -1,
		color: '#000000',
		borderRadius: 0,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {
		backgroundColor: '#f2ebfd',
		borderBottom: '0 solid #dfdfdf',
	},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		backgroundColor: '#f2ebfd',
	},
}))(MuiAccordionDetails);

function FAQ(props) {
	return (
		<div className='bg-white pt-5'>
			<div id='faq' className='container pb-5'>
				<div className='row'>
					<div className='col-12'>
						{faq.map((f, key) => (
							<Accordion key={key}>
								<AccordionSummary expandIcon={<ExpandMore />}>
									<div className='d-flex align-items-center'>
										<span className='ms-4 me-5 fs-2 opacity-25'>
											<b>
												{String(key + 1).padStart(
													2,
													'0'
												)}
											</b>
										</span>
										<b>{f.title}</b>
									</div>
								</AccordionSummary>

								<AccordionDetails>
									<span className='opacity-75 ms-5'>
										{f.content}
									</span>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</div>
			</div>
			<Shadow />
		</div>
	);
}

export default FAQ;
