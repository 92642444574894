const Spinner = ({ className, content }) => {
	return (
		<div
			className={className ? className : null}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<div
				className='spinner-border spinner-border-sm'
				role='status'></div>
			<span className='ms-2'>{content}</span>
		</div>
	);
};

export default Spinner;
