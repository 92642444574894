import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainContext from '../../context/mainContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import http from '../../services/httpService';
import Image from '../common/image';
import Preloader from './../common/preloader';
import ResetForm from './resetForm';

const PasswordReset = (props) => {
	useDocumentTitle(['Reset Password']);
	const { initData, onUserDataChanged } = useContext(MainContext);
	const [lastEmail, setLastEmail] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [isEmailBtnLoading, setIsEmailBtnLoading] = useState(false);
	let isEmailRequestAllowed = Date.now() > lastEmail + initData.emailGap;
	const counterDate = lastEmail + initData.emailGap;

	const handlePasswordReset = async (formData) => {
		if (formData.password !== formData.password_confirmation)
			return setErrors({
				password_confirmation: 'Passwords do not match',
			});
		try {
			const { password, vcCode, email } = formData;
			setIsLoading(true);
			const { data } = await http.resetP({
				password,
				vcCode,
				email,
			});
			setErrors({});
			toast.success(data.message);
			// onUserDataChanged(data.data);
			setTimeout(() => props.history.replace('/dashboard'), 1000);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleEmailBtnClick = async (email) => {
		if (!email)
			return setErrors({
				email: 'Email is required.',
			});
		try {
			setIsEmailBtnLoading(true);
			const { data } = await http.requestPR_VC({ email });
			setLastEmail(Date.now());
			toast.info(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsEmailBtnLoading(false);
		}
	};

	const onRequestTimerCompleted = async () => {
		//just to rerender
		onUserDataChanged();
		//is it needed?
		isEmailRequestAllowed = Date.now() > lastEmail + initData.emailGap;
	};

	if (!initData.emailGap) return <Preloader />;
	if (http.getCurrentUser()) return props.history.replace('/dashboard');
	return (
		<main className='loginPage'>
			<div className='container py-5 align-items-center'>
				<div className='row'>
					<div
						className='col-sm-6 offset-sm-3 col-12'
						style={{ paddingTop: '2rem' }}>
						<div className='card loginCard'>
							<div className='card-body'>
								<div className='text-center'>
									<Link to='/'>
										<Image
											className='w-100 my-4 px-5'
											fileName='logo.png'
										/>
									</Link>
									<h1>Reset Password</h1>
								</div>

								<ResetForm
									errors={errors}
									isLoading={isLoading}
									onSubmit={handlePasswordReset}
									isEmailAllowed={isEmailRequestAllowed}
									counterDate={counterDate}
									onRequestTimerCompleted={
										onRequestTimerCompleted
									}
									isEmailBtnLoading={isEmailBtnLoading}
									onEmailRequest={handleEmailBtnClick}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default PasswordReset;
