import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { RiseLoader } from 'react-spinners';
import styles from '../../../../css/dashboard/ingredients.module.css';
import useGoPro4Result from '../../../../hooks/useGoPro4Result';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import GoPro4Table from '../../ingredients/goPro4Table';

function Bonus() {
	const { initData } = useMain();
	const { end, start, name, amount } = initData.contests.gopro4;
	const tablePageSize = 15;

	const [goProResult] = useGoPro4Result();

	const normalizedData = goProResult ? [...goProResult] : [];
	if (goProResult && normalizedData.length < amount) {
		for (let i = normalizedData.length; i < amount; i++) {
			normalizedData.push({
				email: '',
				uid: null,
				date: null,
			});
		}
	}

	for (const i in normalizedData) normalizedData[i].no = Number(i) + 1;

	const [currentPage, setCurrentPage] = useState(1);
	const data = paginate(normalizedData, currentPage, tablePageSize);
	const [timeIsFinished, setTimeIsFinished] = useState(Date.now() >= end);

	if (Date.now() < start) return null;
	return (
		<>
			{goProResult ? (
				<div className='mb-4 card myCards'>
					<div className='card-body'>
						<h4 className='text-center fs-1 mt-5 mb-3'>{name} Tournament</h4>

						<div className='d-flex felx-wrap justify-content-evenly align-items-center'>
							<div>
								{timeIsFinished ? (
									<h2>Time Is Finished</h2>
								) : (
									<div className='text-center'>
										<Countdown
											className='h2'
											onComplete={() => setTimeIsFinished(true)}
											date={end}
										/>
										<div className='fs-2 d-lg-none'>
											{goProResult.length} / {amount}
										</div>
									</div>
								)}
							</div>
							<div className='text-center'>
								<div className='d-flex justify-content-evenly align-items-center'>
									<div className='fs-2 me-3 d-none d-lg-block'>
										{goProResult.length} / {amount}
									</div>
									<Image
										height='80px'
										fileName='goPro/vipTicket.svg'
									/>
								</div>
								{goProResult.length >= amount && (
									<div className='mt-3'>Tickets Are Finished</div>
								)}
							</div>
						</div>
						<hr className='my-4' />
						<div className='row'>
							<div className='col-12 col-md-8 offset-md-2'>
								{data.length > 0 ? (
									<div className='mb-5'>
										<GoPro4Table
											data={data}
											title={name}
										/>
										<Pagination
											customClass={`mt-1 ${styles.myPagination}`}
											activeClass={styles.myActive}
											totalCount={normalizedData.length}
											pageSize={tablePageSize}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									</div>
								) : (
									<div className='text-center mb-5'>
										<Image
											height='180px'
											fileName='icons/noData.svg'
										/>
										<h4 className='mt-4'>No Data</h4>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='h-50 d-flex justify-content-center align-items-center my-5'>
					<RiseLoader
						speedMultiplier={0.8}
						loading={true}
						size={20}
						sizeUnit='px'
						color='#7b8cff'
					/>
				</div>
			)}
		</>
	);
}

export default Bonus;
