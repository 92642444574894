import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useKYCData from '../../../hooks/useKYCData';
import useKYCRequests from '../../../hooks/useKYCRequests';
import useMain from '../../../hooks/useMain';
import http from '../../../services/httpService';
import config from '../../../config';
import Preloader from '../../common/preloader';
import ReviewForm from './reviewForm';

function ResidentialInfoReview(props) {
	useDocumentTitle(['Residential Info Review']);
	const { initData } = useMain();
	const [KYCData] = useKYCData();
	const { setRequests } = useKYCRequests();
	const [isLoading, setIsLoading] = useState(false);
	const { formOptions } = initData;
	if (!formOptions || !KYCData) return <Preloader />;
	if (!props.location.state) window.location = '/';
	const { email, uid, verification, _id } = props.location.state;
	const { basicInfo, residentialInfo, residentialInfoDoc } = verification;
	const personal = KYCData['Personal Information'];
	const personalKeys = personal.map((a) => a.name);

	const handleReviewSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.submitKYCReviewResult({ ...formData, ...{ userId: _id } });
			setIsLoading(false);
			toast.success(data.message);
			setRequests(data.data);
			props.history.replace('/kyc');
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		}
	};

	return (
		<div className='container my-4'>
			<div className='row gy-5 align-items-center'>
				<div className='col-12'>
					<div className='fw-bold fs-4 text-center'>Residential Info Review</div>
					<div className='text-center'>(Level 3 Request)</div>
				</div>

				<div className='col-12'>
					<div className='d-flex flex-wrap justify-content-evenly align-items-center'>
						<div>
							<b>Email:</b> {email}
						</div>
						<div>
							<b>UID:</b> {uid}
						</div>
					</div>
					<hr />
				</div>

				<div className='col-lg-6 text-center'>
					<img
						loading='lazy'
						src={
							config.mode === 'prod'
								? residentialInfoDoc
								: residentialInfoDoc.replace(
										'https://mmp.monster',
										'http://127.0.0.1:3009'
								  )
						}
						className='w-75'
						alt='document'
						crossOrigin='true'
					/>
					<div className='mt-4'>
						Document Type:{' '}
						<span className='fw-bold'>{residentialInfo.documentType}</span>
					</div>
				</div>
				<div className='col-lg-6'>
					{personalKeys.map((p, key) => (
						<div
							key={key}
							className='mb-1'>
							{personal.find((a) => a.name === p).label} :{' '}
							<span className='fw-bold'>{basicInfo[p]}</span>
						</div>
					))}
				</div>
			</div>
			<div className='row my-5'>
				<div className='col-md-4 offset-md-4'>
					<div className='fw-bold fs-5 text-center my-4'>Submit Review Result</div>
					<ReviewForm
						isLoading={isLoading}
						options={formOptions.reviewKYCFormOptions}
						onSubmit={handleReviewSubmit}
					/>
				</div>
			</div>
		</div>
	);
}

export default ResidentialInfoReview;
