import Joi from 'joi-browser';
import React from 'react';
import Form from '../../common/form';
import Image from '../../common/image';
import Spinner from '../../common/spinner';

class ReviewForm extends Form {
	state = {
		data: {
			result: '',
			reason: '',
		},
		errors: {},
	};

	schema = {
		result: Joi.string()
			.valid(...this.props.options.map((o) => o.id))
			.required()
			.label('Result'),
		reason: Joi.string().allow('').required().label('Reason'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, options } = this.props;

		return (
			<form
				className='text-center'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-1'>
					{options.map((o, key) =>
						this.renderRadioInput({
							formClass: 'mb-0 form-check-inline',
							key,
							name: 'result',
							id: o.id,
							label: (
								<div className='centerAll'>
									<span className='me-1'>{o.label}</span>
									<Image
										height='20px'
										fileName={`icons/${o.id}.svg`}
									/>
								</div>
							),
							checked: o.checked,
							disabled: o.disabled,
						})
					)}
				</div>
				<div className='mb-4'>
					{this.renderTextArea({
						name: 'reason',
						type: 'text',
						placeholder: 'Reason (not mandatory)',
					})}
				</div>
				<div className='mb-4'>
					{this.renderButton(
						'Submit',
						'btn btn-my-navy',
						isLoading && <Spinner content='Submitting...' />
					)}
				</div>
			</form>
		);
	}
}

export default ReviewForm;
