import React from 'react';

const IncomeTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Type</th>
						<th>Max Cap</th>
						<th>Remained Max Cap (Before)</th>
						<th>Amount</th>
						<th>Used Max Cap (After)</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => {
						const percentage = (
							((d.totalMaxCap - d.remainedMaxCap + d.commission) *
								100) /
							d.totalMaxCap
						).toFixed(2);
						return (
							<tr key={key}>
								<td>{d.no}</td>
								<td>{new Date(d.date).toLocaleString()}</td>
								<td>{d.type}</td>
								<td>{`$ ${d.totalMaxCap / 1e6}`}</td>

								<td>{`$ ${d.remainedMaxCap / 1e6}`}</td>
								<td>{`$ ${d.commission / 1e6}`}</td>
								<td>
									<div className='d-flex justify-content-center align-items-center'>
										<span
											className='progress mt-2'
											title={`${percentage}%`}
											style={{
												width: '80px',
												height: '10px',
											}}>
											<div
												className='progress-bar'
												role='progressbar'
												aria-label='Max Cap'
												style={{
													width: `${percentage}%`,
												}}
												aria-valuenow={Number(
													percentage
												)}
												aria-valuemin='0'
												aria-valuemax='100'></div>
										</span>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default IncomeTable;
