import React from 'react';
import ImgViewer from '../common/imgViewer';

function Permissions(props) {
	return (
		<div className='permissions'>
			<div className='container'>
				<div className='row pt-5'>
					<div className='col-12 text-center'>
						<h2 className='text-uppercase text-blue mb-5'>
							Permissions
						</h2>
					</div>
				</div>
				<div>
					<div className='row'>
						<div className='col-sm-4 text-center'>
							<ImgViewer
								height={'200px'}
								path={'home/permissions/1.jpg'}
							/>
						</div>
						<div className='col-sm-4 text-center'>
							<ImgViewer
								height={'200px'}
								path={'home/permissions/2.png'}
							/>
						</div>
						<div className='col-sm-4 text-center mb-4'>
							<ImgViewer
								height={'200px'}
								path={'home/permissions/3.jpg'}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Permissions;
