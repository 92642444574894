import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MainContext from '../../context/mainContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import http from '../../services/httpService';
import Image from '../common/image';
import Preloader from './../common/preloader';
import RegisterForm from './registerFrom';
import { getQueries } from '../../utils/helpers';

const Register = (props) => {
	useDocumentTitle(['Register']);
	const { ref } = getQueries();

	const { initData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState('/');

	const doSubmit = async (formData) => {
		if (formData.password !== formData.password_confirmation)
			return setErrors({
				password_confirmation: 'Passwords do not match',
			});
		if (formData.idStatus === 0)
			return setErrors({
				referralID: 'Press Check button to examine the entered ID.',
			});

		console.log('formData :>> ', formData);
		try {
			setIsLoading(true);
			const { data, headers } = await http.register({
				referralID: formData.referralID.toLowerCase(),
				email: formData.email.trim().toLowerCase(),
				password: formData.password.trim(),
			});
			onUserDataChanged(data);
			http.setJwt(headers['x-auth-token']);
			props.history.replace('/dashboard');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
			setIsLoading(false);
		}
	};

	if (!initData.emailGap) return <Preloader />;
	if (http.getCurrentUser()) http.removeJwt();

	return (
		<main className='loginPage centerAll'>
			<div className='container align-items-center'>
				<div className='row'>
					<div className='col-lg-6 offset-lg-3 col-12'>
						<div className='card loginCard'>
							<div className='card-body'>
								<div className='text-center'>
									<Image
										className={'w-75 my-4 px-5'}
										fileName={'logo.png'}
									/>
									<h1>Register</h1>
								</div>

								<RegisterForm
									errors={errors}
									isLoading={isLoading}
									onSubmit={doSubmit}
									referralID={ref}
								/>

								<p className='text-center mt-4'>
									Already have an account? Please <Link to='/login'>login</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Register;
