import { useCallback, useState } from 'react';

export default function useCenteredTree(defaultTranslate = { x: 0, y: 0 }) {
	const [translate, setTranslate] = useState(defaultTranslate);
	const [dimensions, setDimensions] = useState();
	const containerRef = useCallback((containerElem) => {
		if (containerElem !== null) {
			const { width, height } = containerElem.getBoundingClientRect();
			setDimensions({ width, height });
			setTranslate({ x: width / 2, y: 20 });
		}
	}, []);
	return [dimensions, translate, containerRef];
}
