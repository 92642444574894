import React from 'react';
import { numberWithCommas } from '../../../../utils/helpers';

const CoversTable = ({ table }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-bordered table-sm mmiTable text-center'>
				<thead>
					<tr className='align-middle'>
						<th rowSpan={2}>Insurance Year</th>
						<th rowSpan={2}>Insured Age</th>
						<th colSpan={3}>Insurance Premium</th>
						<th rowSpan={2}>Death for any reason</th>
						<th rowSpan={2}>Death due to an accident</th>
						<th rowSpan={2}>Disabilities</th>
						<th rowSpan={2}>Special Diseases</th>
						<th rowSpan={2}>Physical Disability</th>
						<th rowSpan={2}>Fire Incident</th>
						<th rowSpan={2}>Termination</th>
						<th rowSpan={2}>Mathematical Reserve</th>
						<th rowSpan={2}>Guaranteed 10% Profit</th>
						<th rowSpan={2}>Predicted Rate of 15%</th>
						<th rowSpan={2}>Predicted Rate of 18%</th>
					</tr>
					<tr>
						<th>Monthly</th>
						<th>Annual</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{table.map((d, key) => (
						<tr className='py-0' key={key}>
							<th>{d.year}</th>
							<td>{d.userAge}</td>
							<td>{numberWithCommas(d.monthly)}</td>
							<td>{numberWithCommas(d.annual)}</td>
							<td>{numberWithCommas(d.total)}</td>
							<td>{numberWithCommas(d.death)}</td>
							<td>{numberWithCommas(d.accident)}</td>
							<td>{numberWithCommas(d.disabilities)}</td>
							<td>{numberWithCommas(d.special)}</td>
							<td>{numberWithCommas(d.physical)}</td>
							<td>{numberWithCommas(d.fire)}</td>
							<td>{numberWithCommas(d.termination)}</td>
							<td>{numberWithCommas(d['rate_10'])}</td>
							<td>{numberWithCommas(d['rate_10'])}</td>
							<td>{numberWithCommas(d['rate_15'])}</td>
							<td>{numberWithCommas(d['rate_18'])}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default CoversTable;
