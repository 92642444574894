import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SelectMulti = ({
	name,
	label,
	value,
	onChange,
	options,
	error,
	formText,
	labelClass = '',
	placeholder = '',
}) => {
	const animatedComponents = makeAnimated();
	return (
		<div
			className='form-group'
			style={{ position: 'relative', zIndex: '10' }}>
			<label className={`form-label ${labelClass}`} htmlFor={name}>
				{label}
			</label>
			<Select
				placeholder={placeholder}
				closeMenuOnSelect={false}
				components={animatedComponents}
				id={name}
				name={name}
				value={value}
				onChange={onChange}
				options={options}
				isMulti
			/>
			{formText && <div className='form-text mb-1'>{formText}</div>}
			{error && <div className='alert alert-danger h6 p-2'>{error}</div>}
		</div>
	);
};

export default SelectMulti;
