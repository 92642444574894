import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useGoProResult() {
	const [goProResult, setGoProResult] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/dashboard/bonus/goPro3', '/dashboard/bonus/gopro3'];
		async function getGoProResult() {
			try {
				if (
					!http.getCurrentUser() ||
					goProResult ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getGoPro3Result();
				if (isMounted()) setGoProResult(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getGoProResult();
	}, [isMounted, goProResult, pathname]);
	return [goProResult, setGoProResult];
}
