import { Image, StyleSheet, View } from '@react-pdf/renderer';
import headerMMI from '../img/headerMMI.png';

const styles = StyleSheet.create({
    headerContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    image: {
        width: 600,
        height: 'auto',
    },
});

const MmiFormHeader = () => (
    <View style={styles.headerContainer}>
        <Image style={styles.image} src={headerMMI} />
    </View>
);

export default MmiFormHeader;
