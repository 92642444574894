import React, { useState } from 'react';
// import { toast } from 'react-toastify';
// import http from '../../../../services/httpService';
import useMain from '../../../../hooks/useMain';
import { TextCard } from '../../ingredients/cards';
import ComingSoon from './../../../comingSoon';
import Image from './../../../common/image';
import BuyForm from './buyForm';

const BuyNFTRequest = () => {
	const { userData, onUserDataChanged } = useMain();
	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const handleSubmit = async (formData) => {
		setErrors({});
		setIsLoading(false);
		onUserDataChanged({});
		setTimeout(() => {
			setIsSubmited(true);
		}, 1000);
	};

	return (
		<div className='row'>
			<div className='col-12 text-center'>
				<TextCard
					title={''}
					titleClass={''}
					text={
						<div className='row mt-4'>
							<div className='col-12'>
								<ul
									className='nav nav-tabs nav-fill'
									id='buyNFTRequestTab'
									role='tablist'>
									<li
										className='nav-item'
										role='presentation'>
										<button
											className={`px-0 nav-link active ${
												isLoading ? 'disabled' : ''
											}`}
											id='buy-tab'
											data-bs-toggle='tab'
											data-bs-target='#buy-tab-pane'
											type='button'
											role='tab'
											aria-controls='buy-tab-pane'
											aria-selected='true'>
											Buy
										</button>
									</li>
									<li
										className='nav-item'
										role='presentation'>
										<button
											className={`px-0 nav-link ${
												isLoading ? 'disabled' : ''
											}`}
											id='academy-tab'
											data-bs-toggle='tab'
											data-bs-target='#academy-tab-pane'
											type='button'
											role='tab'
											aria-controls='academy-tab-pane'
											aria-selected='false'>
											Academy
										</button>
									</li>
									<li
										className='nav-item'
										role='presentation'>
										<button
											className={`px-0 nav-link ${
												isLoading ? 'disabled' : ''
											}`}
											id='collection-tab'
											data-bs-toggle='tab'
											data-bs-target='#collection-tab-pane'
											type='button'
											role='tab'
											aria-controls='collection-tab-pane'
											aria-selected='false'>
											Collection
										</button>
									</li>
									<li
										className='nav-item'
										role='presentation'>
										<button
											className={`px-0 nav-link ${
												isLoading ? 'disabled' : ''
											}`}
											id='sell-tab'
											data-bs-toggle='tab'
											data-bs-target='#sell-tab-pane'
											type='button'
											role='tab'
											aria-controls='sell-tab-pane'
											aria-selected='false'>
											Sell
										</button>
									</li>
								</ul>

								<div
									className='tab-content innerTabPanels pt-4'
									id='buyNFTRequestTabContent'>
									<div
										className='tab-pane fade show active'
										id='buy-tab-pane'
										role='tabpanel'
										aria-labelledby='buy-tab'
										tabIndex='0'>
										<div className='row p-4 align-items-center'>
											<div className='col-12'>
												<h4 className='pt-2'>
													Buy from BEM Foundation's
													NFT Collection and sell on
													BEMULATOR (NFT market
													place).
												</h4>
												<p>
													using NFT wallet you can
													purchase NFT tokens from our
													collections.
												</p>
											</div>
											<div className='col-sm-7'>
												<p className='text-start'>
													<b>
														NFT Wallet Balance:{' '}
														{userData.wallets.nft
															.available /
															1e6}{' '}
														USD
													</b>
												</p>
												<BuyForm
													nfts={[]}
													hasSP={userData.hasSP}
													onFormChange={() =>
														setIsSubmited(false)
													}
													isSubmited={isSubmited}
													errors={errors}
													isLoading={isLoading}
													onSubmit={handleSubmit}
												/>
											</div>
											<div className='col-sm-5'>
												<Image fileName='icon3d.png' />
											</div>
										</div>
									</div>

									<div
										className='tab-pane fade'
										id='academy-tab-pane'
										role='tabpanel'
										aria-labelledby='academy-tab'
										tabIndex='0'>
										<div className='row p-4'>
											<div className='px-5 col-12 text-start'>
												<h4 className='mt-5'>
													What is an NFT?
												</h4>
												<p>
													An NFT is a type of
													cryptographic token on a
													blockchain that represents a
													unique asset. These can
													either be entirely digital
													assets or tokenized versions
													of real-world assets.
												</p>

												<h4 className='mt-5'>
													What does NFT stand for?
												</h4>
												<p>
													NFT is an abbreviation for
													non-fungible token.
												</p>
												<h4 className='mt-5'>
													What is fungibility?
												</h4>
												<p>
													Another distinguishing
													feature of an NFT is the
													non-fungibility aspect. A
													good example to illustrate
													fungibility would be
													currencies - where you could
													easily exchange a $5 bill
													for another $5 bill due to
													the homogeneity. These items
													are exchangeable because
													they are defined by their
													value and not their
													uniqueness.
												</p>
												<p>
													NFTs, on the other hand, are
													not interchangeable with one
													another. Each NFT has a
													distinctive and unique
													identifier that makes it
													different from the rest.
													This functions as proof of
													authenticity and ownership
													within the digital realm.
												</p>

												<h4 className='mt-5'>
													How do NFTs work?
												</h4>
												<p>
													There are various frameworks
													for the creation and
													issuance of NFTs. The most
													popular are ERC-721 and
													ERC-1155 on the Ethereum
													blockchain. The BNB Chain
													has its own standards: the
													BEP-721 and BEP-1155. NFTs
													cannot be replicated or
													transferred without the
													owner's permission, even by
													the issuer of the NFT.
												</p>
												<p>
													You can trade NFTs on open
													marketplaces like Binance
													NFT Marketplace, OpenSea,
													and Bemulator. These markets
													help connect buyers with
													sellers, and the value of
													each NFT is unique. NFTs are
													also susceptible to price
													changes in response to
													market demand and supply.
												</p>

												<h4 className='mt-5'>
													How are NFTs created?
												</h4>
												<p>
													The first step to NFT
													creation is minting. Minting
													refers to turning images,
													videos, audio, and other
													digital files into
													crypto-assets on a
													blockchain. With the NFT
													existing on the blockchain,
													it makes it harder to
													manipulate and tamper with.
													The unique data of an NFT
													makes it easy to verify the
													authenticity and ownership.
												</p>
												<p>
													With each NFT created, the
													owner/creator can also store
													specific information inside
													them, such as signing their
													artwork by including their
													signature in an NFT's
													metadata.
												</p>
												<h4 className='mt-5'>
													Do you actually own NFTs?
												</h4>
												<p>
													For majority of the NFTs,
													there can only be one owner
													at a time. The ownership is
													managed via a unique ID and
													metadata that other tokens
													can't replicate.
												</p>
												<p>
													However, fractional NFTs are
													also beginning to gain some
													traction. A fractional NFT
													takes a whole NFT and divide
													them into smaller fractions,
													allowing different numbers
													of people to claim ownership
													of a piece of the same NFT.
												</p>

												<h4 className='mt-5'>
													What are NFT blockchain
													platforms?
												</h4>
												<p>
													An NFT is a crypto asset
													representing something
													unique and collectible using
													blockchain technology. But
													what exactly is a
													blockchain? It is
													essentially a distributed
													database for recording and
													storing information. This
													database is also referred to
													as a digital ledger, and its
													decentralized network
													ensures that data cannot be
													hacked or manipulated. NFTs
													are digital assets that
													leverage blockchain
													technology to ensure
													authenticity and security.
												</p>
												<p>
													Specific to NFTs,
													marketplaces exist to offer
													a secure platform for
													different collectors to buy,
													trade and sell their NFTs on
													the blockchain. These
													marketplaces have a wide
													variety of non-fungible
													tokens for sale, ranging
													from famous artists to even
													amateurs.
												</p>
												<h4 className='mt-5'>
													What are some blockchain
													options?
												</h4>

												<p>
													<b>1. Ethereum </b>
													<br />
													Ethereum is a decentralized,
													open source blockchain
													platform powered by its
													native cryptocurrency, ether
													(ETH). Ethereum smart
													contracts allow participants
													to develop all sorts of
													decentralized financial
													services and applications.
												</p>

												<p>
													<b>2. BNB Chain </b>
													<br />
													An abbreviation of Build' N
													Build, the BNB Chain is
													expanding and building on
													decentralization. The BNB
													Chain aims to increase
													interoperability and develop
													the underlying
													infrastructure of the
													"world's parallel virtual
													environment," which is a
													step forward in Binance's
													recent MetaFi efforts.
												</p>

												<p>
													<b>3. Polygon </b>
													<br />
													Specifically tailored to the
													Ethereum ecosystem, Polygon
													offers developers a
													framework for creating
													Ethereum-compatible
													blockchain networks and
													scaling solutions.
												</p>
												<h4 className='mt-5'>
													Who created NFTs?
												</h4>
												<p>
													NFTs were first introduced
													to the world in May 2014 by
													creator Kevin McCoy. A
													leader in the NFT art realm,
													he minted the first-ever
													NFT, Quantum, which was
													designed by his wife.
													"Quantum" is a video loop of
													an octagon filled with
													denoting circles, arcs, and
													other shapes that share the
													same center. As of 2021, the
													historic NFT is on sale for
													7 million dollars.
												</p>

												<h4 className='mt-5'>
													Why are NFTs rising in
													popularity?
												</h4>
												<p>
													The widespread NFT
													phenomenon has taken the
													world by storm. More than
													just collectible digital
													assets, NFTs are now value
													assets with various use
													cases in the physical and
													virtual world.
												</p>
												<p>
													NFTs are also increasingly
													popularized as a form of
													artistic expression or
													investment tools in the
													digital realm. Even in
													games, the functionality of
													NFTs makes them both an
													investment asset and a
													utility instrument that
													grants players special
													abilities. As the NFT world
													continues to develop and
													extend itself, utility cases
													will eventually evolve
													beyond JPEG collectibles.
												</p>

												<h4 className='mt-5'>
													How many people are buying
													NFTs?
												</h4>
												<p>
													Prior to the crypto boom in
													2017 that led to the
													interest in NFTs, NFTs sales
													per week were estimated to
													be a mere 100. However, with
													the rising popularity of
													NFTs and the ever-expanding
													NFT world, NFTs sales can
													range anywhere from 15,000
													to 50,000 in a week. This
													averages out to be an
													estimated $10 million to $20
													million each week.
												</p>

												<h4 className='mt-5'>
													How do NFTs increase in
													value?
												</h4>
												<p>
													Similar to how physical art
													pieces are valued, the value
													of NFTs is heavily dependent
													on the interaction between
													demand and supply. As demand
													rise due to factors such as
													rarity, utility, and
													speculation, the prices for
													NFTs follow suit and
													increase as well.
												</p>

												<h4 className='mt-5'>
													How to buy NFTs?
												</h4>
												<p>
													There are different methods
													and platforms to purchase
													your NFTs. You can buy NFTs
													via fixed price, auction, or
													from the Bemulator platform
													(coming soon).
												</p>
												<p>
													Now you can get NFTs from
													the Bemland page.
												</p>
												<p>
													You can register a purchase
													request from your NFT wallet
													after reaching the defined
													quorum, or buy NFT directly
													with tether.
												</p>

												<h4 className='mt-5'>
													How to sell NFTs?
												</h4>
												<p>
													There are many different
													methods to sell your NFTs.
													You could leverage the right
													marketplace, share your work
													on social media, host
													giveaways, organize an
													AMA/livestream, join NFT
													groups on community
													platforms, create your own
													website, or even collaborate
													with influencers.
												</p>

												<h4 className='mt-5'>
													How to mint NFTs?
												</h4>
												<p>
													There are many different
													steps to minting your NFTs:
													having crypto to pay the
													minting fee, a
													cryptocurrency wallet to
													store your crypto, a
													blockchain to create your
													non-fungible token, and many
													more!
												</p>

												<h4 className='mt-5'>
													How to invest in NFTs?
												</h4>
												<p>
													When choosing to buy an NFT
													for speculative purposes, it
													is important to analyze the
													different factors that may
													influence an NFT's value.
													These metrics include
													Utility, Rarity, Community
													Size, Trading Volume,
													Potential and Provenance.
												</p>
												<p>
													Keeping these indicators in
													mind helps in better
													evaluating the longevity and
													value of an NFT project for
													your NFT collection or
													crypto portfolio.
												</p>

												<h4 className='mt-5'>
													Where can you trade NFTs?
												</h4>
												<p>
													You can list NFTs for sale
													on reputable market places
													such as Binance, Opensea,
													Bemulator.
												</p>

												<h4 className='mt-5'>
													What are the popular NFTs
													today?
												</h4>
												<p>
													With many NFTs launching on
													the market daily, and
													regardless of everyone's
													personal preferences for
													collectibles, the most
													popular types of NFTs are
													use case NFTs and trading
													card NFTs.
												</p>

												<h4 className='mt-5'>
													What are the different types
													of NFTs?
												</h4>
												<p>
													Massive interest in
													non-fungible tokens (NFTs)
													has led to a boom in
													crypto-collectibles and NFT
													art. Some of the most
													popular NFT use cases are
													Art NFTs, Collectible NFTs,
													Finance NFTs, Gaming NFTs,
													Music NFTs, Real-world asset
													NFTs, and Logistics NFTs.
												</p>

												<h4 className='mt-5'>
													Art NFTs
												</h4>
												<p>
													Art NFTs are created when
													artists digitalize and
													monetize their artwork onto
													the blockchain. Art NFTs get
													their value from two main
													factors: the ability to
													verify the authenticity
													digitally and proving the
													ownership of the particular
													digital asset.
												</p>

												<h4 className='mt-5'>
													Collectible NFTs
												</h4>
												<p>
													Collectible NFTs are unique
													digital assets minted on the
													blockchain, which users can
													collect or trade. Some
													special collectible NFTs are
													limited-edition and can be
													particularly sought after by
													collectors.
												</p>

												<h4 className='mt-5'>
													Music NFTs
												</h4>
												<p>
													Similar to image files or
													videos, Music NFTs are
													created when you attach
													music or audio to an NFT.
													They are a certificate of
													ownership for a unique audio
													or a musical piece that can
													be bought or sold.
												</p>

												<h4 className='mt-5'>
													Video NFTs
												</h4>
												<p>
													Video NFTs are digital
													assets in the form of moving
													pictures. These NFTs have
													been increasingly popular
													due to the ability to own
													digital assets like unique
													videos and experiences from
													their favourite creators,
													the association with these
													creators, and even personal
													taste and collection.
												</p>

												<h4 className='mt-5'>
													Avatar NFTs
												</h4>
												<p>
													NFT avatars are images of a
													character in a "headshot"
													digital profile picture
													format. Each of them is
													individually unique and
													generated by an algorithm
													with different traits. These
													NFTs can be used to foster a
													sense of community, signify
													digital status, and even act
													as a ticket to exclusive
													metaverse events.
												</p>

												<h4 className='mt-5'>
													Game NFTs
												</h4>
												<p>
													Game NFTs differ from simply
													holding crypto-collectibles
													in your wallet. An NFT game
													will use NFTs in its rules,
													mechanism, and player
													interactions. For example, a
													game can offer a rare game
													skin as an NFT, and the
													player who unlocks it first
													takes ownership of it. This
													is growing in popularity
													among gamers who are already
													familiar with the concept of
													valuable, digital items.
												</p>

												<h4 className='mt-5'>
													Trading Card NFTs
												</h4>
												<p>
													Trading card NFTs can be
													used as virtual
													representations of physical
													trading cards. Their appeal
													can be attributed to the
													following factors: it is
													easily verifiable,
													especially when scarcity is
													crucial to the value of the
													card, enduring ownership on
													the blockchain, and rare
													cards as a form of
													investment.
												</p>

												<h4 className='mt-5'>
													Meme NFTs
												</h4>
												<p>
													A phenomenon coined as the
													"meme gold rush," meme NFTs
													can be a new way for meme
													creators to monetize their
													memes. The value of these
													memes will be heavily
													dependent on the virality of
													the meme, the originality,
													and the authenticity of the
													digital asset.
												</p>
											</div>
										</div>
									</div>

									<div
										className='tab-pane fade'
										id='collection-tab-pane'
										role='tabpanel'
										aria-labelledby='collection-tab'
										tabIndex='0'>
										<div className='row p-4'>
											<div className='col-sm-8 offset-sm-2 col-12'>
												<ComingSoon />
											</div>
										</div>
									</div>

									<div
										className='tab-pane fade'
										id='sell-tab-pane'
										role='tabpanel'
										aria-labelledby='sell-tab'
										tabIndex='0'>
										<div className='row p-4'>
											<div className='col-sm-8 offset-sm-2 col-12'>
												<ComingSoon />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default BuyNFTRequest;
