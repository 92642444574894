import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import MmiFormHeader from './MmiFormHeader';
import MmiFormTitle from './MmiFormTitle';
import MmiInsured from './MmiInsured';
import MmiInsurancePolicy from './MmiInsurancePolicy';
import MmiHeirsTable from './MmiHeirsTable';
import MmiObligations from './MmiObligations';
import MmiAcknowledgement from './MmiAcknowledgement';
import MmiFormFooter from './MmiFormFooter';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        lineHeight: 1,
        flexDirection: 'column',
    },
});

const MmiForm = ({ personalData }) => (
    <Document>
        <Page size='A4' style={styles.page}>
            <MmiFormHeader />
            <MmiFormTitle />
            <MmiInsured personalData={personalData} />
            <MmiInsurancePolicy personalData={personalData} />
            <MmiHeirsTable personalData={personalData} />
            <MmiObligations />
            <MmiAcknowledgement />
            <MmiFormFooter />
        </Page>
    </Document>
);

export default MmiForm;
