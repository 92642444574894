import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useKYCData() {
	const [KYCData, setKYCData] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = [
			'/dashboard/myProfile/kyc',
			'/kyc/basicPersonalInfoReview',
			'/kyc/identityInfoReview',
			'/kyc/residentialInfoReview',
		];

		async function fetchData() {
			try {
				if (!http.getCurrentUser() || KYCData || !allowed.includes(pathname)) return;
				const { data } = await http.getKYCData();
				if (isMounted()) setKYCData(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		fetchData();
	}, [isMounted, KYCData, pathname]);
	return [KYCData];
}
