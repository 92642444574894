import React from 'react';
import useMain from '../../../../../hooks/useMain';
import P5Form from './p5Form';

const P5 = ({ onPrevBtnClick, onNextBtnClick, onFieldChange }) => {
	const { initData } = useMain();

	const handleSubmit = (formData) =>
		onNextBtnClick({ name: 'p5', data: formData });

	return (
		<div className='row mb-5'>
			<div className='col-lg-12 col-12'>
				<div className='mb-1'>Page 5</div>
				<div className='fs-4 fw-bold mb-3'>Health Condition</div>
				<P5Form
					onPrevBtnClick={onPrevBtnClick}
					handleChange={onFieldChange}
					onSubmit={handleSubmit}
					options={initData.mmiConfig.contract.yesNoOptions}
				/>
			</div>
		</div>
	);
};

export default P5;
