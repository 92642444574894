import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class TopupForm extends Form {
	state = {
		data: {
			amount: this.props.current || this.props.min,
			network: this.props.options.find((o) => o.checked === true).id,
		},
		errors: {},
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.multiple(this.props.step)
			.required()
			.label('Amount')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message = 'Amount should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${this.props.min} USDT `;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		network: Joi.string().required().label('Network'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, options, min, step, hasActivePaymentRequest } = this.props;
		return (
			<form
				className='text-start'
				onSubmit={this.handleSubmit}>
				<div className='mb-4'>
					<p>Select Network:</p>
					{options.map((o, key) =>
						this.renderRadioInput({
							key,
							name: 'network',
							id: o.id,
							label: o.label,
							checked: o.checked,
							disabled: o.disabled,
						})
					)}
				</div>
				<div className='mb-4'>
					{this.renderInput({
						name: 'amount',
						label: 'Enter Amount (usd)',
						type: 'number',
						min,
						step,
						disabled: hasActivePaymentRequest,
					})}
				</div>
				<div className='mb-4 text-center'>
					{!hasActivePaymentRequest &&
						this.renderButton(
							'Request Payment',
							`btn btn-my-navy`,
							isLoading && <Spinner content={'Requesting...'} />
						)}
				</div>
			</form>
		);
	}
}

export default TopupForm;
