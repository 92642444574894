import React, { Fragment } from 'react';

const InputGroupBtnEnd = ({
	name,
	label,
	error,
	customClass,
	labelClass,
	formText,
	btnClass,
	btnText,
	btnHandler,
	btnDisable = false,
	...rest
}) => {
	return (
		<Fragment>
			<label className={labelClass} htmlFor={name}>
				{label}
			</label>
			<div className={'input-group ' + customClass}>
				<input
					{...rest}
					name={name}
					id={name}
					className='form-control'
				/>
				<button
					onClick={btnHandler}
					className={btnClass}
					type='button'
					disabled={btnDisable}>
					{btnText}
				</button>
			</div>
			{formText && <div className='form-text'>{formText}</div>}
			{error && <div className='alert alert-danger'>{error}</div>}
		</Fragment>
	);
};

export default InputGroupBtnEnd;
