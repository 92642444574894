const bcn = {
	image: 'bcn.jpg',
	carousel: true,
	path: '/bcn',
	name: 'BCN',
	title: 'Buying BCN Token',
	description: 'A portion of the funds will be used to buy BCNs. This is ...',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<p>
						A portion of the funds will be used to buy BCNs. This is
						initially done to provide financial support for the MMP
						WORLD project. Then it is continued to profit from the
						token's price surge (the price increases because of the
						project itself) to pay the monthly interests of the
						investors and reward the assisting managers.
					</p>
					<p>Financial turnover and shaping the market</p>
					<p>
						One of the requirements of a crypto market is the
						increasing number of transactions in any given 24 hours.
						This will enable traders to analyze the currency and
						conduct trading based on that.
					</p>
					<p>
						Part of the raised funds will constantly be used for
						trading BCN/TRX and BCN/USDT currency pairs.
					</p>
					<p>Limiting the fluctuation range of the price</p>
					<p>
						The funds will be used to keep the price at a specified
						range. When major sells or buys are taking place, market
						makers will conduct an opposite of the major sell or buy
						to maintain order in the market and prevent any fears or
						worries. This stabilizes the price. This operation will
						constantly be repeated, and its range will only change
						with an increase in the price. It is only done to
						prevent any changes in the market trend because of the
						actions of one or a few holders.
					</p>
					<p>Increasing the inventory of the liquidity pool</p>
					<p>
						Part of the funds will give depth to the market and
						increase the market liquidity. This fund will be used in
						two ways. First, the token will be bought with half of
						the funds, and the other half will be converted to the
						base currency. Both will be injected into the liquidity
						pool in equal amounts. This will increase the
						possibility of liquidity, and price impact will be
						reduced in trades.
					</p>
					<p>Advertising to attract more holders and traders</p>
					<p>
						The advertising process is one of the most effective
						ways to know the active people in the cryptocurrency
						market. Effective presence in different gatherings,
						campaigns, sponsoring, presence in different social
						media, cryptocurrency media, and creating technical
						content for source websites are all very effective in
						the development of a currency. Of course, it is very
						expensive to be active in these fields. Part of the
						raised funds will be used for social media-based
						development to significantly affect all the projects.
						More investors and traders are attracted each day.
					</p>
					<p>
						<b>Development</b>
					</p>
					<p>
						Another important element in developing a currency has
						it in different exchanges. Being listed on different
						exchanges will attract more holders and traders to the
						currency market, making it more legitimate and credible.
						Other than the need for a market-making budget, being
						listed in exchanges will need an initial fee.
					</p>
					<p>
						Part of the funds will be used to get listed on ranked
						exchanges and credible networks related to
						cryptocurrencies.
					</p>
					<p>
						MMP WORLD project will not use the funds to pay the
						interests of the investors, reward assisting managers,
						or serve its own interests. After the development and
						expansion of any project, and then paying the interests
						of investors and rewarding the staff, the project will
						take the remaining profit, and part of it will be used
						for developing other projects.
					</p>
				</div>
			</div>
		</div>
	),
};

export default bcn;
