import React from 'react';

const ListGroup = ({
	items,
	textProperty,
	valueProperty,
	selectedItem,
	onItemSelect,
	customClass,
	activeClass,
}) => {
	return (
		<ul className={`list-group`}>
			{items.map((item) => (
				<li
					onClick={() => onItemSelect(item)}
					key={item[valueProperty]}
					className={
						item._id === selectedItem._id
							? `list-group-item ${customClass} ${
									activeClass ? activeClass : 'active'
							  }`
							: `list-group-item ${customClass}`
					}>
					{item[textProperty]}
				</li>
			))}
		</ul>
	);
};

ListGroup.defaultProps = {
	textProperty: 'name',
	valueProperty: '_id',
};

export default ListGroup;
