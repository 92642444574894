import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { roundUpMinutes } from '../../../../utils/helpers';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';
import ConfirmModal from '../../ingredients/confirmModal';
import CancelForm from './cancelForm';
import WithdrawalForm from './withdrawalForm';
import { Link } from 'react-router-dom';

const Withdrawal = () => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState(false);

	const requestTime = userData.hasActiveWithdrawRequest
		? new Date(roundUpMinutes(userData.withdrawRequests[0].date)).getTime()
		: 0;
	// addWorkingDays
	const counterDate = initData.withdrawGap + requestTime;
	const currentWithdrawRequestAmount = userData.hasActiveWithdrawRequest
		? userData.withdrawRequests[0].amount / 1e6
		: 0;
	const currentWithdrawRequestWallet = userData.hasActiveWithdrawRequest
		? userData.withdrawRequests[0].wallet
		: '';

	//verification controls
	const mustVerify = initData.verificationControl && userData.verificationLevel !== 'level_4';
	const { withdraw, period } = initData.verificationConfig[userData.verificationLevel];
	const lastWithdraw = userData.withdrawRequests.find((w) => w.status === 'fulfilled');
	const isDateInRange = lastWithdraw
		? Date.now() >= new Date(lastWithdraw.date).getTime() + period
		: true;

	const handleRequestTimerCompleted = async () => {
		try {
			const { data } = await http.getOnlyMe();
			onUserDataChanged(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const handleModalShow = (formData) => {
		if (!formData.isWalletValid && !userData.hasActiveWithdrawRequest) {
			return setErrors({
				wallet: 'Press Check button to examine the Wallet before going further.',
			});
		}

		setCurrentFormData(formData);
		const htmlOnWithdraw = (
			<>
				<div>
					Amount: <b>{formData.amount}</b> USDT
				</div>
				<div>
					Destination Address: <b>{formData.wallet}</b>
				</div>
				<div>
					Approximate processing time: <b>{initData.withdrawGap / (60 * 60 * 1000)}</b>{' '}
					hours
				</div>
			</>
		);
		const htmlOnCancel = (
			<div>Are you sure you want to cancel the current withdrawal request?</div>
		);
		setModalBody(userData.hasActiveWithdrawRequest ? htmlOnCancel : htmlOnWithdraw);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response && !userData.hasActiveWithdrawRequest) handleWithdraw();
		if (response && userData.hasActiveWithdrawRequest) handleCancel();
	};

	const handleWithdraw = async () => {
		const { wallet, amount, securityPassword } = currentFormData;
		try {
			setIsLoading(true);
			const { data } = await http.withdrawRequest({
				wallet,
				amount,
				securityPassword,
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentFormData(null);
		}
	};

	const handleCancel = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.cancelWithdrawRequest({
				securityPassword: currentFormData.securityPassword,
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentFormData(null);
		}
	};

	return (
		<div className='row'>
			<div className='col-12 text-center'>
				<TextCard
					title='Wallet Withdrawal'
					titleClass='fs-2'
					text={
						<div className='row'>
							<div className='col-sm-8 offset-sm-2 col-12'>
								{!userData.isWithdrawAllowed ? (
									<div className='py-5'>
										<div className='centerAll mb-3'>
											<Image
												className='me-3'
												fileName='icons/forbidden.svg'
											/>
											<div className='fs-3'>Access Denied.</div>
										</div>
										<div>
											This account's withdrawal is suspended. Please contact
											admins.
										</div>
									</div>
								) : userData.verificationLevel === 'level_0' &&
								  initData.verificationControl ? (
									<div className='py-5'>
										<div className='centerAll mb-3'>
											<Image
												className='me-3'
												fileName='icons/kyc.svg'
											/>
											<div className='fs-3'>KYC Process Required.</div>
										</div>
										<div>
											You must verify your identity to activate withdrawal.
										</div>

										<Link
											className='btn btn-my-blue mt-4'
											to='/dashboard/myProfile/kyc'>
											Verify Now
										</Link>
									</div>
								) : userData.isAboveAlert ? (
									<div className='py-5'>
										<div className='centerAll mb-3'>
											<Image
												className='me-3'
												fileName='warning.svg'
											/>
											<div className='fs-3'>Max Cap Alert!</div>
										</div>
										<div>
											Withdrawal is not allowed for the{' '}
											<span className='fw-bold'>planners</span> with used
											maxCap more than{' '}
											<span className='fw-bold'>
												{initData.maxCapAlert * 100}% .
											</span>
										</div>
										<div>
											Increase your maxCap to reactivate your withdrawal.
										</div>
										<div>
											Your current used maxCap is:{' '}
											<span className='fw-bold'>
												{(
													(userData.usedMaxCap * 100) /
													userData.maxCap
												).toFixed(2)}
												% .
											</span>
										</div>
									</div>
								) : userData.hasActiveWithdrawRequest ? (
									<>
										<div className='mb-4'>
											<div className='text-center mt-5'>
												<div className='mb-3'>
													You have a pending Withdraw Request of <br />
													<b>${currentWithdrawRequestAmount}</b> to{' '}
													<b>{currentWithdrawRequestWallet}</b>
													<br /> which will be executed on:
												</div>
												<Countdown
													className='h3'
													date={counterDate}
													onComplete={handleRequestTimerCompleted}
												/>
											</div>
										</div>
										<CancelForm
											errors={errors}
											isLoading={isLoading}
											hasSP={userData.hasSP}
											onSubmit={handleModalShow}
										/>
									</>
								) : !mustVerify ? (
									<WithdrawalForm
										errors={errors}
										isLoading={isLoading}
										hasSP={userData.hasSP}
										min={initData.minWithdraw}
										max={initData.maxWithdraw}
										step={initData.stepWithdraw}
										walletBalance={userData.wallets.usdt.available / 1e6}
										onSubmit={handleModalShow}
										mustVerify={mustVerify}
									/>
								) : !isDateInRange ? (
									<div className='mb-4'>
										<div className='text-center mt-5'>
											<div className='mb-3'>
												According to your current verification level you can
												use this service in:
											</div>
											<Countdown
												className='h3'
												date={
													new Date(lastWithdraw?.date).getTime() + period
												}
												onComplete={handleRequestTimerCompleted}
											/>
										</div>
									</div>
								) : (
									<WithdrawalForm
										errors={errors}
										isLoading={isLoading}
										hasSP={userData.hasSP}
										min={initData.minWithdraw}
										max={withdraw}
										step={initData.stepWithdraw}
										walletBalance={userData.wallets.usdt.available / 1e6}
										onSubmit={handleModalShow}
										mustVerify={mustVerify}
									/>
								)}

								<hr className='mt-4' />
								<div className='text-start mb-5'>
									<h5>Wallet withdrawal rules:</h5>
									<ul>
										<li>
											The maximum withdrawal limit for all packages is $
											{initData.maxWithdraw} in each turn.
										</li>
										<li>
											Each withdrawal takes{' '}
											{initData.withdrawGap / (24 * 60 * 60 * 1000)} working
											days.
										</li>
										<li>
											Withdrawal is not allowed for the planners with used
											maxCap more than {initData.maxCapAlert * 100}% .
										</li>
									</ul>
								</div>
								<ConfirmModal
									show={modalShow}
									onClose={handleModalClose}
									title='Wallet Withdrawal'
									body={modalBody}
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default Withdrawal;
