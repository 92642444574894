import React, { useState } from 'react';
// import { toast } from 'react-toastify';
// import http from '../../../../services/httpService';
import useMain from '../../../../hooks/useMain';
import { TextCard } from '../../ingredients/cards';
import SumbitAccountForm from './submitAccountForm';

const BuyTokenRequest = () => {
	const { userData, onUserDataChanged } = useMain();
	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const brokers = [
		'ROBOFOREX',
		'ICM',
		'IFC',
		'CAPITALXTEND',
		'LITEFOREX',
		'WINDSOR',
		'ALPARI',
		'Deltafx',
		'other',
	];

	const accounts = ['STANDARD', 'NANO', 'FIX SPREAD', 'ISLAMIC'];

	const handleSubmit = async (formData) => {
		setErrors({});
		setIsLoading(false);
		onUserDataChanged({});
		setTimeout(() => {
			setIsSubmited(true);
		}, 1000);
	};

	return (
		<div className='row'>
			<div className='col-12'>
				<TextCard
					title={''}
					titleClass={''}
					text={
						<>
							<div className='row my-4'>
								<div className='col-sm-6 offset-sm-3 col-12'>
									<p>
										<b>
											Forex Wallet Balance:{' '}
											{userData.wallets.forex.available /
												1e6}{' '}
											USD
										</b>
									</p>
									<SumbitAccountForm
										brokers={brokers}
										accounts={accounts}
										hasSP={userData.hasSP}
										onFormChange={() =>
											setIsSubmited(false)
										}
										isSubmited={isSubmited}
										errors={errors}
										isLoading={isLoading}
										onSubmit={handleSubmit}
									/>
									<ul className='mt-4'>
										<li>
											After reaching the amount of $5000,
											the investor can request to use the
											MT4 option.
										</li>
										<li>
											After reaching the specified minimum
											amount, the investor introduces a
											forex account.
										</li>
										<li>
											The amount is deposited into the
											investor's personal account.
										</li>
										<li>
											Every investor's account is
											connected to copy trade for 6
											months.
										</li>
										<li>
											After 6 months, they can withdraw
											the principal and interest of their
											money.
										</li>
									</ul>
								</div>
							</div>
						</>
					}
				/>
			</div>
		</div>
	);
};

export default BuyTokenRequest;
