import Anchor from '../common/anchor';
import Image from '../common/image';

const about = {
	path: '/about',
	name: 'About',
	title: 'About BEM Foundation',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='offset-md-3 col-md-6 col-12 text-center'>
					<Image
						className='w-50 mb-4'
						fileName='bemFoundation.webp'
					/>
				</div>

				<div className='col-12'>
					<h2 className='fw-bold'>
						The Best Economic Money Foundation
					</h2>
					<p>
						The BEM Foundation was established in 2020 as an
						entrepreneur in the blockchain field to develop the
						industry globally. The foundation is made up of experts
						in cryptocurrency, blockchain programming, and
						marketing, each with several years of experience in
						their field. Inspired by blockchain principles of
						decentralization and to increase the security level of
						projects, the identities of these individuals remain
						disclosed. The BEM foundation was created to develop a
						blockchain-based ecosystem and job opportunities in
						blockchain. We aim to explore market needs and create
						projects so that everyone can quickly understand
						blockchain projects and start working on them.
					</p>
					<h2 className='fw-bold'>History</h2>
					<p>
						BEM Foundation started its work by creating and offering
						a token. The first project of the BEM Foundation was the
						selling of tokens through ICO. We then provided a
						staking project to generate more financial turnover for
						the tokens. Six months after the launch of the first
						token, and after completing the ICO and staking project,
						BemChain was launched. It was the Launchpad for the BEM
						Foundation and had a very significant role in the
						development of the foundation. Initially, the managers
						of the BEM Foundation managed and guided projects in a
						decentralized manner, but in 2021, they established
						their first official office in Armenia. With the
						development of other projects and the number of BemChain
						active users reaching over 25 thousand, the foundation
						decided to establish another official office in
						Istanbul, Turkey, which is ranked fifth in trading and
						reception of the crypto market in the world ranking.
						This way, the foundation would place itself in a more
						competitive space and expand its work globally. The next
						goal of the BEM Foundation is to expand its support
						offices in Dubai until 2024, which will be the world's
						blockchain hub.
					</p>
					<h2 className='fw-bold'>Our Core Values</h2>
					<p>
						At MMP WORLD, we honor our ecosystem's commitment to
						completing and revealing projects and offering them to
						the market. We adhere to strict ethical standards and
						risk management policies to ensure the security of our
						users' investments. Our ultimate goal is to offer
						comprehensive financial services and help users to fund
						more ways to earn money.
					</p>
					<div className='text-center my-5'>
						<Anchor
							className='btn btn-lg btn-link'
							href='https://bemfoundation.com'
							content={
								<>
									<i className='fas fa-link me-2'></i>
									<span>
										Visit Bem Foundationan Official Webpage
									</span>
								</>
							}
						/>
					</div>

					{/* <ProjectsCarousel /> */}
				</div>
			</div>
		</div>
	),
};

export default about;
