import React from 'react';
import useMain from '../../../hooks/useMain';
import Anchor from '../../common/anchor';
import Spinner from '../../common/spinner';

function ResourceCard({ resource, isLoading, onDelete }) {
	const { title, imageLink, type, link, _id } = resource;
	const { userData } = useMain();
	const isResourceMine = userData.resources.includes(_id);

	return (
		<div className='card myCards text-center'>
			<img
				loading='lazy'
				src={imageLink}
				className='card-img-top'
				alt={_id}
				crossOrigin='true'
			/>

			<div className='card-body'>
				<div className='card-title mt-2'>
					<p className='h6'>
						<b>{title}</b>
					</p>
				</div>
				<div className='card-body py-0'>
					<p>Type: {type}</p>{' '}
					<Anchor content={'Download'} href={link} />
				</div>
				<hr />
				<button
					onClick={() => onDelete(_id)}
					className='btn btn-sm btn-my-navy'
					disabled={!isResourceMine || isLoading}>
					{isLoading ? <Spinner content={'loading'} /> : 'Delete'}
				</button>
			</div>
		</div>
	);
}

export default ResourceCard;
