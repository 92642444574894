import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import {
	FacebookIcon,
	FacebookShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import config from '../config';

function BlogTemplate({
	title,
	content,
	imageLink,
	_id,
	author,
	date,
	description,
}) {
	const { pathname } = useLocation();
	const shareURL = config.baseURL + pathname;

	return (
		<>
			<Helmet>
				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				<meta property='og:url' content={shareURL} />
				<meta property='og:image' content={imageLink} />
				<meta property='og:site_name' content='mmp.world' />
				<meta property='og:locale' content='en_US' />
				<meta property='og:type' content='website' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content={title} />
				<meta name='twitter:description' content={description} />
				<meta name='twitter:image' content={imageLink} />
			</Helmet>
			<div className='blog_header'>
				<div className='container py-5'>
					<div className='row py-5'>
						<div className='col-12 text-center'>
							<h1>
								<b>{title}</b>
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='row'>
					<div className='col-md-8 offset-md-2 text-center'>
						<img
							loading='lazy'
							src={imageLink}
							className='w-75 blog_image'
							alt={_id}
							crossOrigin='true'
						/>
					</div>
				</div>
				<div className='row py-5'>
					<div className='col-12'>
						<figure className='card-text text-center'>
							<blockquote className='blockquote'>
								<p className='fs-3'>{description}</p>
							</blockquote>
							<figcaption className='blockquote-footer mt-3'>
								<em>Written By : {author}</em> <br />
								<span className='fs-5'>
									{new Date(date).toLocaleDateString()}
								</span>
							</figcaption>
						</figure>
						<hr />
						{content}
					</div>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='d-flex justify-content-end'>
							<TwitterShareButton
								title={title}
								url={shareURL}
								hashtags={['mmpworld', 'bcn', 'BCN']}
								className='me-2'>
								<TwitterIcon size={32} round />
							</TwitterShareButton>
							<FacebookShareButton
								url={shareURL}
								quote={title}
								hashtag={'#mmpworld'}
								description={description}
								className='me-2'>
								<FacebookIcon size={32} round />
							</FacebookShareButton>
							<WhatsappShareButton
								url={shareURL}
								title={title}
								className='me-2'>
								<WhatsappIcon size={32} round />
							</WhatsappShareButton>
							<TelegramShareButton url={shareURL} title={title}>
								<TelegramIcon size={32} round />
							</TelegramShareButton>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BlogTemplate;
