import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../../hooks/useMain';
import http from '../../../../../services/httpService';
import P6Form from './p6Form';

const P6 = ({ onPrevBtnClick, totalData }) => {
	const { userData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (formData) => {
		const { securityPassword } = formData;
		const { p1, p2, p3, p4, p5 } = totalData;
		try {
			setIsLoading(true);
			const { data } = await http.pages({
				p1,
				p2,
				p3,
				p4,
				p5,
				securityPassword,
			});
			setIsLoading(false);
			onUserDataChanged(data.data);
			toast.info(data.message);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};
	return (
		<div className='row mb-5'>
			<div className='col-lg-8 offset-lg-2 col-12'>
				<div className='mb-1'>Page 6</div>
				<div className='fs-4 fw-bold mb-3'>Submitting Information</div>
				<div>
					If you apply for the service, 10% of your annual investment
					amount will be deducted for the service.
				</div>
				<P6Form
					hasSP={userData.hasSP}
					isLoading={isLoading}
					onSubmit={handleSubmit}
					onPrevBtnClick={onPrevBtnClick}
				/>
				<div className='fw-bold mt-5'>
					All investor information will be confidential with the
					company and it will not be disclosed to any organization.
				</div>
			</div>
		</div>
	);
};

export default P6;
