import Big from 'big.js';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { numberWithCommas } from '../../../../utils/helpers';
import CopyBtn from '../../../common/copyBtn';
import Image from '../../../common/image';
import ConfirmModal from '../../ingredients/confirmModal';
import DEFIActivationRequestForm from './defiActivationRequestForm';
import SubmitTransactionForm from './submitTransactionForm';

const DEFIInvestment = ({ history }) => {
	const { userData, onUserDataChanged, initData } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	const { defiConfig, trx_price } = initData;
	const { wallets, hasSP, activationRequests, defi } = userData;
	const activationRequest = activationRequests.find(
		(a) => a.activationType === 'defi'
	);
	const activeDEFIs = defi.filter((d) => d.isActive);

	const handleModalShow = (formData) => {
		setCurrentFormData(formData);
		const markup = (
			<>
				<div className='d-flex align-items-center mb-3'>
					<Image className='me-3' fileName='warning.png' />
					<div>DEFI Investment activation request:</div>
				</div>
				<div>
					You will need to deposit{' '}
					<b>
						{numberWithCommas(
							Big(formData.amount).div(Big(trx_price)).toFixed(6)
						)}{' '}
						TRX
					</b>{' '}
					within <b>{defiConfig.activationDeadline / (60 * 1000)}</b>{' '}
					minutes.
				</div>
				<div>Do you confirm?</div>
			</>
		);
		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handleActivationRequest();
	};

	const handleActivationRequest = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.defiActivationRequest(currentFormData);
			onUserDataChanged(data.data);
			toast.success(data.message);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmitTransaction = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.defiSubmitTransaction({
				...formData,
				...{
					activationId: activationRequest._id,
				},
			});
			onUserDataChanged(data.data);
			toast.success(data.message);
			setIsLoading(false);
			history.replace('/dashboard/defiWallet/activeInvestments');
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='card myCards p-4'>
			<div className='row my-4'>
				<div className='col-sm-6 offset-sm-3 col-12'>
					{!!activationRequest ? (
						<div className='text-center'>
							<div className='fs-5'>
								Please send{' '}
								<b>
									{activationRequest.data.depositAmount / 1e6}{' '}
									TRX
								</b>{' '}
								to the below address and submit the form
								provided with the transaction hash
							</div>
							<div className='my-4'>
								<QRCode
									value={activationRequest.data.address}
									level={'Q'}
									size={256}
								/>
							</div>
							<div>
								{activationRequest.data.address}
								<CopyBtn
									content={activationRequest.data.address}
								/>
							</div>
							<div className='mb-3 mt-1'>
								Amount :{' '}
								{activationRequest.data.depositAmount / 1e6}
								<CopyBtn
									content={
										activationRequest.data.depositAmount /
										1e6
									}
								/>
							</div>
							<Countdown
								className='fs-2 text-danger'
								date={activationRequest.deadline}
								onComplete={() => window.location.reload()}
							/>
							<SubmitTransactionForm
								hasSP={hasSP}
								isLoading={isLoading}
								onSubmit={handleSubmitTransaction}
							/>
						</div>
					) : (
						<>
							<div className='fs-2 mb-3 text-center'>
								DEFI Activation Request
							</div>
							<p>
								When the total amount collected in the DEFI
								wallet reaches 500 dollars, the user can request
								a transfer to DEFI. The transfer request to DEFI
								must be made by the user depositing $500 in TRX.
								In this way, the user deposits 500 dollars to
								his account and the project robot buys 500
								dollars TRX and 500 dollars BCN. Both balances
								are then transferred to the wallet that is
								automatically created for the user, and from
								there it is transferred to the sunswap.com
								liquidity pool and frozen for 1 year. Investors
								can see their balance through the "Watch
								Wallet", and after one year, the private key
								will be available to users.
							</p>
							<div className='fs-5 fw-bold my-2 text-center'>
								Current Pool: {defiConfig.currentToken}-TRX
							</div>
							<div className='fs-6 text-center'>
								My Active Investments: {activeDEFIs.length}
							</div>

							<DEFIActivationRequestForm
								trxRate={trx_price}
								walletBalance={wallets.defi.available / 1e6}
								min={defiConfig.min}
								max={
									parseInt(
										wallets.defi.available /
											1e6 /
											defiConfig.step
									) * defiConfig.step
								}
								step={defiConfig.step}
								hasSP={hasSP}
								isLoading={isLoading}
								onSubmit={handleModalShow}
							/>
						</>
					)}
					<div className='mt-5'>
						<b>Note:</b> Your investment will be securely locked in
						for a duration of 1 year. At the end of each period, you
						may request to receive the private key necessary to take
						control of your capital which is provided in the
						liquidity pool.
					</div>
				</div>
			</div>
			<ConfirmModal
				show={modalShow}
				onClose={handleModalClose}
				title={'DEFI Investment'}
				body={modalBody}
			/>
		</div>
	);
};

export default DEFIInvestment;
