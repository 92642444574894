const faq = [
	{
		title: 'From what countries can one invest in the MMP WORLD project?',
		content: (
			<div>
				The MMP WORLD project currently only supports and authenticates
				customers from Asia during the first phase of its launch.
			</div>
		),
	},
	{
		title: 'What is the minimum and maximum investment amount in the MMP WORLD project?',
		content: (
			<div>
				<ul>
					<li>Purple diamond debit card: $50 to $490</li>
					<li>Blue diamond debit card: $500 to $4,990</li>
					<li>Red diamond debit card: $5,000 to $49,990</li>
					<li>Black diamond debit card: $50,000 to $99,990</li>
					<li>Royal diamond debit card: $100,000 to $1,000,000</li>
				</ul>
			</div>
		),
	},
	{
		title: 'What is the monthly profit percentage in the MMP WORLD project?',
		content: (
			<div>
				<ul>
					<li>Purple diamond debit card: 4%</li>
					<li>Blue diamond debit card: 6%</li>
					<li>Red diamond debit card: 8%</li>
					<li>Black diamond debit card: 10%</li>
					<li>Royal diamond debit card: 12%</li>
				</ul>
			</div>
		),
	},
	{
		title: 'Is early termination of the contract possible in the MMP WORLD project?',
		content: (
			<div>
				The MMP WORLD project credit cards have a minimum one-year term,
				but investors can choose to terminate their credit cards earlier
				with a penalty:
				<ul>
					<li>0 to 183 days: 50% penalty</li>
					<li>183 to 365 days: 35% penalty</li>
				</ul>
				Termination is only possible for investors.
			</div>
		),
	},
	{
		title: 'How long are the MMP WORLD project contracts valid for?',
		content: (
			<div>
				The MMP WORLD project contracts have a minimum term of one year,
				but the maximum term varies depending on the maximum cap:
				<ul>
					<li>Purple diamond debit card: 300%</li>
					<li>Blue diamond debit card: 300%</li>
					<li>Red diamond debit card: 400%</li>
					<li>Black diamond debit card: 400%</li>
					<li>Royal diamond debit card: 500%</li>
				</ul>
			</div>
		),
	},
	{
		title: 'How can we invest in the MMP WORLD project?',
		content: (
			<div>
				To invest in the MMP WORLD project, simply fill out the
				information on the "Register" page.
			</div>
		),
	},
	{
		title: 'Which currencies can be used to invest in the MMP WORLD project?',
		content: (
			<div>
				To invest, you can use any of the currencies listed in the
				"Crypto Deposit" section. You can also use a Visa or Mastercard
				to charge your user account.
			</div>
		),
	},
	{
		title: 'Is it possible to increase your investment again on a diamond card?',
		content: <div>Yes, you can increase your investment at any time.</div>,
	},
	{
		title: 'Does our diamond card change by increasing our investment, and will we receive a higher monthly income?',
		content: (
			<div>
				Yes, by increasing your investment and reaching the values of
				higher packages, your earnings will increase based on the new
				package.
			</div>
		),
	},
	{
		title: 'How long will the MMP World project keep on accepting investments?',
		content: (
			<div>
				The MMP World project needs at least 25 billion dollars to lunch
				10 projects of the BEM Foundation. It will continue to raise
				funds and accept investments until the abovementioned amount is
				gathered.
			</div>
		),
	},
	{
		title: 'Where is the central office of the MMP World project?',
		content: (
			<div>
				The MMP World project is an international project, and its main
				office is in Yerevan, Armenia. The support office of the project
				is located in Istanbul, Turkey.
			</div>
		),
	},
	{
		title: 'Is there any personal support and guidance in the MMP World project?',
		content: (
			<div>
				Yes, after investing in the MMP World project, you can use
				personal support by going to the "Customer Support" section.
			</div>
		),
	},
	{
		title: 'Can you start your involvement in the project without a referral?',
		content: (
			<div>
				Yes, if you don't have a referral code, leave that box on the
				registry page empty and click on the "check" button. Please note
				that you will have no support line this way and will be directly
				involved in the project.
			</div>
		),
	},
	{
		title: 'What is the authentication in the MMP WORLD project for?',
		content: (
			<div>
				Authentication in the MMP WORLD is required to keep laundered
				and dirty money out of the project.
			</div>
		),
	},
	{
		title: 'Will our investment information be available to any third parties?',
		content: (
			<div>
				No personal information will be given to any third-party
				entities or organizations and stored along with confidential
				company documents somewhere off the cloud space.
			</div>
		),
	},
	{
		title: 'Do we have to pay taxes for the profit of investing in the MMP WORLD project?',
		content: (
			<div>
				No, in the countries approved for investing in the MMP WORLD
				project, there aren't any rules for taxing crypto-related
				incomes.
			</div>
		),
	},
	{
		title: 'How long will withdrawing the monthly interest or commission take?',
		content: (
			<div>
				To withdraw the monthly interest or commission, you must convert
				your current deposit to USDT via the internal exchange section,
				which will take 72 hours. The withdrawal process of USDT and its
				transfer to your personal wallet will take another 48 hours. So
				for every withdrawal, a maximum of 5 days is needed after the
				money has been transferred to your account.
			</div>
		),
	},
	{
		title: 'Why is the referral commission split into 80% cash and 20% voucher?',
		content: (
			<div>
				80% of the referral commission will be paid in cash, and a 20%
				voucher will be used for launching and running projects. It will
				eventually be withdrawable by the investors.
			</div>
		),
	},
	{
		title: 'Are the vouchers withdrawable?',
		content: (
			<div>
				Yes, there will be separate definitions for each voucher's
				wallet. After reaching the specified target, you can request a
				withdrawal.
			</div>
		),
	},
];

export default faq;
