import React from 'react';
import Countdown from 'react-countdown';
import './styles/digitalWatch.css';

const DigitalWatch = ({ className, text, date, onComplete }) => {
	return (
		<div className={className}>
			<div className='digitalWatch px-3 d-flex flex-wrap justify-content-start'>
				{text && (
					<>
						<div className='digitalWatch_text'>{text}</div>
						<div className='digitalWatch_sign'>{`>>>`}</div>
					</>
				)}
				<div className='digitalWatch_timer text-center'>
					<Countdown
						date={date}
						onComplete={onComplete}
					/>
				</div>
			</div>
		</div>
	);
};

export default DigitalWatch;
