import React from 'react';
import config from '../../../config';
import useMain from '../../../hooks/useMain';
import { DebtCard } from '../ingredients/cards';

const Debt = () => {
	const { userData } = useMain();
	const { official, corporate } = userData.debts;

	return (
		<div className='card myCards'>
			<div className='row'>
				<div className='col-12'>
					<div className='card-body text-center fs-3'>
						Total Remaining Debt: $ {userData.totalDebts / 1e6}
					</div>
				</div>
				<div className='col-md-6'>
					<DebtCard
						name='Corporate'
						debt={corporate}
						pageSize={config.tablePageSize}
					/>
				</div>
				<div className='col-md-6'>
					<DebtCard
						name='Official'
						debt={official}
						pageSize={config.tablePageSize}
					/>
				</div>
			</div>
		</div>
	);
};

export default Debt;
