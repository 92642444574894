import * as bootstrap from 'bootstrap';
import { useEffect, useState } from 'react';

export default function useCollapsible(ref) {
	const [myCollapsible, setMyCollapsible] = useState(undefined);
	const [showOthers, setShowOthers] = useState(true);
	const refCurrent = ref.current;

	useEffect(() => {
		if (refCurrent) {
			setMyCollapsible(
				new bootstrap.Collapse(refCurrent, {
					toggle: false,
				})
			);
		}
	}, [refCurrent]);

	useEffect(() => {
		if (myCollapsible) {
			myCollapsible._element.addEventListener('hidden.bs.collapse', () =>
				setShowOthers(true)
			);
			myCollapsible._element.addEventListener('show.bs.collapse', () => setShowOthers(false));
		}
		return () => {
			if (myCollapsible) {
				myCollapsible._element.removeEventListener('hidden.bs.collapse', () =>
					setShowOthers(true)
				);
				myCollapsible._element.removeEventListener('show.bs.collapse', () =>
					setShowOthers(false)
				);
			}
		};
	}, [myCollapsible]);

	return [myCollapsible, showOthers];
}
