const risks = {
	image: 'risks.jpg',
	path: '/risks',
	name: 'Risk Management',
	title: 'Risk Management',
	description:
		'As a financial platform that works officially and has a permit...',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<h2>Introduction to MMP WORLD</h2>
					<p>
						MMP WORLD is a financial platform that operates with
						official permits and strictly adheres to all laws in the
						blockchain industry. The platform employs reliable risk
						management solutions to ensure the security of users'
						investments.
					</p>
					<h2>Risk Management</h2>
					<p>
						MMP WORLD utilizes two main risk management solutions to
						comprehensively optimize its platform and protect the
						fundamental interests of its investors. Firstly, the
						platform uses open-source smart contracts that automate
						all sections of the platform, guaranteeing high speeds,
						reducing human error, and increasing the project's
						security. Secondly, MMP WORLD implements advanced grid
						trading technology to reduce the risk of trades and
						withdrawal of capital to a safe and controllable degree.
					</p>
					<h2>Development Plan</h2>
					<p>
						MMP WORLD has a unique development plan that ensures the
						stability and profitability of the project. The plan
						involves paying rational interests and rewards to active
						people in the project, paying interests to investors,
						rewarding staff, and returning investments. Raised funds
						are used for market making for the BEMCHAIN (BCN) token.
						This means that the investment goes directly into the
						BCN token market, increasing the token's price.
					</p>
					<p>
						Due to continued fundraising, the project profits from
						100% to 150% in each market. MMP WORLD offers various
						debit cards as a reward to investors, with 70% percent
						of the earned income in blue diamond debit cards and up
						to 120% in royal diamond debit cards. The project owners
						have full ownership of the currencies they do
						market-making on, which protects the raised fund from
						crypto whales, changing strategies of crypto owners, and
						sudden changes in market trends.
					</p>
					<p>
						The same strategy will be used in other projects that
						will be initiated in the next phases and with more
						funds. The raised funds will only be used to develop the
						projects, which have a guaranteed, risk-free, short-term
						return. Financial transparency is a major value of MMP
						WORLD and will remain so.
					</p>
					<p>
						MMP WORLD is a safe and certain way of investing in
						cryptocurrencies, providing a stable and profitable
						investment opportunity for its users.
					</p>
				</div>
			</div>
		</div>
	),
};

export default risks;
