import React, { useState } from 'react';
import useMain from '../../../../hooks/useMain';
import { paginate } from '../../../../utils/paginate';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import TokenTable from '../../ingredients/tokenTable';

const TokenPurchases = () => {
	const pageSize = 15;
	const { userData } = useMain();
	const { token } = userData;
	const [currentPage, setCurrentPage] = useState(1);

	const activeTokens = token.filter((d) => d.isActive);
	const data = paginate(activeTokens, currentPage, pageSize);

	return (
		<div className='card myCards'>
			<div className='row p-4'>
				<div className='col-md-10 offset-md-1 col-12'>
					<div className='fs-1 pt-4 text-center'>Purchases Table</div>
					<div className='fs-3 pb-5 text-center'>
						Active Purchases: {activeTokens.length}
					</div>
					{activeTokens.length > 0 ? (
						<div className='pb-5'>
							<TokenTable data={data} />
							<Pagination
								customClass='mt-1 myPagination'
								activeClass='myActive'
								totalCount={activeTokens.length}
								pageSize={pageSize}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					) : (
						<div className='text-center my-5'>
							<Image height='180px' fileName='icons/noData.svg' />
							<h4 className='mt-4'>There are no purchases.</h4>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TokenPurchases;
