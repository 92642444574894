import React from 'react';
import Image from '../../common/image';
import { subString } from '../../../utils/helpers';

const StarsPioneersTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Rank</th>
						<th>Email</th>
						<th>Team</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr
							className='align-middle'
							key={key}>
							<td>
								<Image
									height='20px'
									fileName={`stars/${d.no}.svg`}
								/>
							</td>
							<td>{subString(d.email, 8, 6, '.')}</td>

							<td>
								{d.team ? (
									<Image
										height='20px'
										fileName={`stars/${d.team}.svg`}
									/>
								) : (
									d.team
								)}
							</td>
							<td>{d.date ? new Date(d.date).toLocaleString() : d.date}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default StarsPioneersTable;
