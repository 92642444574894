import React from 'react';
import Image from '../../common/image';
import { subString } from '../../../utils/helpers';

const FlagshipBattleTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Rank</th>
						<th>UID</th>
						<th>Email</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{getRank(d.no)}</td>
							<td>{d.uid}</td>
							<td>{subString(d.email, 8, 6, '.')}</td>
							<td>{d.date ? new Date(d.date).toLocaleString() : d.date}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getRank = (no) => {
	if (no < 4)
		return (
			<Image
				height='20px'
				fileName={`flagship/${no}.svg`}
			/>
		);

	return no;
};

export default FlagshipBattleTable;
