import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import Image from '../../../common/image';
import InstallmentPaymentForm from './installmentPaymentForm';
import ConfirmModal from '../../ingredients/confirmModal';

const InstallmentPayment = ({ history }) => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	const { purchaseOptions, mmiConfig } = initData;
	const { mmi, wallets } = userData;
	const { installments, nextPayment } = mmi;
	const installment = installments.find((i) => i._id === nextPayment);

	const handleModalShow = (formData) => {
		if (!formData.isOverallBalanceEnough)
			return setErrors({
				amount: 'Not enough balance',
			});

		setCurrentFormData(formData);
		const markup = (
			<>
				<div className='d-flex align-items-center'>
					<Image className={'me-3'} fileName={'warning.png'} />
					<div>Your next installment will be paid:</div>
				</div>
				<ul className='my-4'>
					<li>
						Amount: <b>{installment.amount / 1e6} $</b>
					</li>
					<li>
						Installment Number: <b>{installment.number}</b>
					</li>
					<li>
						Due Date:{' '}
						<b>{new Date(installment.dueDate).toLocaleString()}</b>
					</li>
				</ul>
				<div>Do you confirm?</div>
			</>
		);
		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handlePayment();
	};

	const handlePayment = async () => {
		try {
			const { purchaseType, securityPassword } = currentFormData;
			setIsLoading(true);
			const { data } = await http.installmentPayment({
				paymentId: nextPayment,
				purchaseType,
				securityPassword,
			});
			onUserDataChanged(data.data);
			toast.success(data.message);
			setTimeout(() => {
				history.replace('/dashboard/mmi/installmentsTable');
			}, 1000);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='card myCards'>
			<div className='row p-4'>
				<div className='col-lg-6 offset-lg-3 mt-4'>
					{nextPayment ? (
						<>
							<div className='d-flex justify-content-evenly flex-wrap text-center mb-5 fs-5'>
								<div>
									<b>Awaiting Payment</b>
									<div>
										Installment Number {installment.number}
									</div>
								</div>
								<div>
									<b>Amount</b>
									<div>{installment.amount / 1e6} $</div>
								</div>
								<div>
									<b>Due Date</b>
									<div>
										{new Date(
											installment.dueDate
										).toLocaleString()}
									</div>
								</div>
								<div>
									<b>Deadline</b>
									<div>
										{mmiConfig.paymentDeadline /
											(60 * 60 * 1000)}{' '}
										hours
									</div>
								</div>
							</div>
							<InstallmentPaymentForm
								errors={errors}
								amount={installment.amount / 1e6}
								isLoading={isLoading}
								hasSP={userData.hasSP}
								options={purchaseOptions}
								activationBalance={
									wallets.activation.available / 1e6
								}
								discountBalance={
									wallets.discount.available / 1e6
								}
								onSubmit={handleModalShow}
							/>
						</>
					) : (
						<div className='text-center my-5'>
							<Image
								height={'180px'}
								fileName={'icons/noData.svg'}
							/>
							<h4 className='mt-4'>
								There is no available awaitng installment.
							</h4>
						</div>
					)}
				</div>
				<ConfirmModal
					show={modalShow}
					onClose={handleModalClose}
					title={'Installment Payment'}
					body={modalBody}
				/>
			</div>
		</div>
	);
};

export default InstallmentPayment;
