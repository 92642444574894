import React from 'react';

const InvestmentDashboardTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center caption-top'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Amount</th>
						<th>Interest</th>
						<th>This Month Profit</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => {
						return (
							<tr key={key}>
								<td>{d.no}</td>
								<td>{d.date}</td>
								<td>{d.cumulative || '$' + d.amount / 1e6}</td>
								<td>{d.percentage}%</td>
								<td>${d.profit}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default InvestmentDashboardTable;
