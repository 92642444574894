import Joi from 'joi-browser';
import React from 'react';
import Form from '../../../../common/form';
import Image from '../../../../common/image';

class SingleDocumentUploadForm extends Form {
	state = {
		data: {
			documentType: '',
			securityPassword: '',
			img: '',
		},
		errors: {},
		images: { img: [] },
		currentImageName: '',
		defaultImage: { img: null },
	};

	schema = {
		securityPassword: Joi.string().required().label('Security Password'),
		documentType: Joi.string()
			.valid(...this.props.options)
			.required()
			.label('Document Type'),
		img: Joi.string().required().label('Img'),
	};

	doSubmit = () => this.props.onSubmit(this.state);

	componentDidUpdate(prevProps, prevState) {}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, maxFileSize, acceptType, options, hasSP } = this.props;

		return (
			<form
				className='my-5'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderSelectNormal({
						name: 'documentType',
						label: 'Select Document Type',
						options,
					})}
				</div>
				<div className='mb-4'>
					{this.renderFileUpload({
						name: 'img',
						label: 'Document Image',
						maxFileSize,
						acceptType,
						formText: `Supported types are: ${acceptType.join(', ')}. Max file size: ${
							maxFileSize / 1024 ** 2
						} MB`,
						style: 'uploadBox',
						defaultImage: this.state.defaultImage.img,
					})}
				</div>
				<div className='text-center mb-4'>
					{this.renderSubmitBtnWithSPass({
						divClass: 'mt-3',
						hasSP,
						isLoading,
						text: (
							<div className='mx-3 centerAll'>
								<Image
									height='25px'
									fileName='icons/submit.svg'
								/>
								<span className='ms-2'>Submit</span>
							</div>
						),
						loadingText: 'Submitting...',
					})}
				</div>
			</form>
		);
	}
}

export default SingleDocumentUploadForm;
