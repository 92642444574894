import Joi from 'joi-browser';
import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../../../../common/form';
import Spinner from '../../../../common/spinner';
import Image from '../../../../common/image';

class P6Form extends Form {
	state = {
		data: {
			securityPassword: '',
			accept: '',
		},
		errors: {},
	};

	schema = {
		securityPassword: Joi.string().required().label('Security Password'),
		accept: Joi.boolean().valid(true).required().label('Accept'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP, onPrevBtnClick } = this.props;

		return (
			<form
				className='row mt-4 text-start'
				onSubmit={this.handleSubmit}>
				<div className='col-md-8 offset-md-2 col-12'>
					{this.renderChecks({
						name: 'accept',
						label: (
							<span>
								As the person filling out this MMI insurance service request form, I
								declare that all the answers I have given are completely correct and
								truthful and I have not hidden any information that may cause MMI
								insurance experts to make a mistake. I am aware that any
								misrepresentation and refusal to tell the truth is a legal
								authorization for MMI Insurance to cancel the policy subject of this
								service request form and will result in the loss of benefits for me.
							</span>
						),
					})}
				</div>

				<div className='col-md-8 offset-md-2 col-12 mt-4'>
					{this.renderInputGroupBtnEnd({
						name: 'securityPassword',
						label: 'Security Password',
						type: 'password',
						btnClass: 'btn btn-my-navy',
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
						formText: (
							<Link
								className='btn btn-link'
								to='/dashboard/myProfile/securityPassword'>
								Forgot password?
							</Link>
						),
					})}
					<div className='text-center mt-3'>
						<button
							type='button'
							onClick={onPrevBtnClick}
							className='btn btn-my-navy me-2 me-lg-4'>
							<div className='d-flex align-items-center justify-content-center'>
								<i className='fas fa-arrow-left'></i>
								<span className='ms-2'>Previous</span>
							</div>
						</button>
						{hasSP ? (
							this.renderButton(
								<div className='mx-3 d-flex align-items-center justify-content-center'>
									<span className='me-2'>Submit</span>
									<Image
										fileName='contract/submit.png'
										width='24px'
									/>
								</div>,
								`btn btn-my-navy`,
								isLoading && <Spinner content='Submitting...' />
							)
						) : (
							<Link
								className='text-danger fs-5'
								to='/dashboard/myProfile/securityPassword'>
								<em>Security Password</em> is not set, please set your{' '}
								<em>Security Password</em> and try again.
							</Link>
						)}
					</div>
				</div>
			</form>
		);
	}
}

export default P6Form;
