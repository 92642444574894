import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from './../../common/image';

function ReceiptModal({ data }) {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<i
				className='fas fa-receipt'
				onClick={handleShow}
				style={{ cursor: 'pointer' }}></i>

			<Modal show={show} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title className='d-flex aling-items-center'>
						<Image
							height={'40px'}
							className='me-2'
							fileName={'logo512.png'}
						/>
						<span className='fs-4'>Internal Transfer Receipt</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<b>Transfer Type:</b> {data.transferType}{' '}
					</div>
					<div>
						<b>Status:</b> Confirmed{' '}
						<i className='fas fa-check'></i>
					</div>
					<div>
						<b>Amount:</b> {data.amount / 1e6} USD
					</div>
					<div>
						<b>From:</b> {data.sender}{' '}
					</div>
					<div>
						<b>To:</b> {data.receiver}{' '}
					</div>
					<div>
						<b>Reamrks:</b> <em>{data.remarks}</em>{' '}
					</div>
					<div>
						<b>Date:</b> {new Date(data.date).toLocaleString()}{' '}
					</div>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-evenly align-items-center'>
					<button onClick={handleClose} className='btn btn-my-navy'>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ReceiptModal;
