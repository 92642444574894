import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import config from './config';
import App from './App';
import BackOffice from './BackOffice';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'bootstrap/dist/css/bootstrap.css';
import './scss/customBS.scss';
import './css/variables.css';
import './css/dashboard.css';
import './css/home.css';
import './css/blog.css';
import './css/backOffice.css';
import './css/navbar.css';
import './css/index.css';

const domains = {
	app: <App />,
	backOffice: <BackOffice />,
};

ReactDOM.render(
	<BrowserRouter>{domains[config.domain]}</BrowserRouter>,
	document.getElementById('root')
);

serviceWorker.unregister();
