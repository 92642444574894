import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import { TextCard } from '../../ingredients/cards';
import http from './../../../../services/httpService';
import Disable2faForm from './disable2faForm';
import Enable2faForm from './enable2faForm';
import Setup2faForm from './setup2faForm';
import VerifyCodeForm from './verifyCodeForm';

function GoogleAuthenticator() {
	const { userData, onUserDataChanged } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [OTP, setOTP] = useState({ base32: '', auth_url: '' });

	const setup2fa = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.generateOTP(formData);
			setOTP(data);
			setIsLoading(false);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
		}
	};

	const handleSubmitCode = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.verifyOTP(formData);
			onUserDataChanged(data.data);
			setIsLoading(false);
			toast.success(data.message);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const disable2fa = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.disableOTP(formData);
			onUserDataChanged(data.data);
			setIsLoading(false);
			toast.success(data.message);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const enable2fa = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.enableOTP(formData);
			onUserDataChanged(data.data);
			setIsLoading(false);
			toast.success(data.message);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const isNotVerified_noOTP = (
		<>
			<p>2FA is not activated for this account.</p>
			<p>Secure your account with TOTP two-factor Authentication.</p>
			<Setup2faForm
				isLoading={isLoading}
				hasSP={userData.hasSP}
				onSubmit={setup2fa}
			/>
		</>
	);

	const isNotVerified_OTP = (
		<>
			<h5 className='text-purple'>
				Configuring Google Authenticator or Authy
			</h5>
			<hr className='mt-0' />
			<ol>
				<li>
					Install Google Authenticator (IOS - Androird) or Authy (IOS
					- Androird)
				</li>
				<li>In the authenticator app, select "+" icon.</li>
				<li>
					Select "scan a barcode (or QR code)" and use the phone's
					camera to scan this barcode.
				</li>
			</ol>
			<h5 className='text-purple'>Scan QR Code</h5>
			<hr className='mt-0' />
			<div className='text-center mb-4'>
				<QRCode value={OTP.auth_url} level={'Q'} size={256} />
			</div>
			<h5 className='text-purple'>Or Enter Code Into Your App</h5>
			<hr className='mt-0' />
			<p>
				Secret Key: <b>{OTP.base32}</b> <br />
				<i>
					You need to keep this secret key in a safe place for later
					use.
				</i>
			</p>
			<h5 className='text-purple'>Verify Code</h5>
			<hr className='mt-0' />
			<VerifyCodeForm isLoading={isLoading} onSubmit={handleSubmitCode} />
		</>
	);

	const isVerified_isEnabled = (
		<>
			<p className='text-center mb-4'>
				<b>2FA is enabled!</b>
			</p>
			<h5 className='text-purple'>Disable 2FA</h5>
			<hr className='mt-0' />
			<Disable2faForm
				isLoading={isLoading}
				hasSP={userData.hasSP}
				onSubmit={disable2fa}
			/>
		</>
	);

	const isVerified_isNotEnabled = (
		<>
			<p className='text-center mb-4'>
				<b>2FA is disabled!</b>
			</p>
			<h5 className='text-purple'>Enable 2FA</h5>
			<hr className='mt-0' />
			<Enable2faForm
				isLoading={isLoading}
				hasSP={userData.hasSP}
				onSubmit={enable2fa}
			/>
		</>
	);

	return (
		<TextCard
			text={
				<div className='row my-5'>
					<div className='col-sm-4 offset-sm-4 col-12'>
						<h3>Mobile App Authentication (2FA)</h3>
						<hr className='mb-4' />
						{userData.otp.verified
							? userData.otp.enabled
								? isVerified_isEnabled
								: isVerified_isNotEnabled
							: OTP.auth_url
							? isNotVerified_OTP
							: isNotVerified_noOTP}
					</div>
				</div>
			}
		/>
	);
}

export default GoogleAuthenticator;
