import React from 'react';

const BreadcrumbsTabs = ({ root, crumbs, className }) => {
	if (crumbs.length < 1) {
		return null;
	}
	return (
		<nav className={className}>
			<ol className='breadcrumb' style={{ marginBottom: 0 }}>
				<li className='breadcrumb-item'>{root}</li>
				{crumbs.map((c, key) => (
					<li key={key} className={`breadcrumb-item`}>
						{c}
					</li>
				))}
			</ol>
		</nav>
	);
};
export default BreadcrumbsTabs;
