import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMain from '../../hooks/useMain';
import http from '../../services/httpService';
import Preloader from '../common/preloader';
import Image from './../common/image';
import ChangeEmailForm from './users/changeEmailForm';
import ChangeWalletForm from './users/changeWalletForm';
import GetUserForm from './users/getUserForm';
import SuspendForm from './users/suspendForm';

function Users() {
	useDocumentTitle(['Users']);
	const { initData } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState(null);
	if (!initData.domains) return <Preloader />;

	const handleChangeWallet = async (formData) => {
		try {
			setUser(null);
			setIsLoading(true);
			const { data } = await http.changeWallet(formData);
			toast.success(<div className='text-break'>{data.message}</div>);
			setUser(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data)
				toast.error(<div className='text-break'>{ex.response.data}</div>);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSuspend = async (formData) => {
		const { uid } = formData;
		const inDomains = [];
		const domains = initData.domains.map((a) => a.id);
		for (const i in domains) {
			const res = {};
			res.domain = domains[i];
			res.rule = formData[res.domain] === 'yes';
			inDomains.push(res);
		}

		try {
			setUser(null);
			setIsLoading(true);
			const { data } = await http.suspend({ uid, inDomains });
			toast.success(data.message);
			setUser(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeEmail = async (formData) => {
		try {
			setUser(null);
			setIsLoading(true);
			const { data } = await http.changeEmail(formData);
			toast.success(<div className='text-break'>{data.message}</div>);
			setUser(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data)
				toast.error(<div className='text-break'>{ex.response.data}</div>);
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetUser = async (formData) => {
		try {
			setUser(null);
			setIsLoading(true);
			const { data } = await http.getUser(formData);
			toast.success(data.message);
			setUser(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	const userTopKeys = user ? Object.keys(user.top) : [];
	const userMainKeys = user ? Object.keys(user.main) : [];
	const userMmpwKeys = user ? Object.keys(user.mmpw) : [];

	return (
		<div className='container my-4'>
			<div className='row g-5 mb-5'>
				<div className='col-12'>
					<div className='fw-bold fs-3 text-center'>Users Management</div>
				</div>

				<div className='col-lg-4'>
					<div className='fw-bold my-4 text-center'>Change Wallet</div>
					<ChangeWalletForm
						user={user}
						isLoading={isLoading}
						onSubmit={handleChangeWallet}
					/>
				</div>
				<div className='col-lg-4'>
					<div className='fw-bold my-4 text-center'>Suspend User</div>
					<SuspendForm
						user={user}
						onSubmit={handleSuspend}
						isLoading={isLoading}
						domains={initData.domains}
						options={[
							{
								id: 'yes',
								label: 'Yes',
								disabled: false,
							},
							{
								id: 'no',
								label: 'No',
								disabled: false,
							},
						]}
					/>
				</div>
				<div className='col-lg-4'>
					<div className='fw-bold my-4 text-center'>Change Email</div>
					<ChangeEmailForm
						user={user}
						isLoading={isLoading}
						onSubmit={handleChangeEmail}
					/>
				</div>
				<hr />
				<div className='offset-lg-2 col-lg-8'>
					<div className='fw-bold mb-4 text-center'>Get User</div>
					<GetUserForm
						user={user}
						isLoading={isLoading}
						onSubmit={handleGetUser}
						userMainProps={initData.userMainProps}
					/>
				</div>
			</div>
			{user && (
				<div className='row my-5'>
					<div className='col-12'>
						<div className='fw-bold mb-3 fs-5 text-center'>User:</div>
						<div className='d-flex flex-wrap align-items-center justify-content-center fs-5'>
							{userTopKeys.map((r, key) => (
								<div
									key={key}
									className='mx-5 my-3'>
									<div>
										<span className='me-1'>{r}: </span>
										<span className='fw-bold text-my-purple text-break'>
											{user.top[r]}
										</span>
									</div>
								</div>
							))}
						</div>
					</div>
					<hr className='my-4' />
					<div className='col-12'>
						<div className='d-flex align-items-center justify-content-center mb-4'>
							<Image
								height='32px'
								fileName='icons/bemchain.png'
							/>
							<div className='ms-2 fw-bold'>Bemchain Properties</div>
						</div>
						<div className='row gy-3 align-items-center'>
							{userMainKeys.map((r, key) => (
								<div
									key={key}
									className='col-md-3 col-lg-2'>
									<div className='d-flex flex-wrap'>
										<span className='me-1'>{r}: </span>
										<span className='fw-bold text-my-green text-break'>
											{user.main[r]}
										</span>
									</div>
								</div>
							))}
						</div>
					</div>
					<hr className='my-4' />
					<div className='col-12'>
						<div className='d-flex align-items-center justify-content-center mb-4'>
							<Image
								height='32px'
								fileName='icons/mmp.png'
							/>
							<div className='ms-2 fw-bold'>MMP Properties</div>
						</div>
						<div className='row gy-3 align-items-center'>
							{userMmpwKeys.map((r, key) => (
								<div
									key={key}
									className='col-md-3 col-lg-2'>
									<div className='d-flex flex-wrap'>
										<div className='me-1'>{r}: </div>
										<div className='fw-bold text-my-blue text-break'>
											{user.mmpw[r]}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Users;
