import React from 'react';
import Anchor from '../../common/anchor';
import Image from '../../common/image';
import { TextCard } from '../ingredients/cards';

function DownloadApp() {
	return (
		<TextCard
			titleClass={''}
			title={''}
			text={
				<>
					<div className='row'>
						<div className='col text-center'>
							<h4 className='my-5'>
								MMP application is for easier access of users to
								site services.
							</h4>
							<h5>
								This application is available in both Android &
								IOS
							</h5>
							<div className='d-flex justify-content-center my-5'>
								<Anchor
									href={'/comingSoon'}
									className='me-4'
									content={
										<Image
											className={'w-100'}
											fileName={'appStore.png'}
										/>
									}
								/>
								<Anchor
									href={'/comingSoon'}
									className='me-4'
									content={
										<Image
											className={'w-100'}
											fileName={'google.png'}
										/>
									}
								/>
								<Anchor
									href={'/download/mmp.apk'}
									className='me-4'
									content={
										<Image
											className={'w-100'}
											fileName={'apk.png'}
										/>
									}
								/>
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
}

export default DownloadApp;
