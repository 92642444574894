import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../hooks/useMain';
import http from '../../../services/httpService';
import { TextCard } from '../ingredients/cards';
import ConfirmModal from '../ingredients/confirmModal';
import Image from '../../common/image';
import EarlyTerminationForm from './earlyTerminationForm';

function EarlyTermination() {
	const { initData, userData, onUserDataChanged } = useMain();
	const isInvestor = userData.class === 'investor';
	const isBeingTerminated = userData.terminationRequest.isActive;
	const isTerminated = userData.terminationRequest.isApproved;

	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	const handleModalShow = (formData) => {
		setCurrentFormData(formData);
		const markup = (
			<div className='d-flex align-items-center'>
				<Image className={'me-3'} fileName={'warning.png'} />
				<div>
					Are you sure u want to terminate all of your contracts?{' '}
					<br />
					This action is not reversible.
				</div>
			</div>
		);
		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handleTerminate();
	};

	const handleTerminate = async () => {
		const { securityPassword } = currentFormData;
		try {
			setIsLoading(true);
			const { data } = await http.terminate({
				securityPassword,
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentFormData(null);
		}
	};

	return (
		<TextCard
			text={
				<div className='row g-5 mt-2 mb-4'>
					<div className='col-sm-4 text-center my-4'>
						<h4 className='mb-3'>MMI Termination Rules</h4>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>Termination Periods</th>
									<th>Refund Percentage</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>less than 1 year</td>
									<td>0</td>
								</tr>
								<tr>
									<td>1 ~ 2 Years</td>
									<td>50%</td>
								</tr>
								<tr>
									<td>2 ~ 3 Years</td>
									<td>70%</td>
								</tr>
								<tr>
									<td>more than 3 Years</td>
									<td>100%</td>
								</tr>
							</tbody>
							<caption>
								Refund details are based on your installments
								payment date
							</caption>
						</table>
					</div>

					<div className='col-sm-4 text-center my-4'>
						<h4 className='mb-3'>Investment Termination Rules</h4>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>Termination Periods</th>
									<th>Processing Fee</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>0 ~ 6 Months</td>
									<td>50%</td>
								</tr>
								<tr>
									<td>6 ~ 12 Months</td>
									<td>35%</td>
								</tr>
								<tr>
									<td>more than 12 Months</td>
									<td>0</td>
								</tr>
							</tbody>
							<caption>
								Fee details are based on your investment package
								purchase date
							</caption>
						</table>
					</div>

					<div className='col-sm-4 text-center my-4'>
						<h4 className='mb-3'>Corporate Debts Rules</h4>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>Termination Periods</th>
									<th>Corporate Debt</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>0 ~ 365 Days</td>
									<td>75%</td>
								</tr>
								<tr>
									<td>365 ~ 730 Days</td>
									<td>50%</td>
								</tr>
								<tr>
									<td>more than 730 Days</td>
									<td>0</td>
								</tr>
							</tbody>
							<caption>
								<em>
									<b>Sponsor</b>
								</em>{' '}
								and{' '}
								<em>
									<b>Binary</b>
								</em>{' '}
								commissions are deducted from consultants'
								accounts upon contract termination. <b>50%</b>{' '}
								of any commission or investment profit is used
								to settle the contract termination debt.
							</caption>
						</table>
					</div>

					<div className='col-12 text-center my-4'>
						{isBeingTerminated && (
							<p className='text-danger fs-4'>
								Your termination request is being reviewed!
							</p>
						)}
						{isTerminated && (
							<p className='text-danger fs-4'>
								Your termination request is approved and your
								account is terminated.
							</p>
						)}
						<p>
							Early Termination rules are only available for the
							Investors who have not used the income generating
							plan.
						</p>
						<h5>
							Your Current Class:
							<b> {capitalize(userData.class)}</b>
						</h5>
					</div>
					<div className='col-sm-4 offset-sm-4 text-center my-4'>
						{!userData.isUserActive ? (
							<p>You do not have any active contract.</p>
						) : isInvestor ? (
							<>
								<div className='text-start'>
									Note: <br />
									<ul>
										<li>This process is not reversible.</li>
										<li>
											your request will be reviewed in 10
											working days.
										</li>
									</ul>
								</div>
								{initData.terminationRequest ? (
									<EarlyTerminationForm
										isLoading={isLoading}
										hasSP={userData.hasSP}
										onSubmit={handleModalShow}
									/>
								) : (
									<div className='text-danger'>
										Termination is not active at the moment,
										please try again later.
									</div>
								)}
							</>
						) : (
							<p>
								Early termination is only active for Investors
							</p>
						)}
					</div>

					<ConfirmModal
						show={modalShow}
						onClose={handleModalClose}
						title={'Early Termination'}
						body={modalBody}
					/>
				</div>
			}
		/>
	);
}

export default EarlyTermination;
