import { Big } from 'big.js';
import React from 'react';
import Marquee from 'react-fast-marquee';
import useMain from '../hooks/useMain';
import Image from './common/image';

const Prices = () => {
	const { prices } = useMain();
	if (!prices.currentPrice) return null;
	const list = [
		{
			token: 'bcn',
			price: prices.bcn_price,
			unit: 'trx',
		},
		{
			token: 'tim',
			price: prices.tim_price,
			unit: 'trx',
		},
		{
			token: 'bems',
			price: prices.currentPrice / 1e6,
			unit: 'trx',
		},
		{
			token: 'bsm',
			price: Number(Big(prices.bcn_price).mul(10).toFixed(6)),
			unit: 'trx',
		},
		{
			token: 'bcn',
			price: prices.bcn_price_usd,
			unit: 'usdt',
		},
		{
			token: 'tim',
			price: prices.tim_price_usd,
			unit: 'usdt',
		},
		{
			token: 'bems',
			price: Number(
				Big(prices.currentPrice).mul(prices.trx_price).div(Big(10).pow(6)).toFixed(6)
			),
			unit: 'usdt',
		},
		{
			token: 'bsm',
			price: Number(Big(prices.bcn_price_usd).mul(10).toFixed(6)),
			unit: 'usdt',
		},
	];
	return (
		<div className='prices py-1'>
			<Marquee
				pauseOnHover={true}
				autoFill={true}
				speed={40}>
				{list.map((l, key) => (
					<div
						key={key}
						className='centerAll px-5'>
						<Image
							height='25px'
							fileName={`tokens/${l.token}.svg`}
						/>
						<div className='mx-1'>{l.token.toUpperCase()} : </div>
						<div>
							{l.price} {l.unit}{' '}
						</div>
					</div>
				))}
			</Marquee>
		</div>
	);
};

export default Prices;
