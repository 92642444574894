import React from 'react';
import Hash from '../../common/hash';

const WalletTransactiosTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Type</th>
						<th>Amount</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{d.no}</td>
							<td>{new Date(d.date).toLocaleString()}</td>
							<td>{d.type}</td>
							<td
								className={`text-${
									d.type === 'Withdraw' ? 'danger' : 'success'
								}`}>
								{d.type === 'Withdraw'
									? -d.amount / 1e6
									: d.amount / 1e6}{' '}
								USDT
							</td>
							<td>
								{d.hash ? (
									<Hash hash={d.hash} color={'blue'} />
								) : (
									d.action
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default WalletTransactiosTable;
