import { capitalize } from 'lodash';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useMain from '../../hooks/useMain';

function MaxCapModal() {
	const { initData } = useMain();
	const cards = Object.keys(initData.cards).filter((c) => c !== 'white');

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<div onClick={handleShow} style={{ cursor: 'pointer' }}>
				<i className='far fa-question-circle'></i>
			</div>

			<Modal show={show} onHide={handleClose} size='lg' centered>
				<Modal.Header closeButton>
					<Modal.Title className='fs-2'>Max Cap</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Max cap rules:</h4>
					<div className='table-responsive'>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>Investment Package</th>
									<th>Value</th>
									<th>Max Capped of Total Bonuses</th>
								</tr>
							</thead>
							<tbody>
								{cards.map((c, key) => (
									<tr key={key}>
										<td>
											{`${capitalize(c)} Diamond Card`}
										</td>
										<td>
											{initData.cards[c].min / 1e6} to{' '}
											{initData.cards[c].max / 1e6}
										</td>
										<td>
											{initData.cards[c].maxCap}% of total
											investment value
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<h5>When the Max Cap is full:</h5>
					<p>
						When your max cap is filled, you need to buy the package
						again.
					</p>
					<ul>
						<li>
							If you upgrade your package before the Max cap is
							filled, your purchase will be combined with the
							previous purchase and the profit calculation rules
							will change based on the upgraded amount.
						</li>
						<li>
							If you buy a new package after the Max cap is
							filled, your package value will be equal only to
							your new purchase amount. If your Max cap is filled
							and you want to reinvest, the purchase amount must
							be equal or greater than your last active package.
						</li>
					</ul>
					<h5>Note:</h5>
					<p className='mb-0'>
						When the Max cap is profitable and you don't renew your
						package, your position will turn into a White card.{' '}
						<br />
						In this case, your points and sales of your team becomes
						zero and the following features will be disabled for
						you:
					</p>
					<ul>
						<li> Internal Exchange</li>
						<li> Internal Transfer</li>
						<li> Early Termination</li>
					</ul>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-evenly align-items-center'>
					<button onClick={handleClose} className='btn btn-my-navy'>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default MaxCapModal;
