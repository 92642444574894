import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	section: {
		marginTop: 14,
	},
	formSubtitle: {
		marginLeft: 40,
		marginBottom: 4,
	},
	formBox: {
		border: '1px solid black',
		padding: 8,
	},
	formRow: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingBottom: 10,
	},
	formRowLast: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingBottom: 0,
	},
	formInputContainer1: {
		width: 320,
		flexDirection: 'row',
	},
	formInputContainer2: {
		width: 220,
		flexDirection: 'row',
	},
	formInputLabel: {
		paddingRight: 4,
	},
});

const MmiInsurancePolicy = ({ personalData }) => (
	<View style={styles.section}>
		<Text style={styles.formSubtitle}>Insurance Policy</Text>
		<View style={styles.formBox}>
			<View style={styles.formRow}>
				<View style={styles.formInputContainer1}>
					<Text style={styles.formInputLabel}>
						Insurance Policy Number:
					</Text>
					<Text style={styles.formInput}>
						{personalData.ipNumber}
					</Text>
				</View>
				<View style={styles.formInputContainer2}>
					<Text style={styles.formInputLabel}>Issue Date:</Text>
					<Text style={styles.formInput}>
						{personalData.issueDate}
					</Text>
				</View>
			</View>
			<View style={styles.formRowLast}>
				<View style={styles.formInputContainer1}>
					<Text style={styles.formInputLabel}>Contract Period:</Text>
					<Text style={styles.formInput}>
						{personalData.contractPeriod}
					</Text>
				</View>
				<View style={styles.formInputContainer2}>
					<Text style={styles.formInputLabel}>Completion Date:</Text>
					<Text style={styles.formInput}>
						{personalData.completionDate}
					</Text>
				</View>
			</View>
		</View>
	</View>
);

export default MmiInsurancePolicy;
