import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useKYCData from '../../../../../hooks/useKYCData';
import useMain from '../../../../../hooks/useMain';
import jsonToFormData from '../../../../../utils/jsonToFormData';
import http from '../../../../../services/httpService';
import Preloader from '../../../../common/preloader';
import BasicPersonalInfoForm from './basicPersonalInfoForm';

const BasicPersonalInfo = () => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [KYCData] = useKYCData();
	const [isLoading, setIsLoading] = useState(false);
	const [currentIDType, setCurrentIDType] = useState(undefined);
	const [currentHeirIDType, setCurrentHeirIDType] = useState(undefined);
	if (!KYCData || !userData._id) return <Preloader />;

	const { verificationBasicInfo } = userData;
	const children = Object.values(KYCData).reduce((acc, curr) => acc.concat(curr), []);
	const formStateData = {};
	for (const i in children)
		formStateData[children[i].name] =
			verificationBasicInfo && verificationBasicInfo[children[i].name]
				? verificationBasicInfo[children[i].name]
				: children[i].name === 'idType' || children[i].name === 'heirIdType'
				? 'National ID'
				: children[i].name === 'issuingCountry' || children[i].name === 'heirIssuingCountry'
				? 'Iran, Islamic Republic of'
				: '';

	const idTypeValue =
		currentIDType || (verificationBasicInfo && verificationBasicInfo.idType) || 'National ID';
	const heirIdTypeValue =
		currentHeirIDType ||
		(verificationBasicInfo && verificationBasicInfo.heirIdType) ||
		'National ID';

	const handleSelectChange = ({ currentTarget: input }) => {
		if (input.name === 'issuingCountry')
			setCurrentIDType(
				input.value === 'Iran, Islamic Republic of' ? 'National ID' : 'Passport'
			);
		if (input.name === 'heirIssuingCountry')
			setCurrentHeirIDType(
				input.value === 'Iran, Islamic Republic of' ? 'National ID' : 'Passport'
			);
	};

	const handleSubmit = async (formState) => {
		try {
			setIsLoading(true);
			const myData = { ...formState.data };

			const imagesKeys = Object.keys(formState.images);
			for (const i in imagesKeys) delete myData[imagesKeys[i]];

			const formData = jsonToFormData(myData);

			for (const i in imagesKeys)
				if (formState.images[imagesKeys[i]].length === 1) {
					console.log(imagesKeys[i]);
					console.log(formState.images[imagesKeys[i]][0]);
					formData.append(imagesKeys[i], formState.images[imagesKeys[i]][0].file);
				}

			const { data } = await http.setBasicPersonalInfo(formData);
			setIsLoading(false);
			onUserDataChanged(data.data);
			toast.info(data.message);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='row mb-5'>
			<div className='col-lg-10 offset-lg-1 col-12'>
				<div className='fs-4 fw-bold mb-3 text-center'>Personal Information Form</div>
				<BasicPersonalInfoForm
					hasSP={userData.hasSP}
					isLoading={isLoading}
					data={KYCData}
					titles={Object.keys(KYCData)}
					formStateData={formStateData}
					maxFileSize={initData.formOptions.maxFileSize}
					acceptType={initData.formOptions.acceptType}
					children={Object.values(KYCData).reduce((acc, curr) => acc.concat(curr), [])}
					gender={initData.formOptions.gender}
					heirGender={initData.formOptions.gender}
					maritalStatus={initData.formOptions.maritalStatus}
					employmentStatus={initData.formOptions.employmentStatus}
					preferredContactMethod={initData.formOptions.preferredContactMethod}
					idType={initData.formOptions.idType}
					heirIdType={initData.formOptions.idType}
					country={initData.countries.map((c) => c.name)}
					issuingCountry={initData.countries.map((c) => c.name)}
					heirIssuingCountry={initData.countries.map((c) => c.name)}
					idTypeValue={idTypeValue}
					heirIdTypeValue={heirIdTypeValue}
					onSelectChange={handleSelectChange}
					onSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default BasicPersonalInfo;
