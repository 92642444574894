import React from 'react';
import Countdown from 'react-countdown';
import useMain from '../../../hooks/useMain';
import Hash from '../../common/hash';

const TokenTable = ({ data }) => {
	const { initData } = useMain();
	const { defiConfig } = initData;

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Number</th>
						<th>Address</th>
						<th>Activation Date</th>
						<th>Release Date</th>
						<th>Token</th>
						<th>Amount</th>
						<th>Transaction</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => {
						const releaseDate =
							new Date(d.activationDate).getTime() +
							defiConfig.period;
						return (
							<tr key={key}>
								<td>{Number(key) + 1}</td>
								<td>{d.address}</td>
								<td>
									{new Date(
										d.activationDate
									).toLocaleString()}
								</td>
								<td>
									{Date.now() < releaseDate ? (
										<Countdown
											className=''
											date={releaseDate}
											onComplete={() =>
												window.location.reload()
											}
										/>
									) : (
										<button
											disabled
											className='btn btn-sm btn-primary'>
											Request PK
										</button>
									)}
								</td>
								<td>{d.token}</td>
								<td>{d.tokenAmount / 1e6}</td>
								<td>
									<Hash hash={d.tokenTransaction} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default TokenTable;
