import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import useMain from '../hooks/useMain';
import http from './../services/httpService';
import Image from './common/image';
import Preloader from './common/preloader';
import Spinner from './common/spinner';

const Email = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { initData, userData, onUserDataChanged } = useMain();
	if (!initData.emailGap || !userData._id) return <Preloader />;
	if (userData.emailStatus === 'Active') return <Redirect to={'/'} />;

	const isEmailAllowed = Date.now() > userData.lastEmail + initData.emailGap;

	const handleClick = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.requestActivation();
			onUserDataChanged(data);
			toast.info('Eamil sent, please check your inbox');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const onRequestTimerCompleted = async () => {
		try {
			const { data } = await http.getOnlyMe();
			onUserDataChanged(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<div className='text-center'>
			<Image className={'w-50'} fileName={`error.png`} />
			<h2>Email Is Not Confirmed!</h2>
			<p>
				Please check your inbox and confirm your email. <br />
				Remember to check the spam folder as well.{' '}
			</p>
			<button
				type='button'
				className='btn btn-lg btn-my-navy'
				onClick={handleClick}
				disabled={!isEmailAllowed || isLoading}>
				{isEmailAllowed ? (
					isLoading ? (
						<Spinner content={'Requesting...'} />
					) : (
						'Send the activation link'
					)
				) : (
					<Countdown
						date={userData.lastEmail + initData.emailGap}
						onComplete={onRequestTimerCompleted}
					/>
				)}
			</button>
		</div>
	);
};

export default Email;
