import React from 'react';
import { Redirect } from 'react-router-dom';
import useBlog from './../../../hooks/useBlog';
import BlogTemplate from '../../blogTemplate';
import Preloader from '../../common/preloader';
import { StringToJSX } from './../../common/stringToJSX';

function BlogPreview(props) {
	const _id = props.match.params._id;
	const [blog] = useBlog(_id);

	if (!blog) return <Preloader />;
	if (!_id || blog === 'notFound') return <Redirect to={'/blogs'} />;
	return (
		<BlogTemplate
			title={blog.title}
			description={blog.description}
			content={<StringToJSX domString={blog.content} />}
			imageLink={blog.imageLink}
			_id={blog._id}
			author={blog.author.profile.fullName}
			date={blog.date}
		/>
	);
}

export default BlogPreview;
