import useImage from '../../hooks/useImage';

const Image = ({
	loadMode = 'lazy',
	fileName,
	alt,
	className = '',
	...rest
}) => {
	const { loading, error, image } = useImage(fileName);

	if (error) return alt ? alt : '';

	return (
		<>
			{loading ? (
				loading
			) : (
				<img
					loading={loadMode}
					className={className}
					src={image}
					alt={alt ? alt : fileName}
					{...rest}
				/>
			)}
		</>
	);
};

export default Image;
