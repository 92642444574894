import Joi from 'joi-browser';
import React, { Fragment } from 'react';
import Image from '../../../../common/image';
import IDUploader from './idUploader';

class BasicPersonalInfoForm extends IDUploader {
	state = {
		data: {
			...{
				securityPassword: '',
				accuracy: '',
				aml: '',
				selfPassport: '',
				heirPassport: '',
				selfIdFront: '',
				selfIdBack: '',
				heirIdFront: '',
				heirIdBack: '',
			},
			...this.props.formStateData,
		},
		errors: {},
		images: {
			selfPassport: [],
			heirPassport: [],
			selfIdFront: [],
			selfIdBack: [],
			heirIdFront: [],
			heirIdBack: [],
		},
	};

	schema = {
		securityPassword: Joi.string().required().label('Security Password'),
		accuracy: Joi.boolean()
			.valid(true)
			.required()
			.label('Accuracy and Truthfulness Declaration'),
		aml: Joi.boolean().valid(true).required().label('AML'),
		selfPassport: Joi.string().required().allow('').label('Passport'),
		heirPassport: Joi.string().required().allow('').label('Heir Passport'),
		selfIdFront: Joi.string().required().allow('').label('Id Card Front'),
		selfIdBack: Joi.string().required().allow('').label('Id Card Back'),
		heirIdFront: Joi.string().required().allow('').label('Heir Id Card Front'),
		heirIdBack: Joi.string().required().allow('').label('Heir Id Card Back'),
	};

	doSubmit = () => this.props.onSubmit(this.state);

	componentDidMount() {
		const { children } = this.props;
		for (const i in children) {
			let expression = Joi.string().label(children[i].label);
			if (children[i].type === 'email') expression = expression.email();
			if (children[i].type === 'select')
				expression = expression.valid(...this.props[children[i].name]);
			this.schema[children[i].name] = expression;
		}
		if (this.props.idTypeValue === 'Passport') {
			this.schema.selfPassport = Joi.string().required().label('Passport');
			this.schema.selfIdFront = Joi.string().required().allow('').label('Id Card Front');
			this.schema.selfIdBack = Joi.string().required().allow('').label('Id Card Back');
		}
		if (this.props.idTypeValue === 'National ID') {
			this.schema.selfPassport = Joi.string().required().allow('').label('Passport');
			this.schema.selfIdFront = Joi.string().required().label('Id Card Front');
			this.schema.selfIdBack = Joi.string().required().label('Id Card Back');
		}
		if (this.props.heirIdTypeValue === 'Passport') {
			this.schema.heirPassport = Joi.string().required().label('Heir Passport');
			this.schema.heirIdFront = Joi.string().required().allow('').label('Heir Id Card Front');
			this.schema.heirIdBack = Joi.string().required().allow('').label('Heir Id Card Back');
		}
		if (this.props.heirIdTypeValue === 'National ID') {
			this.schema.heirPassport = Joi.string().required().allow('').label('Heir Passport');
			this.schema.heirIdFront = Joi.string().required().label('Heir Id Card Front');
			this.schema.heirIdBack = Joi.string().required().label('Heir Id Card Back');
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.idTypeValue !== this.props.idTypeValue) {
			const state = { ...this.state };
			state.data.idType = this.props.idTypeValue;
			this.setState(state);
			if (this.props.idTypeValue === 'Passport') {
				this.schema.selfPassport = Joi.string().required().label('Passport');
				this.schema.selfIdFront = Joi.string().required().allow('').label('Id Card Front');
				this.schema.selfIdBack = Joi.string().required().allow('').label('Id Card Back');
			}
			if (this.props.idTypeValue === 'National ID') {
				this.schema.selfPassport = Joi.string().required().allow('').label('Passport');
				this.schema.selfIdFront = Joi.string().required().label('Id Card Front');
				this.schema.selfIdBack = Joi.string().required().label('Id Card Back');
			}
		}
		if (prevProps.heirIdTypeValue !== this.props.heirIdTypeValue) {
			const state = { ...this.state };
			state.data.heirIdType = this.props.heirIdTypeValue;
			this.setState(state);
			if (this.props.heirIdTypeValue === 'Passport') {
				this.schema.heirPassport = Joi.string().required().label('Heir Passport');
				this.schema.heirIdFront = Joi.string()
					.required()
					.allow('')
					.label('Heir Id Card Front');
				this.schema.heirIdBack = Joi.string()
					.required()
					.allow('')
					.label('Heir Id Card Back');
			}
			if (this.props.heirIdTypeValue === 'National ID') {
				this.schema.heirPassport = Joi.string().required().allow('').label('Heir Passport');
				this.schema.heirIdFront = Joi.string().required().label('Heir Id Card Front');
				this.schema.heirIdBack = Joi.string().required().label('Heir Id Card Back');
			}
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP, data, onSelectChange, maxFileSize, acceptType } = this.props;
		const titles = Object.keys(data);
		return (
			<form
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				{titles.map((t, key) => (
					<Fragment key={key}>
						<div className='row row-cols-lg-4 row-cols-1 g-2 my-4 text-start'>
							<div className='col-lg-12 fw-bold text-my-blue'>{t}</div>
							{data[t].map((d, k) => (
								<div
									key={k}
									className={`col-lg-${d.size * 3}`}>
									{d.type === 'select' &&
									d.name !== 'idType' &&
									d.name !== 'heirIdType'
										? this.renderSelectNormal({
												name: d.name,
												label: d.label,
												options: this.props[d.name] || [],
												extraHandler: onSelectChange,
										  })
										: d.name === 'idType'
										? this.renderInput({
												readOnly: d.name === 'idType',
												name: d.name,
												label: d.label,
												type: d.type,
										  })
										: this.renderInput({
												readOnly: d.name === 'heirIdType',
												name: d.name,
												label: d.label,
												type: d.type,
										  })}
								</div>
							))}
						</div>

						{t === 'ID Card Information'
							? this.renderIDUploader({
									side: 'self',
									type: this.props.idTypeValue,
									maxFileSize,
									acceptType,
							  })
							: null}
						{t === 'Heir ID Card Information'
							? this.renderIDUploader({
									side: 'heir',
									type: this.props.heirIdTypeValue,
									maxFileSize,
									acceptType,
							  })
							: null}
						<hr />
					</Fragment>
				))}

				<div className='row g-2 my-4'>
					<div className='col-12'>
						{this.renderChecks({
							name: 'accuracy',
							label: (
								<span>
									I declare that all my answers are accurate and truthful. I
									understand that any misrepresentation or refusal to provide
									truthful information authorizes MMP.world to cancel the policy
									related to this service request, resulting in the loss of my
									benefits.
								</span>
							),
						})}
					</div>
					<div className='col-12'>
						{this.renderChecks({
							name: 'aml',
							label: (
								<span>
									I hereby declare that I am aware of and accept all anti-money
									laundering (AML) regulations.
								</span>
							),
						})}
					</div>
					<div className='col-lg-6 offset-lg-3 text-center'>
						{this.renderSubmitBtnWithSPass({
							divClass: 'mt-3',
							hasSP,
							isLoading,
							text: (
								<div className='mx-3 centerAll'>
									<Image
										height='25px'
										fileName='icons/submit.svg'
									/>
									<span className='ms-2'>Submit</span>
								</div>
							),
							loadingText: 'Submitting...',
						})}
					</div>
				</div>
			</form>
		);
	}
}

export default BasicPersonalInfoForm;
