import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import DashboardCaption from './DashboardCaption';
import DashboardTable from './DashboardTable';
import DashboardTitle from './DashboardTitle';

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
		lineHeight: 1,
		flexDirection: 'column',
	},
});

const Dashboard = ({ covers, title }) => {
	return (
		<Document>
			<Page size='A4' orientation='landscape' style={styles.page}>
				<DashboardTitle title={title} />
				<DashboardTable covers={covers} />
				<DashboardCaption />
			</Page>
		</Document>
	);
};

export default Dashboard;
